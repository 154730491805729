/*
 * @Author: sqk
 * @Date: 2020-08-07 13:28:41
 * @LastEditTime: 2022-05-15 10:53:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\utils.js
 */
import { saveAs } from "file-saver";
import CryptoJS from "crypto-js";
let Util = {};

export default Util;
//========================ant design 表单验证规则==============================//
// 数字验证
(Util.NumberValidator = (rule, value, callback) => {
  const idcardReg = /^[0-9]*$/;
  if (!idcardReg.test(value)) {
    callback("请输入数字");
  }
  // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
  callback();
}),
  (Util.NumberValidatorBank = (rule, value, callback) => {
    const idcardReg = /^[0-9]*$/;
    if (!idcardReg.test(value)) {
      callback("请输入正确的银行卡号");
    }
    // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
    callback();
  }),
  // 手机号验证
  (Util.MobileNumberValidator = (rule, value, callback) => {
    const idcardReg = /^1(3|4|5|6|7|8|9)\d{9}$/;
    if (!idcardReg.test(value)) {
      callback("请输入正确的手机号");
    }
    // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
    callback();
  }),
  // 邮箱验证=>
  (Util.EmailValidator = (rule, value, callback) => {
    const idcardReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
    if (!idcardReg.test(value)) {
      callback("请输入正确的邮箱地址");
    }
    // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
    callback();
  }),

  // 邮编验证=>
  (Util.ZipCodeValidator = (rule, value, callback) => {
    const reg = /^[0-9][0-9]{5}$/;
    if (!reg.test(value)) {
      callback("请输入正确的邮编");
    }
    // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
    callback();
  }),
  
  // 银行卡号验证=>
  (Util.BankCardValidator = (rule, value, callback) => {
    const reg = /^([1-9]{1})(\d{15}|\d{16}|\d{17}|\d{18})$/;
    if (!reg.test(value)) {
      callback("请输入正确的银行卡号");
    }
    // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
    callback();
  }),
  // 身份证验证

  (Util.IDValidator = (rule, value, callback) => {
    // const idcardReg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
    //弱验证
    const idcardReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|x|X)$/;
    // if (!idcardReg.test(value)) {
    //   callback("请输入正确的身份证号");
    // }

    /**
     * @desc 校验身份证号是否合法
     * @param {String} idCardNum 18 位身份证号
     * @return {Boolean}
     */
    function validateIdCardNum(idCardNum) {
      // 非法字符串
      if (typeof idCardNum !== "string") return false;
      // 所有身份证前两位代表的是地区
      const city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外",
      };
      const birthday =
        idCardNum.substr(6, 4) +
        "/" +
        Number(idCardNum.substr(10, 2)) +
        "/" +
        Number(idCardNum.substr(12, 2));
      const d = new Date(birthday);
      const newBirthday =
        d.getFullYear() +
        "/" +
        Number(d.getMonth() + 1) +
        "/" +
        Number(d.getDate());
      const currentTime = new Date().getTime();
      const time = d.getTime();
      // 身份证系数列表
      const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      // 设定身份证通过相加运算得到值对应的身份证最后一位的列表
      const arrCh = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
      let sum = 0;
      // 非法身份证
      if (!/^\d{17}(\d|x)$/i.test(idCardNum)) return false;
      // 非法地区 非法地区
      if (city[idCardNum.substr(0, 2)] === undefined) return false;
      // 非法生日
      if (time >= currentTime || birthday !== newBirthday) return false;

      // 计算当前身份证最后一位的值
      for (let i = 0; i < 17; i++) {
        sum += idCardNum.substr(i, 1) * arrInt[i];
      }

      const residue = arrCh[sum % 11];
      // 非法身份证哦
      if (residue !== idCardNum.substr(17, 1)) return false;

      return true;
    }
    if (!validateIdCardNum(value)) {
      callback("请输入正确的身份证号");
    }

    // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
    callback();
  }),
  //========================ant design 表单验证规则 End==============================//
  //js 中判断某个元素是否存在于某个 js 数组中 = >  isInArray(arr, "test"); // 判断 test 字符串是否存在于 arr 数组中，存在返回true 否则false，此处将返回trueue
  (Util.isInArray = (arr, val) => {
    let testStr = "," + arr.join(",") + ",";
    return testStr.indexOf("," + val + ",") != -1;
  });
//格式化对象数组 ([{name:'a',age:'1'},{name:'b',age:'2'}],'name') = > ['a','b']
Util.getArr = (array, key) => {
  let arr = [];
  array.forEach((el) => {
    arr.push(el[key]);
  });
  return arr;
};

// 获取页面宽高
Util.getPageWidth = () => {
  let pageWidth = document.documentElement.clientWidth; // 页面可视窗口宽度
  pageWidth = pageWidth < 1280 ? 1280 : pageWidth;
  return pageWidth;
};
Util.getPageHeight = () => {
  let pageHeight = document.documentElement.clientHeight; // 页面可视窗口高度
  pageHeight = pageHeight < 720 ? 720 : pageHeight;
  return pageHeight;
};
// 日期格式化 this.$util.dateFmt(new Date(),'yyyy-MM-dd hh:mm:ss')
Util.dateFmt = (date, fmt) => {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

//保留几位小数（四舍五入）this.$util.getFloat(num, 2)
Util.getFloat = (num, n) => {
  n = n ? parseInt(n) : 0;
  if (n <= 0) {
    return Math.round(num);
  }
  num = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); //四舍五入
  num = Number(num).toFixed(n); //补足位数
  return num;
};

//删除左右两端的空格 this.$util.trim(str)
Util.trim = (str) => {
  str = str.replace(/^(\s|\u00A0)+/, "");
  for (var i = str.length - 1; i >= 0; i--) {
    if (/\S/.test(str.charAt(i))) {
      str = str.substring(0, i + 1);
      break;
    }
  }
  return str;
  // return str.replace(/(^\s*)|(\s*$)/g, "");
};

//获取字符长度 把双字节的替换成两个单字节的然后再获得长度
Util.getStrLen = (str) => {
  var realLength = 0,
    len = str ? str.length : 0,
    charCode = -1;
  for (var i = 0; i < len; i++) {
    charCode = str.toString().charCodeAt(i);
    if (charCode >= 0 && charCode <= 128) realLength += 1;
    else realLength += 2;
  }
  return realLength;
};
Util.downloadFile = (blob, name) => {
  saveAs(blob, name);
  // if (
  //     "download" in document.createElement("a") &&
  //     navigator.userAgent.indexOf("Edge") == -1
  // ) {
  //   var elink = document.createElement("a");
  //   elink.download = name;
  //   elink.style.display = "none";
  //   elink.href = URL.createObjectURL(blob);
  //   document.body.appendChild(elink);
  //   elink.click();
  //   URL.revokeObjectURL(elink.href); // 释放URL 对象
  //   document.body.removeChild(elink);
  // } else {
  //   navigator.msSaveBlob(blob, name);
  // }
};
Util.blobToJson = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(blob);
    reader.onload = (event) => {
      try {
        let content = JSON.parse(reader.result);
        if (typeof content == "string") {
          content = JSON.parse(content);
        }
        resolve(content);
      } catch (err) {
        reject(err);
      }
    };
  });
};

// 数字转大写金额
Util.getaL = (n) => {
  let fraction = ['角', '分'];
  let digit = [
    '零', '壹', '贰', '叁', '肆',
    '伍', '陆', '柒', '捌', '玖'
  ];
  let unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ];
  let head = n < 0 ? '欠' : '';
  n = Math.abs(n);
  let s = '';
  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  n = Math.floor(n);
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = '';
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整');
}
// 判断当前账号是否有菜单权限
Util.isHas=(name, dataList)=> {
  for (let i = 0; i < dataList.length; i++) {
    let aaItem = dataList[i].subMenuList;
    for (let j = 0; j < aaItem.length; j++) {
      let bbItem = aaItem[j].subMenuList;
      for (let c = 0; c < bbItem.length; c++) {
        if (bbItem[c].sourceName == name) {
          // return bbItem[c];
          return true;
        }
      }
    }
  }
  return false;
}



// 根据屏幕宽度设置字体大小
Util.setFont = num => {
  let pageWidth = Util.getPageWidth(); // 页面可视窗口高度
  let width = (pageWidth / 1920) * num;
  let pageHeight = Util.getPageHeight(); // 页面可视窗口高度
  let height = (pageHeight / 1080) * num;
  if (width > height) {
    return width;
  } else {
    return height;
  }
};


Util.formatDecimal=(num)=> {
  num = Number(num);
  if (num == 0) {
    return num + '';
  } else
  if (num > 1 && num < 10000) {
    return num + '';
  } else {
    return (num / 10000).toFixed(1) ;
  }
}
// 时间格式化-前缀不补0
Util.formatDateNoFill = (date, type = 'Y-M-D h:m:s') => {
  let timestamp
  let myDate
  let hour
  let time
  if (date) {
    timestamp = parseInt(date, 10)
    if (timestamp < 10000) {
      timestamp = date
    }
    if (typeof timestamp === 'number') {
      myDate = new Date(timestamp)
    } else {
      myDate = new Date(timestamp.replace(/-/g, '/'))
    }
    hour = myDate.getHours()
  } else {
    myDate = new Date()
    hour = myDate.getHours()
  }
  const Y = myDate.getFullYear()
  const M = myDate.getMonth() + 1
  const D =myDate.getDate()
  const h = hour
  const m = myDate.getMinutes()
  const s = myDate.getSeconds()
  time = type.replace('Y', Y)
  time = time.replace('M', M)
  time = time.replace('D', D)
  time = time.replace('h', h)
  time = time.replace('m', m)
  time = time.replace('s', s)
  return time
}

// 密码加密
Util.passwordEncryPtion = (password) => {
  let CRYPTOJSKEY = "abcdefgabcdefg12";
  // 密码加密
  var plaintText = password;
  var options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  };
  var key = CryptoJS.enc.Utf8.parse(CRYPTOJSKEY);
  var encryptedData = CryptoJS.AES.encrypt(plaintText, key, options);
  var encryptedBase64Str = encryptedData.toString().replace(/\//g, "_");
  return encryptedBase64Str
}
// 当前日期加30天
Util.getDate30 = () => {
  var date = new Date()  //当前日期2021-3-9
  var fff = new Date(date.setDate(date.getDate() + 29)).toLocaleDateString() //获取当前日期后加30天    2021/4/8
  let dateAfter =  fff.replace(/\//g,'-')
  return dateAfter

}
  Util.getDateSpecify = (num) => {
    var date = new Date()  //当前日期2021-3-9
    var dateAfter = new Date(date.setDate(date.getDate() + num)).toLocaleDateString() //获取当前日期后加30天    2021/4/8
    // let dateAfter =  fff.replace(/\//g,'-')
    return dateAfter

  }
// 时间格式化
Util.formatDate = (date, type = 'Y-M-D h:m:s') => {
  let timestamp
  let myDate
  let hour
  let time
  if (date) {
    timestamp = parseInt(date, 10)
    if (timestamp < 10000) {
      timestamp = date
    }
    if (typeof timestamp === 'number') {
      myDate = new Date(timestamp)
    } else {
      myDate = new Date(timestamp.replace(/-/g, '/'))
    }
    hour = myDate.getHours()
  } else {
    myDate = new Date()
    hour = myDate.getHours()
  }
  const Y = myDate.getFullYear()
  const M = myDate.getMonth() + 1 < 10 ? `0${myDate.getMonth() + 1}` : myDate.getMonth() + 1
  const D = myDate.getDate() < 10 ? `0${myDate.getDate()}` : myDate.getDate()
  const h = hour < 10 ? `0${hour}` : hour
  const m = myDate.getMinutes() < 10 ? `0${myDate.getMinutes()}` : myDate.getMinutes()
  const s = myDate.getSeconds() < 10 ? `0${myDate.getSeconds()}` : myDate.getSeconds()
  time = type.replace('Y', Y)
  time = time.replace('M', M)
  time = time.replace('D', D)
  time = time.replace('h', h)
  time = time.replace('m', m)
  time = time.replace('s', s)
  return time
}
// 时间戳转日期
Util.timestampConversion=(timestamp)=>{
  // 时间戳
  let date = new Date(parseInt(timestamp) );
  let Year = date.getFullYear();
  let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
  let  GMT =  Year + '-' + Moth + '-' + Day
  return GMT
}
// 获取当前时间
Util.getNowDate = ()=> {
  let yy = new Date().getFullYear();
  let mm = new Date().getMonth() + 1;
  let dd = new Date().getDate();
  let hh = new Date().getHours();
  let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
  let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
  return yy + '-' + mm + '-' + dd + '-' + hh + ':' + mf + ':' + ss;
}
