export default [
  {
    path: "/saleInput",
    name: "saleInput",
    redirect: "/saleInput",
    component: () => import("@/layout/TopDownLayout/Frame.vue"),
    children: [
      {
        path: "/saleInput",
        component: () => import("@/views/channelInput/saleNew/index.vue"),
        meta:{
          title: "零售订单录入",
          keepAlive: false,
          isBarShow: true
        }
      },
      {
        path: "/channelNew",
        component: () => import("@/views/channelInput/channelNew/index.vue"),
        meta:{
          title: "渠道订单录入",
          keepAlive: false,
          isBarShow: true
        }
      },
    ]
  }
];