/*
 * @Author: your name
 * @Date: 2020-11-03 17:56:20
 * @LastEditTime: 2020-11-04 20:42:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\settlement.js
 */

export default [
  // DEMO
  {
    path: "/confirm",
    name: "confirm",
    redirect: "/confirm",
    component: () => import("@/layout/TopDownLayout/Frame.vue"),
    children: [{
        path: "/confirm",
        name: 'confirms',
        component: () => import("@/views/settlement/index.vue"),
        meta: {
          title: "订单结算",
          keepAlive: false,
          titles: '返回购物车',
          isBarShow: true
        }
      },
      // 订单提交成功
      {
        path: "confirmsuccess",
        name: 'orderSubmit',
        component: () => import("@/views/orderSubmit/index.vue"),
        meta: {
          title: "提交成功",
          keepAlive: false,
          isBarShow: true
        }
      },
      // 工程专区， 特惠专区
      {
        // path: "/specialZone",
        // name: "specialZone",
        // redirect: "/specialZone",
        // component: () => import("@/layout/TopDownLayout/Frame.vue"),
        // children: [
        // {
        path: "special",
        name: 'specialZones',
        component: () => import("@/views/specialZone/index.vue"),
        meta: {
          title: "订单结算",
          titles: '返回专区列表',
          keepAlive: false
        }
        // }
        // ]
      },
      {
        // path: "/specialZone",
        // name: "specialZone",
        // redirect: "/specialZone",
        // component: () => import("@/layout/TopDownLayout/Frame.vue"),
        // children: [
        // {
        path: "specialZonesGC",
        name: 'specialZonesGC',
        component: () => import("@/views/specialZoneGC/index.vue"),
        meta: {
          title: "订单结算",
          titles: '返回专区列表',
          keepAlive: false
        }
        // }
        // ]
      },
      // 活动专区结算
      {
        path: "activity",
        name: 'activityZone',
        component: () => import("@/views/activityZone/index.vue"),
        meta: {
          title: "订单结算",
          titles: '返回专区列表',
          keepAlive: false
        }

      },
      // 渠道采购结算
      {
        path: "channel",
        name: 'channel',
        component: () => import("@/views/channel/index.vue"),
        meta: {
          title: "订单结算",
          keepAlive: false,
          titles: '返回购物车',
        }
      },
      // 渠道零售订单录入
      {
        path: "channelInput",
        name: 'channelInput',
        component: () => import("@/views/channelInput/index.vue"),
        meta: {
          title: "渠道订单录入",
          keepAlive: false,
          isBarShow: true
        }
      }, // 渠道零售订单录入
      {
        path: "inputSuccess",
        name: 'inputSuccess',
        component: () => import("@/views/channelInput/succes/index.vue"),
        meta: {
          title: "订单录入成功",
          keepAlive: false,
          isBarShow: true
        }
      },
      // 套购专区
      {
        path: "package",
        name: 'package',
        component: () => import("@/views/ArbitrageZone/index.vue"),
        meta: {
          title: "套购专区",
          titles:'返回专区列表',
          keepAlive: false
        }
      },
      // 再来一单
      {
        path: "orderAgain",
        name: 'confirmagain',
        component: () => import("@/views/confirmAgain/index.vue"),
        meta: {
          title: "再来一单结算",
          keepAlive: false,
          isBarShow: true,
          titles:'返回订单中心'
        }
      },
      // 提交结果页
      {
        path: "submitEnding",
        component: () => import("@/views/submitEnding/index"),
        meta: {
          title: "提交结果页",
          keepAlive: false,
          isBarShow: true
        }
      },
      //认购订单初始化
      {
        path: "subOrder",
        name: 'subOrder',
        component: () => import("@/views/subOrder/index.vue"),
        meta: {
          title: "认购订单初始化",
          keepAlive: false,
          isBarShow: true
        },
      },
      {
        path: "/activesubscriptionPage",
        name: 'activesubscriptionPage',
        component: () => import("@/views/activesubscriptionPage/index.vue"),
        meta: {
          title: "认购结算",
          keepAlive: false,
          isBarShow: true
        },
        
      },

      {
        path: "/combinedOrderSettlement",
        name: 'combinedOrderSettlement',
        component: () => import("@/views/activity/combinedOrderSettlement/index.vue"),
        meta: {
          title: "认购结算",
          keepAlive: false,
          isBarShow: true
        },

      },
      {
        path: "/wxPay",
        name: 'wxPay',
        component: () => import("@/views/activesubscriptionPage/wxPay/index"),
        meta: {
          title: "微信支付",
          keepAlive: false,
          isBarShow: true
        },
      },
      {
        path: "channelSubOrder",
        name: 'channelSubOrder',
        component: () => import("@/views/channelSubOrder/index.vue"),
        meta: {
          title: "渠道认购订单",
          keepAlive: false,
          isBarShow: true
        },
      }
      
    ]
  }
];