<template>
  <a-modal
    title="图片预览"
    v-model="visible"
    wrapClassName="messageName"
    :width="800"
    :closable="false"
    centered
    :maskClosable="false"
  >
    <div class="img-box">
      <el-image v-for="(item,index) in imgURL" :src="item" :key="index" alt :preview-src-list="imgURL"></el-image>
    </div>
    

    <template slot="footer">
      <a-button class="ant-btn-primary" @click="handleOk">知道了</a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "img-preview-modal",
  data() {
    return {
      // imgurl: []
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    imgURL: {
      type: Array
    },
    imgurl: {
      type: Array
    },
  },
  methods: {
    handleOk() {
      this.$emit("update:visible", false);
    },
  }
};
</script>
<style lang="less" scoped>
/deep/ .messageName {
  .ant-modal-content {
    text-align: center;
    // height: 100vh;
    .ant-modal-body {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 30px 70px 0 70px;
      .el-icon-circle-close{
        color: #fff;
      }
      .img-box{
        max-height:600px;
        overflow-y: auto;
        > div{
          width:100%;
          padding-left: 10px;
          + div{
            margin-top: 20px;
          }
        }
        img {
          width:100%;
          // width: 764px;
          // height: 400px;
        }
      }
      
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 24px 0 30px;
      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
      }
      .ant-btn-primary {
        color: #fff;
        background: #00aaa6;
      }
    }
  }
}
</style>


