<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      :confirm-loading="pageLoadFlag"
      @cancel="cancle"
      wrapClassName="cancelNamePowerAttorney"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"
    >
      <a-spin :spinning="pageLoadFlag">
        <div class="modal_close" @click="cancle">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
          <span class="modal_title">
            <i class="modal_title_line"></i>自提委托书信息
          </span>
        </div>
        <a-spin  :spinning="showLoading">
          <div class="cancelContent">
              <a-form-model ref="ruleForm" class="selectBox" :model="formTable" :rules="rules">
                <a-form-model-item class="a-form-model-item everyBoxF three" prop="name">
                  <span slot="label" class="lableF">提货人姓名</span>
                  <a-input style="width: 300px" v-model="formTable.name" placeholder="请输入" />
                </a-form-model-item>
                <a-form-model-item class="a-form-model-item everyBoxF three" prop="idCard">
                  <span slot="label" class="lableF">提货人身份证号</span>
                  <a-input style="width: 300px"  v-model="formTable.idCard" placeholder="请输入" />
                </a-form-model-item>
                <a-form-model-item class="a-form-model-item everyBoxF three" prop="phone">
                  <span slot="label" class="lableF">提货人手机号</span>
                  <a-input style="width: 300px"  v-model="formTable.phone" placeholder="请输入" />
                </a-form-model-item>
                <a-form-model-item class="a-form-model-item everyBoxF three" prop="carNum">
                  <span slot="label" class="lableF">提货人车牌号</span>
                  <a-input style="width: 300px" v-model="formTable.carNum" placeholder="请输入" />
                </a-form-model-item>
              </a-form-model>
              </div>
          <p class="warningTxt">温馨提示：分销商需待上级代理授权后完成签署；代理商和直营商可直接完成签署。</p>
        </a-spin>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>

import { dimission, save } from "@/views/basicInformation/components/businessAddEdit/api";

export default {
  name: "powerAttorneyModal",
  components: {},
  data() {
    // 身份证号校验
    const checkIdNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('身份证不能为空'))
      }
      if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        callback(new Error('请输入正确的二代身份证号码'))
      } else {
        callback()
      }
    }
    return {
      // 表单数据
      formTable: {
        name: '',// 提货人姓名
        idCard: '',//身份证号，
        phone: '',
        carNum: '',
      },

      rules: {
        name: [
          { required: true, message: "请输入提货人姓名", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请输入提货人身份证号", trigger: "blur" },
          { validator: this.$util.IDValidator, trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入提货人手机号", trigger: "blur" },
          {
            validator: this.$util.MobileNumberValidator,
            trigger: 'blur',
          },
        ],
        carNum: [
          { required: true, message: "请输入提货人车牌号", trigger: "blur" },
        ]
      },
      showLoading:false

    };
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    pageLoadFlag: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    visible: {
      immediate: true,
      handler(newValue) {
        this.formTable = {
            name: '',// 提货人姓名
            idCard: '',//身份证号，
            phone: '',
            carNum: '',
        }
      }
    },
  },
  mounted() {

  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.showLoading = true
          this.$emit("cancelReasonHandOk", this.formTable);
          this.showLoading = false

        }else{
          this.$message.warning("请将信息填写完整", 2);
        }
      });

    },
    cancle() {
      this.$emit("update:visible", false);
      this.$forceUpdate()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNamePowerAttorney {
  .ant-input{
    border: 1px solid #eee!important;
  }
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px 0px 0 0px;
      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        .ant-form-item{
          display: flex;
          flex-direction: row;
          align-items: flex-start !important;
        }
        .ant-form-item-label {
          text-align: right;
          width: 130px;

        }

        .selectBox {
          //margin-top: 16px;
          display: flex;
          flex-direction: column;

          .everyBox {
            display: flex;
            align-items: center;
            margin-left: 110px;
            margin-top: 24px;
            .lableF{
              .redTip{
                color: #D9161C;
              }
              //width: 120px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;

              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
}
            .lable {
              .redTip{
                color: #D9161C;
              }
              width: 120px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;

              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }
            /deep/.ant-select-auto-complete.ant-select .ant-input {
              //border-width: 0px!important;
              width: 300px!important;
              border-color: #eee;;
            }
            .ant-select-auto-complete.ant-select .ant-input {
              width: 300px!important;
              border-color: #eee;;
            }
            .ant-select-selection--single {
              width: 300px;
            }

            /deep/ .ant-select-selection {
              width: 300px!important;
              height: 32px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
            }

            .aInput {
              width: 416px !important;
              height: 32px !important;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
              color: #262626;
            }

            .userInput {
              width: 200px;
              margin-right: 16px;
            }

            .radioGroup {
              margin-left: 8px;
            }

            .userSorce {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              //align-items: center;
              max-width: 530px;

              .smallSorce {
                padding: 0 14px 0 14px;
                color: #00AAA6;
                height: 32px;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                text-align: center;
                line-height: 32px;
                margin-right: 8px;
                margin-bottom: 12px;
                cursor: pointer;
              }

              .smallSorceActice {
                cursor: pointer;
                height: 32px;
                background: #00AAA6;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                padding: 0 14px 0 14px;
                color: #fff;
                margin-right: 8px;
                margin-bottom: 12px;
                //border: none;
              }
            }

            .circleBox {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }

          }
          .everyBoxF {
            display: flex;
            align-items: center;
            margin-left: 99px;
            margin-top: 24px;
            .lableF{
              .redTip{
                color: #D9161C;
              }
              //width: 120px;
              //margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;
              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }
            /deep/.ant-select-auto-complete.ant-select .ant-input {
              //border-width: 0px!important;
              width: 300px!important;
              border-color: #eee;;
            }
            .ant-select-auto-complete.ant-select .ant-input {
              width: 300px!important;
              border-color: #eee;;
            }
            .ant-select-selection--single {
              width: 300px;
            }

            /deep/ .ant-select-selection {
              width: 300px!important;
              height: 32px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
            }

            .ant-form-explain{
              font-size: 12px;
              margin-top: 4px;
              color:#D9161C;
            }
            .aInput {
              width: 416px !important;
              height: 32px !important;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
              color: #262626;
            }

            .userInput {
              width: 200px;
              margin-right: 16px;
            }

            .radioGroup {
              margin-left: 8px;
            }

            .userSorce {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              //align-items: center;
              max-width: 530px;

              .smallSorce {
                padding: 0 14px 0 14px;
                color: #00AAA6;
                height: 32px;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                text-align: center;
                line-height: 32px;
                margin-right: 8px;
                margin-bottom: 12px;
                cursor: pointer;
              }

              .smallSorceActice {
                cursor: pointer;
                height: 32px;
                background: #00AAA6;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                padding: 0 14px 0 14px;
                color: #fff;
                margin-right: 8px;
                margin-bottom: 12px;
                //border: none;
              }
            }

            .circleBox {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }

          }
          .three {
            /deep/ textarea.ant-input {
              border: 1px solid #EEEEEE;
            }
          }
          .everyBox:first-child{
            margin-top: 40px;
          }

          .proBox {
            display: flex;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start;

            .prooneBox {
              display: flex;

              .prouctBox {
                display: flex;
                flex-direction: column;

                .proItem {
                  display: flex;
                  margin-bottom: 16px;
                  align-items: center;

                  /deep/ .ant-select-selection {
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;
                  }

                }
              }
            }

            .addNEWbTN {
              cursor: pointer;
              width: 180px;
              height: 32px;
              border-radius: 3px;
              border: 1px dashed #EEEEEE;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #1890FF;
              margin-left: 332px;

              img {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }

          }

          .biaoqian {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .biaoqianBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start
            }

            .tips {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              margin-left: 109px;
              margin-top: -6px;
            }

          }
          .laiyuan {
            margin-top: 12px;
          }

          .adress {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div {
              display: flex;
            }

            .userDsInputress {
              margin-top: 16px;
              margin-left: 106px;
              width: 500px;
            }
          }
        }


        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 4px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }

      .modal_close {
        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
      .warningTxt {
        font-size: 11px;
        font-weight: 400;
        color: #AAAAAA;
        line-height: 16px;
        margin-top:40px;
        margin-left:22px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:16px 0px  30px 0;
      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }

  }
}
</style>


