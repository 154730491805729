<!--
 * @Author: 尚宏浩 aaa@hisense.com
 * @Date: 2024-08-24 13:39:33
 * @LastEditors: 尚宏浩 aaa@hisense.com
 * @LastEditTime: 2024-08-27 18:56:56
 * @FilePath: \B2B\src\components\global\CopyText\CopyText.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <span class="fu" @click="copyTextToClipboard">复制</span>
</template>
  
<script>

export default {
    name: 'CopyText',
    props: {
        text: String
    },
    methods: {
        async copyTextToClipboard() {
            try {
                console.log('navigator', navigator, navigator.clipboard)
                await navigator.clipboard.writeText(this.text);
                console.log('Text copied to clipboard');
                this.$message.info('复制成功');
            } catch (err) {
                console.error('Failed to copy text: ', err);
                // 使用传统复制
                // this.copyTextToClipboard(this.text);
                // 使用示例
                this.copyOld(); // 'myElementId' 是你想复制文本的元素ID
            }
        },
        copyOld() {
            console.log('copyTextToClipboard')
            // 创建一个临时的输入框来存储文本
            const input = document.createElement('input');
            input.value = this.text;

            // 将输入框添加到页面，但不显示
            document.body.appendChild(input);
            input.select(); // 选择输入框的内容

            // 尝试复制内容
            const result = document.execCommand('copy');

            // 移除临时输入框
            document.body.removeChild(input);

            if(result){
                this.$message.info('复制成功');
            }else{
                this.$message.info('复制失败');
            }
        }


    }
}
</script>
  
<style lang="less" scoped>
.fu {
    color: #1890FF !important;
    margin-left: 12px;
}
</style>
  