<template>
    <div>
        <a-modal class="hisense-agreement" v-model="visible" title="海信线上专卖店商家入驻协议" :width="842" :height="500" :keyboard="false"
            :footer="null" :closable="true" :maskClosable="false" @cancel="disagree">
            <div class="contentBox">
                <h-scroll class="scrollBox" :settings="{wheelPropagation: false}">
                    <div style="display: flex">
                        <p class="size-weight">生效日期：</p>
                        <p>2024年06月07日</p>
                    </div>
                    <p class="p-top size-weight">修订摘要：</p>
                    <p class="p-top size-indent">如果您对本协议的条款有疑问的，请通过海信在线客服渠道进行询问，海信线上客服将向您解释条款内容。如果您不同意本协议的任意内容，或者无法准确理解海信对条款的解释，请不要进行后续操作。</p>
                    <p class="p-top size-weight">协议正文：</p>
                    <p class="p-top size-indent">第一条：立约目的为了规范海信线上专卖店商家经营秩序，明确海信、海信线上专卖店商家的权利义务，保障双方及消费者的合法权益，根据海信相关规则的有关内容拟定本协议。</p>
                    <p class="p-top size-indent">第二条：本协议由青岛海信商贸发展有限公司（以下简称“海信”）与申请成为海信线上专卖店的商家（以下简称“商家”或“您”）签署。</p>
                    <p class="p-top size-indent">第三条：协议生效和适用范围</p>
                    <p class="p-top size-indent">3.1、您通过线上专卖店入驻页面点击确认，即表示您与海信已达成协议并同意接受本协议的全部约定内容。 本协议自您的申请获得海信审核通过之时起生效。</p>
                    <p class="p-top size-indent">3.2、海信线上专卖店商家的申请在获得海信审核通过后，在本协议有效期间，商家在海信线上专卖店平台开展经营活动应遵守本协议的约定。</p>
                    <p class="p-top size-indent">第四条：入驻海信线上专卖店的条件和审核流程</p>
                </h-scroll>
            </div>
            <div class="btn-group">
                <h-btn class="h-btn" :fontSize="16" :width="140" :height="40"
                    style="background:#AAA;border:none;margin-right: 24px" @click="disagree">不同意
                </h-btn>
                <h-btn class="h-btn" :fontSize="16" :width="140" :height="40" @click="agree">我已同意协议
                </h-btn>
            </div>
        </a-modal>

    </div>
</template>
<script>
import { env } from "@/common/constant";
export default {
    name: "HisenseAgreement",
    data() {
        return {
            privacyPolicyInfor: {}
        };
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        msid: {
            type: String || Number,
        },
        o2oShopId: {
            type: String || Number,
        },
        cacheSign: {
            type: String || Number,
        }
    },
    methods: {
        agree() {
            if(this.cacheSign){
                window.open(`${env == "dev" || env == "test" || env == "uat" ? 'https://test.shop.hisense.com' : 'https://mall.hisense.com'}/xtwLogout?cacheSign=${this.cacheSign}&type=${this.$store.state.user.userInfo.account.type}&ciscustomerid=${this.$store.state.user.userInfo.customer.id}`);
            }
            this.$emit("update:visible", false);
        },
        disagree() {
            this.$emit("update:visible", false);
        },
    }
};
</script>
<style lang="less" scoped>
.hisense-agreement {
    /deep/ .ant-modal-close-x {
        display: block !important;
        width: 42px !important;
        height: 42px !important;
        font-size: 16px !important;
        font-style: normal !important;
        line-height: 42px !important;
        text-align: center !important;
        text-transform: none !important;
        text-rendering: auto !important
    }
    /deep/ .ant-modal-close-x:hover {
        background-color: #E4F8F7 !important;
        border-radius: 0px 10px 0px 0px;
        color: #08ABA8 !important;
        width: 42px;
        height: 42px;
    }
    .ant-modal-content {
        border-radius: 10px !important;
    }
    /deep/ .ant-modal-title {
        color: #262626;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
    }
    /deep/ .ant-modal-header {
        border-radius: 10px 10px 0 0;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #262626;
        padding-top: 34px;
        padding-bottom: 16px;
        border: none;
    }
    /deep/ .ant-modal-body {
        padding: 0px 34px 0px 34px;
        border-radius: 10px !important;

    }
    .contentBox {
        margin: 0 auto;
        height: 300px;

        .scrollBox {
            width: 100%;
            position: relative;
            padding-top: 0px;
            font-size: 14px;
            color: #262626;
            height: 100%;
            .size-weight{
                font-weight: bold;
            }
            .size-indent{
                text-indent: 30px;
            }
            .p-top{
                margin-top: 20px;
            }
        }

        .titleBox {
            text-align: left;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 12px;

            .title {
                height: 30px;
                line-height: 30px;
                margin-bottom: 16px;
                font-size: 20px;
                font-weight: 500;
                color: #262626;
            }

            .time {
                height: 16px;
                font-size: 12px;
                color: #aaaaaa;
            }
        }

        .content {
            color: #262626 !important;
            text-align: left;
            .contentInfo {
                color: #262626 !important;
            }
            p {
                color: #262626 !important;
            }
        }
    }

    .btn-group {
        text-align: center;
        padding: 24px 0 24px 0;
    }
}
</style>