/*
 * @Author: your name
 * @Date: 2020-08-24 16:03:19
 * @LastEditTime: 2022-08-24 08:29:26
 * @LastEditors: xiaojie 1264801634@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\main.js
 */
import '@babel/polyfill';
require('promise.prototype.finally').shim()
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./global";
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import "./permission";

// Vue.config.productionTip = false;
// Vue.use(ElementUI);
Vue.config.silent = true
window.$vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
