/*
 * @Description: 
 * @Version: 2.0
 * @Autor: sqk
 * @Date: 2020-07-10 15:30:51
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-06 20:10:39
 */ 
// import Vue from "vue";
import VueRouter from "vue-router";
const files = require.context(".", true, /\.js$/);
let configRouters = [];
/**
 * inject routers
 */
files.keys().forEach(key => {
  if (key === "./index.js") return;
  configRouters = configRouters.concat(files(key).default);
});

const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default configRouters;