import http from "@/utils/request";

// 特惠专区 
export function specialZone(data) {
    return http({
        url: '/placeOrder/orderDetail.nd',
        method: 'post',
        data
    })
}

// 在线充值
export function saveMoney() {
    return http({
        url: '/cart/selfOrderPay.nd',
        method: 'get'
    })
}
// 充值记录
export function saveHisetory() {
    return http({
        url: '/cart/selfOrderQuery.nd',
        method: 'get'
    })
}

// 特惠订单提交
export function specialSubmit(data) {
    return http({
        url: '/preferential/saveProductForm.nd',
        method: 'post',
        data
    })
}
// 获取工程列表数据
export function getEngineerOrder() {
    return http({
        url: "/engineering/getEngineerOrder.nd?pageNo=1",
        method: "get"
    })
}

// 工程订单提交
export function engineerSubmit(data) {
    return http({
        url: '/engineering/saveEngineerOrder.htm',
        method: 'post',
        // contentType: "application/json;charset=UTF-8",
        // type: 'stringfy',
        data
    })
}


//时效信息
export function orderWeek(data) {
    return http({
        method: "post",
        url: "/cart/orderWeek.nd",
        data
    });
}
// 购物车查询  
export function orderCar(params) {
    return http({
        url: '/cart/settlement.nd',
        method: 'get',
        params
    })
}
// 统仓地址联系人address/changeCustomerAddress.nd
export function orderAddress(data) {
    return http({
        url: 'address/changeCustomerAddress.nd',
        method: 'post',
        data
    })
}

// 分销商 分销地址

export function showAnother(params) {
    return http({
        url: '/cart/showAnotherOrderList.nd',
        method: 'get',
        params
    })
}

// 收货地址切换 带出联系人
export function addessChange(params) {
    return http({
        url: '/address/changeCustomerAddress.nd',
        method: 'get',
        params
    })
}

// 获取城市接口
export function cityChange(data) {
    return http({
        url: '/customer/getCity.nd',
        method: 'post',
        data
    })
}

// 获取城镇区
export function townChange(data) {
    return http({
        url: '/customer/getDistrict.nd',
        method: 'post',
        data
    })
}

// 获取城镇区下一级
export function getQu(data) {
    return http({
        url: '/customer/getTown.nd',
        method: 'post',
        data
    })
}

// 分销切换 请求分销商地址
export function getDISAdd(data) {
    return http({
        url: '/address/changeDistributionAddress.nd',
        method: 'post',
        data
    })
}
// 地址切换 获取联系人
export function getPhone(data) {
    return http({
        url: '/address/changeCustomerAddress.nd',
        method: 'post',
        data
    })
    // return http({
    //     url: '/address/getAddressContacts.nd?id=' + data.id,
    //     method: 'get',
    //     data
    // })
}

// 要求到货周 
export function getWeek(data) {
    return http({
        url: '/cart/orderWeek.nd',
        method: 'post',
        data
    })
}

// 结算商品列表  价格信息等 
export function goodsList(data) {
    return http({
        url: '/product/getPrices.nd',
        method: 'post',
        data
    })
}
// 获取库存数 
export function getNumber(data) {
    return http({
        url: '/product/getStocks.nd',
        method: 'post',
        data
    })
}

// 购物车订单提交
export function cartSubmit(data) {
    return http({
        url: '/cart/saveOrder.nd',
        method: 'post',
        data
    })
}
// 查看预占用额度明细
export function quotaDeatail(params) {
    return http({
        url: 'balance/queryWaitBalanceInfoList.nd',
        method: 'get',
        params
    })
}
// 服务方式
export function serviceInformation(params) {
    return http({
        url: '/comm/queryServiceTypeSelect.nd',
        method: 'get',
        params
    })
}


// 销量所属门店
export function SalesShopInfoList1(data) {
    return http({
        method: 'post',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        url: '/comm/findCustomerShopInfoList.nd',

        data
    })
}
// 判断是否为一次项工程
// /engineering/checkIsOneProject/{id}/{orgId}.nd
export function checkIsOneProject(params) {
    return http({
        url: '/engineering/checkIsOneProject'+'/'+params.id+'/'+params.orgId + '.nd',
        method: 'get',
        // params
    })
}