<!--
 * @Author: sqk
 * @Date: 2020-09-02 10:28:52
 * @LastEditTime: 2021-06-21 23:20:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\filtrate\Filtrate.vue
-->


<template>
  <a-spin class="mid-spin" :spinning="filterLoadFlag" size="large">
    <div class="formFiltrate-box clearfix" :class="{ more: isShowMoreFlag }">
      <div class="tool-box">
        <div class="btn-group">
          <h-btn :width="66" :height="32" content="搜索" @click="searchFun" />
          <h-btn
            :width="66"
            :height="32"
            :customType="3"
            content="重置"
            @click="resetFun"
          />
        </div>
        <h-btn
          v-if="isToggleFlag"
          class="zk"
          :width="40"
          :height="28"
          :content="isShowMoreFlag ? '收起' : '展开'"
          :level="3"
          @click="isShowMoreFlag = !isShowMoreFlag"
        />
      </div>
      <!-- <ul class="form clearfix" :style="{'padding-left': formDistance + 'px'}"> -->
      <ul class="form">
        <!-- <li v-for="(item,index) in filList" :key="index" :style="setStyle(index)"> -->
        <!-- <li v-for="(item,index) in filList" :key="index" :style="{'margin-left': itemMLWidth + 'px'}"> -->
        <li v-for="(item, index) in filList" :key="index">
          <span :style="{ width: item.maxLen * 19 + 6 + 'px' }"
            >{{ item.title }}{{ item.title ? ":" : "" }}</span
          >
          <div class="form-item-box">
            <a-input
              v-if="item.inputType == 'text'"
              v-model="filForm[item.targetId]"
              allowClear
              placeholder="请输入"
            />
            <a-select
              :mode="item.multiple == 2 ? 'multiple' : 'default'"
              v-if="item.inputType == 'select'"
              v-model="filForm[item.targetId]"
              :maxTagCount="2"
              showSearch
              showArrow
              :allowClear="item.canNull !== 'N'"
              @change="sltChange(item)"
              placeholder="请选择"
            >
              <a-select-option
                v-for="option in item.list"
                :key="option.id"
                :value="option.id + '^' + option.name"
                >{{ option.name }}</a-select-option
              >
            </a-select>
            <!-- <a-select
            v-if="item.inputType == 'select' && item.multiple == '2'"
            v-model="filForm[item.targetId]"
            placeholder="请选择"
          >
            <a-select-option
              v-for="option in item.list"
              :key="option.id"
              :value="option.id"
            >{{option.name}}</a-select-option>
            </a-select>-->
            <a-range-picker
              :allowClear="item.canNull !== 'N'"
              v-if="item.inputType == 'indate'"
              v-model="filForm[item.targetId + '_indate']"
              type="date"
            >
              <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
            <a-date-picker
              :allowClear="item.canNull !== 'N'"
              v-if="item.inputType == 'date'"
              v-model="filForm[item.targetId + '_date']"
              type="date"
            >
              <a-icon slot="suffixIcon" type="calendar" />
            </a-date-picker>
            <a-month-picker
              :allowClear="item.canNull !== 'N'"
              v-if="item.inputType == 'dateMonth'"
              v-model="filForm[item.targetId + '_month']"
            >
              <a-icon slot="suffixIcon" type="calendar" />
            </a-month-picker>
            <div v-if="item.inputType == 'innum'" class="number-range-box">
              <a-input-number
                :min="1"
                :max="filForm[item.targetId + 'innum2'] - 1"
                v-model="filForm[item.targetId + 'innum1']"
                @change="innumChange(item.targetId)"
              />
              <i>至</i>
              <a-input-number
                :min="
                  filForm[item.targetId + 'innum1'] + 1
                    ? filForm[item.targetId + 'innum1'] + 1
                    : 1
                "
                v-model="filForm[item.targetId + 'innum2']"
                @change="innumChange(item.targetId)"
              />
            </div>
            <a-cascader
              v-model="filForm[item.targetId + 'dzA']"
              v-if="item.inputType == 'region'"
              :options="filForm[item.targetId]"
              :load-data="loadData"
              placeholder="请选择"
              @popupVisibleChange="popupVisibleChange($event, item)"
              @change="onChange"
            />
            <a-cascader
              v-model="filForm[item.targetId + 'dzA']"
              v-if="item.inputType == 'region2'"
              :options="filForm[item.targetId]"
              :load-data="loadDataLevel2"
              placeholder="请选择"
              @popupVisibleChange="popupVisibleChange($event,item)"
              @change="onChange"
            />
            <a-select
              v-if="item.inputType == 'selectSearch'"
              v-model="filForm[item.targetId + '_search']"
              show-search
              label-in-value
              placeholder="请搜索"
              style="width: 100%"
              :filter-option="false"
              :show-arrow="false"
              :allowClear="item.canNull !== 'N'"
              @search="handleSearch($event, item)"
              @select="handleSelect(item)"
              @blur="handleSelect(item)"
            >
              <a-spin
                v-if="searchLoading"
                slot="notFoundContent"
                size="small"
              />
              <a-select-option v-for="e in searchList" :key="e">{{
                e
              }}</a-select-option>
            </a-select>
          </div>
        </li>
      </ul>
    </div>
  </a-spin>
</template>
<script>
import http from "@/utils/request";
import { mapState } from "vuex";
//获取三级联动地区组织
import { getRegin } from "@/utils/api";
import settlement from "@/views/settlement";

export default {
  name: "baseformFiltrate",
  data() {
    return {
      itemMLWidth: 90,
      maxSize: [], //每列最大字数
      isToggleFlag: false, //是否显示‘收起/展开按钮’ （是否筛选项超过6条）
      isShowMoreFlag: false, //true->收起（已展开）false->展开（已收起）
      filForm: {
        //筛选表单绑定value的对象
        // address:null
        // ...this.addressObj
      },
      addressTargetId: "",
      options1: [
        {
          value: "zhejiang",
          label: "Zhejiang",
          isLeaf: false
        },
        {
          value: "jiangsu",
          label: "Jiangsu",
          isLeaf: false
        }
      ],
      options: [
        // {
        //   value: 'zhejiang',
        //   label: 'Zhejiang',
        //   isLeaf: false,
        // },
        // {
        //   value: 'jiangsu',
        //   label: 'Jiangsu',
        //   isLeaf: false,
        // },
      ],
      searchList: [],
      searchLoading: false
    };
  },
  props: {
    //设置表单之间边距
    itemDistance: {
      type: [Number, String],
      default: 58
    },
    //表单整体左边距
    formDistance: {
      type: [Number, String],
      default: 44
    },
    isNextRequest: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    filList: {
      handler() {
        // this.filForm = {...this.filForm,...this.addressObj};
        //拼装空数据- 》保持排列样式
        if (this.filList.length % 3 == 2) {
          this.filList.push({ title: "" });
        }

        this.maxSize = [];
        //是否显示‘收起/展开按钮’ （是否筛选项超过6条）
        if (this.filList.length > 6) {
          this.isToggleFlag = true;
        } else {
          this.isToggleFlag = false;
        }

        this.filList.forEach((itemL1, idx) => {
          //有默认值
          if (
            itemL1.defaultValue &&
            itemL1.defaultValue != null &&
            itemL1.defaultValue != ""
          ) {
            //确定表单类型
            switch (itemL1.inputType) {
              case "text": //文本
                this.$set(this.filForm, itemL1.targetId, itemL1.defaultValue);
                break;
              case "select": //下拉 不判断单选还是多选 只根据默认值是否有逗号 判断有几个默认值
                if (
                  itemL1.canNull == "N" &&
                  itemL1.list &&
                  itemL1.list.length > 0
                ) {
                  //默认选中第一项
                  this.$set(
                    this.filForm,
                    itemL1.targetId,
                    itemL1.list[0].id + "^" + itemL1.list[0].name
                  );
                } else {
                  var selectArr = [];
                  for (const itemL2 of itemL1.list) {
                    if (itemL1.defaultValue.indexOf(itemL2.id) != -1) {
                      if (itemL1.defaultValue.indexOf(",") == -1) {
                        //单个默认值
                        this.$set(
                          this.filForm,
                          itemL1.targetId,
                          itemL1.defaultValue + "^" + itemL2.name
                        );
                      } else {
                        //多个默认值

                        //遍历相加 拼接，
                        for (const item of itemL1.defaultValue.split(",")) {
                          if (item == itemL2.id) {
                            selectArr.push(itemL2.id + "^" + itemL2.name);
                          }
                        }
                        //赋值
                        this.$set(this.filForm, itemL1.targetId, selectArr);
                      }
                    }
                  }
                }
                break;
              case "date": //日期
                var dateDftVal_;
                if (itemL1.defaultValue == 0) {
                  dateDftVal_ = this.$moment(new Date(this.sysdate));
                } else {
                  dateDftVal_ = this.$moment(new Date(this.sysdate)).add(
                    -itemL1.defaultValue,
                    "months"
                  );
                }
                this.$set(this.filForm, itemL1.targetId + "_date", dateDftVal_);
                break;
              case "dateMonth": //月份选择
                var monthDftVal_;
                if (itemL1.defaultValue == 0) {
                  monthDftVal_ = this.$moment(new Date());
                } else {
                  monthDftVal_ = this.$moment(new Date());
                }
                this.$set(
                  this.filForm,
                  itemL1.targetId + "_month",
                  monthDftVal_
                );
                break;
              case "indate": //日期范围
                var indateDftVal_ = [];
                indateDftVal_[1] = this.$moment(new Date(this.sysdate));
                if (itemL1.defaultValue == 0) {
                  indateDftVal_[0] = this.$moment(new Date(this.sysdate));
                } else {
                  indateDftVal_[0] = this.$moment(new Date(this.sysdate)).add(
                    -itemL1.defaultValue,
                    "months"
                  );
                }
                this.$set(
                  this.filForm,
                  itemL1.targetId + "_indate",
                  indateDftVal_
                );
                break;
              case "innum": //日期范围
                this.$set(
                  this.filForm,
                  itemL1.targetId + "innum1",
                  Number(itemL1.defaultValue.split("_")[0])
                );
                this.$set(
                  this.filForm,
                  itemL1.targetId + "innum2",
                  Number(itemL1.defaultValue.split("_")[1])
                );
                break;
              default:
                break;
            }
          }

          if (idx == this.filList.length - 1 && this.isNextRequest) {
            this.callBack();
          }

          //每列最大字数 无则添加 有则更新
          if (!this.maxSize[idx % 3]) {
            this.maxSize[idx % 3] = this.$util.getStrLen(itemL1.title);
          } else {
            this.maxSize[idx % 3] =
              this.$util.getStrLen(itemL1.title) - this.maxSize[idx % 3] > 0
                ? this.$util.getStrLen(itemL1.title)
                : this.maxSize[idx % 3];
          }
        });

        //表单边距 = (总宽度 - （行总字数 * 14 + 富裕边距10 + 行总表单框宽度 + 行总表单与title间距）)/3
        let itemMLWidth =
          (1188 -
            ((this.maxSize[0] + this.maxSize[1] + this.maxSize[2]) * 7 +
              20 +
              690 +
              30)) /
          4;
        this.itemMLWidth = itemMLWidth;
        this.filList.forEach((item, idx) => {
          if (item.inputType == "region" || item.inputType == "region2") {
            //联动Key默认空数组
            this.$set(this.filForm, item.targetId, []);
          }
          item.maxLen = this.maxSize[idx % 3] / 2;
        });
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: state => state.formFiltrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: state => state.formFiltrate.isLoading,
      sysdate: state => state.formFiltrate.sysdate
    })
  },
  created() {},
  mounted() {},
  methods: {
    addressArr(item) {
      if (this.filForm[item.targetId] instanceof Array) {
        return this.filForm[item.targetId];
      } else {
        this.filForm[item.targetId] = [];
        return this.filForm[item.targetId];
      }
      // this.filForm[item.targetId]
    },
    //搜索
    searchFun() {
      this.callBack();
    },
    //重置
    resetFun() {
      // this.$refs['form'].resetFields();
      Object.keys(this.filForm).forEach(key => {
        this.filForm[key] = undefined;
      });
      this.callBack();
    },
    //三级联动弹窗
    popupVisibleChange(isOpen, item) {
      this.addressTargetId = item.targetId;
      //第一次打开 或者 清空后无数据 --》请求数据
      if (isOpen && (!item["fisrtOpenFlag"] || !this.filForm[item.targetId])) {
        //定义成数组类型
        item["fisrtOpenFlag"] = true;
        //弹开
        getRegin({ pCode: 0 }).then(res => {
          this.filForm[item.targetId] = res.data.list.map(item => {
            return {
              value: item["code"],
              label: item["name"],
              isLeaf: false
            };
          });
        });
      } else {
        // alert(222)
        // item['fisrtOpenFlag'] = false;
      }
    },
    onChange(value) {
    },
    loadData(selectedOptions) {
      this.loadDataCom(selectedOptions)
    },
    loadDataLevel2(selectedOptions) {
      this.loadDataCom(selectedOptions,2)
    },
    loadDataCom(selectedOptions, level) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      const params = {
        pCode: targetOption.value
      }
      if(level){
        params.level = level
      }
      getRegin(params).then(res => {
        targetOption.loading = false;
        // if (res.data.code == 0 && res.data.list.length == 0) {
        //   //最后一级
        //   targetOption.isLeaf = true;
        //   // this.filForm[this.addressTargetId] = [...this.filForm[this.addressTargetId]];
        //   return;
        // } else {
        targetOption.children = res.data.list.map(item => {
          return {
            value: item["code"],
            label: item["name"],
            isLeaf: item["desc"] == "child" ? true : false
          };
        });
        // }

        // this.$set(this.filForm,this.filForm[this.addressTargetId],targetOption)

        //更新数据
        this.filForm[this.addressTargetId] = [
          ...this.filForm[this.addressTargetId]
        ];
      });
    },
    //回调传出
    callBack() {
      //得到form键值对象 海信采购可直接使用 渠道采购需要拼装到paramList中
      let NewFilForm = {};
      for (const key in this.filForm) {
        if (key && this.filForm[key]) {
          if (key.indexOf("_date") != -1) {
            //日期
            //去掉‘_date’
            let newKey = key.slice(0, key.length - 5);
            NewFilForm[newKey] = this.$util.dateFmt(
              new Date(this.filForm[key]),
              "yyyy-MM-dd"
            );
          } else if (key.indexOf("_month") != -1) {
            //日期
            //去掉‘_month’
            let newKey = key.slice(0, key.length - 6);
            NewFilForm[newKey] = this.$util.dateFmt(
              new Date(this.filForm[key]),
              "yyyyMM"
            );
          } else if (key.indexOf("_indate") != -1) {
            //日期范围
            //去掉‘_indate’
            let newKey = key.slice(0, key.length - 7);
            if (this.filForm[key].length == 2) {
              NewFilForm[newKey] =
                this.$util.dateFmt(
                  new Date(this.filForm[key][0]),
                  "yyyy-MM-dd"
                ) +
                "_" +
                this.$util.dateFmt(
                  new Date(this.filForm[key][1]),
                  "yyyy-MM-dd"
                );
            }
          } else if (key.indexOf("innum") != -1) {
            //数字范围
          } else if (key.indexOf("_search") != -1) {
            //文本搜索
            NewFilForm[key.split("_")[0]] = this.filForm[key].key;
          } else {
            //其他
            //多选框
            if (this.filForm[key] instanceof Array) {
              //将key截取出来
              let arr = [];
              for (const filItem of this.filList) {
                if (filItem.targetId == key && filItem.inputType != "region" && filItem.inputType != "region2") {
                  //多选框
                  for (const item of this.filForm[key]) {
                    arr.push(item.split("^")[0]);
                  }
                  NewFilForm[key] = arr.join(",");
                } else if (
                  filItem.targetId == key &&
                  (filItem.inputType == "region" || filItem.inputType == "region2")
                ) {
                  //地区联动框
                  //找到key找数据
                  let keyVal = key + "dzA";
                  // NewFilForm[key]  = this.filForm[keyVal][this.filForm[keyVal].length - 1];
                  NewFilForm[key] =
                    this.filForm[keyVal] && this.filForm[keyVal].length > 0
                      ? this.filForm[keyVal][this.filForm[keyVal].length - 1]
                      : "";
                }
              }
            } else {
              //否-》输入框 是-》数字框，单选框,
              NewFilForm[key] =
                this.filForm[key].indexOf("^") != "-1"
                  ? this.filForm[key].split("^")[0]
                  : $.trim(this.filForm[key]);
            }
          }
        }
      }

      //定义return 到父页面的查询入参对象
      let paramsObj = {};
      //定义一个新数组 格式化成如上数据
      let newFilterArr = [];
      for (const itemL1 of this.filList) {
        paramsObj[itemL1.targetId] = "";
        if (itemL1.autoQuery == "1") {
          //拼装对象
          let targetObj = {};
          let val = "";
          for (const key in NewFilForm) {
            if (itemL1.targetId == key) {
              val = NewFilForm[key];
              break;
            }
          }
          targetObj = {
            inputType: itemL1.inputType,
            field: itemL1.targetId,
            sqlField: itemL1.sqlField,
            fieldType: itemL1.fieldType,
            logic: itemL1.logic,
            value: val,
            autoQuery: itemL1.autoQuery, //是偶拼接参数，1拼接，0 放到原来的参数
            multiple: itemL1.multiple,
            extSql: itemL1.extSql
          };
          newFilterArr.push(targetObj);
        } else {
          //拼装海信采购空参
          // paramsObj[itemL1.targetId] = "";
          // NewFilForm[itemL1.targetId] = '';
        }
      }
      // paramsObj = { ...paramsObj, paramList: newFilterArr };

      //NewFilForm 海信采购查询用 paramsObj渠道采购查询用
      // this.$emit("search", [
      //   { ...paramsObj, ...NewFilForm },
      //   { ...paramsObj, paramList: newFilterArr }
      // ]);
      this.$emit("search", [
        { ...paramsObj, ...NewFilForm },
        { paramList: newFilterArr }
      ]);
    },
    //日期选择
    indateChange(date, dateString, targetId) {
      this.filForm[targetId] =
        date && date.length > 0 ? dateString.join("_") : null;
    },
    dateChange(date, dateString, targetId) {
      this.filForm[targetId] = dateString ? dateString : null;
    },
    //数字选择
    innumChange(targetId) {
      this.filForm[targetId] =
        (this.filForm[targetId + "innum1"]
          ? this.filForm[targetId + "innum1"]
          : "") +
        "_" +
        (this.filForm[targetId + "innum2"]
          ? this.filForm[targetId + "innum2"]
          : "");
    },
    //样式
    setStyle(idx) {
      let style = {};
      //设置两条表单之间边距
      if ((idx + 1) % 3) {
        style.marginRight = this.itemDistance + "px";
      }

      return style;
    },

    //文本框搜索
    async handleSearch(val, item) {
      this.searchLoading = true;

      //依赖的父级数组
      let parentArr = [];
      //多个父级
      if (item.parentInput && item.parentInput.indexOf(",") != -1) {
        parentArr = item.parentInput.split(",");
      } else if (item.parentInput) {
        //一个父级
        parentArr.push(item.parentInput);
      }
      //获取热搜入参-》根据父级的值来请求
      let params = {};
      for (const key in this.filForm) {
        for (const e of parentArr) {
          if (e == key && this.filForm[key]) {
            if (Array.isArray(this.filForm[key])) {
              //多选框
              if (this.filForm[key].length > 0) {
                params[key] = this.filForm[key]
                  .map(j => {
                    return j.split("^")[0];
                  })
                  .join(",");
              }
            } else {
              //单选框
              params[key] = this.filForm[key].split("^")[0];
            }
          }
        }
      }
      //   let list = [
      //   'led50k300u','led50k300u6666'
      // ]
      // this.searchList = list;
      let res = await http({
        method: "get",
        url: item.dictURL,
        params: { term: val, ...params }
        // params:{
        //   term:val,
        //   // matklId:'20160119087' //后续待加
        // }
      });
      if (res.data.code == 0) {
        this.searchLoading = false;
        this.searchList = res.data.list;
      }
    },
    handleSelect(item) {
      // this.filForm[item.targetId] = this.filForm[item.targetId + '_search'].key;
      this.searchList = [];
      this.searchLoading = false;
    },
    //下拉框变动
    sltChange(item) {
      for (const e of this.filList) {
        //类型是热搜框且父级有该选择项 -》 重置
        if (
          e.inputType == "selectSearch" &&
          e.parentInput.indexOf(item.targetId) != "-1"
        ) {
          if (this.filForm[e.targetId + "_search"]) {
            this.$message.info(
              e.title + " 关联的" + item.title + "已变动，请重新选择",
              3
            );
            this.filForm[e.targetId] = undefined;
            this.filForm[e.targetId + "_search"] = undefined;
          }
        }
      }
    },
    //暂不使用
    labelColFun(idx) {
      let len = JSON.parse(JSON.stringify(this.maxSize))[idx % 3];
      if (idx)
      return len / 2;
    },
    //暂不使用
    wrapperColFun(idx) {
      return 14;
    }
  }
};
</script>

<style lang="less" scoped>
.formFiltrate-box {
  max-width: 1188px;
  margin: 0 auto;
  height: 178px;
  overflow: hidden;
  background: #fff;
  position: relative;
  transition: all 0.2s;
  &.more {
    height: auto;
  }
  .tool-box {
    position: absolute;
    width: 224px;
    height: 32px;
    top: 16px;
    right: 0px;
    button {
      float: left;
      letter-spacing: 1px;
      padding: 0;
      + button {
        margin-left: 16px;
      }
    }
    .zk {
      margin-top: 3px;
      margin-right: 18px;
      float: right;
    }
  }
  .form {
    width: 100%;
    height: auto;
    padding: 66px 22px 0 0px;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    // .ant-form-item {
    //   width: 33.33%;
    //   float: left;
    // }
    li {
      float: left;
      margin-bottom: 24px;
      > span {
        float: left;
        font-size: 14px;
        color: #262626;
        text-align: right;
        line-height: 32px;
      }
      .form-item-box {
        width: 170px;
        height: 32px;
        // border: 1px solid;
        float: left;
        margin-left: 10px;
      }
    }
    li:nth-child(3n + 0) {
      margin-right: 0px;
    }
  }

  // 表单样式
  //输入框
  /deep/.ant-input-affix-wrapper .ant-input {
    padding: 0 16px;
    line-height: 32px;
    color: #262626;
    font-size: 14px;
    background-color: #fff;
    // border: 1px solid #eee;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  //下拉选择框
  /deep/.ant-select {
    width: 100%;
    .ant-select-selection__rendered {
      margin-right: 24px;
      overflow: hidden;
      ul {
        width: 999px;
      }
    }
    .ant-select-selection {
      border: 1px solid #ddd;
    }
    .ant-select-selection__rendered {
      margin-left: 16px;
    }
  }
  //日期选择框
  /deep/.ant-calendar-picker {
    width: 100%;
    .ant-calendar-picker-input {
      border: 1px solid #ddd;
      &:hover {
        border-color: #aaa;
      }
    }
    .ant-calendar-picker-input.ant-input {
      text-align: left;
    }
    .ant-calendar-range-picker-input {
      width: 39%;
    }
  }
  /deep/.ant-cascader-picker {
    width: 100%;
  }
  //数字选择框
  .number-range-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-input-number {
      width: 44%;
    }
  }
}
</style>
