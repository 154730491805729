<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="648"
    :closable="false"
    centered
    :maskClosable="false"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>新建用户来源
      </span>
    </div>
    <div class="cancelContent">
      <div class="btnGroup">
        <div
          v-for="(item,index) in userSorceListNew"
          :key="index"
          @click="changeSorceBtns(item)"
          :class="item.id===SorceIdsNow ? 'clickBtnActive':'clickBtn'"
        >
          {{ item.source }}
        </div>
      </div>
    </div>
    <div class="cancelInfo">
      <span class="cancleZi">自定义:</span>
      <a-input
        placeholder="请输入用户来源"
        @change="textChange"
        :value="textarea"
        v-model="textarea"
      />
    </div>
  </a-modal>
</template>

<script>
import service from "@/utils/request";

export default {
  name: "addUserSource-modal",
  data() {
    return {
      fetching: false,
      submitBtn: true,
      list: [],
      isEdit: false,
      reason: "",
      textarea: "",
      SorceIdsCherk: "",
      SorceIdsNow:'',
      userSorceListNew: []
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    userSorceList: {
      type: Array,
      default: () => []
    },
    SorceIds: {
      type:  String,
      default:''

    }
  },
  watch: {
    SorceIds: {
      immediate: true,
      handler(newValue) {
        this.SorceIdsCherk = newValue;
        this.SorceIdsNow = newValue
      }
    },
    userSorceList: {
      immediate: true,
      handler(newValue) {
        this.userSorceListNew = newValue;
        if(this.userSorceListNew.length > 0) {
          this.userSorceListNew.forEach(item=>{
            item.id = (item.id).toString()
          })
        }

      }
    },
    visible: {
      immediate: true,
      handler(newValue) {
      }
    }
  },
  mounted() {
  },
  methods: {
    //查询数据
    search() {

    },
    changeSorceBtns(item) {
      if(this.SorceIdsNow == item.id) {
        this.SorceIdsNow = ''
        return
      }
      this.SorceIdsNow = item.id;
      // this.$emit("changeSorceBtn", item);
      this.$forceUpdate();
    },
    textChange(e) {
      if (e.target.value) {
        this.submitBtn = false;
      }
      // this.textarea = e.target.value;
    },
    changeInput(value) {
      this.submitBtn = true;
    },
    handleOk() {
      if (!this.textarea.trim()) {
        this.$emit("cancelReasonHandOk",this.textarea, this.SorceIdsNow);
        this.$emit("update:visible", false);
        return
      }
      this.$emit("update:visible", false);
      this.$emit("cancelReasonHandOk", this.textarea);
      this.textarea = "";
    },
    cancle() {
      this.SorceIdsNow = this.SorceIdsCherk
      this.$emit("changeBtnnew", this.SorceIdsCherk);
      this.$emit("update:visible", false);
      this.textarea = "";


    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      .cancelContent {
        .btnGroup {
          display: flex;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
        }

        display: flex;
        justify-content: center;
        width: 470px;

        .clickBtn {
          cursor: pointer;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 16px;
          font-size: 13px;
          font-weight: 400;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 16px;
          border: none;
          font-size: 13px;
          font-weight: 400;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        margin-bottom: 36px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 56px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px
        }

        .ant-input {
          width: 300px;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 0px 0 30px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


