<!--
 * @Author: sqk
 * @Date: 2020-08-21 16:11:33
 * @LastEditTime: 2021-01-30 16:13:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\Btn\Btn.vue
-->

<template>
  <a-button
    v-if="isBtnShow"
    v-bind="$attrs"
    :loading="isLoading"
    :type="level == '2' || level == '3' ? 'link' : type"
    :style="setStyle()"
    @click="click"
    class="h-btn"
    :class="[level == '3' ? 'ant-btn-link-levelThree' : '',customType ? ('type' + customType) : '' ]"
  >
    <a-icon v-if="iconSite == 'left' && icon" :type="icon" />
    <span>
      <slot>{{ content }}</slot>
    </span>
    <a-icon v-if="iconSite == 'right' && icon" :type="icon" />
  </a-button>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "h-btn",
  data() {
    return {
      
    };
  },
  props: {
    width: {
      type: [Number, String],
      default: 84
    },
    height: {
      type: [Number, String],
      default: 28
    },
    content: {
      type: String,
      default: "按钮"
    },
    type: {
      type: String,
      default: "primary"
    },
    customType: {
      type: [Number, String],
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    iconSite: {
      type: String,
      default: "left"
    },
    borderRadius: {
      type: [Number, String],
      default: "4"
    },
    fontSize: {
      type: [Number, String],
      default: "14"
    },
    level: {
      type: [Number, String],
      default: "1"
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    permissionCode:{
      type: String,
      default: ""
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
      btnList: state => state.user.btnList
    }),
    isBtnShow(){
      if(this.permissionCode && this.btnList && this.btnList.length > 0){
        for (const item of this.btnList) {
          if(item == this.permissionCode){
            return true
          }
        }
        return false
      }else{
        return true
      } 
    }
  },
  mounted() {},
  methods: {
    setStyle() {
      let style = {};
      style.width = this.width + "px";
      style.height = this.height + "px";
      style.lineHeight = this.height - 2 + "px";
      style.borderRadius = this.borderRadius + "px";
      style.background = this.background;
      style.color = this.color;
      style.fontSize = this.fontSize  + "px";
      style.borderColor = this.borderColor;
      style.border = this.border;
      return style;
    },

    click() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="less" scoped>
@blue: #1890ff;
@green: #00aaa6;
.h-btn.ant-btn {
  text-align: center;
  height: auto;
  padding: 0 14px;
  &.ant-btn-primary{
    &[disabled]{
      border-color: @green;
      background-color: @green;
      color: #fff;
      opacity: 0.4;
    }
  }
  
  &.type2 {
    background-color: #FF8F3C;
    color: #fff;
    border: 1px solid #FF8F3C;
    &:hover {
      background-color: #FB9A53;
      border: 1px solid #FB9A53;
    }
    &:active{
      background-color: #F2802C;
      border: 1px solid #F2802C;
    }
  }
  &.type3 {
    background-color: #aaa;
    color: #fff;
    border: 1px solid #aaa;
    &:hover {
      background-color: #999;
      border: 1px solid #999;
    }
    &:active{
      background-color: #777;
      border: 1px solid #7777;
    }
  }
  &.type4 {
    background-color: #E4F8F7;
    color: #00AAA6;
    border: 1px solid #E4F8F7;
    &:hover {
      background-color: #dceded;
      border: 1px solid #dceded;
    }
    &:active{
      background-color: #cbdfdf;
      border: 1px solid #cbdfdf;
    }
  }
}
button.ant-btn-default {
  background-color: #aaa;
  color: #fff;
  border: 1px solid #aaa;
  &:hover,
  &:focus {
    background-color: #bbb;
    color: #fff;
    border: 1px solid #bbb;
  }
}
.h-btn.button,
.ant-btn-link {
  color: @blue;
  &:focus {
    color: @blue;
  }
  &:hover {
    color: @blue;
  }
}
button.ant-btn-link-levelThree {
  color: #777777;
  &:focus {
    color: @green;
  }
  &:hover {
    color: @green;
  }
}
</style>
