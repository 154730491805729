export default [{
  path: "/boothStoreExpenses",
  name: "boothStoreExpenses",
  // redirect: "/confirm"
  component: () => import("@/layout/TopDownLayout/Frame.vue"),
  children: [
    {
      path: "/boothStoreExpenses",
      name: "boothStoreExpenses",
      component: () => import("@/views/boothStoreExpenses/index.vue"),
      meta: {
        title: "展台需求提报",
        keepAlive: false,
        isBarShow: true
      }
    },
    // 编辑
    // {
    //   path: "/boothStoreExpenses/boothStoreEdit",
    //   name: "boothStoreEdit",
    //   component: () => import("@/views/boothStoreExpenses/boothStoreEdit/index.vue"),
    //   meta: {
    //     title: "展台需求提报编辑",
    //     keepAlive: false,
    //     isBarShow: true
    //   }
    // },
    // 查看
    {
      path: "/boothStoreExpenses/boothStoreView",
      name: "boothStoreExpenses/boothStoreView",
      component: () => import("@/views/boothStoreExpenses/boothStoreView/index.vue"),
      meta: {
        title: "展台需求提报查看",
        keepAlive: false,
        isBarShow: true
      }
    },
    // 新增
    {
      path: "/boothStoreExpenses/boothStoreAddAndEdit",
      name: "boothStoreExpenses/boothStoreAddAndEdit",
      component: () => import("@/views/boothStoreExpenses/boothStoreAddAndEdit/index.vue"),
      meta: {
        title: "展台需求提报新增",
        keepAlive: false,
        isBarShow: true
      }
    }


  ]
}];