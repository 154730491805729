/*
 * @Author: sunqingkun
 * @Date: 2020-11-17 16:08:23
 * @LastEditTime: 2024-08-23 09:09:16
 * @LastEditors: 尚宏浩 aaa@hisense.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\store\modules\filtrate.js
 */

import util from "./util";
import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
import store from "@/store";
import {
  systemConfigFormItem, //获取筛选列表接口
  querySelfQueryConfig
} from "@/utils/api.js";
const state = {
  isFenXiao: "N", //是否是分销商 Y-》是-》渠道采购    N-》否-》海信采购
  filterConfigStr: "", //调用筛选接口的入参
  filList: [], //筛选组件数据
  isLoading: false, //筛选组件loading状态
  sysdate: "",
  // productDftFilList:[],//商品橱窗 默认选中列表
};

const mutations = {
  SET_CONFIG_STR: (state, params) => {
    let filterConfigStr = "";
    state.filterConfigStr = "";
    //获取用户信息
    let userInfo = store.state.user.userInfo
      ? store.state.user.userInfo
      : localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : "";
    //渠道采购海信采购编号字符串 17451是渠道采购，17452或者17453 海信采购 （可能同时存在）.parentInput.indexOf(",") != -1
    if (!userInfo) {
      return;
    }

    // 判断时海信采购还是渠道采购账号 (marketModels.indexOf("17452") != -1 || marketModels.indexOf("17453") != -1) -> 海信采购 暂时不用
    // let marketModels;

    // if (params.marketModels) {
    //   marketModels = params.marketModels;
    // } else {
    //   marketModels = userInfo.account.marketModels;
    // }

    switch (params.toPath.path) {
      case "/order/cis":
        //订单中心
        // filterConfigStr = "ordersCenter";
        break;
      case "/order/dms":
        //订单中心
        // filterConfigStr = "dmsOrderList";
        break;
      case "/cispurchaseProgress":
        filterConfigStr = "hisenseSalesProgress";
        break;
      case "/index":
        filterConfigStr = "hisenseSalesProgress";
        // filterConfigStr = "dmsSalesOrderCondition";
        break;
      case "/channelOrder/orderList":
        filterConfigStr = "dmsSalesOrderCondition";
        break;
      case "/stockCenter/stockAdjustment": //库存调整记录查询
        filterConfigStr = "dmsStockTransCondition";
        break;
      case "/stockCenter/myStock": //我的库存查询
        filterConfigStr = "dmsInventoryCondition";
        break;
      case "/stockCenter/distributorStock": //分销商库存查询
        filterConfigStr = "dmsInventoryHisenseCondition";
        break;
      case "/dmspurchaseProgress":
        // 渠道采购进度
        filterConfigStr = "dmsPurchaseOrderProcessCondition";
        break;
      case "/channelOrder/salesProgress":
        // 销售采购进度
        filterConfigStr = "dmsSalesOrderProcessCondition";
        break;
      case "/cispurchaseProgress1":
        // 海信采购进度
        filterConfigStr = "hisenseSalesProgress";
        break;
      case "/stockCenter/exceedStock": //超期库存查询
        filterConfigStr = "exceedStockList";
        break;
      case "/stockCenter/shareLog": //超期库存申请记录查询
        filterConfigStr = "exceedStockListLog";
        break;
      case "/stockCenter/inventoryLog": //库存村流水
        filterConfigStr = "dmsAllInventoryLogCondition";
        break;
      case "/stockCenter/inventoryLogNew": //库存新流水
        filterConfigStr = "dmsAllGicInventoryLogCondition";
        break;
      case "/channelOrder/orderReview":
        filterConfigStr = "dmsDistributorRetailOrderCondition";
        break;
      // case "/channelOrder/orderList":
      //   filterConfigStr = "dmsSalesOrderCondition";
      //   break;
      case "/center/inspection": //寻店查询
        filterConfigStr = "exceedStockListLog";
        break;
      case "/marketActivityResult":
        filterConfigStr = "subscribeList";
        break;
      case "/salesOrderChange/list":
        filterConfigStr = "dmsSalesOrderChangeCondition";
        break;
      // 我的oto
      case "/center/orderoTo":
        filterConfigStr = "ordero2o";
        break;
      case "/channelOrder/distributorPurchase":
        //分销直采订单
        filterConfigStr = "salesOrderListFx";
        break;
      case "/center/returnGoods":
        //统仓退货
        filterConfigStr = "unifyWarehouseReturnList";
        break;
      // 分销商退货列表
      case "/channelReturns/distributorReturnList":
        filterConfigStr = "dmsDistributorReturnOrderListCondition";
        break;
      // 代理商入库列表
      case "/channelReturns/agentWarehousingList":
        filterConfigStr = "dmsAgentReturnOrderListCondition";
        break;
      case "/hisenseReturn/defectiveReturnList":
        //残次退列表
        filterConfigStr = "defectiveList";
        break;
      case "/reports/operation":
        //运营商结构报表
        filterConfigStr = "reportStructure";
        break;
      case "/channelWholesalePrice/businessChannelPriceList":
        //渠道批发价格商家列表
        filterConfigStr = "channelPriceDistributor";
        break;
      case "/productChannelWholesalePrice/productChannelPriceList":
        //渠道批发价格产品列表
        filterConfigStr = "channelPriceProduct";
        break;
      case "/reports/purchase":
        //采购报表
        filterConfigStr = "salesReportList";
        break;
      case "/threeQualityManagement/thirdQradeReceiving":
        //三品提报
        filterConfigStr = "pmsShopPlanShopPageQuery";
        break;
      case "/threeQualityManagement/thirdQradeReport":
        //三品收货
        filterConfigStr = "omsShopDeliveryShopPageQuery";
        break;
      case "/activityConfigurationList":
        // 活动配置
        filterConfigStr = "marketActivity";
        break;
      case "/stockCenter/unsettledReservation":
        // 未结预留
        filterConfigStr = "notReleaseReserveCondition";
        break;

      case "/basicInfo":
        if (params.toPath.fullPath == "/basicInfo?type=1") {
          filterConfigStr = "accountList";
        }
        // 人员档案管理
        if (params.toPath.fullPath == "/basicInfo?type=2") {
          filterConfigStr = "personList";
        }
        // 合作的分销商
        if (params.toPath.fullPath == "/basicInfo?type=3") {
          filterConfigStr = "distributorList";
        }
        // 贸易关系
        if (params.toPath.fullPath == "/basicInfo?type=4") {
          filterConfigStr = "custBaseMarket";
        }
        // 收货仓库
        if (params.toPath.fullPath == "/basicInfo?type=5") {
          filterConfigStr = "custShopInfoCk";
        }
        // 我的门店
        if (params.toPath.fullPath == "/basicInfo?type=6") {
          filterConfigStr = "custShopInfo";
        }
        // 分销的抢单
        if (params.toPath.fullPath == "/basicInfo?type=7") {
          filterConfigStr = "custShopInfoFx";
        }
        // 分销商的仓库
        if (params.toPath.fullPath == "/basicInfo?type=8") {
          filterConfigStr = "fxShopCkList";
        }
        //我的下沉门店
        if(params.toPath.fullPath == '/basicInfo?type=9') {
          filterConfigStr = "custShopInfoXc";
        }
         // 电子合同
         if (params.toPath.fullPath == "/basicInfo?type=10") {
          filterConfigStr = "customerInfoSign";
        }
        // 政策合同
        if (params.toPath.fullPath == "/basicInfo?type=11") {
          filterConfigStr = "custPolicyInfoList";
        }

        break;
      case "/stockCenter/stockAgeQuery":
        // 库龄查询
        filterConfigStr = "exceedInvageList";
        break;
      case "/assessmentNotice":
        // 考核通知单
        filterConfigStr = "custAssessNotice";
        break;
      case "/stockCenter/inventorySnapshot": //库存快照
        filterConfigStr = "baseGicInventoryTmp";
        break;
      case "/stockCenter/issueDetails":
        filterConfigStr = "dmsGicInventoryLogSummaryCondition";
        break;
      //  展台需求门店
      case "/boothStoreExpenses":
        filterConfigStr = "exhibitionStandDemandReport";
        break;
      case "/IntentionList":
        filterConfigStr = "shopPotentialUser";
        break;
      case "/touchRecord":
        filterConfigStr = "shopPotentialShortMessage";
        break;
      case "/OpportunityList":
        filterConfigStr = "shopPotentialProductList";
        break;
      case "/hisenseReturn/defectiveReturnListNew":
        filterConfigStr = "defectiveNewList";
        break;
      case "/hisenseReturn/hisenseReturnList":
        filterConfigStr = "returnOrderList";
        break;
      case "/installationRepairReporting/repairReportinglist":
        filterConfigStr = "reportInstallRepair";
        break;
      case "/serviceFeeModule/serviceFreeList":
        filterConfigStr = "orderServiceFee";
        break;
      case "/engineeringReport":
        filterConfigStr = "gongchengtibao";
        break;
      // 管辖的门店
      case "/jurisdiction/store":
        filterConfigStr = "shopInfoApplyGrid";
        break;
      // 管辖的仓库
      case "/jurisdiction/warehouse":
        filterConfigStr = "shopInfoCkApplyGrid";
        break;
      // 管辖的商家
      case "/jurisdiction/merchants":
        filterConfigStr = "customerInfoGrid";
        break;
      //  退货
      case "/salesReturn/returnReceiptList":
        filterConfigStr = "getSalesReturnList";
        break;
      case "/salesReturn/genuineReturnList":
        filterConfigStr = "agentReturnOrderListNew";
        break;
      case "/purchaseReturn/purchaseReturnList":
        filterConfigStr = "getPurchaseReturnList";
        break;
      case "/purchaseReturn/purchaseGenuineList":
        filterConfigStr = "distributorReturnOrderList";
        break;
        // 以旧换新
      case "/tradeInoldForNew":
        filterConfigStr = "oldMachineOrder";
        break;
      default:
    }
    state.filterConfigStr = filterConfigStr;

    
  },
  SET_FILLIST: (state, filList) => {
    state.filList = filList;
  },
  SET_FILLOADING: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  SET_SYSDATE: (state, sysdate) => {
    state.sysdate = sysdate;
  },
  // SET_PRODUCT_DFT_LIST: (state, productDftFilList) => {
  //   state.productDftFilList = productDftFilList;
  // },
};

const actions = {
  systemConfigFormItem({ dispatch, commit, state }) {
    if (!state.filterConfigStr) {
      return;
    }
    //筛选请求开始 -> 开启loadidng
    commit("SET_FILLOADING", true);
    //商品橱窗的筛选需要加上catalogId -》 productList+catalogId 产品列表 其他无需多加
    let param = {
      formCode: state.filterConfigStr,
      filter: store.state.user.toPath.query.filter
        ? store.state.user.toPath.query.filter
        : "",
      onlyUserParam: store.state.user.toPath.query.onlyUserParam
        ? store.state.user.toPath.query.onlyUserParam
        : "",
    };
    Promise.all([
      systemConfigFormItem(param),// 表单
      querySelfQueryConfig({ account: store.state.user.userInfo.account.id}),// 缓存的表单顺序
    ]).then((res) => {
      if (res[0].status == 200 && res[0].data.code == 0) {
        //异步获取二次请求数据后拼接列表数据
        commit("SET_SYSDATE", res[0].data.sysdate);
        
        
        const paramList = res[0].data.systemConfigForm.paramList;
        const formSortList = res[1]?.data?.data || [];
        // 筛选条件最终展示数据
        let afterSortList = [];
        // 有后台缓存
          if(formSortList.some(j=>j.key == state.filterConfigStr)){
            formSortList.map((j,idx)=>{
              if(j.key == state.filterConfigStr){
                 // 不在后台缓存中(新加的筛选条件) -> 筛选条件放到最后
                const newAddParamList = paramList.filter(k=>!j.userData.includes(k.field));
  
                j.userData.split(',').map((e,idx2)=>{
                  for (const item of paramList) {
                    if(e == item.field){
                      // field
                      afterSortList[idx2] = item;
                    }
                  }
                })
                afterSortList = [...afterSortList.filter(j=>j.field), ...newAddParamList];
              }
            })
          }else{
            // 无后台缓存
            afterSortList = paramList;
          }
          
        dispatch("getNewArr", afterSortList.filter(j=>j.field))
        // dispatch("getNewArr", res[0].data.systemConfigForm.paramList)
          // dispatch("getNewArr", util.dataList.systemConfigForm.paramList)
          .then((arr) => {
            commit("SET_FILLIST", []);
            // dispatch('getNewArr',util.windowInitList.systemConfigForm.paramList).then((arr) => {
            commit("SET_FILLOADING", false);
            commit("SET_FILLIST", arr);
            // //返回res
            // resolve(res[0]);
          })
          .catch((error) => {
            console.log(error);
            commit("SET_FILLOADING", false);
          });
      } else {
        //关闭loadidng
        commit("SET_FILLOADING", false);
      }
    }).catch(() => {
      commit("SET_FILLOADING", false);
    });
    // return new Promise((resolve) => {
    //   systemConfigFormItem(param)
    //     .then((res) => {
    //       if (res.status == 200 && res.data.code == 0) {
    //         //异步获取二次请求数据后拼接列表数据
    //         commit("SET_SYSDATE", res.data.sysdate);
    //         dispatch("getNewArr", res.data.systemConfigForm.paramList)
    //           // dispatch("getNewArr", util.dataList.systemConfigForm.paramList)
    //           .then((arr) => {
    //             commit("SET_FILLIST", []);
    //             // dispatch('getNewArr',util.windowInitList.systemConfigForm.paramList).then((arr) => {
    //             commit("SET_FILLOADING", false);
    //             commit("SET_FILLIST", arr);
    //             //返回res
    //             resolve(res);
    //           })
    //           .catch((error) => {
    //             console.log(error);
    //             commit("SET_FILLOADING", false);
    //           });
    //       } else {
    //         //关闭loadidng
    //         commit("SET_FILLOADING", false);
    //       }
    //     })
    //     .catch(() => {
    //       commit("SET_FILLOADING", false);
    //     });
    // });
  },

  // 筛选数据重构
  async getNewArr(content, paramList) {
    //新格式数组拼装
    let paramListNewArr = [];
    for (const key in paramList) {
      //判断是否有可选项
      // if (paramList[key].dictList && paramList[key].dictList.length > 0) {
      let paramListItemObj = {};
      //DMS接口传参使用 Gxx 或 orgId 等
      paramListItemObj.targetId = paramList[key].field;
      //DMS接口传参使用 sqlField
      paramListItemObj.sqlField = paramList[key].sqlField;
      //DMS接口传参使用 fieldType
      paramListItemObj.fieldType = paramList[key].fieldType;
      //DMS接口传参使用 logic
      paramListItemObj.logic = paramList[key].logic;
      //DMS接口传参使用 value  or  默认值 初始化筛选用
      paramListItemObj.defaultValue = paramList[key].value;
      //DMS接口传参使用 autoQuery  用于dms反参是哪种格式 1-》拼接 （将DMS接口传参拼入paramList中传出） 2-》带入 与cis规则相同 放入到整体入参中
      paramListItemObj.autoQuery = paramList[key].autoQuery;
      //DMS接口传参使用 multiple or 是否多选 2 多选   1 单选
      paramListItemObj.multiple = paramList[key].multiple;
      //DMS接口传参使用 extSql
      paramListItemObj.extSql = paramList[key].extSql;

      // 表单编码 -> 暂时给拖拽本地存储记录用
      paramListItemObj.formCode = paramList[key].formCode;
      //筛选条件组名称
      paramListItemObj.title = paramList[key].name;
      //根据inputClass = producttag 是-》混入filter入参字段当入参 否-》单个 入参
      paramListItemObj.inputClass = paramList[key].inputClass;
      //文本框类型 hidden隐藏 其他暂不使用
      paramListItemObj.inputType = paramList[key].inputType;
      //是否必填 -》 必填清空时如果有默认值 自动赋值 无默认值用当前值 都没有则为空搜 && 搜索时判断
      // paramListItemObj.isTrue = paramList[key].isTrue;//->canNull字段 ‘CHECK’

      //请求数据的入参 - 》上一层级字段 -》 无则直接请求接口 有则当作get请求入参
      paramListItemObj.parentInput = paramList[key].parentInput;
      //选项之间发生联动后 二次请求的路径
      paramListItemObj.dictURL = paramList[key].dictURL;
      //根据dictType 判断是否二次请求 2 -》 二次请求 4->dms二次请求
      if (paramList[key].dictType == "2") {
        let params = {};
        //如果parentInput有值则参数拼装并传空 然后空查
        if (paramList[key].parentInput) {
          //拼装空参
          let paramArr = paramList[key].parentInput.split(",");
          for (const key in paramArr) {
            let paramItem = "filter" + (parseInt(key) + 1).toString();
            //入参赋空值 {filter1:'',filter2:''}
            params[paramItem] = "";
          }
        }
        //二次请求 并拼装数据
        let res = await http({
          method: "get",
          url: paramList[key].dictURL,
          params,
        });
        // if (res.status == 200 && res.data.code == 0) {
        paramList[key].dictList = res.data.list;
        // }
      } else if (paramList[key].dictType == "4") {
        let params = {
          formCode: state.filterConfigStr,
          field: paramList[key].field,
        };
        //二次请求 并拼装数据
        let res = await dmshttp({
          url: "getConditionOptions",
          data: params,
        });
        if (res) {
          paramList[key].dictList =
            JSON.parse(res.data).data || JSON.parse(res).data;
        }
      }

      //默认是否使用下拉中的第一项作为默认选项 'N' -》是
      paramListItemObj.canNull = paramList[key].canNull;
      //排序 暂不用
      paramListItemObj.sortNo = paramList[key].orderNo;
      //是否展示更多按钮
      paramListItemObj.isShowMoreBtn = false;
      //是否展示更多
      paramListItemObj.isShowMoreFlag = false;

      paramListItemObj.list = actions.getNewList(
        paramList[key].dictList,
        paramListItemObj.inputType
      );

      paramListNewArr.push(paramListItemObj);
      // }
    }

    // paramListNewArr = [...paramListNewArr,...paramListNewArr]
    return paramListNewArr;
  },
  //获得新格式的 dictList 数组
  getNewList(paramList) {
    let newArr = [];
    for (let key in paramList) {
      //去除无名项
      if (!paramList[key].name) {
        continue;
      }
      newArr.push({
        id: paramList[key].code,
        name: paramList[key].name,
        isActive: false,
      });
    }

    return newArr;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
