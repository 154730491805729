/*
 * @Author: sq
 * @Date: 2020-10-15 13:42:38
 * @LastEditTime: 2023-12-19 10:33:15
 * @LastEditors: chuzhengyang.ex chuzhengyang.ex@hx-partners.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\discountsZone.js
 */

export default [
  // 隐私政策相关
  {
    path: "/privacy",
    name: "privacy",
    redirect: "/privacy",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/privacy",
        component: () => import("@/views/privacy/index.vue"),
        meta: {
          title: "隐私政策",
          isMenuHide: true,
        }
      },
      {
        path: "/personalInfo",
        component: () => import("@/views/privacy/personalInfo.vue"),
        meta: {
          title: "个人信息清单",
          isMenuHide: true,
        }
      },
      {
        path: "/positionInformation",
        component: () => import("@/views/privacy/positionInformation.vue"),
        meta: {
          title: "位置信息",
          isMenuHide: true,
        }
      },
      {
        path: "/telephonePermissions",
        component: () => import("@/views/privacy/telephonePermissions.vue"),
        meta: {
          title: "电话权限",
          isMenuHide: true,
        }
      },
      {
        path: "/accessingPhotoAlbums",
        component: () => import("@/views/privacy/accessingPhotoAlbums.vue"),
        meta: {
          title: "访问相册",
          isMenuHide: true,
        }
      },
      {
        path: "/accessCamera",
        component: () => import("@/views/privacy/accessCamera.vue"),
        meta: {
          title: "访问相机",
          isMenuHide: true,
        }
      },
      {
        path: "/thirdPartyInfo",
        component: () => import("@/views/privacy/thirdPartyInfo.vue"),
        meta: {
          title: "第三方个人信息共享清单",
          isMenuHide: true,
        }
      },
      {
        path: "/shopPersonalInfo",
        component: () => import("@/views/privacy/shopPersonalInfo.vue"),
        meta: {
          title: "个人信息清单",
          isMenuHide: true,
        }
      },
      {
        path: "/shopThirdPartyInfo",
        component: () => import("@/views/privacy/shopThirdPartyInfo.vue"),
        meta: {
          title: "第三方个人信息共享清单",
          isMenuHide: true,
        }
      }
    ]
  }
];
