<template>
  <a-cascader
    :style="setStyle()"
    v-model="addressVal"
    change-on-select
    :disabled="disabled"
    :options="addressData"
    :load-data="loadData"
    placeholder="请选择省、市、区/县、乡镇/街道"
    @popupVisibleChange="popupVisibleChange"
    @change="onChange"
    v-bind="$attrs"
  />
</template>

<script>
//获取联动地区组织
import { getRegin, getReginById } from "@/utils/api";
export default {
  name: "address-from",
  data() {
    return {
      fisrtOpenFlag: false,
      addressTargetId: "",
      addressVal: [],
      addressData: [],
      dftData: [
        {
          value: "340000000000",
          label: "安徽省",
          isLeaf: false,
          loading: false,
          children: [
            {
              value: "340600000000",
              label: "淮北市",
              isLeaf: false,
              loading: false,
              children: [
                {
                  value: "340603000000",
                  label: "相山区",
                  isLeaf: true,
                },
                {
                  value: "340621000000",
                  label: "濉溪县",
                  isLeaf: true,
                },
                {
                  value: "340602000000",
                  label: "杜集区",
                  isLeaf: true,
                },
                {
                  value: "340601000000",
                  label: "市辖区",
                  isLeaf: true,
                },
                {
                  value: "340604000000",
                  label: "烈山区",
                  isLeaf: true,
                },
              ],
            },
            {
              value: "341500000000",
              label: "六安市",
              isLeaf: false,
            },
            {
              value: "340100000000",
              label: "合肥市",
              isLeaf: false,
            },
            {
              value: "340300000000",
              label: "蚌埠市",
              isLeaf: false,
            },
            {
              value: "341700000000",
              label: "池州市",
              isLeaf: false,
            },
            {
              value: "340400000000",
              label: "淮南市",
              isLeaf: false,
            },
            {
              value: "341000000000",
              label: "黄山市",
              isLeaf: false,
            },
            {
              value: "340700000000",
              label: "铜陵市",
              isLeaf: false,
            },
            {
              value: "340800000000",
              label: "安庆市",
              isLeaf: false,
            },
            {
              value: "341200000000",
              label: "阜阳市",
              isLeaf: false,
            },
            {
              value: "341100000000",
              label: "滁州市",
              isLeaf: false,
            },
            {
              value: "341800000000",
              label: "宣城市",
              isLeaf: false,
            },
            {
              value: "340200000000",
              label: "芜湖市",
              isLeaf: false,
            },
            {
              value: "340500000000",
              label: "马鞍山市",
              isLeaf: false,
            },
            {
              value: "341300000000",
              label: "宿州市",
              isLeaf: false,
            },
            {
              value: "341600000000",
              label: "亳州市",
              isLeaf: false,
            },
          ],
        },
        {
          value: "110000000000",
          label: "北京市",
          isLeaf: false,
        },
        {
          value: "500000000000",
          label: "重庆市",
          isLeaf: false,
        },
        {
          value: "350000000000",
          label: "福建省",
          isLeaf: false,
        },
        {
          value: "620000000000",
          label: "甘肃省",
          isLeaf: false,
        },
        {
          value: "440000000000",
          label: "广东省",
          isLeaf: false,
        },
        {
          value: "450000000000",
          label: "广西壮族自治区",
          isLeaf: false,
        },
        {
          value: "520000000000",
          label: "贵州省",
          isLeaf: false,
        },
        {
          value: "460000000000",
          label: "海南省",
          isLeaf: false,
        },
        {
          value: "130000000000",
          label: "河北省",
          isLeaf: false,
        },
        {
          value: "410000000000",
          label: "河南省",
          isLeaf: false,
        },
        {
          value: "230000000000",
          label: "黑龙江省",
          isLeaf: false,
        },
        {
          value: "420000000000",
          label: "湖北省",
          isLeaf: false,
        },
        {
          value: "430000000000",
          label: "湖南省",
          isLeaf: false,
        },
        {
          value: "220000000000",
          label: "吉林省",
          isLeaf: false,
        },
        {
          value: "320000000000",
          label: "江苏省",
          isLeaf: false,
        },
        {
          value: "360000000000",
          label: "江西省",
          isLeaf: false,
        },
        {
          value: "210000000000",
          label: "辽宁省",
          isLeaf: false,
        },
        {
          value: "150000000000",
          label: "内蒙古自治区",
          isLeaf: false,
        },
        {
          value: "640000000000",
          label: "宁夏回族自治区",
          isLeaf: false,
        },
        {
          value: "630000000000",
          label: "青海省",
          isLeaf: false,
        },
        {
          value: "370000000000",
          label: "山东省",
          isLeaf: false,
        },
        {
          value: "140000000000",
          label: "山西省",
          isLeaf: false,
        },
        {
          value: "610000000000",
          label: "陕西省",
          isLeaf: false,
        },
        {
          value: "310000000000",
          label: "上海市",
          isLeaf: false,
        },
        {
          value: "510000000000",
          label: "四川省",
          isLeaf: false,
        },
        {
          value: "120000000000",
          label: "天津市",
          isLeaf: false,
        },
        {
          value: "540000000000",
          label: "西藏自治区",
          isLeaf: false,
        },
        {
          value: "650000000000",
          label: "新疆维吾尔自治区",
          isLeaf: false,
        },
        {
          value: "530000000000",
          label: "云南省",
          isLeaf: false,
        },
        {
          value: "330000000000",
          label: "浙江省",
          isLeaf: false,
        },
      ],
    };
  },
  props: {
    width: {
      type: [Number, String],
      default: 84,
    },
    height: {
      type: [Number, String],
      default: 28,
    },
    addressDftVal: {
      type: Array,
      default: () => [],
    },
    type: {
      type: [Number, String],
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    //编辑状态下 传入code数组 -》 进行默认值赋值操作
    addressDftVal: {
      handler() {
        if (this.addressDftVal && this.addressDftVal.length > 3) {
          this.addressVal = this.addressDftVal; //this.addressDftVal.slice(1, this.addressDftVal.length);
          this.fisrtOpenFlag = true;
          this.editInit();
        } else {
          this.addressVal = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {},
  mounted() {},
  methods: {
    setStyle() {
      let style = {};
      style.width = this.width + "px";
      style.height = this.height + "px";
      return style;
    },

    //编辑时初始化数据
    async editInit() {
      try {
        //获取根据地区code请求的整合数组数据
        const parr = [...["0"], ...this.addressDftVal].map((item) => {
          return new Promise((resolve, reject) => {
            let dataArr = [];
            if (this.type == 1) {
              getRegin({ pCode: item, level: 4 }).then((re) => {
                if (re && re.data && re.data.code == 0) {
                  dataArr = re.data.list.map((item) => {
                    return {
                      value: item["code"],
                      label: item["name"],
                      isLeaf: item["desc"] == "child" ? true : false,
                    };
                  });
                }
                resolve(dataArr);
              });
            } else {
               getReginById({ pid: item, level: 4 }).then((re) => {
                if (re && re.data && re.data.code == 0) {
                  dataArr = re.data.list.map((item) => {
                    return {
                      value: item["code"],
                      label: item["name"],
                      isLeaf: item["desc"] == "child" ? true : false,
                    };
                  });
                }
                resolve(dataArr);
              });
            }
          });
        });
        const res = await Promise.all(parr).then((res_) => {
          //建立父级地区编码与请求子地区数据对应关系 -> 放便整理后续数据格式
          let dftObj = {};
          //拼装最上级编码
          const dftCodeArr = [...["0"], ...this.addressDftVal];
          for (const idx in dftCodeArr) {
            dftObj[dftCodeArr[idx]] = res_[idx];
          }
          //寻找下级，有下级则增加children
          const fun = (item) => {
            for (const itemCode in dftObj) {
              if (item.value == itemCode) {
                if (dftObj[itemCode] && dftObj[itemCode].length > 0 && !item.isLeaf) {
                  item.children = dftObj[itemCode];
                  for (const item_ of item.children) {
                    fun(item_);
                  }
                }
              }
            }
          };
          for (const item of res_[0]) {
            fun(item);
          }

          //获得格式化后的数据
          this.addressData = res_[0];
        });
      } catch (e) {
        console.log(e);
      }
    },
    // 接口联动
    async loadData(selectedOptions) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      let res_ = null;
      if (this.type == 1) {
        res_ = await getRegin({ pCode: targetOption.value, level: 4 });
      } else {
        res_ = await getReginById({ pid: targetOption.value, level: 4 });
      }
      // getRegin({ pCode: targetOption.value, level:4 }).then(res => {
      if (res_.data.code == 0) {
        targetOption.loading = false;
        targetOption.children = res_.data.list.map((item) => {
          return {
            value: item["code"],
            label: item["name"],
            isLeaf: item["desc"] == "child" ? true : false,
          };
        });

        //更新数据
        this.addressVal = [...this.addressVal];
      }

      // });
    },
    // 地址弹窗打开关闭
    async popupVisibleChange(isOpen) {
      if (isOpen == false && this.addressVal.length < 4) {
        this.addressVal = [];
        this.$emit("onAddressChange", []);
        return;
      }
      //第一次打开 或者 清空后无数据 --》请求数据
      if (isOpen && (!this.fisrtOpenFlag || !this.addressVal)) {
        //定义成数组类型
        this.fisrtOpenFlag = true;
        let res_ = null;
        //弹开
        if (this.type == 1) {
          res_ = await getRegin({ pCode: 0 });
        } else {
          res_ = await getReginById({ pid: 0 });
        }
        // getRegin({ pCode: 0 }).then(res => {
        if (res_.data.code == 0) {
          this.addressData = res_.data.list.map((item) => {
            return {
              value: item["code"],
              label: item["name"],
              isLeaf: false,
            };
          });
        }

        // });
      } else {
        // this.fisrtOpenFlag = false;
      }
    },

    // 选择后触发
    onChange(value) {
      if (value.length > 3 || value.length == 0) {
        this.$emit("onAddressChange", this.addressVal);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@blue: #1890ff;
@green: #00aaa6;
.ant-cascader-picker {
  /deep/input {
    width: 100%;
    height: 100%;
  }
}
</style>
