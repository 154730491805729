export default [
  {
    path: "/IntendedUserEntry",
    name: "IntendedUserEntry",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/IntendedUserEntry",
        component: () => import("@/views/userOperations/IntendedUserEntry/index.vue"),
        meta: {
          title: "意向用户录入", //配置title
          keepAlive: false, //是否缓存
          isBarShow: false
        }
      },
      {
        path: "/IntentionList",
        component: () => import("@/views/userOperations/IntentionList/index.vue"),
        meta: {
          title: "意向用户列表", //配置title
          keepAlive: false, //是否缓存
          isBarShow: true
        }
      },
      {
        path: "/IntentionDetail",
        component: () => import("@/views/userOperations/IntentionDetail/index.vue"),
        meta: {
          title: "意向用户详情", //配置title
          keepAlive: false, //是否缓存
          isBarShow: false
        }
      },
      {
        path: "/OpportunityList",
        component: () => import("@/views/userOperations/OpportunityList/index.vue"),
        meta: {
          title: "销售机会", //配置title
          keepAlive: false, //是否缓存
          isBarShow: false
        }
      },
      {
        path: "/OpportunityDetail",
        component: () => import("@/views/userOperations/OpportunityDetail/index.vue"),
        meta: {
          title: "销售机会详情", //配置title
          keepAlive: false, //是否缓存
          isBarShow: false
        }
      },
      {
        path: "/touchRecord",
        component: () => import("@/views/userOperations/touchRecord/index.vue"),
        meta: {
          title: "触达记录", //配置title
          keepAlive: false, //是否缓存
          isBarShow: true
        }
      },

    ]
  }
];

