/*
 * @Description:
 * @Version: 2.0
 * @Autor: sqk
 * @Date: 2020-08-10 14:50:06
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-20 14:03:08
 */

import Vue from "vue";
import VueRouter from "vue-router";
import CommonRouters from "./common";
import routes from "./modules";

Vue.use(VueRouter);

//动态菜单盒子
export let asyncRoutes = [];

const createRouter = () =>
  new VueRouter({
    // mode: "history", // 需要服务端支持
    base: "/",
    scrollBehavior: () => ({ y: 0 }),
    routes: routes.concat(CommonRouters),
    beforeRouteEnter() {
      // 在当前路由改变，但是该组件被复用时调用
    }
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  asyncRoutes = [];
  router.matcher = newRouter.matcher; //reset router
}

export default router;
