
export default [
    {
        path: "/storeMarketingActivities",
        name: "storeMarketingActivities",
        redirect: "/storeMarketingActivities",
        component: () => import("@/layout/TopDownLayout/Frame.vue"),
        children: [
            {
                path: "/storeMarketingActivities",
                component: () => import("@/views/storeMarketingActivities/index.vue"),
                meta:{
                    title:"代理商市场活动",
                }
            },
            {
                path: "/storeMarketingActivities/activityView",
                component: () => import("@/views/storeMarketingActivities/activityView/index.vue"),
                meta:{
                    title:"活动查看",
                }
            },
            {
                path: "/storeMarketingActivities/addOredit",
                component: () => import("@/views/storeMarketingActivities/addOredit/index.vue"),
                meta:{
                    title:"活动新增",
                }
            },
        ]
    }
];
  