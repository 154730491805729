export default [
  {
    path: "/intendedMerchant",
    name: "intendedMerchant",
    // redirect: "/intendedMerchant",
    // component: () => import("@/layout/TopDownLayout/Frame.vue"),
    component: () => import("@/views/IntendedMerchant/index.vue"),
    children: [
      {
        path: "/intendedMerchant",
        component: () => import("@/views/IntendedMerchant/index.vue")

      }
    ]
  },
  {
    path: "/IntendedMerchantSuccess",
    name: "IntendedMerchantSuccess",
    // redirect: "/intendedMerchant",
    // component: () => import("@/layout/TopDownLayout/Frame.vue"),
    component: () => import("@/views/IntendedMerchantSuccess/index.vue"),
    children: [

      {
        path: "/IntendedMerchantSuccess",
        component: () => import("@/views/IntendedMerchantSuccess/index.vue")

      }
    ]
  }
];
  