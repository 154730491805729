<template>
  <a-modal 
    v-model="visible" 
    @ok="handleOk" 
    @cancel="cancle" 
    wrapClassName="name" 
    :width='maskWidth'
    :closable="false"
    centered
    :maskClosable='false'
    >
    <a-icon v-if="type=='delete'" type="exclamation-circle" />
    <a-icon  v-if="type=='confirm'" type="check-circle" style="color: #1890FF"/>
    <div v-if="messageList && messageList.length > 0">
      <p style="text-align: center;" v-for="item in messageList" :key="item">
        {{item}}
      </p>
    </div>
    <p v-else>
      {{info}}
    </p>
    <template slot="footer" v-if="showBtn">
      <a-button class="ant-btn-primary" style="border: 1px solid #CCCCCC;color: #aaa;background: #fff;box-shadow: none;text-shadow: none" @click="cancle">返回核对</a-button>
      <a-button class="ant-btn-primary" @click="handleOk">确定提交</a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  // 示例
  // <delete-modal 
  // :visible.sync="visible"
  // info='删除提示文字'
  // @deleteHandOk="handlerok"
  // ></delete-modal>
  name:'delete-modal',
  data(){
    return{
    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    info:{
      type:String,
      default:'确认删除商品吗？'
    },
    type:{
      type:String,
      default:'delete'
    },
    showBtn:{
      type:Boolean,
      default:false
    },
    messageList: {
      type: Array,
      default: []
    },
    maskWidth: {
      type: Number,
      default: 460
    }
  },
  methods:{
    handleOk(){
      this.$emit('update:visible',false)
      this.$emit('deleteHandOk')
    },
    cancle(){
      this.$emit('update:visible',false)
	    this.$emit('deleteHandCancle')
    }
  }
}
</script>
<style lang="less" scoped>
  /deep/ .name{
    .ant-modal-content{
      text-align:center;
      .ant-modal-body{
        padding:30px 0 0px 0;
        .anticon{
          font-size:54px;
          color:#FF8F3C;
          padding-bottom:12px;
        }
        div{
          padding: 0 40px;
          margin-bottom: 40px;
          text-align: left;
          p{
            padding: 0;
            margin: 0;
            line-height: inherit;
          }
        }
        p{
          //height: 24px;
          font-size: 18px;
          //font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #262626;
          line-height: 24px;
          padding: 20px;
          margin-bottom: 20px;
          padding-top: 0px
        }
      }
      .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:24px 0 30px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
        border: none;
      }
    }
    }
    
  }
</style>


