/*
 * @Author: sqk
 * @Date: 2020-08-07 16:16:59
 * @LastEditTime: 2020-11-27 15:46:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\api.js
 */
import http from "@/utils/request";
import dmsService from "@/utils/dmsrequest2";

//橱窗列表数据查询接口
export function productSearch(data) {
  return http({
    method: "post",
    url: "/product/search.nd",
    data
  });
}
//渠道采购库存接口
export function getChannelStockLIST(data) {
  return dmsService({
    url: "hasProductInventory",
    data
  });
}