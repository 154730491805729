<!-- 配置需要显示的列 -->
<template>
	<div class="setCloumnBox">
		<el-button class='btn' slot="reference" @click="setShow = !setShow">调整列</el-button>
		<a-modal v-model="setShow" wrapClassName="setCloumnPop" :width='620' :closable="false" centered
			:maskClosable="false" @ok="handleOk" @cancel="cancle">
			<div class="content">
				<!-- <h2>调整列</h2> -->
				<div class="modal_close" @click="cancle">
					<span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
				</div>
				<div class="modal_title_box">
					<span class="modal_title">
						<i class="modal_title_line"></i>调整列
					</span>
				</div>
				<el-table border :columns="tcolumns" :data="tableColumns_" max-height="500">
					<el-table-column prop="index" label="列序号" width="80">
					</el-table-column>
					<el-table-column prop="columnTitle" label="列名">
					</el-table-column>
					<el-table-column prop="isShow" label="显示/隐藏列" width="158">
						<template slot-scope="scope">
							<a-radio-group v-model="scope.row.isShow" :options="isShowOptions" />
						</template>
					</el-table-column>
					<el-table-column prop="edit" label="操作" width="180">
						<!-- @click.native.prevent="deleteRow(scope.$index, tableData)" -->
						<template slot-scope="scope">
							<el-button @click.native.prevent="toTopFun(scope)" type="text" size="small">
								置顶
							</el-button>
							<el-button @click.native.prevent="toBottomFun(scope)" type="text" size="small">
								置底
							</el-button>
							<el-button @click.native.prevent="toMoveUp(scope)" type="text" size="small">
								上移
							</el-button>
							<el-button @click.native.prevent="toMoveDown(scope)" type="text" size="small">
								下移
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- <template slot="footer">
			<a-button class="ant-btn-primary" @click="setShow = false">知道了</a-button>
			</template> -->
		</a-modal>
	</div>


</template>

<script>
import {
	mapState
} from "vuex";
export default {
	name: "set-table-row-new",
	data() {
		return {
			setShow: false,
			tcolumns: [
				{
					label: '列序号',
					prop: 'index',
				},
				{
					label: '列名',
					prop: 'columnTitle',
				},
				{
					label: '显示/隐藏列',
					prop: 'isShow',
				},
				{
					label: '操作',
					prop: 'edit',
				},
			],
			isShowOptions: [
				{ label: '显示', value: '1' },
				{ label: '隐藏', value: '0' },
			],
			tableColumns_: [],
		};
	},
	props: {
		tableColumns: {
			// 列数据
			type: Array,
			default: []
		},
	},
	watch: {
		tableColumns: {
			handler() {
				this.reGetData();
				// this.$emit('initTableColumns', this.tableColumns_);
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		...mapState({
			// //处理后的筛选数据list
			// filList: state => state.formFiltrate.filList,
			// //筛选组件加载loadding
			// filterLoadFlag: state => state.formFiltrate.isLoading,
			// sysdate: state => state.formFiltrate.sysdate,

			// formCode
			formCode: state => state.formFiltrate.filterConfigStr,
		})
	},
	mounted() {
		// this.setList();
		// this.getRows();
		this.$nextTick(()=>{
			if(this.tableColumns_ && this.tableColumns_.length > 0){
				const tableColumns_Ex = this.tableColumns_.slice();
				for (const item of this.tableColumns) {
					if(item.label == '操作'){
						tableColumns_Ex.push({
							label: "操作",
							width: item.width || 180,
							fixed: "right",
							slot: item.slot,
							isShow: '1',
						})
					}
				}
				// if(this.tableColumns.some(j=>j.label == '操作')){
				// 	tableColumns_Ex.push({
				// 		label: "操作",
				// 		width: 180,
				// 		fixed: "right",
				// 		slot: "btnGroup",
				// 		isShow: '1',
				// 	})
				// }
				this.$emit('initTableColumns', tableColumns_Ex);
			}
		})
		
	},
	methods: {
		handleOk() {
			// this.setShow = false;
			// 将改动后的列数据存到本地存储且 反馈给页面 重新渲染一下列数据
			const this_ = this;
			
			if(this.tableColumns_ && this.tableColumns_.length > 0){
				const tableColumns_Ex = this.tableColumns_.slice();
				for (const item of this.tableColumns) {
					if(item.label == '操作'){
						tableColumns_Ex.push({
							label: "操作",
							width: item.width || 180,
							fixed: "right",
							slot: item.slot,
							isShow: '1',
						})
					}
				}
				this.$emit('initTableColumns', tableColumns_Ex);

				// 存储
				const formCode = this.formCode;
				let tableCloumnsLocalData = [];

				if (localStorage.getItem("tableCloumnsLocalData")) {
					tableCloumnsLocalData = JSON.parse(localStorage.getItem("tableCloumnsLocalData"));
					if (tableCloumnsLocalData.some(j => j.formCode == formCode)) {
						// 有则覆盖缓存
						// debugger
						const newObj_ = {
							formCode: formCode,
							data: tableColumns_Ex
						}
						tableCloumnsLocalData.splice(tableCloumnsLocalData.findIndex(j => j.formCode == formCode), 1, newObj_)
					} else {
						// 无则创建缓存
						tableCloumnsLocalData.push({
							formCode: formCode,
							data: tableColumns_Ex
						})
					}
				} else {
					tableCloumnsLocalData.push({
						formCode: formCode,
						data: tableColumns_Ex
					})
				}
				localStorage.setItem("tableCloumnsLocalData", JSON.stringify(tableCloumnsLocalData));
				this.setShow = false;
			}
			// this.$emit('initTableColumns', this.tableColumns_);
		},
		cancle() {
			this.setShow = false;
			this.reGetData();
		},
		// 重新刷新数据
		reGetData(){
			const formCode = this.formCode;
				let tableColumns_ = [];
				let tableCloumnsLocalData = [];
				// 判断是否有缓存 有->看情况缓存列数据 无->使用传入列数据
				if (localStorage.getItem("tableCloumnsLocalData")) {
					tableCloumnsLocalData = JSON.parse(localStorage.getItem("tableCloumnsLocalData"));
					if (tableCloumnsLocalData.some(j => j.formCode == formCode)) {
						// 判断请求出来的列数据与缓存中的列数据 长度是否相同/项是否相同 -> 相同 用缓存数据  不同 用请求数据
						if (this.tableColumns.length == tableCloumnsLocalData.find(j => j.formCode == formCode).data?.length) {
							tableColumns_ = tableCloumnsLocalData.find(j => j.formCode == formCode).data
							const localItemList = tableCloumnsLocalData.find(j => j.formCode == formCode).data
							for (const item of localItemList) {
								if (this.tableColumns.some(e => e.formCode != item.formCode)) {
									tableColumns_ = this.tableColumns.slice();
									return;
								}
							}
						} else {
							tableColumns_ = this.tableColumns.slice();
						}

					} else {
						tableColumns_ = this.tableColumns.slice();
					}
				} else {
					tableColumns_ = this.tableColumns.slice();
				}
				// tableColumns_ = tableColumns_.filter(j=>j.label != '操作');
				this.tableColumns_ = tableColumns_.filter(j=>j.label != '操作').map((j, index) => {
						return {
							...j,
							index: index + 1,
							columnTitle: j.label,
							isShow: j.isShow === '0' ? '0' : '1',
						}
				})
		},
		// 置顶
		toTopFun(scope) {
			const itemObj = scope.row;
			// 删除
			this.tableColumns_.splice(scope.$index, 1);
			// 增加
			this.tableColumns_.unshift(itemObj);
			// 重新排序
			this.resetSort();
		},
		// 置底
		toBottomFun(scope) {
			const itemObj = scope.row;
			// 删除
			this.tableColumns_.splice(scope.$index, 1);
			// 增加
			this.tableColumns_.push(itemObj);
			// 重新排序
			this.resetSort();
		},
		// 上移
		toMoveUp(scope) {
			if (scope.$index == 0) return;
			this.tableColumns_.splice(scope.$index - 1, 1, ...this.tableColumns_.splice(scope.$index, 1, this.tableColumns_[scope.$index - 1]));
			this.resetSort();
		},
		// 下移
		toMoveDown(scope) {
			if (scope.$index == this.tableColumns_.length - 1) return;
			this.tableColumns_.splice(scope.$index + 1, 1, ...this.tableColumns_.splice(scope.$index, 1, this.tableColumns_[scope.$index + 1]));
			this.resetSort();
		},

		resetSort() {
			// 重新排序
			this.tableColumns_ = this.tableColumns_.map((j, index) => {
				return {
					...j,
					index: index + 1,
				}
			})
		}

	}
};
</script>

<style lang="less" scoped>
.btn {
	text-align: center;
	padding: 0 12px;
	background-color: #00AAA6;
	color: #fff;
	box-sizing: border-box;
	width: 66px;
	height: 28px;
	line-height: 26px;
	border-radius: 4px;
	font-size: 14px;
	border: 1px solid #00AAA6;
	text-align: center;
}

.btn:hover {
	background-color: #1db8b0;
	border-color: #1db8b0;
}

.btn:active {
	background-color: #1db8b0;
	border-color: #1db8b0;
}
.setCloumnBox{
	display: inline-block
}
/deep/ .setCloumnPop {
	.ant-modal-content {
		text-align: center;
		border-radius: 10px;

		.ant-modal-body {
			padding: 30px 24px 0 24px;

			.modal_close {

				position: absolute;
				width: 42px;
				height: 42px;
				top: 0px;
				right: 0;
				line-height: 42px;
				border-radius: 0 10px 0 0;
				text-align: center;
				cursor: pointer;

				.modal_close_icon {
					color: #aaa;
					font-size: 16px;
				}
			}

			.modal_close:hover {
				background: #E4F8F7;

				.modal_close_icon {
					color: #08ABA8;
				}
			}

			.modal_title_box {
				text-align: center;
				//padding-bottom: 50px;

				.modal_title {
					position: relative;
					color: #262626;
					text-align: center;
					z-index: 10;
					font-size: 16px;
					font-weight: 600;
					line-height: 22px;
					letter-spacing: 1px;

					.modal_title_line {
						display: inline-block;
						width: 100%;
						position: absolute;
						top: 13px;
						left: 0;
						border-top: 10px solid #E4F8F7;
						z-index: -10;
					}
				}
			}

			.el-table {
				margin-top: 21px;
				color: #777;
				thead {
					tr{
						color: #000;
						// background-color: #EBEFF4;
						font-weight: 500;
						th{
							background-color: #F5F7FA;
						}
					}
				}
				.ant-radio-wrapper{
					span{
						color: #777;
					}
				}
				.el-button{
					font-size: 14px;
				}
				.el-table__body{
					tbody{
						td{
							padding: 6.5px 0;
						}
					}
				}
			}
		}

		p {
			font-size: 18px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #262626;
			line-height: 30px;
		}

		.ant-modal-footer {
			text-align: center;
			border-top: 0px solid #fff;
			padding: 44px 0px 30px 0;

			.ant-btn {
				width: 120px;
				height: 40px;
				border-radius: 4px;
				font-size: 16px;
				color: #777;
			}

			.ant-btn-primary {
				color: #fff;
				background: #00AAA6;
				margin-left: 24px;
			}
		}
	}

}

.setCloumnPop2 {
	/deep/.ant-modal-content {
		border-radius: 10px;

		.ant-modal-body {
			align-items: center;
			justify-content: center;
			display: flex;
			flex-direction: column;
			padding-bottom: 0px;

		}

		/deep/.ant-modal-footer {
			text-align: center;
			border-top: 0px solid #fff;
			padding: 44px 0px 30px 0;

			.ant-btn {
				width: 120px;
				height: 40px;
				border-radius: 4px;
				font-size: 16px;
				color: #777;
			}

			.ant-btn-primary {
				color: #fff;
				background: #00AAA6;
				margin-left: 24px;
			}
		}
	}

	.modal_close {

		position: absolute;
		width: 42px;
		height: 42px;
		top: 0px;
		right: 0;
		line-height: 42px;
		border-radius: 0 10px 0 0;
		text-align: center;
		cursor: pointer;

		.modal_close_icon {
			color: #aaa;
			font-size: 16px;
		}
	}

	.modal_close:hover {
		background: #E4F8F7;

		.modal_close_icon {
			color: #08ABA8;
		}
	}

	.modal_title_box {
		text-align: center;
		//padding-bottom: 50px;

		.modal_title {
			position: relative;
			color: #262626;
			text-align: center;
			z-index: 10;
			font-size: 16px;
			font-weight: 600;
			line-height: 22px;
			letter-spacing: 1px;

			.modal_title_line {
				display: inline-block;
				width: 100%;
				position: absolute;
				top: 13px;
				left: 0;
				border-top: 10px solid #E4F8F7;
				z-index: -10;
			}
		}
	}

	// /deep/.ant-modal-close-x {
	// 	width: 42px;
	// 	height: 42px;
	// 	border-top-right-radius: 10px;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	.close-icon {
	// 	width: 42px;
	// 	height: 42px;
	// 	text-align: center;
	// 	line-height: 42px;
	// 	i {
	// 		//color: #00aaa6;
	// 		font-size: 16px;
	// 	}
	// 	}
	// 	&:hover {
	// 	background: #e4f8f7;
	// 	i {
	// 		color: #00aaa6;
	// 	}
	// 	}
	// }
}
</style>
