/*
 * @Author: sqk
 * @Date: 2020-08-10 15:46:45
 * @LastEditTime: 2020-10-30 09:30:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\home.js
 */

export default [
  // 首页
  {
    path: "/favorites",
    name: "favorites",
    redirect: "/favorites",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [{
      path: "/favorites",
      component: () => import("@/views/favorites/index.vue"),
      meta: {
        title: "收藏夹", //配置title
        keepAlive: false, //是否缓存
        isBarShow: true,
      },
    }, ]
  }
];