export default [
  {
    path: "/installationRepairReporting",
    name: "installationRepairReporting",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/installationRepairReporting/workOrderSubmission",
        component: () => import("@/views/installationRepairReporting/workOrderSubmission/index.vue"),
        meta: {
          title: "工单提交", //配置title
          keepAlive: false, //是否缓存
          isBarShow: false
        }
      },
      {
        path: "/installationRepairReporting/repairReportinglist",
        component: () => import("@/views/installationRepairReporting/repairReportinglist/index.vue"),
        meta: {
          title: "工单列表", //配置title
          keepAlive: false, //是否缓存
          isBarShow: true
        }
      },



    ]
  }
];

