/*
 * @Author: sunqingkun
 * @Date: 2020-12-24 15:26:00
 * @LastEditTime: 2020-12-24 15:43:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\statisics.js
 */




export default [
    // DEMO
    {
        path: "/statistics",
        name: "statistics",
        redirect: "/statistics",
        component: () => import("@/layout/TopDownLayout/Frame.vue"),
        children: [
            {
                path: "/statistics",
                component: () => import("@/views/statistics/index.vue"),
                meta:{
                    title:"常规应急占比分析",
                    keepAlive: false,
                    isBarShow:true
                }
            },
        ]
    }
];
