<template>
  <div>
    <!--      用户协议-->
    <a-modal class="userAgreementModal" v-model="visible" title="用户协议" :width="842" :height="580"
             :keyboard="false"
             :footer="null"
             :closable="true"
             :maskClosable="false"
             @cancel="disagree"
    >
      <div class="contentBox">
        <h-scroll class="scrollBox" :settings="{wheelPropagation: false}">
          <div class="content">
            <p
              v-html="privacyPolicyInfor.pageContent"
            >{{ privacyPolicyInfor.pageContent ? privacyPolicyInfor.pageContent : "" }}</p>
            <p><span style="font-weight: 600;margin-top: 16px">点击同意并接受即表示您已阅读了解并同意</span><span
              style="text-decoration:underline;color: #00AAA6;cursor:pointer;" @click="gotoPrivacy">《海信信天翁系统隐私政策》</span>
            </p>
          </div>
        </h-scroll>

      </div>
      <div class="btn-group">
        <h-btn
          class="h-btn"
          :fontSize="16"
          :width="140"
          :height="40"
          @click="agree"
        >同意并接受
        </h-btn>
        <h-btn
          class="h-btn"
          :fontSize="16"
          :width="140"
          :height="40"
          style="background:#AAA;border:none;margin-left: 24px"
          @click="disagree"
        >取消
        </h-btn>
      </div>
    </a-modal>

  </div>
</template>
<script>
import { getprivacyPolicyInfo } from "@/views/home/api";

export default {
  // 示例
  // <volume-pop></volume-pop>
  name: "userModal",
  data() {
    return {
      privacyPolicyInfor: {}
    };

  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }

  },
  mounted() {
    this.getprivacyPolicy();

  },
  methods: {
    // 链接到隐私政策
    gotoPrivacy(){
      let routeUrl = this.$router.resolve({
        path: "/privacy",

      });
      window.open(routeUrl.href, "_blank");
    },
    agree() {
      localStorage.setItem("agreePrivacy", true);
      localStorage.setItem('UserAgreementVersionNumber',this.privacyPolicyInfor.pageKey)
      this.$emit("update:visible", false);

    },
    disagree() {
      localStorage.setItem("agreePrivacy", false);
      this.$emit("update:visible", false);

    },
    // 获取隐私政策数据
    getprivacyPolicy() {
      let data = {
        loginPlant: "PC"
      };
      getprivacyPolicyInfo(data).then(res => {
        if (res.data.code == 0) {
          this.privacyPolicyInfor = res.data.list[0];
        } else {
          this.privacyPolicyInfor = [];
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.userAgreementModal {
  /deep/ .ant-modal-close-x {
    display: block !important;
    width: 42px !important;
    height: 42px !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 42px !important;
    text-align: center !important;
    text-transform: none !important;
    text-rendering: auto !important
  }

  /deep/ .ant-modal-close-x:hover {
    background-color: #E4F8F7 !important;
    border-radius: 0px 10px 0px 0px;
    color: #08ABA8 !important;
    width: 42px;
    height: 42px;
  }

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-title {
    color: #262626;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }

  /deep/ .ant-modal-header {
    border-radius: 10px 10px 0 0;
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #262626;
    padding-top: 34px;
    padding-bottom: 16px;
    border: none;
  }

  /deep/ .ant-modal-body {
    padding: 0px 34px 0px 34px;
    //background: linear-gradient(180deg, #FFFFFF 0%, #E1F6F5 100%);÷
    border-radius: 10px !important;

  }

  .ant-modal-content {
    // height: 580px!important;

  }

  .contentBox {
    // width: 1108px;
    // background-color: #eeeeee;
    margin: 0 auto;
    max-height: 640px;
    //padding-top: 10px;
    //padding-bottom: 24px;

    .scrollBox {
      width: 100%;
      position: relative;
      padding-top: 0px;
      //height: 300px;
    }

    .titleBox {
      // height: 109px;
      text-align: left;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 12px;

      .title {
        height: 30px;
        line-height: 30px;
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 500;
        color: #262626;
      }

      .time {
        height: 16px;
        font-size: 12px;
        color: #aaaaaa;
      }
    }

    .content {
      color: #262626 !important;
      //margin-top: 24px;
      text-align: left;
      // max-height: 400px;
      // overflow-y: auto;
      .contentInfo {
        color: #262626!important;
      }
      p{
        color: #262626!important;
      }
    }
  }

  .btn-group {
    text-align: center;
    padding: 24px 0 24px 0;

    .btn-group:last-child {
      background: #aaa !important;
      margin-left: 24px;
    }
  }
}
</style>