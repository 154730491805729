import { getToken, setToken, removeJession, removeToken } from "@/utils/auth";
// import { login, logout, getInfo } from "@/utils/api";
import {
  login,
  logout,
  addToCart, //加入购物车
  getCartNum, //获取购物车数量
  getCartInfoList, //获取购物车列表接口
  getAlert,
  getUnTreatNum
} from "@/utils/api";
// import { resetRouter } from "@/router";
import { message } from "ant-design-vue"; //ui库的消息提示
import router from "@/router";
import { env } from "@/common/constant"
import Util from "@/utils/utils";
const state = {
  page_width: Util.getPageWidth(), // 当前页面宽度
  page_height: Util.getPageHeight(), // 当前页面高度
  token: getToken(),
  userInfo: "",
  userType: "",
  name: "",
  avatar: "",
  introduction: "",
  roles: [],
  cart: 0,
  cartObj: "", //购物车右上角数据对象
  dbTotalCount: "", //上待办数量
  isGoLogin: true, //是否跳登陆页
  isZMIsPay: false, // 专卖店待办是否支付成功
  toPath: "", //下一页路由
  fromPath: "", //上一页路由
  getVolumnText: "一件商品十块钱,",
  productImgBaseUrl: "", //产品图片头路径 -》 首页商品楼层接口获取
  wlzImgsArr: [], //产品物料组图片 -》 首页商品楼层接口获取
  imgObj:{
    dftImg:'',
    hotImg:'',
    newImg:'',
    rxImg:''
  },
  barBottom: 120,
  isModelShow:false,//更新购物车时 加loading
  menuList:[],
  btnList:[],
  loginLaterFlag:true,//是否已登录
  osInfo:'',//操作系统
  broName:'',//浏览器
  axiosArr: [] // 储存cancel axio
};

const mutations = {
  // 更改获取的页面宽度
  SET_WIDTH: state => {
    state.page_width = Util.getPageWidth();
  },
  // 更改获取的页面高度
  SET_HEIGHT: state => {
    state.page_height = Util.getPageHeight();
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_USER_TYPE: (state, userType) => {
    state.userType = userType;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_CART: (state, cartNum) => {
    state.cart = cartNum;
  },
  SET_DB_TOTAL_COUNT: (state, dbTotalCount) => {
    state.dbTotalCount = dbTotalCount;
  },
  SET_CART_Obj: (state, cartObj) => {
    state.cartObj = cartObj;
  },
  SET_ZM_ISPAY: (state, isZMIsPay) => {
    state.isZMIsPay = isZMIsPay;
  },
  SET_ISGOLOGIN: (state, isGoLogin) => {
    state.isGoLogin = isGoLogin;
  },
  SET_TO_PATH: (state, toPath) => {
    state.toPath = toPath;
  },
  SET_FROM_PATH: (state, fromPath) => {
    state.fromPath = fromPath;
  },
  SET_URL: (state, productImgBaseUrl) => {
    state.productImgBaseUrl = productImgBaseUrl;
    //存储一份用户信息到localStorage中 防止刷新后信息丢失
    localStorage.setItem("productImgBaseUrl", productImgBaseUrl);
  },
  SET_WLZ_IMGS: (state, wlzImgsArr) => {
    state.wlzImgsArr = wlzImgsArr;
    //存储一份用户信息到localStorage中 防止刷新后信息丢失
    localStorage.setItem("wlzImgsArr", JSON.stringify(wlzImgsArr));
  },

  SET_BAR_BOTTOM: (state, isHidden) => {
    //侧边栏 底边距
    if (isHidden) {
      state.barBottom = -384;
    } else {
      state.barBottom = 120;
    }
  },
  SET_isModelShow: (state, isModelShow) => {
    //侧边栏 底边距
    state.isModelShow = isModelShow;
  },
  SET_MENU_LIST: (state, menuList) => {
    //存储menulist
    state.menuList = menuList;
  },
  SET_BTN_LIST: (state, btnList) => {
    //存储按钮list
    state.btnList = btnList;
  },
  SET_TABLE_WIDTH: (state, tableObj) => {
    //存储menulist
    // state.menuList = tableObj;
    return tableObj;
  },
  SET_LOGIN_LATER_FLAG: (state, loginLaterFlag) => {
    //存储menulist
    state.loginLaterFlag = loginLaterFlag;
    localStorage.setItem("loginLaterFlag", loginLaterFlag);
  },
  //获得活动展示默认图
  getMatkImg(state, matkIdObj) {
    let wlzImgsArr = state.wlzImgsArr ? state.wlzImgsArr : JSON.parse(localStorage.getItem("wlzImgsArr"));
    for (const item of wlzImgsArr) {
      if(item.matklIds.indexOf(matkIdObj.matkId) != -1 ){
        //找到对应物料组 -》根据type拿图 -》dft默认 new新品 hot爆款 rx热销
        // let imgHeadUrl = matkIdObj.matkId + '/' + matkIdObj.productCode + '/180-180/';
        // debugger
        state.imgObj.dftImg =  item.mastreImage;
        state.imgObj.hotImg =  item.hotImage;
        state.imgObj.rxImg =  item.oldImage;
        state.imgObj.newImg =  item.newImage;
      }
    }
  },
  // 获取操作系统信息 
getosInfo(state) {
  const userAgent = navigator.userAgent.toLowerCase();
  let name = 'Unknown';
  let version = 'Unknown';
  if (userAgent.indexOf('win') > -1) {
      name = 'Windows';
      if (userAgent.indexOf('windows nt 5.0') > -1) {
        name = 'Windows 2000';
      } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
        name = 'Windows XP';
      } else if (userAgent.indexOf('windows nt 6.0') > -1) {
        name = 'Windows Vista';
      } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
        name = 'Windows 7';
      } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
        name = 'Windows 8';
      } else if (userAgent.indexOf('windows nt 6.3') > -1) {
        name = 'Windows 8.1';
      } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
        name = 'Windows 10';
      } else {
        name = 'Unknown';
      }
  } else if (userAgent.indexOf('iphone') > -1) {
      name = 'Iphone';
  } else if (userAgent.indexOf('mac') > -1) {
      name = 'Mac';
  } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
      name = 'Unix';
  } else if (userAgent.indexOf('linux') > -1) {
      if (userAgent.indexOf('android') > -1) {
          name = 'Android';
      } else {
          name = 'Linux';
      }
  } else {
      name = 'Unknown';
  }
  state.osInfo = name;
  //存储一份用户信息到localStorage中 防止刷新后信息丢失
  localStorage.setItem("osInfo", JSON.stringify(name));
},
// 获取浏览器信息 
getBro (state) {
  let broName = 'Runing'
  let strStart = 0
  let strStop = 0
  let temp = ''
  let userAgent = window.navigator.userAgent // 包含以下属性中所有或一部分的字符串：appCodeName,appName,appVersion,language,platform
  // FireFox
  if (userAgent.indexOf('Firefox') !== -1) {
      strStart = userAgent.indexOf('Firefox')
      temp = userAgent.substring(strStart)
      broName = temp.replace('/', ' 版本号')
  }
  // Edge
  if (userAgent.indexOf('Edge') !== -1) {
      /* broName = 'Edge浏览器'; */
      strStart = userAgent.indexOf('Edge')
      temp = userAgent.substring(strStart)
      broName = temp.replace('/', ' 版本号')
  }
  // IE浏览器
  if (userAgent.match(/msie ([\d.]+)/)) {
      /* broName = 'IE浏览器'; */
      let s = userAgent.match(/msie ([\d.]+)/)
      let res = 'IE ' + s[1]
      broName = res + res.replace(/[^0-9.]/ig, '')
  }
  // 360极速模式可以区分360安全浏览器和360极速浏览器
  if (userAgent.indexOf('WOW') !== -1 && userAgent.indexOf('NET') < 0 && userAgent.indexOf('Firefox') < 0) {
      if (navigator.javaEnabled()) {
          broName = '360安全浏览器-极速模式'
      } else {
          broName = '360极速浏览器-极速模式'
      }
  }
  // 360兼容
  if (userAgent.indexOf('WOW') !== -1 && userAgent.indexOf('NET') !== -1 && userAgent.indexOf('MSIE') !== -1 && userAgent.indexOf('rv') < 0) {
      broName = '360兼容模式'
  }
  // Chrome浏览器
  if (userAgent.match(/Chrome\/([\d.]+)/)) {
      /* broName = 'Chrome浏览器'; */
      strStart = userAgent.indexOf('Chrome')
      strStop = userAgent.indexOf('Safari')
      temp = userAgent.substring(strStart, strStop)
      broName = temp.replace('/', ' 版本号')
  }
  // safari
  if (userAgent.match(/Version\/([\d.]+).*Safari/)) {
      strStop = userAgent.indexOf('Safari')
      temp = userAgent.substring(strStop)
      broName = temp.replace('/', ' 版本号')
  }
  // opera
  if (userAgent.match(/Opera.([\d.]+)/)) {
      strStop = userAgent.indexOf('Opera')
      temp = userAgent.substring(strStop)
      broName = temp.replace('/', ' 版本号')
  }
  // return broName
  state.broName = broName;
  //存储一份用户信息到localStorage中 防止刷新后信息丢失
  localStorage.setItem("broName", JSON.stringify(broName));
},
  // 记录请求列表
  setAxiosArr (state, cancel) {
    state.axiosArr.push(cancel)
  },

  // 清空请求
  clearAxiosArr (state) {
    state.axiosArr.forEach(item => {
      if(item){
        item()
      }
      
    })
    state.axiosArr = []
  },

};

const actions = {
  // 更改页面宽度
  changePageWidth({ commit }) {
    commit("SET_WIDTH");
  },
  // 更改页面高度
  changePageHegiht({ commit }) {
    commit("SET_HEIGHT");
  },
  cart({ commit }, cartNum) {
    commit("SET_CART", cartNum);
  },
  cartObj({ commit }, cartObj) {
    commit("SET_CART_Obj", cartObj);
  },
  productImgBaseUrl({ commit }, productImgBaseUrl) {
    commit("SET_URL", productImgBaseUrl);
  },
  
  setTabelWidth({ commit }, tableObj) {
    //建立表格字符长度对象
    let keyWordLenObj = {};
    const {columns,tableData} = tableObj
    for (const DataItem of tableData) {
        for (const Datakey in DataItem) {
          //数字转字符串 方便处理及省略显示
          DataItem[Datakey] = DataItem[Datakey] ? DataItem[Datakey].toString() : '';
          if(keyWordLenObj[Datakey]){
            //有key则 比大小后更新
            if(keyWordLenObj[Datakey] <= DataItem[Datakey] ? DataItem[Datakey].toString().length : 0){
              keyWordLenObj[Datakey] = window.$util.getStrLen(DataItem[Datakey]) * 8;
            }
          }else{
            //无key则添加
            keyWordLenObj[Datakey] = DataItem[Datakey] ? window.$util.getStrLen(DataItem[Datakey]) * 8 : 0;
          }
        }
    }

    let tableWidth = 0;
    for (const columnItem of columns) {
      //45筛选与边距 预留宽度
      let titleWidth
      if(columnItem.sorter){
        titleWidth = window.$util.getStrLen(columnItem.title) * 8 + 56;
      }else{
        titleWidth = window.$util.getStrLen(columnItem.title) * 8 + 46;
      }
      for (const key in keyWordLenObj) {
          if(columnItem.dataIndex == key){
            //表头宽度 32padding边距
            if((keyWordLenObj[key] + 46) < titleWidth){
              //小于表头宽度
              columnItem.width = titleWidth;
            }else{
              //大于等于表头宽度
              if((keyWordLenObj[key] + 46) < 360){
                //小于最大宽度
                columnItem.width =  keyWordLenObj[key] + 46;
              }else{
                //最大宽度
                columnItem.width = 360;
              }
            }
            tableWidth += columnItem.width;
            
          }
      }
    }
    tableObj.tableWidth = tableWidth;
    return tableObj
  },
  //获取待办信息数量
  async getUnTreatNum({ commit }) {
    let params = { pageNo: 1, pageSize: 20 };
    getUnTreatNum(params)
      .then((res) => {
        commit("SET_DB_TOTAL_COUNT", res.data.taskNum || 0);
      })
      .catch((error) => {
        commit("SET_DB_TOTAL_COUNT", 0);
        console.log(error);
      });
  },
  //获取购物车数量
  async getCartNum({ commit }) {
    // getCartNum().then((cartNum) => {
    //   commit("SET_CART", cartNum.data);
    // });
    // getCartInfoList().then((res) => {
    //   if (res.data.code == "success") {
    //     commit("SET_CART_Obj", res.data);
    //   }
    // });
    // this.isModelShow = true;
    commit("SET_isModelShow", true);
    let numRes = await getCartNum();
    //let CartInfoRes = await getCartInfoList();
    if(numRes){
      commit("SET_isModelShow", false);
      commit("SET_CART", numRes.data);
      //commit("SET_CART_Obj", CartInfoRes.data);
    }else{
      setTimeout(()=>{
        commit("SET_isModelShow", false);
      },5000)
    }
  },
  //获取购物车右上角列表
  getCartInfoList({ commit }) {
    // getCartInfoList().then((res) => {
    //   if (res.data.code == "success") {
    //     commit("SET_CART_Obj", res.data);
    //   }
    // });
  },
  addToCart({ dispatch }, params) {
    //policyId 政策id
    const { productId, orgId, num, policyId } = params;
    return new Promise((resolve) => {
      if (num <= 0) {
        message.info("请选择数量");
        return;
      }
      addToCart({
        productId: productId,
        orgId: orgId,
        num: num,
        policyId: policyId ? policyId : "",
      }).then((response) => {
        if (response.data.code ==0 ) {
          message.success("加入购物车成功");
          //获取购物车数量
          dispatch("getCartNum");
          //获取购物车列表
          //dispatch("getCartInfoList");
          resolve();
        } else {
          message.error(response.data.msg);
        }
      });
    });
  },
//获取提示信息数组 存储到local中 后续判断取用信息
    // if(!localStorage.getItem('msgArr')){
    //   getAlert().then((res)=>{
    //     localStorage.setItem('msgArr',JSON.stringify(res.data.list));
    //   })
    // }
  login({ commit }, userInfo) {
    const { username, password, imgCode } = userInfo;
    return new Promise((resolve) => {
      login({
        userName: username,
        password: password,
        imgCode: imgCode,
        targetUrl: userInfo.targetUrl
      }).then((response) => {
        if (response.data.code === 0) {
          const { ssoLoginToken, userName } = response.data;
          if(env == 'dev'){
            commit("SET_TOKEN", ssoLoginToken);
            setToken(ssoLoginToken);
          }
          commit("SET_NAME", userName);
          commit("SET_USERINFO", response.data);


          //操作系统 浏览器信息
          commit("getosInfo");
          commit("getBro");
          // alert(env)
          if (
            //海信采购账号
            (response.data.account.marketModels.indexOf("17452") !== -1 ||
              response.data.account.marketModels.indexOf("17453") !== -1) &&
            response.data.account.marketModels.indexOf("17451") == -1
          ) {
            commit("SET_USER_TYPE", 1);
          } else if (
            //渠道采购账号
            response.data.account.marketModels.indexOf("17451") !== -1 &&
            response.data.account.marketModels.indexOf("17452") == -1 &&
            response.data.account.marketModels.indexOf("17453") == -1
          ) {
            commit("SET_USER_TYPE", 2);
          } else if (
            //共存账号
            (response.data.account.marketModels.indexOf("17451") !== -1 &&
              response.data.account.marketModels.indexOf("17452") !== -1) ||
            response.data.account.marketModels.indexOf("17453") !== -1
          ) {
            commit("SET_USER_TYPE", 3);
          }

          //存储一份用户信息到localStorage中 防止刷新后信息丢失
          localStorage.setItem("userInfo", JSON.stringify(response.data));

          localStorage.setItem("userType", JSON.stringify(state.userType));
          
          resolve(response);
        } else {
          resolve(response);
          // alert(response.data.msg);
          // this.$message.error(response.data.msg)
        }
      });
    });
  },

  //get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      // getInfo(state.token)
      //   .then(response => {
      const response = {
        data: {
          roles: ["admin"],
          name: "唐玄奘",
          avatar: "isAva",
          introduction: "佛学家、翻译家、旅行家",
        },
      };
      const { data } = response;

      if (!data) {
        reject("获取用户信息失败");
      }

      const { roles, name, avatar, introduction } = data;

      if (!roles || roles.length <= 0) {
        reject("getInfo:用户 roles 错误");
      }

      commit("SET_ROLES", roles);
      commit("SET_NAME", name);
      commit("SET_AVATAR", avatar);
      commit("SET_INTRODUCTION", introduction);
      resolve(data);
      // })
      // .catch(error => {
      //   reject(error);
      // });
    });
  },

  //user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit("SET_TOKEN", "");
          commit("SET_ROLES", []);
          removeJession();
          commit("SET_USERINFO", "");
          //删除用户信息到localStorage中
          localStorage.setItem("userInfo", "");
          localStorage.setItem("noReadVisibleNum", "0");
          removeToken();
          // router.resetRouter();
          router.push({
            path: "/login",
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //重置token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_ROLES", []);
      commit("SET_TOKEN", "");
      commit("noReadVisibleNum", "0");
      removeToken();
      removeJession();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
