<template>
<!--  :getPopupContainer="triggerNode =>  triggerNode.parentNode|| document.body"加上这个会导致第一次弹出位置不对-->
  <a-popover :title="title" trigger="hover" placement="topRight" overlayClassName="axcbdj990" >
    <template slot="content">
      {{list}}
    </template>
      <i class="iconfont icon-tijiwenti-pc toast"></i>
  </a-popover>
</template>
<script>
export default {
  // 示例
  // <volume-pop></volume-pop>
  name: "volumePop",
  data() {
    return {
      title:'物流费用规则',
      list:null
    };
  },
  mounted(){
    this.getMessage()
  },
  methods:{
    getMessage(){
       const msg =  this.$configTotal({
          route: '/cart',
          id:'14181084184',
          //  msgCount: 1,
        })
        this.list = msg.msgDesc
    }
  }
};
</script>

<style lang="less">
  // 物流规则
  .axcbdj990{
    .ant-popover-inner{
      padding:0 16px;
    }
    .ant-popover-title{
      padding:12px 0px 12px 0px;
      box-sizing: border-box;
      min-width:150px;
      font-size: 16px;
    }
    .ant-popover-inner-content{
      padding:12px 0px 12px 0px;
      font-size: 14px;
    }
  }
  .toast {
      color:#00AAA6;
      cursor: pointer;
      font-size: 16px;
    }
  
</style>
