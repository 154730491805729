/*
 * @Author: your name
 * @Date: 2020-10-26 20:05:03
 * @LastEditTime: 2024-08-27 18:14:39
 * @LastEditors: 尚宏浩 aaa@hisense.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\purchaseOrder.js
 */

export default [{
  path: "/order",
  name: "order",
  redirect: "/order",
  component: () => import("@/layout/TopDownLayout/Frame"),
  children: [{
      path: "/order/detail",
      component: () => import("@/views/order/details/index.vue"),
      meta: {
        title: "采购订单详情",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/order/detail_ecpx",
      component: () => import("@/views/order/detailsEcpx/index.vue"),
      meta: {
        title: "ECPX订单详情",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/order/detailsEngineering",
      component: () => import("@/views/order/detailsEngineering/index.vue"),
      meta: {
        title: "工程订单详情",
        keepAlive: false,
        isBarShow: true
      },
    },

    // {
    //   path: "/order",
    //   component: () => import("@/views/order/index.vue"),
    //   meta:{
    //     title:"采购订单列表", 
    //     keepAlive: false,
    //     isBarShow:true,
    //     filterConfigStr:'ordersCenter'
    //   },
    // },
    {
      path: "/order/cis",
      component: () => import("@/views/order/index.vue"),
      meta: {
        title: "海信采购订单",
        keepAlive: true,
        isBarShow: true,
        filterConfigStr: 'ordersCenter'
      },
    },
    {
      path: "/order/ecpx",
      component: () => import("@/views/order/ecpx/index.vue"),
      meta: {
        title: "ECPX电商订单明细",
        keepAlive: true,
        isBarShow: true,
        filterConfigStr: 'ecpx'
      },
    },
    {
      path: "/order/dms",
      component: () => import("@/views/order/channelOrder/index.vue"),
      meta: {
        title: "渠道采购订单",
        keepAlive: false,
        isBarShow: true,
        filterConfigStr: 'dmsOrderList'
      },
    },
    {
      path: "/order/progress",
      component: () => import("@/views/order/progress/index.vue"),
      meta: {
        title: "采购订单进度详情",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/channelOrder/detail",
      component: () => import("@/views/order/channelOrder/details/index.vue"),
      meta: {
        title: "渠道采购订单详情",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/channelOrder/progress",
      component: () => import("@/views/order/channelOrder/progress/index.vue"),
      meta: {
        title: "渠道采购订单进度详情",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/retailOrder/detail",
      component: () => import("@/views/order/retailOrder/details/index.vue"),
      meta: {
        title: "渠道采购订单详情",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/retailOrder/detailRetail",
      component: () => import("@/views/order/retailOrder/detailsReail/index.vue"),
      meta: {
        title: "零售采购订单详情",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/retailOrder/detailFX",
      component: () => import("@/views/order/retailOrder/detailsFX/index.vue"),
      meta: {
        title: "分销采购订单详情",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/retailOrder/progress",
      component: () => import("@/views/order/retailOrder/progress/index.vue"),
      meta: {
        title: "零售采购订单进度详情",
        keepAlive: false,
        isBarShow: true
      },
    },
    // 销售订单-渠道订单-出库
    {
      path: "/channelOrder/wharehouse",
      name: 'wharehouse',
      component: () => import("@/views/order/channelWarehouseOut/index.vue"),
      meta: {
        title: "订单出库",
        keepAlive: false,
        isBarShow: true
      },
    },
    // 销售订单-渠道订单-审核
    {
      path: "/channelOrder/orderExamine",
      name: 'rderExamine',
      component: () => import("@/views/order/channelExamine/index.vue"),
      meta: {
        title: "订单审核",
        keepAlive: false,
        isBarShow: true
      },
    },
    // 海信采购进度开发
    {
      path: "/cispurchaseProgress",
      component: () => import("@/views/order/hisenseProcurementProgress/index.vue"),
      meta: {
        title: "海信采购进度汇总",
        keepAlive: false,
        isBarShow: true
      },
    },
    // 认购参与结果
    {
      path: "/marketActivityResult",
      component: () => import("@/views/order/marketActivityResult/index.vue"),
      meta: {
        title: "活动参与记录",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/dmspurchaseProgress",
      component: () => import("@/views/order/dmsProcurementProgress/index.vue"),
      meta: {
        title: "渠道采购进度汇总",
        keepAlive: false,
        isBarShow: true
      },
    },
    // 销售订单列表
    {
      path: "/channelOrder/distributorPurchase",
      name: 'distributorPurchase',
      component: () => import("@/views/order/distributorPurchase/index.vue"),
      meta: {
        title: "分销商直采订单",
        keepAlive: false,
        isBarShow: true
      },
    },
    // 分销商直采订单列表
    {
      path: "/channelOrder/orderList",
      name: 'orderList',
      component: () => import("@/views/order/saleOrderList/index.vue"),
      meta: {
        title: "销售订单",
        keepAlive: false,
        isBarShow: true
      },
    },
    // 销售订单审核列表
    {
      path: "/channelOrder/orderReview",
      name: 'orderreview',
      component: () => import("@/views/order/orderReview/index.vue"),
      meta: {
        title: "分销零售订单",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/channelOrder/salesProgress",
      name: 'salesProgress',
      component: () => import("@/views/order/salesProgress/index.vue"),
      meta: {
        title: "销售订单进度汇总",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/channelOrder/quickDelivery",
      name: 'quickDelivery',
      component: () => import("@/views/order/channelWarehouseOut/quickDelivery/index.vue"),
      meta: {
        title: "快速出库",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/channelOrder/quickAudit",
      name: 'quickDelivery',
      component: () => import("@/views/order/channelExamine/quickAudit/index.vue"),
      meta: {
        title: "快速审核",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/channelOrder/saleOrderOut",
      name: 'saleOrderOut',
      component: () => import("@/views/order/channelWarehouseOut/saleOrderOut/index.vue"),
      meta: {
        title: "零售订单出库",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/channelOrder/xinxiaomiOutbound",
      name: 'xinxiaomiOutbound',
      component: () => import("@/views/order/channelWarehouseOut/xinxiaomiOutbound/index.vue"),
      meta: {
        title: "零售订单出库",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/channelOrder/hisenseo2oOutbound",
      name: 'hisenseo2oOutbound',
      component: () => import("@/views/order/channelWarehouseOut/hisenseo2oOutbound/index.vue"),
      meta: {
        title: "审核出库",
        keepAlive: false,
        isBarShow: true
      },
    },
    {
      path: "/engineeringReport",
      name: 'engineeringReport',
      component: () => import("@/views/engineeringReport/index.vue"),
      meta: {
        title: "工程报备",
        keepAlive: false,
        isBarShow: true
      },
    },


  ]
}];