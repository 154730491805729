<template>
  <div>
    <div ref="template">
      <slot name="print-content" :getChineseNumber="getChineseNumber">
        <!-- <span>编写你自己的打印区域组件，然后slot插入到vue-easy-print</span> -->
      </slot>
      
    </div>
    <!-- 这里按钮偷了个懒，直接拿了element-ui的css name来用。 -->
    <div v-if="buttonShow">
      <button @click="print" type="button" :class="buttonClass">
        <!-- <span> -->
          <!-- {{ $t('frame.components.startPrint') }} -->
        <!-- </span> -->
        <slot name="print-btn" />
        <!-- <slot :getChineseNumber="getChineseNumber">
          <span>编写你自己的打印区域组件，然后slot插入到vue-easy-print</span>
        </slot> -->
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'h-print',
  components: {},
  props: {
    // 是否显示默认的打印按钮
    buttonShow: {
      type: Boolean,
      default: false
    },
    //数加载完毕
    isDataOk: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: String,
      default: 'el-button el-button--default'
    },
    beforeCopy: Function,
    beforePrint: Function
  },
  // watch:{
  //   isDataOk: {
  //     handler() {
  //       if(this.isDataOk){
  //         alert(1)
  //         this.print();
  //       }
  //     },
  //   }
  // },
  watch:{
    isDataOk(){
      if(this.isDataOk){
          this.print();
        }
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let printI = document.getElementById('easyPrintIframe')
      if (!printI) {
        printI = document.createElement('iframe')
        printI.id = 'easyPrintIframe'
        printI.style.position = 'fixed'
        printI.style.width = '0'
        printI.style.height = '0'
        printI.style.top = '-100px'

        // 兼容ie
        if (window.location.hostname !== document.domain && navigator.userAgent.match(/msie/i)) {
          printI.src =
            'javascript:document.write("<head><script>document.domain=\\"' +
            document.domain +
            '\\";</s' +
            'cript></head><body></body>")'
        }
        printI.onload = () => {
          this.getStyle()
        }

        document.body.appendChild(printI)
      } else {
        this.getStyle()
      }
    },
    print() {
      if (typeof this.beforeCopy === 'function') {
        alert(1)
        // 检测到有复制前需要执行的功能
        this.beforeCopy()
      }

      const $iframe = document.getElementById('easyPrintIframe')
      // 复制body，打印内容
      $iframe.contentDocument.body.innerHTML = this.$refs.template.innerHTML

// var link = document.createElement("link");
//        link.rel = "stylesheet";
//        link.type = "text/css";
//        link.href = "@/views/salesProgress/index.less";
//        console.log(link)
//        var head = document.getElementsByTagName("head")[0];
//        head.appendChild(link);
      if (typeof this.beforePrint === 'function') {
        // 检测到有打印前需要执行的功能
        // 比如有些二维码组件无法直接复制dom完成。
        this.beforePrint()
      }

      // 执行打印
      this.$nextTick(() => {
        setTimeout(() => {
          $iframe.contentWindow.print()
        }, 100)
      })
    },
    getStyle() {
      const printI = document.getElementById('easyPrintIframe')
      let str = ''
      const styles1 = document.querySelectorAll('style')
      for (let i = 0; i < styles1.length; i++) {
        str += styles1[i].outerHTML
      }

      printI.contentDocument.head.innerHTML = str
      // 添加link引入
      const styles = document.querySelectorAll('link')
      for (let i = 0; i < styles.length; i++) {
        // chrome 正常，firefox不正常，能执行到，但是添加没结果
        const link = document.createElement('link')
        link.setAttribute('rel', 'stylesheet')
        if (styles[i].type) link.setAttribute('type', styles[i].type)
        else link.setAttribute('type', 'text/css')
        link.setAttribute('href', styles[i].href)
        link.setAttribute('media', 'all')
        printI.contentDocument.head.appendChild(link)
      }
    },
    getChineseNumber(currencyDigits) {
      // 转换数字到中文大写，请用prop传递给模版组件，这个函数在网上扣的。
      const MAXIMUM_NUMBER = 99999999999.99
      // Predefine the radix characters and currency symbols for output:
      const CN_ZERO = '零'
      const CN_ONE = '壹'
      const CN_TWO = '贰'
      const CN_THREE = '叁'
      const CN_FOUR = '肆'
      const CN_FIVE = '伍'
      const CN_SIX = '陆'
      const CN_SEVEN = '柒'
      const CN_EIGHT = '捌'
      const CN_NINE = '玖'
      const CN_TEN = '拾'
      const CN_HUNDRED = '佰'
      const CN_THOUSAND = '仟'
      const CN_TEN_THOUSAND = '万'
      const CN_HUNDRED_MILLION = '亿'
      const CN_SYMBOL = '' // 可以设置前缀 比如 人民币
      const CN_DOLLAR = '元'
      const CN_TEN_CENT = '角'
      const CN_CENT = '分'
      const CN_INTEGER = '整'

      // Variables:
      let integral // Represent integral part of digit number.
      let decimal // Represent decimal part of digit number.
      let outputCharacters // The output result.
      const digits = [CN_ZERO, CN_ONE, CN_TWO, CN_THREE, CN_FOUR, CN_FIVE, CN_SIX, CN_SEVEN, CN_EIGHT, CN_NINE]
      const bigRadices = ['', CN_TEN_THOUSAND, CN_HUNDRED_MILLION]
      const decimals = [CN_TEN_CENT, CN_CENT]
      const radices = ['', CN_TEN, CN_HUNDRED, CN_THOUSAND]
      let zeroCount
      let i, p, d
      let quotient, modulus

      // Validate input string:
      if (currencyDigits === undefined) {
        return ''
      }
      currencyDigits = currencyDigits.toString()
      if (currencyDigits === '') {
        // alert('Empty input!');
        return ''
      }
      if (currencyDigits.match(/[^,.\d]/) != null) {
        // alert('Invalid characters in the input string!');
        return ''
      }
      if (currencyDigits.match(/^((\d{1,3}(,\d{3})*(.((\d{3},)*\d{1,3}))?)|(\d+(.\d+)?))$/) == null) {
        // alert('Illegal format of digit number!');
        return ''
      }

      // Normalize the format of input digits:
      currencyDigits = currencyDigits.replace(/,/g, '') // Remove comma delimiters.
      currencyDigits = currencyDigits.replace(/^0+/, '') // Trim zeros at the beginning.
      // Assert the number is not greater than the maximum number.
      if (Number(currencyDigits) > MAXIMUM_NUMBER) {
        alert('您输入的金额太大，请重新输入!')
        return ''
      }

      // Process the coversion from currency digits to characters:
      // Separate integral and decimal parts before processing coversion:
      const parts = currencyDigits.split('.')
      if (parts.length > 1) {
        integral = parts[0]
        decimal = parts[1]
        // Cut down redundant decimal digits that are after the second.
        decimal = decimal.substr(0, 2)
      } else {
        integral = parts[0]
        decimal = ''
      }
      // Start processing:
      outputCharacters = ''
      // Process integral part if it is larger than 0:
      if (Number(integral) > 0) {
        zeroCount = 0
        for (i = 0; i < integral.length; i++) {
          p = integral.length - i - 1
          d = integral.substr(i, 1)
          quotient = p / 4
          modulus = p % 4
          if (d === '0') {
            zeroCount++
          } else {
            if (zeroCount > 0) {
              outputCharacters += digits[0]
            }
            zeroCount = 0
            outputCharacters += digits[Number(d)] + radices[modulus]
          }
          if (modulus === 0 && zeroCount < 4) {
            outputCharacters += bigRadices[quotient]
          }
        }
        outputCharacters += CN_DOLLAR
      }
      // Process decimal part if there is:
      if (decimal !== '') {
        for (i = 0; i < decimal.length; i++) {
          d = decimal.substr(i, 1)
          if (d !== '0') {
            outputCharacters += digits[Number(d)] + decimals[i]
          }
        }
      }
      // Confirm and return the final output string:
      if (outputCharacters === '') {
        outputCharacters = CN_ZERO + CN_DOLLAR
      }
      if (decimal === '') {
        outputCharacters += CN_INTEGER
      }
      outputCharacters = CN_SYMBOL + outputCharacters
      return outputCharacters
    }
  }
}
</script>
