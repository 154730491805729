<!--
 * @Author: sqk
 * @Date: 2020-08-21 14:26:17
 * @LastEditTime: 2020-11-12 16:17:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\Breadcrumb\Breadcrumb.vue
-->
<template>
  <a-breadcrumb class="breadcrumb" separator=">">
    <a-breadcrumb-item v-for="(item,index) in data" :key="index">
      <span v-if="data.indexOf(item) === data.length - 1">{{ item.title }}</span>
      <router-link
        v-else
        :to="{ path: item.path === '' ? '/' : item.path ,query:item.query?item.query:''}"
      >{{ item.title }}</router-link>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>
<script>
export default {
  name: "breadcrumb",

  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
  },
  methods: {}
};
</script>
<style scoped lang="less">
.breadcrumb {
  text-align: left;
  width: 1188px;
  margin: 0 auto;
  height: 52px;
  line-height: 52px;
  &.ant-breadcrumb > span:last-child {
    color: #262626;
  }
  .ant-breadcrumb-separator {
    margin: 0 12px;
  }
  &.ant-breadcrumb a {
    color: #777;
  }
  .ant-breadcrumb-separator {
    color: #777;
  }
  .ant-breadcrumb-link {
    a {
      &:hover {
        color: #00aaa6;
      }
    }
  }
}
</style>
