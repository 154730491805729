export default [
    {
        path: "",
        name: "channelWholesalePrice",
        redirect: "",
        component: () => import("@/layout/TopDownLayout/Frame"),
        children: [
            {
                path: "/channelWholesalePrice/businessChannelPriceList",
                name: 'businessChannelPriceList',
                component: () => import("@/views/channelWholesalePrice/businessChannelPriceList/index.vue"),
                meta: {
                    title: "商家渠道批发价格",
                    keepAlive: false,
                    isBarShow: true
                }
            },    
            {
                path: "/channelWholesalePrice/businessChannelPriceadd",
                name: 'businessChannelPriceadd',
                component: () => import("@/views/channelWholesalePrice/businessChannelPriceadd/index.vue"),
                meta: {
                    title: "新增商家渠道批发价格",
                    keepAlive: false,
                    isBarShow: true
                }
            },    
            
            
        ]
    }
]