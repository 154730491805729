export default [
    {
        path: "/productDetailH5",
        name: "productDetailH5",
        component: () => import("@/views/productDetailH5/index.vue")
    },
    {
        path: "/operatingManualH5",
        name: "operatingManualH5",
        component: () => import("@/views/center/operatingManualH5/index.vue"),
        meta:{
            title:"操作手册详情",
            keepAlive: false,
            isBarShow:true
        },
    },
    {
        path: "/oldMachineCouponList",
        name: 'oldMachineCouponList',
        component: () => import("@/views/oldMachineCouponList/index.vue"),
    }
]