export default [
  {
    path: "/batchImportPage",
    name: "batchImportPage",
    redirect: "/batchImportPage",
    component: () => import("@/layout/TopDownLayout/Frame.vue"),
    children: [
      {
        path: "/batchImportPage",
        component: () => import("@/views/batchImportPage/index.vue"),
        meta:{
          title:"订单批量导入",
          keepAlive: false,
          isBarShow:true,
        }
      },
    ]
  }
];