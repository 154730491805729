/*
 * @Author: sqk
 * @Date: 2020-08-10 15:46:45
 * @LastEditTime: 2021-03-26 12:48:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\common.js
 */


export default [
  // {
  //   path: "/login",
  //   name: "",
  //   component: () => import("@/views/login/index")
  // },
  // 找回密码
  {
    path: "/findePassword",
    name: "findePassword",
    component: () => import("@/views/findePassword/index")
  },
  // 找回密码下一步 输入短信验证码
  {
    path: '/getCode',
    name: 'getcode',
    component: () => import("@/views/getCode/index")
  },
  {
    path: '/correctionError',
    name: 'correctionError',
    // component: () => import("@/views/getCode/index")
  }
  // {
  //   path: "*",
  //   name: "lost",
  //   hidden: true, // 是否隐藏
  //   component: () => import("@/views/404")
  // }
];