import http from "./request";

// var data = {
//     route: this.$route.fullPath,//路由
//     id,//配置id（主键）,
//     msgCount: 1,
//   }
export function configTotal(data) {
    var requestData = {
        msgCount: 1,
        msgId: data.id,
        sourceId: ''
    }
    var dataobj = {
        route: data.route,
        msgId: data.id,
        msgCount: 1
    }
    var realData = JSON.parse(localStorage.getItem('msgArr'));
    var realMenu = JSON.parse(localStorage.getItem('menuList'))
    // 根据路由名称 获取对应id
    for (let item of realMenu) {
        if (data.route == item.framePath) {
            requestData.sourceId = item.id
        }
    }
    // 获取对应主键并调用弹出记录接口
    for (let item of realData) {
        if (item.id == data.id) {
            //记录调用次数
            /*http({
                url: '/msg/updateMsgCount.nd',
                method: 'post',
                dataobj
            }).then(res => {
                console.log(res)
            })*/
            return item;
        }
    }
}


// 格式化信息
// function formatTotal(data) {
//     return http({
//         url: '/msg/updateMsgCount.nd',
//         method: 'post',
//         data
//     })
// }