
export default [
    {
        path: "/storeActivities",
        name: "storeActivities",
        redirect: "/storeActivities",
        component: () => import("@/layout/TopDownLayout/Frame.vue"),
        children: [
            {
                path: "/storeActivities",
                component: () => import("@/views/storeActivities/index.vue"),
                meta: {
                    title: "专卖店活动",
                }
            },
            {
                path: "/storeActivities/activityView",
                component: () => import("@/views/storeActivities/activityView/index.vue"),
                meta: {
                    title: "活动查看",
                }
            },
            {
                path: "/storeActivities/addOredit",
                component: () => import("@/views/storeActivities/addOredit/index.vue"),
                meta: {
                    title: "活动新增",
                }
            },
            {
                path: "/storeActivities/editOredit",
                component: () => import("@/views/storeActivities/editOredit/index.vue"),
                meta: {
                    title: "活动编辑",
                }
            },
            {
                path: "/storeActivities/oreditUpload",
                component: () => import("@/views/storeActivities/oreditUpload/index.vue"),
                meta: {
                    title: "专卖店上传凭证",
                }
            },
            {
                path: "/storeActivities/oreditUpload/success",
                component: () => import("@/views/storeActivities/oreditUpload/success.vue"),
                meta: {
                    title: "支付成功",
                }
            },

        ]
    },
    {
        path: "/campaignList",
        name: "campaignList",
        redirect: "/campaignList",
        component: () => import("@/layout/TopDownLayout/Frame.vue"),
        children: [
            {
                path: "/campaignList",
                component: () => import("@/views/monopolyShopActivity/campaignList/index.vue"),
                meta: {
                    title: "专卖店活动列表",
                    keepAlive: false, //是否缓存
                    isBarShow: true
                }
            },

        ]
    },
    {
        path: "/creatStoreActivities",
        name: "creatStoreActivities",
        redirect: "/creatStoreActivities",
        component: () => import("@/layout/TopDownLayout/Frame.vue"),
        children: [
            {
                path: "/creatStoreActivities",
                component: () => import("@/views/monopolyShopActivity/creatStoreActivities/index.vue"),
                meta: {
                    title: "创建专卖店活动",
                }
            },

        ]
    }
];
