export default [{
  path: "/rewardList",
  name: "rewardList",
  // redirect: "/confirm",
  component: () => import("@/layout/TopDownLayout/Frame.vue"),
  children: [
    {
      path: "/rewardList",
      name: "rewardList",
      component: () => import("@/views/rewardList/index.vue"),
      meta: {
        title: "奖励结算列表",
        keepAlive: false,
        isBarShow: true
      }
    },
    {
      path: "/rewardList/rewardView",
      name: "rewardView",
      component: () => import("@/views/rewardList/rewardView/index.vue"),
      meta: {
        title: "奖励结算/奖励明细",
        keepAlive: false,
        isBarShow: true
      }
    }

  ]
}];