import service from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
import http from "@/utils/request";
import axios from "axios";


//确认收货
export function confirmReceipt(data) {
  return service({
    method: "post",
    url: "/order/confirmReceipt.nd?sapId="+data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    // data: data
  });
}


//保存评价
export function saveEvaluate(data) {
  return service({
    method: "post",
    url: "/orderEvaluation/saveEvaluate.nd",
    data: data
  });
}
//获取评价
export function getEvaluate(data) {
  return service({
    method: "post",
    url: "/orderEvaluation/init.nd",
    data: data
  });
}
// 单笔入库
export function inStockSignle(data) {
  return dmshttp({
    url: 'purchaseOrderIn',
    data
  })
}
// 查仓库
export function findBaseData(data) {
  return dmshttp({
    url: 'findBaseData',
    data
  })
}
export function getImg(data) {
  return dmshttp({
    url: 'toOmsView',
    data
  })
}
/**
 * 取消sap订单
 * @param {*} data 
 * @returns 
 */
export function cancleSap(data) {
    return service({
        method: "post",
        url: "/order/cancelSapOrderFlowStart.nd",
        data: data
    });
  }
/**
 * 取消1160订单
 * @param {*} data 
 * @returns 
 */
  export function cancleSap1160(data) {
    return service({
        method: "post",
        url: "/cts/ctsApi.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data: data
    });
  }

  // 获取咨询数据
// orderAppraise/queryTag.nd
export function queryTag(data) {
    return service({
        method: "post",
        url: "orderAppraise/queryTag.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}

// 提交咨询投诉数据

export function saveDeliveryData(data) {
    return service({
        method: "post",
        url: "orderAppraise/save.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}

// 获取咨询内容

export function queryAppraise(data) {
    return service({
        method: "post",
        url: "orderAppraise/queryAppraise.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}