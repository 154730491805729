export default [
  // DEMO
  {
    path: "/submitEndActivity",
    name: "submitEndActivity",
    redirect: "/submitEndActivity",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/submitEndActivity",
        component: () => import("@/views/submitEndActivity/index"),
        meta:{
          title:"提交结果页",
          keepAlive: false 
        }
      },
    ]
  },
  {
    path: "/submitEndActivitySuccess",
    name: "/submitEndActivitySuccess",
    component: () => import("@/views/submitEndActivitysucess/index.vue"),
    meta: {
      title: "提交结果页", //配置title
      keepAlive: false, //是否缓存
      isBarShow: false,
    },
  }
];