<template>
  <div class="changeCountBox">
    <div
      @click="reduce"
      class="project-collapse-menu-control-box-btn project-collapse-menu-control-box-minus"
      :class="
        newValue <= min || disabled
          ? 'project-collapse-menu-control-box-btnNotAllow'
          : ''
      "
    >
      －
    </div>
    <a-input
      :size="size"
      @change="inputChange"
      v-model="newValue"
      :disabled="newValue >= max || newValue < min || disabled"
      class="inputText"
    >
    </a-input>
    <div
      @click="add"
      class="project-collapse-menu-control-box-btn project-collapse-menu-control-box-btn2"
      :class="
        newValue >= max || disabled
          ? 'project-collapse-menu-control-box-btnNotAllow'
          : ''
      "
    >
      +
    </div>
  </div>
</template>
<script>
export default {
  name: "ChangeCount",
  data() {
    return {
      newValue: 0
    };
  },
  props: {
    value: {
      default: 0
    },
    max: {
      default: 1000
    },
    min: {
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      default: "default"
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.newValue = Number(newValue);
      }
    }
  },
  methods: {
    add() {
      if (this.newValue >= this.max || this.disabled) return false;
      let value = parseInt(this.newValue);
      this.newValue = value += 1;
      this.$emit("update:value", value);
      this.parentChange();
    },
    reduce() {
      if (this.newValue <= this.min || this.disabled) return false;
      let value = parseInt(this.newValue);
      this.newValue = value += -1;
      this.$emit("update:value", value);
      this.parentChange();
    },
    inputChange() {
      this.newValue = this.newValue.toString().replace(/[^\d]/g, "");
      if(this.newValue>this.max){
        this.newValue = this.max
      }
      this.$emit("update:value", this.newValue);
      this.parentChange();
    },
    parentChange() {
      this.$emit("datachange");
    }
  }
};
</script>
<style scoped>
.ant-input-group-wrapper {
  min-width: 100px;
}

.inputText {
  text-align: center;
  border: none;
  color: #00AAA6;
  width: calc(100% - 54px);
}

.project-collapse-menu-control-box-btn {
  cursor: pointer;
  font-size: 18px;
  width: 50px;
  font-weight: bold;
  text-align: center;
  -webkit-user-select: none;
}
.project-collapse-menu-control-box-minus{
  transform: scaleX(1.5);
}
.project-collapse-menu-control-box-btn2 {
  color: #00aaa6;
}
.project-collapse-menu-control-box-btnNotAllow {
  cursor: not-allowed;
}
.changeCountBox {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
</style>
