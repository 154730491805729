/*
 * @Author: sqk
 * @Date: 2020-09-02 10:26:41
 * @LastEditTime: 2021-01-12 15:25:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\product.js
 */


// import store from "@/store";
// console.log(111)
// console.log(store.state.user)
// setTimeout(()=>{console.log(store.state.user['toPath'])},1)
// setTimeout(()=>{
//   console.log(store.state.user.toPath)
//   let catalogId = 
// },1)
export default [
    // DEMO
    {
      path: "/product",
      name: "product",
      redirect: "/product",
      component: () => import("@/layout/TopDownLayout/Frame"),
      children: [
        {
          path: "/product",
          component: () => import("@/views/product/index.vue"),
          meta:{
            title:"商品橱窗", 
            keepAlive: false,
            isBarShow:true,
            filterConfigStr:'productList'
            // filterConfigStr:'productList' + store.state.user.toPath.query.catalogId || ""
          }
        },
        {
          path: "/product/detail",
          component: () => import("@/views/product/detail/index.vue"),
          meta:{
            title:"商品详情", 
            keepAlive: false,
            isBarShow:true
          },
        }
      ]
    }
  ];
  