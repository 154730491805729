<!--
 * @Author: sqk
 * @Date: 2020-08-13 17:04:51
 * @LastEditTime: 2021-06-28 16:16:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\ToolBar\ToolBar.vue
-->
<template>
  <!-- <div
      class="page-toolbar"
      :class="{hide:!isShow}"
      :style="$route.path == '/index' ? 'bottom:' + $store.state.user.barBottom + 'px' : ''"
  > -->
  <div
      class="page-toolbar"
      :class="{hide:!isShow}"
  >
  <!-- 暂时去掉 -->
    <!-- <div
        title="即时通讯"
        v-if="$store.state.user.userInfo"
        class="head-icon"
        @click="toCall"
        :class="noReadNum > 1 ? 'no-read' : 'all-read'"
    ></div> -->
    <div class="is-show" :class="isShow ? 'hvr-icon-forward' : 'hvr-icon-back' "  :title="isShow ? '收起' : '展开'" @click="isShow = !isShow">
      <i v-show="isShow" class="iconfont icon-shangpin-you right hvr-icon"></i>
      <i v-show="!isShow" class="iconfont icon-shangpin-zuo left hvr-icon"></i>
    </div>
    <!-- <span class="more hvr-icon-forward" title="更多" @click="toInfoMoreFun">
            <span>查看更多</span>
            <i class="iconfont icon-shouye-gonggao-youdianji hvr-icon"></i>
          </span> -->
    <ul>
      <!-- <li @mouseover="codeShowFun(true)" @mouseleave="codeShowFun(false)">
        <i class="iconfont icon-xiazaiAPP-moren"></i>
        <h6>下载APP</h6>
      </li> -->
      <a-popover placement="leftTop" overlayClassName="code-down-container">
        <template slot="content">
          <div class="code-down-box">
            <!-- <img src="./code.png" alt /> -->
            <div class="code-item">
              <h1>Hisense信天翁微信小程序</h1>
              <div class="c-box">
                <div class="img-box">
                  <img :src="wxsrc" />
                  <p>
                    <i>微信扫一扫</i>进入小程序
                  </p>
                </div>
              </div>
            </div>
            <div class="code-item type2">
              <h1>Hisense信天翁APP</h1>
              <div class="c-box">
                <div class="img-box">
                  <img :src="ansrc" />
                  <p>安卓客户端</p>
                </div>
                <div class="img-box">
                  <img :src="IOSsrc" />
                  <p>iOS客户端</p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <li>
          <i class="iconfont icon-xiazaiAPP-moren"></i>
          <h6>下载APP</h6>
        </li>
      </a-popover>
      <li @click="toGrzx" v-if="$store.state.user.userInfo">
        <i class="iconfont icon-gerenzhongxin-moren"></i>
        <h6>个人中心</h6>
      </li>
      <!-- <li @click="toXdgj">
        <i class="iconfont icon-xiadangongju-moren"></i>
        <h6>下单工具</h6>
      </li> -->
<!--       <a-popover  placement="leftTop" overlayClassName="code-down-container tyfk">-->
<!--        <template slot="content">-->
<!--          <div class="code-down-box code-down1" >-->
<!--            &lt;!&ndash; <img src="./code.png" alt /> &ndash;&gt;-->
<!--            <div class="code-item xtwt" >-->
<!--              &lt;!&ndash; <h1>欢迎体验反馈</h1> &ndash;&gt;-->
<!--              <div class="c-box">-->
<!--                <img src="~@/assets/common/编组 5.png" />-->
<!--                &lt;!&ndash; <div class="img-box">-->
<!--                  <img src="~@/assets/common/编组 5.png" />-->
<!--                </div> &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="textBox">-->
<!--              <p class="bigText">-->
<!--                遇到问题了吗-->
<!--              </p>-->
<!--              <p class="smallText">-->
<!--                “系统层面的问题请点此反馈”-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </template>-->
<!--        <li v-if="$store.state.user.userInfo"  @click="toYjjb('xtQuestion')">-->
<!--          <i class="iconfont icon-zixunwentipingjia"></i>-->
<!--          <h6  >系统问题</h6>-->
<!--        </li>-->
<!--      </a-popover>-->
      <li @click="toGwc" v-if=" $store.state.user.userInfo &&showActiveBox && (userInfo.account.loginSystem.indexOf('14168810880') != '-1' || userInfo.account.loginSystem.indexOf('14168810879') != '-1')">
        <span class="count-flag">{{userInfo ? cart : 0}}</span>
        <i class="iconfont icon-shouye-gouwuche"></i>
        <h6>购物车</h6>
      </li>
<!--     <li @click="toYjjb('homepageType')" v-if="$store.state.user.userInfo">-->
<!--        <i class="iconfont icon-yijianjubao-moren"></i>-->
<!--        <h6>咨询投诉</h6>-->
<!--      </li>-->
      <a-popover  placement="left" overlayClassName="code-down-containerZX tyfk">
        <template slot="content">
          <div class="code-down-box2 code-down2" >
            <div class="code-item xtwt" >
              <img src="@/assets/common/商家服务@2x.png" alt="">
              <div class="textBox">
                <p class="topTxt">商家服务专线 <span>0532-55522736</span> </p>
                <p class="bottomTxt">服务时间：周一至周日 8:00-20:00</p>
              </div>
            </div>
            <div class="code-item xtwt" >
              <img src="@/assets/common/商家投诉@2x.png" alt="">
              <div class="textBox">
                <p class="topTxt">商家投诉专线 <span>0532-83878888</span> </p>
                <p class="bottomTxt">服务时间：周一至周日 8:00-20:00</p>
              </div>
            </div>
            <div class="code-item xtwt" >
              <img src="@/assets/common/商家物流@2x.png" alt="">
              <div class="textBox">
                <p class="topTxt">商家物流热线 <span>0532-86683737</span> </p>
                <p class="bottomTxt">服务时间：周一至周日 8:00-20:00</p>
              </div>
            </div>
            <div class="code-item xtwt" style="cursor: pointer" @click="toWTfk('homepage','fk')">
              <img src="@/assets/common/咨询投诉.png" alt="">
              <div class="textBox" style="display: flex">
<!--                 需要展开弹窗-->
                <p class="topTxt">问题反馈    </p>
                <p class="bottomTxt">服务时间：24小时</p>
              </div>
            </div>
            <div class="code-item xtwt" style="cursor: pointer" @click="toWTfk('homepage','hf')">
              <img src="@/assets/common/问题回复@3x.png" alt="">
<!--               跳转列表-->
              <div class="textBox" style="display: flex">
                <p class="topTxt">问题回复    </p>
                <p class="bottomTxt">服务时间：24小时</p>
              </div>
            </div>
          </div>
        </template>
        <li v-if="$store.state.user.userInfo"  >
          <i class="iconfont icon-zixunwentipingjia"></i>
          <h6>问题反馈</h6>
        </li>
      </a-popover>
      <li>
        <a-back-top :visibilityHeight="0" />
        <i class="iconfont icon-zhiding-huaguo"></i>
        <h6>返回顶部</h6>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  env
} from "@/common/constant";
// 导入api接口
import {
  getUnTreatNum //未读公告数量、未处理待办数量、未读消息数量接口
  // 获取登录张号密码
  // getss
} from "@/utils/api.js";
import {menuList} from "@/utils/api.js"
export default {
  name: "tool-bar",
  data() {
    return {
      codeCls: "",
      noReadNum: 1,
      isShow:true,
      wxsrc:'',
      ansrc:'',
      IOSsrc:'',
      showActiveBox:false
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    ...mapState({
      cart: state => state.user.cart,
      menuList: state =>
      state.user.menuList.filter(
        it => it.subMenuList && it.subMenuList.length > 0
      )
    }),
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    },
  },
  mounted() {
    // 根据mate中isKeepBar标志 判断bar是否常显
    if(!this.$route.meta.isKeepBar){
      setTimeout(()=>{
        this.isShow = false;
      },5000)
    }else{
      this.isShow = true;
    }
    // 根据是否有未读确定哈利状态 暂时不用
    // this.getUnTreatNum();
    // 如果是测试显示测试图片
    if(env=='dev' || env=='test' || env=='uat') {
      this.wxsrc = require( '../../../assets/common/ceshxcx.jpg')
      this.ansrc = require( '../../../assets/common/azceshi.png')
      this.IOSsrc = require( '../../../assets/common/iosceshi.png')
    } else {
      // 如果是正式展示正式
       this.wxsrc = require( '../../../assets/common/toolbar-code-wx2.png')
       this.ansrc = require( '../../../assets/common/ercode_prd.png')
       this.IOSsrc = require( '../../../assets/common/toolbar-code-ios2.png')
    }
    if (this.userInfo) {
      this.getMenuList()
    }
    
  },
  methods: {
 // 获取列表信息
    getMenuList() {
    //调用接口
      let params = { pageNo: 1, pageSize: 20,  loginPlant:'WEBNEW'};
      menuList(params)
        .then(res => {
          if (res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            res.data.list
            for(let i = 0;i< res.data.list.length;i++) {
              if(res.data.list[i].sourceName === '个人中心') {
                let twoItem = res.data.list[i];
                for(let a = 0;a<twoItem.subMenuList.length;a++) {
                  if(twoItem.subMenuList[a].sourceName === '其他') {
                    let three = twoItem.subMenuList[a]
                    for(let bb = 0;bb<three.subMenuList.length;bb++) {
                      if(three.subMenuList[bb].sourceName === '购物车'){
                        this.showActiveBox = true
                        break
                      } else {
                        this.showActiveBox = false
                      }
                    }
                    break
                  }else {
                    this.showActiveBox = false
                  }
                }
              }
            }
        }
      })
      .catch(error => {
        console.log(error);
      });
      
    },
    toGrzx() {
      this.$router.push({
        path: "/basicInfo?type=1"
      });
      // 集成wa切换路由的时候调用
      if (typeof window._paq !== 'undefined' ) {
        window._paq.push(['setCustomUrl', '/basicInfo?type=1']);
        window._paq.push(['setDocumentTitle','人员账号管理']);
        window._paq.push(['trackPageView']);
      }
    },
    toXdgj() {
      this.$router.push({
        path: "/"
      });
    },
    toWTfk(sourceType,type) {
      if (typeof window._paq !== 'undefined' ) {
        window._paq.push(['setCustomUrl', '/center/consultNewPage']);
        window._paq.push(['setDocumentTitle','问题反馈明细']);
        window._paq.push(['trackPageView']);
      }
      this.$router.push({
        path: "/center/consultNewPage",
        query:{
          sourceType:sourceType,
          type:type
        }
      });
    },
    toGwc() {
      if (typeof window._paq !== 'undefined' ) {
        window._paq.push(['setCustomUrl', '/cart']);
        window._paq.push(['setDocumentTitle','购物车']);
        window._paq.push(['trackPageView']);
      }
      this.$router.push({
        path: "/cart"
      });
    },
    toCall() {
      // getss()
      //   .then(res => {
      //     console.log(res);
      //     window.localStorage.setItem("accounts", JSON.stringify(res.data));
      //     console.log();
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
      window.open("/im/index.html", "_blank");
    },
    codeShowFun(isShow) {
      // this.isCodeShow = isShow;
      if (isShow) {
        this.codeCls = "fadeInLeft";
      } else {
        this.codeCls = "fadeOutLeft";
      }
    },
    //已读未读-》哈利状态-》趴着 坐着
    async getUnTreatNum() {
      let res = await getUnTreatNum();
      if (JSON.stringify(res.data) != "{}") {
        this.noReadNum =
            parseInt(res.data.GG.unreadNum ? res.data.GG.unreadNum : 0) +
            parseInt(res.data.MSG.unreadNum ? res.data.MSG.unreadNum : 0) +
            parseInt(res.data.TASK.unreadNum ? res.data.TASK.unreadNum : 0);
      } else {
        this.noReadNum = 0;
      }
    }
  }
};
</script>

<style lang="less">
.code-down-container {
  &.tyfk{
    .ant-popover-content > .ant-popover-arrow{
      top: 30px!important;
    }
    .ant-popover-inner {  
.ant-popover-inner-content {
        .code-down-box{
          width: 200px;
          height: 200px;
        }
        .code-down1 {
         
            width: 230px;
            height: 251px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .xtwt{
              width: 230px;
              height:150px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              // background: linear-gradient(180deg, #BDFFFC 0%, rgba(135, 255, 219, 0) 100%);opacity: 0.52;border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              .c-box {
                width: 230px;
                height: 150px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .textBox {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .bigText {
                font-size:16px;
                color:#262626!important;
                margin-top:12px
              }
              .smallText {
                font-size:13px;
                color:#aaa!important;
                margin-top:8px
              }
            }
        }
      }
    }
      
    }
  .ant-popover-inner {
    border-radius: 8px;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.17);
    .ant-popover-inner-content {
      padding: 0;

      .code-down-box {
        width: 470px;
        height: 200px;
        padding: 0 20px;
        .code-item {
          width: 160px;
          float: left;
          h1 {
            width: 160px;
            height: 26px;
            background: #ecf8f8;
            border-radius: 0px 0px 13px 13px;
            font-size: 12px;
            color: #00aaa6;
            line-height: 26px;
            text-align: center;
          }
          .c-box {
            .img-box {
              width: 120px;
              height: 120px;
              border: 1px solid #eeeeee;
              padding: 4px;
              text-align: center;
              margin: 13px auto 0;
              border-radius: 4px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            p {
              font-size: 12px;
              font-weight: 400;
              color: #262626;
              line-height: 20px;
              margin-top: 12px;
              text-align: center;
              white-space: nowrap;
              display: flex;
              justify-content: center;
              i {
                color: #00aaa6;
              }
            }
          }

          &.type2 {
            width: 252px;
            float: right;
            h1 {
              margin: 0 auto;
            }
            .img-box {
              float: left;
            }
            .img-box + .img-box {
              margin: 12px 0 0 12px;
            }
          }
        }

        // background: #ffffff;
        // box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.17);

        // box-shadow: 0 0 10px rgba(30, 30, 30, 0.1);
        // img {
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 2px;
        // }
      }
      
    }
  }
}
.code-down-containerZX {
  &.tyfk{
    .ant-popover-content > .ant-popover-arrow{
      //top: 30px!important;
    }
    .ant-popover-inner {
      .ant-popover-inner-content {
        border-radius: 8px!important;
        width: 318px;
        height: 332px;
        position: relative;
        .code-down-box2{
          background: url("~@/assets/common/56.png");
          width: 318px;
          height: 100%;
          border-radius: 8px;
          background-size: 100% 100%;
          position: absolute;
          padding:24px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .code-item {
            width: 278px;
            height: 62px;
            background: linear-gradient(90deg, #ECF8F8 0%, #F8F8F8 100%);
            border-radius: 8px;
            margin-top: 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-left: 16px;
            img{
              width: 28px;
              height: 28px;
              margin-right: 12px;
            }
            .textBox {
              display: flex;
              flex-direction: column;
              p{
                font-weight: 500;
                color: #262626;
                line-height: 17px;
                font-size: 12px;
              }
              .topTxt {
                font-size: 12px;
                font-weight: 500;
                color: #262626;
                line-height: 17px;
                span{
                  color: #00AAA6;
                }
              }
              .bottomTxt{
                font-size: 12px;
                font-weight: 400;
                color: #AAAAAA;
                line-height: 12px;
                margin-top: 4px;
              }
            }

          }
          .code-item:first-child{
            margin-top: 0;
          }
        }

      }
    }

  }
  .ant-popover-inner {
    border-radius: 8px;
    //box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.17);
    .ant-popover-inner-content {
      padding: 0;
      .code-down-box {
        width: 318px;
        height: 332px;
        padding: 0 20px;
        .code-item {

        }

        // background: #ffffff;
        // box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.17);

        // box-shadow: 0 0 10px rgba(30, 30, 30, 0.1);
        // img {
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 2px;
        // }
      }

    }
  }
}

.page-toolbar {
  position: fixed;
  right: 20px;
  bottom: 120px;
  z-index: 999;
  transition: bottom 0.6s,right .36s cubic-bezier(.62,.88,.71,.99);
  // position: relative;
  &.hide{
    right: -80px;
  }
  .is-show{
    width: 20px;
    height: 60px;
    left: -20px;
    top: calc(50% - 30px);
    position: absolute;
    background: #fff;
    border-radius: 6px 0 0 6px;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    text-align: center;
    &::after{
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      right: -2px;
      top: 0;
      background: #fff;
    }
    &:hover{
      > i{
        color: #00aaa6;
        // transform: rotate(180deg)
      }
    }
    > i{
      line-height: 60px;
      text-indent: 4px;
      color: #777;
      font-size: 12px;
      &.left{
        text-indent: 0px;
      }
    }
  }
  .head-icon {
    position: absolute;
    // top:-60px;
    // left: -16px;
    top: -76px;
    left: -5px;
    width: 86px;
    height: 86px;
    // background: url("./hasInfoIcon.png") no-repeat center;
    background: url("./notHasInfoIcon3.png") no-repeat center;
    background-size: cover;
    z-index: 2;
    cursor: pointer;

    &.no-read {
      top: -70px;
      left: -10px;
      background: url("./hasInfoIcon3.png") no-repeat center;
      // background: url("./notHasInfoIcon.png") no-repeat center;
      background-size: cover;
    }
    &.all-read {
      background: url("./notHasInfoIcon3.png") no-repeat center;
      background-size: cover;
    }
  }
  ul {
    // box-shadow: 4px 4px 10px rgba(30, 30, 30, 0.1);
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    overflow: hidden;
    padding: 8px 0 10px;
    background-color: #fff;
    li {
      width: 80px;
      height: 66px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: all 0.15s;
      > i {
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 24px;
        color: #777;
        transition: all 0.15s;
      }
      &:hover {
        > i {
          color: #00aaa6;
        }
        h6 {
          color: #00aaa6;
        }
      }
      h6 {
        margin-top: 6px;
        font-size: 12px;
        line-height: 1;
        color: #777777;
        transition: all 0.15s;
      }
      // &:after {
      //   content: "";
      //   position: absolute;
      //   width: 54px;
      //   height: 1px;
      //   left: 5px;
      //   bottom: 0;
      //   background-color: #eee;
      // }
      // &:last-child {
      //   &:after {
      //     display: none;
      //   }
      // }

      //购物车数量标志
      .count-flag {
        position: absolute;
        top: 4px;
        right: 18px;
        height: 16px;
        background: #ff8f3c;
        padding: 0 4px;
        border-radius: 8px;
        font-size: 12px;
        color: #ffffff;
        line-height: 16px;
        text-align: center;
      }

      .ant-back-top {
        width: 64px;
        height: 64px;
        position: absolute;
        right: 0;
        bottom: 0;
        .ant-back-top-content {
          display: none;
        }
      }
    }
  }
}
</style>