/*
 * @Author: sq
 * @Date: 2020-09-17 16:32:42
 * @LastEditTime: 2020-11-18 16:16:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\projectArea.js
 */

export default [
  // DEMO
  {
    path: "/engineering",
    name: "engineering",
    redirect: "/engineering",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/engineering",
        component: () => import("@/views/engineering/index.vue"),
        meta: {
          title: "工程专区",
          keepAlive: false,
          isBarShow:true,
          filterConfigStr:'enginerList'
        }
      }
    ]
  }
];
