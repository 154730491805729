export default [
  {
    path: "/annualReport",
    name: "annualReport",
    component: () => import("@/views/annualReport/index.vue"),
    meta: {
      title: "年度报告", //配置title
      keepAlive: false, //是否缓存
      isBarShow: false,
    },
  },
];