
import http from "@/utils/request";


// 智能地址识别
export function addressRecognition(data) {
    return http({
        method: "post",
        url: "cts/ctsApi.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}


































































