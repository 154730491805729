<template>
  <div class="product-wrap">
    <div class="productTop">
      <div class="item1">
        <div class="item1Img">
          <!-- ../../../assets/shoppingCart/product.png -->
          <!-- <img :src="commodityImg" alt />
          -->
          <h-img :imgUrl="imgUrl" :errorImg="errorImg"></h-img>
        </div>
        <div class="item1Val">
          <div class="item1Title">
            <p class="item1PP">{{commodityName}}</p>
            <p class="buyCar" v-if="orderCar" @click="carClick">
              <img src="../../../assets/purchaseOrder/shoppingCart-default.png" alt />
            </p>
          </div>
          <p>
            <span v-if="pp">品牌：{{pp}}</span>
            <span v-if="color">颜色：{{color}}</span>
          </p>
          <p v-if="Opp">
            品牌：{{Opp}}
          </p>
<!--          <p v-if="OCompensationType">-->
<!--            补差类型：{{OCompensationType}}-->
<!--          </p>-->
          <p v-if="volume">体积：{{volume}}</p>
        </div>
      </div>
      <div class="item2">
        <div class="item2Title" v-if="price">
          ￥{{price}}
          <span v-if="priceLine">￥{{priceLine}}</span>
        </div>
        <div class="item2Item">
          <p v-if="FixedDiscount">固定折扣:{{FixedDiscount}}</p>
          <p v-if="PricingGroup">定价组：{{PricingGroup}}</p>
<!--          <p v-if="CompensationType">补差类型：{{CompensationType}}</p>-->
          <p v-if="Rebate">单台返利￥{{Rebate}}</p>
          <slot name="soltPrice"></slot>
        </div>
      </div>
      <div class="item3">
        <div class="item3Title" v-if="KC">×{{KC}}</div>
        <div class="item3Item">
          <div class="item3Items">
            <p v-if="HisenseStock">海信库存{{HisenseStock}}</p>
            <p v-if="ShareStock">共享库存{{ShareStock}}</p>
          </div>
          <div class="item3Items">
            <p v-if="review">待总部评审({{review}})</p>
            <p v-if="delivered">待发货({{delivered}})</p>
            <p v-if="Negotiated">已商收({{Negotiated}})</p>
            <p v-if="Dumping">甩货({{Dumping}})</p>
            <slot name="soltPolicy"></slot>
          </div>
          <p v-if="cancled">已取消({{cancled}})</p>
        </div>
      </div>
      <div class="item4">
        <div class="item4Title" v-if="Subtotal">小计：￥{{Subtotal}}</div>
        <!-- 采购订单详情采购订单列表取消是否显示 -->
        <p class="purchase" v-if="orderCancle" @click="cancleClick(item)">取消</p>
        <!-- 采购订单详情采购订单列表退货是否显示 -->
        <p class="purchase" v-if="orderReturn">退货</p>
        <!-- 采购订单详情采按钮是否显示 -->
        <p class="purchase" v-if="orderDetail">查看退货详情</p>
        <!-- 采购 -->
        <p class="purchase" v-if="orderPJ" @click="PJClick(item)">评价</p>
        <slot name="slotCLick"></slot>>
      </div>
    </div>
    <!-- 商品行显示内容 -->
    <div v-if="settlement">
      <div class="line"></div>
      <div class="settlement">
        <div class="settlementChose" @click="handleChange(makeShure)">
          <a-radio :checked="makeShure"></a-radio>
        </div>
        <div>
          <div class="settleTitle" v-if="PreferentialPolicy">{{PreferentialPolicy}}</div>
          <div class="settlementVal">
            <p v-if="TPC">批次：{{TPC}}</p>
            <p v-if="Tprice">提货价：￥{{Tprice}}</p>
            <p v-if="TZK">直扣：￥{{TZK}}</p>
            <p v-if="TKP">开票价:￥{{TKP}}</p>
            <p v-if="TAllNumber">总数量：{{TAllNumber}}</p>
            <p v-if="TKG">可购数量：{{TKG}}</p>
            <p v-if="TYG">已购数量：{{TYG}}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 订单详情下方政策 -->
    <div v-if="orderDetailPolicy">
      <div class="line"></div>
      <div class="settlement" v-if="!purchaseOrder">
        <div>
          <div class="settleTitle" v-if="PTZC">{{PTZC}}</div>
          <div class="settlementVal">
            <p v-if="DPC">批次：{{DPC}}</p>
            <p v-if="StandardPrice">提货价：￥{{StandardPrice}}</p>
            <p v-if="ZK">直扣：￥{{ZK}}</p>
            <p v-if="TicketPrice">开票价：￥{{TicketPrice}}</p>
            <p v-if="DAllNumber">总数量：{{DAllNumber}}</p>
            <p v-if="DKG">可购数量：{{DKG}}</p>
            <p v-if="DYG">已购数量：{{DYG}}</p>
          </div>
        </div>
      </div>
      <div class="pur-commodity-msg-bot" v-else>
        <p>{{ PTZC }}</p>
        <span>提货价：￥{{StandardPrice}}</span>
        <span>折扣：{{ saleZK }}%</span>
        <span>直扣：￥{{ ZK }}</span>
        <span>开票价：￥{{ TicketPrice }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrencyItem",
  props: {
    item: {
      required: true
    },
    settlementItem: {
      required: false
    },
    // 特购专区
    settlement: {
      required: true,
      default: false
    },
    // 是否订单详情
    purchaseOrder: {
      default: false
    },
    // 订单
    // orderDetail: {
    //   required: true,
    //   default: false
    // },
    // 订单详情政策是否显示
    orderDetailPolicy: {
      required: true,
      default: false
    },
    // 购物车按钮是否显示
    orderCar: {
      required: true,
      default: false
    },
    // 订单取消按钮是否显示
    orderCancle: {
      required: true,
      default: false
    },
    // 订单退货按钮是否显示
    orderTh: {
      default: false
    },
    // 订单退货详情按钮是否显示
    orderTHDetail: {
      default: false
    },
    // 商品图片
    commodityImg: {
      required: false
    },
    //商品名字
    commodityName: {
      required: false
    },
    // 品牌
    pp: {
      required: false
    },
    color: {
      required: false
    },
    KC: {
      required: false
    },
    // 体积
    volume: {
      required: false
    },
    price: {
      required: false
    },
    priceLine: {
      required: false
    },
    FixedDiscount: {
      required: false
    },
    PricingGroup: {
      required: false
    },
    CompensationType: {
      required: false
    },
    Rebate: {
      required: false
    },
    HisenseStock: {
      required: false
    },
    ShareStock: {
      required: false
    },
    review: {
      required: false
    },
    delivered: {
      required: false
    },
    Negotiated: {
      required: false
    },
    Dumping: {
      required: false
    },
    cancled: {
      required: false
    },
    Subtotal: {
      required: false
    },
    // 特惠政策
    PreferentialPolicy: {
      required: false
    },
    PC: {
      required: false
    },

    // 通用结算页面数据
    TPC: {
      required: false
    },
    Tprice: {
      required: false
    },
    TZK: {
      required: false
    },
    TKP: {
      required: false
    },
    TAllNumber: {
      required: false
    },
    TYG: {
      required: false
    },
    TKG: {
      required: false
    },
    // 订单详情多余
    // 普通政策
    PTZC: {
      required: false
    },
    DPC: {
      required: false
    },
    StandardPrice: {
      required: false
    },
    ZK: {
      required: false
    },
    saleZK: {
      required: false
    },
    TicketPrice: {
      required: false
    },
    DAllNumber: {
      required: false
    },
    DKG: {
      required: false
    },
    DYG: {
      required: false
    },
    imgUrl: {
      required: false
    },
    errorImg: {
      required: false
    },
    orderPJ:{
      required:false
    },
    //订单详情
    Opp: {
      //品牌
      required: false
    },
    OCompensationType: {
      //补差类型
      required: false
    },
    // 退货
    orderReturn:{
      required:false
    },
    // 查看退货详情
    orderDetail:{
      required:false
    }
  },
  data() {
    return {
      makeShure: true
    };
  },
  methods: {
    // changeOpened(item) {
    //   this.$set(item, "opened", !item.opened);
    //   // item.opened = !item.opened
    // },
    // // 点击取消按钮
    cancleClick(data) {
      this.$emit("cancleClick", data);
    },
    // 特购页面点击是否需要特购优惠
    handleChange(data) {
      this.makeShure = !this.makeShure;
      this.$emit("settleChose", data);
    },
    // 点击购物车
    carClick() {
      this.$emit("carClick", "");
    },
    // 点击评价
    PJClick(data) {
      this.$emit("PJClick", data);
    }
  }
};
</script>

<style scoped lang="less">
.product-wrap {
  width: 1140px;
  // height: 250px;
  padding: 23px 0 20px 24px;
  // margin: 20px auto 0;
  // margin-top: 24px;
  background: #fff;
  box-sizing: border-box;
  text-align: left;
  border: 1px solid #eeeeee;
  .productTop {
    display: flex;
    justify-content: flex-start;
    .item1 {
      width: 392px;
      height: 100px;
      display: flex;
      justify-content: flex-start;
      .item1Img {
        margin-right: 16px;
        margin-left: 10px;
        width: 100px;
        height: 100px;
      }
      .item1Val {
        text-align: left;
        padding-top: 5px;
        box-sizing: border-box;
        .item1Title {
          font-family: PingFangSC-Medium;
          font-size: 14px;
          color: #262626;
          line-height: 20px;
          display: flex;
          justify-items: flex-start;
          align-items: center;
          p {
            // width: 240px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            margin-top: 0;
          }
          .item1PP {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #262626;
          }
          .buyCar {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background: #ecf8f8;
            text-align: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-top: 6px;
            }
          }
        }
        p {
          margin-top: 8px;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #aaaaaa;
          line-height: 16px;
        }
      }
    }
    .item2 {
      width: 210px;
      margin-left: 60px;
      .item2Title {
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #262626;
        font-weight: 500;
        line-height: 20px;
        padding-top: 5px;
        box-sizing: border-box;
        span {
          text-decoration: line-through;
          font-size: 12px;
          color: #aaaaaa;
        }
      }
      .item2Item {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #aaaaaa;
        line-height: 16px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        p {
          margin-right: 10px;
          margin-top: 8px;
        }
      }
    }
    .item3 {
      width: 210px;
      margin-left: 24px;
      .item3Title {
        padding-top: 5px;
        box-sizing: border-box;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .item3Item {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #aaaaaa;
        line-height: 16px;

        p {
          margin-top: 8px;
        }
        .item3Items {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          p {
            margin-right: 10px;
          }
        }
      }
    }
    .item4 {
      width: 164px;
      padding-top: 5px;
      box-sizing: border-box;
      margin-left: 24px;
      .item4Title {
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        font-weight: 500;
      }
      .purchase {
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #777777;
        line-height: 16px;
        cursor: pointer;
        margin-top: 8px;
      }
      .purchase:hover {
        color: #00aaa6;
      }
    }
  }

  .line {
    width: 1092px;
    height: 1px;
    border: 1px solid #eeeeee;
    margin-top: 22px;
  }
  .settlement {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .settleTitle {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #777777;
      line-height: 16px;
      margin-left: 24px;
      margin-bottom: 8px;
      margin-top: 16px;
    }
    .settlementVal {
      width: 800px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-left: 24px;
      p {
        width: 120px;
        text-align: left;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #aaaaaa;
        line-height: 16px;
        margin-right: 50px;
        margin-top: 8px;
      }
    }
  }
  .pur-commodity-msg-bot{
    width: 1076px;
    height: 82px;
    border-top: 1px solid #EEEEEE;
    margin: 0 auto;
    text-align: left;
    :nth-child(2){
      margin-left: 24px;
    }
    :nth-child(3){
      margin: 0 50px;
    }
    :nth-child(4){
      margin-right: 50px;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      color: #777777;
      line-height: 16px;
      margin: 18px 0 16px 24px;
    }
    span{
      font-size: 12px;
      font-weight: 400;
      color: #AAAAAA;
      line-height: 16px;
    }
  }
}
</style>