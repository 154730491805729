/*
 * @Author: sunzuoyuan
 * @Date: 2020-12-10 20:05:03
 * @LastEditTime: 2021-06-07 16:44:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath:
 */

export default [
  {
    path: "/jurisdiction",
    name: "jurisdiction",
    redirect: "/jurisdiction/store",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/jurisdiction/merchants",
        component: () => import("@/views/jurisdiction/merchants/list/index.vue"),
        meta:{
          title:"管辖的商家",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/jurisdiction/store",
        component: () => import("@/views/jurisdiction/store/list/index.vue"),
        meta:{
          title:"管辖的门店",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/jurisdiction/storeAddEdit",
        component: () => import("@/views/distributionStores/addDistributionStore/index.vue"),
        // component: () => import("@/views/jurisdiction/store/addEdit/index.vue"),
        meta:{
          title:"新增/编辑/查看管辖的门店",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/jurisdiction/warehouse",
        component: () => import("@/views/jurisdiction/warehouse/list/index.vue"),
        meta:{
          title:"管辖的仓库",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/jurisdiction/warehouseAddEdit",
        component: () => import("@/views/jurisdiction/warehouse/addEdit/index.vue"),
        meta:{
          title:"新增/编辑/查看管辖的仓库",
          keepAlive: false,
          isBarShow:true
        },
      },
    ]
  }
];
