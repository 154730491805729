/*
 * @Author: mayu.ex 15163353598@163.com
 * @Date: 2024-03-20 14:11:45
 * @LastEditors: mayu.ex 15163353598@163.com
 * @LastEditTime: 2024-03-20 17:56:29
 * @FilePath: \B2B\src\router\modules\tradeInoldForNew.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [{
    path: "/tradeInoldForNew",
    name: "tradeInoldForNew",
    // redirect: "/confirm",
    component: () => import("@/layout/TopDownLayout/Frame.vue"),
    children: [{
        path: "/tradeInoldForNew",
        name: 'tradeInoldForNew',
        component: () => import("@/views/tradeInoldForNew/index.vue"),
        meta: {
          title: "旧机订单列表",
          keepAlive: false,
          isBarShow: true,
        }
      },
      {
        path: "/tradeInoldForNewDetail",
        name: 'tradeInoldForNewDetail',
        component: () => import("@/views/tradeInoldForNew/detail/index.vue"),
        meta: {
          title: "旧机订单详情",
          keepAlive: false,
          isBarShow: true,
        }
      }
  
    ]
  }];