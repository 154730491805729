import { asyncRoutes } from "@/router";
import constantRoutes from "@/router/modules/constantRoutes";
// import { getRoutes } from "@/utils/api";
import Layout from "@/layout/basicLayout";

const asyncRoutesRes = {
  data: [
    {
      children: [
        {
          url: "/orderCenter"
        }
      ],
      menu_name: "系统管理",
      menu_id: 1,
      url: "#",
      path: "/orderCenter",
      redirect: "/index"
    },
    {
      menu_name: "系统监控",
      url: "#",
      menu_id: 2,
      path: "/loginxxx",
      redirect: "/index"
    }
  ]
};

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.role.includes(role));
  } else {
    return true;
  }
}

/**
 * 后台查询出菜单数据拼装成路由格式的数据
 * @param routes
 * @param data
 */

export function generaMenu(routes, data) {
  data.forEach(item => {
    const menu = {
      path: item.url === "#" ? item.path : item.url,
      component:
        item.url === "#"
          ? Layout
          : resolve => require([`@/views${item.url}`], resolve),
      children: [],
      name: item.menu_name,
      meta: { title: item.menu_name, id: item.menu_id, roles: ["admin"] }
    };
    if (item.children && item.children.length > 0) {
      generaMenu(menu.children, item.children);
    }
    routes.push(menu);
  });
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = [];

  routes.forEach(route => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}

const state = {
  routes: [],
  addRoutes: []
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      const loadMenuData = [];
      // 先查询后台返回路由数据
      // getRoutes().then(response => {
      let { data } = asyncRoutesRes;
      Object.assign(loadMenuData, data);
      generaMenu(asyncRoutes, loadMenuData);
      let accessedRoutes;
      if (roles.includes("admin")) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      }
      commit("SET_ROUTES", accessedRoutes);
      resolve(accessedRoutes);
      // });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
