/*
 * @Description: 配置文件
 * @Author: gaorubin
 * @Date: 2020-05-15 10:09:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-23 11:24:50
 * @FilePath: /pangea-frontend/src/common/constant.js
 */

// api
export const serverApiTest =
    "http://pangea-cloud-gate-pangea-2-dev-oracle.devapps.hisense.com"; // 测试
//const serverApiUat = "http://pangea-gateway-test.devapps.hisense.com/download/"; // uat
const serverApiUat = "http://front.devapps.hisense.com/download/"; // uat
const serverApiProd = ""; // 正式
const serverApiDev = "http://localhost:8080"; // 本地

//dms api
// const dmsApiProduct = "https://xinshang.hisense.com:8443";
// const dmsApiDev = "http://dmstest.hisense.com";
const dmsApiUat = "/dms";
const dmsApiProduct = "/dms";
const dmsApiDev = "/dmsapi";

//panger  api  下载中心

const pangeaDev = '/pangesapi'

// 高德地图 api
const amapDev = '/amapapi'

// url
const ServerWebUrlTest = "/front/"; // 测试  http://front.devapps.hisense.com
const ServerWebUrlUat = "/front/"; // uat http://front.devapps.hisense.com
const ServerWebUrlProd = "/front/"; // 正式
const ServerWebUrlDev = "/api"; // 本地开发

//默认图片头路径
// const ServerWebUrlProdImg = "http://3s.hisense.com/front/"; // 正式
// 修改正式环境上图片地址
const ServerWebUrlProdImg = "https://xtw.hisense.com/front/";
const ServerWebUrlDevImg = "http://xtwdev.clouddev.hisense.com/front/"; // 本地开发图片路径

//老版本正式路径
const oldPublicUrlProd = "http://3s.hisense.com/front/index.htm"; // 本地开发图片路径



export const env = process.env.VUE_APP_CONFIG;

// 图片基础地址
const imgProductSrc = "https://3s-static.hisense.com/";
// const imgDevSrc = 'http://nginx-proxy-cis.devapps.hisense.com/';
const imgDevSrc = "http://3s-static.hisense.com/productImages/";
export const commImgUrl = env == "dev" ? imgDevSrc : imgProductSrc;

//待办公告通知图片地址
// 更改图片地址为正式地址
const imgDevSrc2 = 'http://nginx-proxy-cis.devapps.hisense.com/';
// const imgDevSrc2 = 'https://3s-static.hisense.com/'
// export const infoImgUrl = env == "dev" ? imgDevSrc2 : imgProductSrc;
export const infoImgUrl =
    env === "dev" ?
        imgDevSrc2 :
        env === "test" ?
            imgDevSrc2 :
            env === "uat" ?
                imgDevSrc2 :
                imgProductSrc;
// 在线充值
const saveMoneyUrlTest = 'http://pfs.fssc.hisense.com:7009/pfs/olineorder.do';
const saveMoneyUrlUat = 'http://pfs.fssc.hisense.com:7009/pfs/olineorder.do';


//在线充值 充值记录
export const saveMoneyType = env === "dev" ?
    saveMoneyUrlTest :
    env === "test" ?
        saveMoneyUrlTest :
        env === "uat" ?
            saveMoneyUrlTest :
            saveMoneyUrlUat;

// 版本正式测试切换
const testVersion = "http://b2b-front-cis.clouddev.hisense.com/index.htm";
const formalVersion = "http://3s.hisense.com/front/loginUser.htm";

export const versionSize = env == 'dev' ? testVersion : env == 'test' ? testVersion : env == 'uat' ? testVersion : formalVersion;

// 底部链接跳转设置
const testURL = "http://b2b-front-cis.clouddev.hisense.com/";
const formalURL = "http://3s.hisense.com/front/"


export const jumpURL =
    env === "dev" ?
        testURL :
        env === "test" ?
            testURL :
            env === "uat" ?
                testURL :
                formalURL;
// api
export const serverApi =
    env === "dev" ?
        serverApiDev :
        env === "test" ?
            serverApiTest :
            env === "uat" ?
                serverApiUat :
                serverApiProd;

// url
export const publicPath =
    env === "dev" ?
        ServerWebUrlDev :
        env === "test" ?
            ServerWebUrlTest :
            env === "uat" ?
                ServerWebUrlUat :
                ServerWebUrlProd;

// dms url
export const dmsPublicPath =
    env === "dev" ? dmsApiDev : env === "uat" ? dmsApiUat : dmsApiProduct;
// 现在只有测试地址
export const pangeaPublicPath = env === "dev" ? pangeaDev : env === "test" ? ServerWebUrlTest : env === "uat" ? serverApiUat : serverApiProd;
export const amapPublicPath = amapDev;

export const defaultImgPath =
    env === "dev" ?
        ServerWebUrlDevImg :
        env === "test" ?
            ServerWebUrlDevImg :
            env === "uat" ?
                ServerWebUrlDevImg :
                ServerWebUrlProdImg;


//老版本地址
export const oldPublicPath =
    env === "dev" ?
        ServerWebUrlDevImg :
        env === "test" ?
            ServerWebUrlDevImg :
            env === "uat" ?
                ServerWebUrlDevImg :
                oldPublicUrlProd;

const cisDevPath = '/cisapi'
const cisTestPath = 'http://cisdev.devapps.hisense.com/'
const cisTestPath2 = 'http://cisdev.clouddev.hisense.com/'
const cisProductPath = 'https://cis.hisense.com/'
export const cisPublicPath = env === "dev" ? cisDevPath : env === "test" ? cisTestPath : env === "uat" ? cisTestPath : cisProductPath;

export const cisPublicPath2 = env === "dev" ? cisTestPath2 : env === "test" ? cisTestPath2 : env === "uat" ? cisTestPath2 : cisProductPath;

// 是否是打包环境
export const isProduction = env === "test" || env === "uat" || env === "prod";