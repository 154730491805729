export default [{
  path: "/salesOrderChange",
  name: "salesOrderChange",
  redirect: "/salesOrderChange/list",
  component: () => import("@/layout/TopDownLayout/Frame"),
  children: [{
      path: "/salesOrderChange/list",
      component: () => import("@/views/salesOrderChange/index.vue"),
      meta: {
        title: "销售转换",
        keepAlive: false,
        isBarShow: true
      },
    }
  ]
}];