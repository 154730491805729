import http from "@/utils/request";
// 列表查询
export function getUserList(data) {
  return http({
    method: "post",
    url: "/fast/potential/shopPotentialUser/page.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}

// 头部数据
export function shopPotentialCustNum(data) {
  return http({
    method: "post",
    url: "/fast/userReport/shopPotentialCustNum.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}
// 导出
export function getExport(data) {
  return http({
    url: '/fast/potential/shopPotentialUser/export.nd',
    method: 'get',
    params: data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    responseType: 'blob'
  })
}

//发短信
export function sendMessageA(data) {
  return http({
    method: "post",
    url: "/shopShortMessage/sendMessage.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
//  获取未读通知
export function queryNotReadMessageNumber(data) {
  return http({
    url: 'cts/ctsApi.nd',
    method: 'post',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  })
}