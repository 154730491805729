<template>
  <a-modal
    v-if="type == 'allotApply'"
    :width="852"
    :height="411"
    title
    v-model="visible"
    @cancel="cancle"
    class="showbgmodel111"
    :footer="null"
  >
    <div class="table">
      <el-table
        :data="data"
        :span-method="objectSpanMethod"
        style="width: 100%;"
        :cell-style="cellStyle"
        :header-cell-style="tableHeaderCellStyle"
      >
        <el-table-column
          v-for="(col, index) in formThead"
          :key="index"
          :label="col.label"
          :column-key="index.toString()"
          :prop="col.prop"
          align="center"
        >
        </el-table-column>
      </el-table>
      <p style="margin-top:8px;color: #777777;font-size:12px">
        提示：该数据每天24点更新
      </p>
    </div>
  </a-modal>
</template>

<script>
import { string } from "clipboard";
export default {
  name: "sales-modal",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    }
  },

  data() {
    return {
      show: false,
      // modelShowsale: true,
      formThead: [
        {
          label: "",
          prop: "id",
          width: 250
        },
        {
          label: "",
          prop: "name",
          width: 250
        },
        {
          label: "销售出库台数",
          prop: "ckun",
          width: 250
        },
        {
          label: "调拨台数",
          prop: "dbnum"
        },
        {
          label: "总数量",
          prop: "total"
        },
        {
          label: "当前比例",
          prop: "dbbl"
        },
        {
          label: "系统限制比例",
          prop: "dbblxz"
        }
      ],
      tableData: [
        {
          id: "12987122",
          name: "王小虎",
          amount1: "234",
          amount2: "3.2",
          amount3: 10
        },
        {
          id: "12987123",
          name: "王小虎",
          amount1: "165",
          amount2: "4.43",
          amount3: 12
        },
        {
          id: "12987124",
          name: "王小虎",
          amount1: "324",
          amount2: "1.9",
          amount3: 9
        },
        {
          id: "12987125",
          name: "王小虎",
          amount1: "621",
          amount2: "2.2",
          amount3: 17
        },
        {
          id: "12987126",
          name: "王小虎",
          amount1: "539",
          amount2: "4.1",
          amount3: 15
        },
        {
          id: "12987126",
          name: "王小虎",
          amount1: "539",
          amount2: "4.1",
          amount3: 15
        }
      ]
    };
  },
  watch: {
    data: {
      handler: function(newval, oldval) {},
      deep: true
    }
  },
  mounted() {},

  methods: {
    handleOk() {
      this.$emit("update:visible", false);
    },
    cancle() {
      this.$emit("update:visible", false);
    },
    changeTabledata() {},
    tableHeaderCellStyle({ row, column, rowIndex, columnIndex }) {
      let cellStyle;
      cellStyle =
        "border-right:1px  solid #eee;border:1px  solid #eee;padding:7px 0 !important;";
      return cellStyle;
      // return "border:1px  solid #eee";
      // if (rowIndex == 0) {
      //   return "border-left:1px solid #eee";
      // }
      // if (columnIndex == 7) {
      //   return "border-right:1px solid #eee";
      // }
    },

    cellStyle({ row, column, rowIndex, columnIndex }) {
      let cellStyle;
      cellStyle =
        "border 1px solid #eee;border-top:none!important;padding:7px 0 !important;";
      if (columnIndex == 0) {
        // 第一列样式设置
        // return "border-left: 1px solid #eee;border-right: 1px solid #eee;background:#F7F7F7;color:#262626;text-align:center;pointer-events: none !important;";
        if (rowIndex == 0) {
          return "border-left: 1px solid #eee;background:#F7F7F7;color:#262626;text-align:center;border-right: 1px solid #eee";
        }
        if (rowIndex == 1) {
          return "border-left: 1px solid #eee;background:#F7F7F7;color:#262626;text-align:center;border-right: 1px solid #eee";
        }
        if (rowIndex == 2) {
          return "border-left: 1px solid #eee;background:#F7F7F7;color:#262626;text-align:center;border-right: 1px solid #eee";
        }
        if (rowIndex == 3) {
          return "border-left: 1px solid #D0F5F4;background:#ECF8F8;color:#262626;text-align:center;border-right: 1px solid #D0F5F4";
        }
        if (rowIndex == 4) {
          return "border-left: 1px solid #D0F5F4;background:#ECF8F8;color:#262626;text-align:center;border-right: 1px solid #D0F5F4";
        }
        if (rowIndex == 5) {
          return "border-left: 1px solid #D0F5F4;background:#ECF8F8;color:#262626;text-align:center;border-right: 1px solid #D0F5F4; ";
        }
      } else if (
        // 2-7列 4-6行样式设置
        columnIndex == 1 ||
        columnIndex == 2 ||
        columnIndex == 3 ||
        columnIndex == 4 ||
        columnIndex == 5 ||
        columnIndex == 6
      ) {
        if (rowIndex == 0) {
          return "border-right: 1px solid #eee;pointer-events: none !important";
        }
        if (rowIndex == 1) {
          return "border-right: 1px solid #eee;pointer-events: none !important";
        }
        if (rowIndex == 2) {
          return "border-right: 1px solid #eee;pointer-events: none !important";
        }
        if (rowIndex == 3) {
          return "background:#ECF8F8;border-top: 1px solid #D0F5F4;border-right: 1px solid #D0F5F4;pointer-events: none !important";
        }
        if (rowIndex == 4) {
          return "background:#ECF8F8;border-top: 1px solid #D0F5F4;border-right: 1px solid #D0F5F4;pointer-events: none !important";
        }
        if (rowIndex == 5) {
          return "background:#ECF8F8;border-top: 1px solid #D0F5F4;border-right: 1px solid #D0F5F4;pointer-events: none !important";
        }
      } else if (columnIndex == 1) {
        return "border-left: 1px solid #eee;pointer-events: none !important";
      } else {
        return "";
      }

      return cellStyle;
    },
    // 表格样式做处理
    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      let style;
      style = "border 1px solid #eee;";
      if (columnIndex == 0) {
        // 第一列样式设置
        // return "border-left: 1px solid #eee;background:#F7F7F7;color:#262626;text-align:center";
        if (rowIndex == 0) {
          return "border-left: 1px solid #eee;background:#F7F7F7;color:#262626;text-align:center";
        }
        if (rowIndex == 1) {
          return "border-left: 1px solid #eee;background:#F7F7F7;color:#262626;text-align:center";
        }
        if (rowIndex == 2) {
          return "border-left: 1px solid #eee;background:#F7F7F7;color:#262626;text-align:center";
        }
        if (rowIndex == 3) {
          return "border-left: 1px solid #ECF8F8;background:#ECF8F8;color:#262626;text-align:center";
        }
        if (rowIndex == 4) {
          return "border-left: 1px solid #ECF8F8;background:#ECF8F8;color:#262626;text-align:center";
        }
        if (rowIndex == 5) {
          return "border-left: 1px solid #ECF8F8;background:#ECF8F8;color:#262626;text-align:center";
        }
      } else if (
        // 2-7列 4-6行样式设置
        columnIndex == 1 ||
        columnIndex == 2 ||
        columnIndex == 3 ||
        columnIndex == 4 ||
        columnIndex == 5 ||
        columnIndex == 6
      ) {
        if (rowIndex == 0) {
          return "border-right: 1px solid #eee;";
        }
        if (rowIndex == 1) {
          return "border-right: 1px solid #eee;";
        }
        if (rowIndex == 2) {
          return "border-right: 1px solid #eee;";
        }
        if (rowIndex == 3) {
          return "background:#ECF8F8;border-top: 1px solid #D0F5F4;border-right: 1px solid #D0F5F4;";
        }
        if (rowIndex == 4) {
          return "background:#ECF8F8;border-top: 1px solid #D0F5F4;border-right: 1px solid #D0F5F4;";
        }
        if (rowIndex == 5) {
          return "background:#ECF8F8;border-top: 1px solid #D0F5F4;border-right: 1px solid #D0F5F4;";
        }
      } else if (columnIndex == 1) {
        return "border-left: 1px solid #eee;";
      } else {
        return "";
      }
      return style;
    },
    // 第一列合并处理
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 3 === 0) {
        if (columnIndex === 0) {
          return [1, 3];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      }
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.showbgmodel111 {
  /deep/ .ant-modal {
    margin-top: 40px !important;
  }
  /deep/ .ant-modal-body {
    padding: 0;
    padding-top: 54px !important;
    margin-bottom: 69px !important;
    padding-left: 34px !important;
    padding-right: 34px !important;
  }
  /deep/.el-table td,
  .el-table th {
    padding: 7px 0px !important;
  }
  /deep/ .ant-modal-close-x:hover {
    color: #00aaa6 !important;
    background: #e4f8f7 !important;
    border-radius: 0px 10px 0px 0px !important;
  }
  /deep/.el-table__footer-wrapper,
  .el-table__header-wrapper {
    border: 1px solid #eee !important;
  }
  /deep/.el-table__empty-block {
    width: 100% !important;
    border: 1px solid #eee !important;
  }
  // /deep/.el-table {
  //   margin-top: 34px !important;
  // }
  /deep/.el-table td,
  .el-table th {
    padding: 7px 0px !important;
  }
  /deep/ .ant-modal-content {
    border-radius: 10px !important;
  }

  /deep/ .ant-input:hover {
    border-color: #00aaa6 !important;
  }

  /deep/.ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    font-size: 14px;
    // font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  /deep/.ant-modal-close-x {
    height: 48px !important;
  }
  /deep/ .ant-modal-close-x:hover {
    color: #00aaa6;
    background: #e4f8f7;
    border-radius: 0px 10px 0px 0px;
  }

  /deep/.ant-table-thead > tr > th {
    font-weight: bold;
    border: 1px solid #eee !important;
  }
  /deep/.ant-modal-content {
    height: 411px !important;
  }
  /deep/ .ant-modal-content {
    /deep/ .ant-modal-body {
      padding: 0;
      padding-top: 54px !important;
      margin-bottom: 69px !important;
      padding-left: 34px !important;
      padding-right: 34px !important;

      .table {
        width: 783px;
        height: 288px !important;

        /deep/.el-table th > .cell {
          font-size: 14px;
          // font-weight: 500;
          color: #262626;
        }
        // /deep/.el-table td,
        // .el-table th {
        //   padding: 7px 0px !important;
        // }
        /deep/.el-table__header-wrapper {
          border: 1px solid #eee;
        }
        /deep/ .el-table td,
        .el-table th tr {
          pointer-events: none !important;
        }
      }
    }
  }
}
/deep/ .el-table td,
.el-table th tr {
  pointer-events: none !important;
}
/deep/ .ant-modal {
  margin-top: 40px !important;
}
</style>