<template>
  <a-modal 
    v-model="visible" 
    wrapClassName="messageName" 
    :width='460'
    :closable="false"
    centered
    :maskClosable='false'
    >
    <a-icon type="info-circle" />
    <p>
      {{info}}
    </p>
    <template slot="footer">
      <a-button class="ant-btn-primary" @click="handleOk">知道了</a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  // 示例
  // <message-modal 
  // :visible.sync="visible"
  // info='提示文字'
  // ></message-modal>
  name:'message-modal',
  data(){
    return{
    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    info:{
      type:String,
      default:''
    }
  },
  methods:{
    handleOk(){
      this.$emit('update:visible',false)
      this.$emit('handOk')
    }
  }
}
</script>
<style lang="less" scoped>
  /deep/ .messageName{
    .ant-modal-content{
      text-align:center;
      .ant-modal-body{
        padding:30px 70px 0 70px;
        .anticon{
          font-size:54px;
          color:#1890FF;
          padding-bottom:12px;
        }
        p{
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #262626;
          line-height: 30px;
        }
      }
      .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:24px 0 30px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        font-size: 16px;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
      }
    }
    }
    
  }
</style>


