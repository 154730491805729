/*
 * @Author: mayu
 * @Date: 2020-09-28 14:26:41
 * @LastEditTime: 2021-01-31 23:17:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: B2B\src\router\modules\shoppingCart.js
 */



export default [{
    path: "/terminalManagement",
    name: "terminalManagement",
    // redirect: "/confirm",
    component: () => import("@/layout/TopDownLayout/Frame.vue"),
    children: [{
        path: "/terminalManagement/trainingRecords",
        name: 'trainingRecords',
        component: () => import("@/views/terminalManagement/trainingRecords/index.vue"),
        meta: {
          title: "培训打卡记录",
          keepAlive: false,
          isBarShow: true,
        }
      }
  
    ]
  }];