export default [
  {
    path: "/communityCompass",
    name: "communityCompass",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/communityCompass/list",
        component: () => import("@/views/communityCompass/list/index.vue"),
        meta: {
          title: "小区罗盘", //配置title
          keepAlive: false, //是否缓存
          isBarShow: false
        }
      },


    ]
  }
];

