// 根据id获取对应弹框提示
export function getAlertInfoByCode(code){
  let data = {}
  let currCode = code
  let b2bAlert = localStorage.getItem('msgArr')
  if(currCode && b2bAlert){
    let result = JSON.parse(b2bAlert)
    result = result.filter((item)=>{
      return item.msgCode == currCode
    })

    if(result && result.length > 0){
      data = result[0]
    }
  }
  // msg = msg.replace(/<\/br>/g, "\n").replace(/<br\/>/g, "\n")
  return data
}