// 报表
export default [
    {
        path: "/reports",
        name: "reports",
        redirect: "/reports",
        component: () => import("@/layout/TopDownLayout/Frame.vue"),
        children: [
            {
                path: "operation",
                component: () => import("@/views/reports/operation/index.vue"),
                meta:{
                    title:"运营商结构",
                    keepAlive: false,
                    isBarShow:true
                }
            },
            {
                path: "inventory",
                component: () => import("@/views/reports/inventory/index.vue"),
                meta:{
                    title:"库存",
                    keepAlive: false,
                    isBarShow:true
                }
            },
            {
                path: "purchase",
                component: () => import("@/views/reports/purchase/index.vue"),
                meta:{
                    title:"采购",
                    keepAlive: false,
                    isBarShow:true
                }
            },
            {
                path: "channelPurchase",
                component: () => import("@/views/reports/channelPurchase/index.vue"),
                meta:{
                    title:"渠道采购",
                    keepAlive: false,
                    isBarShow:true
                }
            },
            {
                path: "sales",
                component: () => import("@/views/reports/sales/index.vue"),
                meta:{
                    title:"销售",
                    keepAlive: false,
                    isBarShow:true
                }
            },
            {
                path: "inventManage",
                component: () => import("@/views/reports/inventManage/index.vue"),
                meta:{
                    title:"库存经营报表",
                    keepAlive: false,
                    isBarShow:true
                }
            }
        ]
    }
];
