/*
 * @Author: sqk
 * @Date: 2020-08-07 14:38:39
 * @LastEditTime: 2022-11-17 23:16:12
 * @LastEditors: yuchengpeng yuchengpeng.ex
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\request.js
 */
import axios from "axios";
import router from "../router";
// import Vuex from "vuex";
import store from "../store";
import Qs from "qs";
// import { message,notification } from "ant-design-vue"; //ui库的消息提示
import { publicPath } from "@/common/constant.js";
import { removeJession, removeToken } from "./auth";
// const BASE_API = process.env.VUE_APP_API_BASE_URL; // 地址
import Logan from 'logan-web';
const BASE_API = publicPath; // 地址
//操作信息数组
const msgArr = JSON.parse(localStorage.getItem('msgArr'));
// axios.defaults.withCredentials = true//axios请求携带cookie配置
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
//信天翁PC端标志
axios.defaults.headers.common["loginplant"] = "front_new_pc";
//请求的header里增加一个参数appversion，pc端直接固定写死 2.0
axios.defaults.headers.common["appversion"] = "2.0";
// 获取token 给header
let name = 'ssoLoginToken' + "=";
let reg = /^['|"](.*)['|"]$/;
let ca = document.cookie.split(';');
for (let i = 0; i < ca.length; i++) {
  let c = ca[i].trim();
  if (c.indexOf(name) == 0) {
    var token = (c.substring(name.length, c.length)).replace(reg, "$1")
  }
  axios.defaults.headers.common['ssoLoginToken'] = token;

}
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;utf-8";
// "application/json;utf-8";
// axios.defaults.withCredentials = true // open cookie
// axios.defaults.xsrfCookieName = 'iatsSessionKey' // the name of the cookie to use as a value for xsrf token
let ignoreWarn = false; // 是否忽略提醒
// create axios instance
const service = axios.create({
  mute: false,
  baseURL: BASE_API, // base_url for api
  timeout: 120 * 1000, // request overtime time
  withCredentials: true,
});
let configData = {}
//请求拦截
service.interceptors.request.use(
  (config) => {
    //设置请求头 -》 记录请求url
    config.headers.common['apiUrl'] = config.url;
    configData=config
    if (config.contentType) {
      config.headers.post['Content-Type'] = config.contentType;
    }

    // 记录请求 之后清空
    config.cancelToken = new axios.CancelToken(function (cancel) {

      store.commit("user/setAxiosArr", cancel);
    });

    let name = 'ssoLoginToken' + "=";
    let reg = /^['|"](.*)['|"]$/;
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) == 0) {
        var token = (c.substring(name.length, c.length)).replace(reg, "$1")
      }
      axios.defaults.headers.common['ssoLoginToken'] = token;

    }

    //忽略警告
    config.ignoreWarn ? (ignoreWarn = true) : (ignoreWarn = false);
    if (config.method === "get") {
      //cis接口
      config.paramsSerializer = function (data) {
        return Qs.stringify(data, { arrayFormat: "brackets" });
      };
    }
    // deal post request
    if (config.method === "post") {
      config.transformRequest = [
        function (data) {
          if (config.type == 'stringfy') {
            return JSON.stringify(data)
          } else {
            // Do whatever you want to transform the data
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
            }
            return ret;
          }

        },
      ];
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);
//响应拦截
service.interceptors.response.use(

  (response) => {
    // 确认收货弹窗
    if (response.data.code == 400) {
      window.$vue.$message.warning({
        content: response.data.msg || response.data.message,
        duration: 3 //延时2.5s
      });
    }
    if (msgArr && msgArr.length > 0) {
      for (const item of msgArr) {
        if (item.msgCode == response.data.msgCode) {
          //1成功提示 2确认提示 3业务报错 4异常信息
          switch (item.msgType) {
            case 1:
              window.$vue.$message.success({
                content: item.msgDesc,
                duration: 3 //延时2.5s
              });
              break;
            case 2:
              window.$vue.$info({
                class: 'alert-box',
                // title: '这里是code，请查看',
                content: item.msgDesc,
              });
              break;
            case 3:
              window.$vue.$notification.warning({
                message: item.msgDesc,//标题
                description: item.msgTypeName + '代码：' + item.msgCode,//内容
                duration: 5, //延时3s
                key: 'one'
              });
              break;
            case 4:
              window.$vue.$notification.error({
                message: item.msgDesc,//标题
                description: item.msgTypeName + '代码：' + item.msgCode,//内容
                duration: 5, //延时3s
                key: 'one'
              });
              break;
            default:
              break;
          }

        }
      }
    }
    if (response.status === 200) {
      return response;
    } else {
      // token过期 --> 清除登陆的信息，提示用户重新登陆
      if (response.data.status == "401") {
        // todo -> 清除token和账号
        // localStorage.removeItem("user");
        // localStorage.removeItem("tokenId");

        // 清楚vuex中的 请求来的 用户信息
        // this.$store.commit("user", null);
        store.commit("user/logout", "");
        window.$vue.$notification.warning({
          message: "安全信息已过期，请重新登陆",
          // description: response.data.message,
          duration: 3, //延时2.5s
          onClose: function () {
            //跳转登录页
            // alert(111)
            router.push("/login");
          },
        });
      }
    }
  },
  /**
   * error callback change callback by judge status code
   */
  (error) => {

    console.error("err" + error); // for debug
    if (!error.response) {
      return;
    }
    console.log('失败')
    if (error.response.status === 400) {
      if (error.response.data.code == 400) {
        // alert(11)
        // Vuex.dispatch("user/logout",'');
        //判断是否返回报错信息
        if (error.response.data.msg && error.response.data.msg.indexOf('请登录') != '-1') {
          if (!store.state.user.isGoLogin) {
            localStorage.setItem("userInfo", "");
            removeToken()
            removeJession()
            store.state.user.userInfo = ''
            return;
          } else {
            window.$vue.$notification.warning({
              message: "安全信息已过期，请重新登陆",
              // description: response.data.message,
              duration: 3, //延时2.5s
              key: 'one'
            });
          }
          store
            .dispatch("user/logout")
            .then(() => {

              // window.$vue.$notification.success("退出成功!");
            })
            .catch((err) => {
              console.error(err);
            });
          // router.push({
          //   path: "/login",
          // });
          return;
        } else {
          window.$vue.$notification.warning({
            message: error.response.data.msg,
            // description: response.data.message,
            duration: 3, //延时2.5s
            key: 'one'
          });
        }



      }
    } else if (error.response.status === 401) {
      // token失效 ,重新获取token
      // todo -> 清除token和账号
      // localStorage.removeItem("user");
      // localStorage.removeItem("tokenId");

      // 清楚vuex中的 请求来的 用户信息
      // this.$store.commit("user", null);
      // Vuex.commit("user", null);
      window.$vue.$notification.warning({
        message: "安全信息已过期，请重新登陆",
        // description: response.data.message,
        duration: 3, //延时2.5s
        onClose: function () {
          //跳转登录页
          // alert(111)
          router.push("/login");
        },
      });
    } else if (error.response.status === 500) {
      // 服务器断开
      window.$vue.$notification.error({
        message: "服务器断开,请重试",
        // description: response.data.message,
      });
    } else if (error.response.status === 403) {
      //无auth授权，后台不允许访问
      window.$vue.$notification.error({
        message: "无auth授权，后台不允许访问",
        // description: response.data.message,
      });
    }
    if (!ignoreWarn) {
      window.$vue.$notification.error({
        message: "服务器繁忙，请稍后重试！",
        // description: data.message
      });
    }
    Logan.log(error,3)
    return Promise.reject(error);
  }
);

export default service;
