
export default [{
  path: "/policyRebate",
  name: "policyRebate",
  // redirect: "/confirm",
  component: () => import("@/layout/TopDownLayout/Frame.vue"),
  children: [
    {
      path: "/policyRebate",
      name: 'policyRebate',
      component: () => import("@/views/policyRebate/index.vue"),
      meta: {
        title: "政策返利",
        keepAlive: false,
        isBarShow: true,
      }
    },
    {
      path: "/policyRebate/todoDetail",
      name: 'policyRebate/todoDetail',
      component: () => import("@/views/policyRebate/todoDetail/index.vue"),
      meta: {
        title: "政策对账单",
        keepAlive: false,
        isBarShow: true,
      }
    },
    {
      path: "/policyRebate/rebateDetail",
      name: 'policyRebate/rebateDetail',
      component: () => import("@/views/policyRebate/rebateDetail/index.vue"),
      meta: {
        title: "政策返利详情",
        keepAlive: false,
        isBarShow: true,
      }
    },

  ]
}];