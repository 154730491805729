// 销售订单
let xsdd = [{
	label: "客户名称",
	prop: "customerName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "订单创建日期",
	prop: "createDate",
	ellipsis: true,
	sortable: 'custom',
	sortName: 'so.document_date',
	width: 200,
	value: true
},
{
	label: "物料组",
	prop: "materialGroupName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "型号",
	prop: "model",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "颜色",
	prop: "colour",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "库存状态",
	prop: "invStatusName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "销售单号",
	prop: "documentNum",
	ellipsis: true,
	slot: "documentNum",
	sorter: true,
	sortName: 'so.document_num',
	sortable: 'custom',
	width: 200,
	value: true
},
{
	label: "采购单号",
	prop: "purchaseNum",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货单号",
	prop: "stvDocumentNum",
	ellipsis: true,
	slot: "stvDocumentNum",
	width: 200,
	value: true
},
{
	label: "发货仓库",
	prop: "gicOutWarehouseName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "配送方式",
	prop: "deliveryTypeName",
	ellipsis: true,
	width: 100,
	value: true
},
	{
		label: "自提委托书",
		prop: "attorneyLetter",
		ellipsis: true,
		slot: "attorneyLetter",
		width: 500,
		value: true
	},
{
	label: "订单状态",
	prop: "statusName",
	ellipsis: true,
	sorter: true,
	sortName: 'so.status',
	sortable: 'custom',
	width: 150,
	value: true
},
{
	label: "发货单状态",
	prop: "stvStatusName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "物流状态",
	prop: "omsStatusName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货单创建时间",
	prop: "stvCreateDate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "下单数量",
	prop: "totalNum",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "审核数量",
	prop: "backnowledgedQty",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货数量",
	prop: "bDemandQty",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "甩货数量",
	prop: "shQty",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "签收数量",
	prop: "signQty",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "拒收数量",
	prop: "rejectQty",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "短少数量",
	prop: "lostQty",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "签收时间",
	prop: "signDate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "订单金额",
	prop: "totalAmount",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货金额",
	prop: "outAmount",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "参与活动单号",
	prop: "userActivityCode",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "活动类型",
	prop: "discountTypeName",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "组合编码",
	prop: "packageCode",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "产品分组编码",
	prop: "productGroup",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "产品分组说明",
	prop: "productGroupRemark",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "比例",
	prop: "packageRateNum",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "产品编码",
	prop: "productCode",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "订单审核时间",
	prop: "lastSumbmitDate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货日期",
	prop: "outDate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货库存状态",
	prop: "soiInvStatusName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "销售单补差类型",
	prop: "invStatusTypeName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货单补差类型",
	prop: "soiInvStatusTypeName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "收货地址",
	prop: "addressDesc",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "联系人",
	prop: "customerName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "联系电话",
	prop: "phone",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "下单平台",
	prop: "dataSourceValue",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "下单用户",
	prop: "customizedcreator",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "销售单类型",
	prop: "documentTypeName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "销售类型",
	prop: "retailTypeName",
	ellipsis: true,
	width: 100,
	value: true
},
{
	label: "销售门店",
	prop: "storeName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "期望送货日期",
	prop: "edt",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "预计送达时间",
	prop: "reserveTime",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "客户编码",
	prop: "customerCode",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "销售组织名称",
	prop: "orgName",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "活动名称",
	prop: "activityName",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "退货状态",
	prop: "returnStatusName",
	ellipsis: true,
	value: true,
	width: 160,
},
{
	label: "参与活动单号",
	prop: "userActivityCode",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "是否专卖店",
	prop: "isSpeclalShop",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "是否跨品类",
	prop: "isCrossCategory",
	ellipsis: true,
	width: 160,
	value: true
}
]
// 采购订单
let cgdd = [{
	label: "采购订单编码",
	prop: "orderCode",
	sortable: 'custom',
	sortName: "salesOrder.orderCode",
	slot: "orderCode",
	width: 400,
	value: true
},
{
	label: "订单创建日期",
	prop: "orderDate",
	sortable: 'custom',
	sortName: "salesOrder.createdDate",
	width: 200,
	value: true
},
{
	label: "截止订单失效天数",
	prop: "effectiveDays",
	// sortable: 'custom',
	// sortName: "salesOrder.effectiveDays",
	width: 200,
	value: true
},
{
	label: "下单商家编码",
	prop: "mdmCode",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "下单商家名称",
	prop: "fullName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "订单类型",
	prop: "orderTypeName",

	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "订单状态",
	prop: "orderStatusName",
	key: '23',
	width: 200,
	value: true
},
{
	label: "供应商",
	prop: "fwOrgName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "物料组",
	prop: "matklName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "型号",
	prop: "zzprdmodel",

	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "颜色",
	prop: "productColor",
	ellipsis: true,
	width: 160,
},
{
	label: "批次",
	prop: "batch",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "定价组",
	prop: "priceGroupName",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "补差类型",
	prop: "makeUpType",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "返利比例(%)",
	prop: "rebates",
	align: "right",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "提货价",
	prop: "basePrice",
	align: "right",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "政策直扣",
	prop: "policyDeduct",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "政策折扣",
	prop: "policyDiscount",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "固定折扣",
	prop: "fixedDiscount",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "开票价",
	prop: "billPrice",
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "下单数量",
	prop: "qty",
	key: '32424',
	ellipsis: true,
	width: 160,
	value: true
},
{
	label: "金额",
	prop: "atmPrice",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "政策编码",
	prop: "policyCode",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "要求到货周",
	prop: "weekName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "订单评审日期",
	prop: "checkDate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "扣款日期",
	prop: "payDate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货单号",
	prop: "sapOrderCode",
	ellipsis: true,
	slot: "sapOrderCode",
	width: 200,
	value: true
},
{
	label: "发货单状态",
	prop: "statusName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货单创建时间",
	prop: "sapOrderDate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货单体积合计(m³)",
	prop: "allLoadVolume",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货单金额(元)",
	prop: "sendAtmPrice",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货单数量",
	prop: "erpQty",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "出库数量",
	prop: "outboundQty",
	// ellipsis: true,
	width: 200,
	value: true
},
{
	label: "签收数量",
	prop: "signQty",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "甩货数量",
	prop: "shQty",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "入库数量",
	prop: "actualQty",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "允许提前交货",
	prop: "isAllowAdvancDeliver",
	ellipsis: true,
	width: 200,
	value: true
},

{
	label: "晚到标识",
	prop: "appointmentLate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "发货日期",
	prop: "outboundDate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "预计送达日期",
	prop: "eDate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "实际签收时间",
	prop: "signedDate",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "配送方式",
	prop: "deliveryTypeName",
	ellipsis: true,
	width: 200,
	value: true
},
	{
		label: "自提委托书",
		prop: "attorneyLetter",
		ellipsis: true,
		slot: "attorneyLetter",
		width: 500,
		value: true
	},
{
	label: "收货地址",
	prop: "address",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "联系人-联系电话",
	prop: "contactAndMoblie",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "统仓收货标识",
	prop: "shareFlagName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "收货统仓",
	prop: "shareWarehouseName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "收货分销编码",
	prop: "fenXiaoCode",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "收货分销商名称",
	prop: "fenXiaoName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "采购下单平台",
	prop: "orderSourceName",
	ellipsis: true,
	width: 200,
	value: true
},
{
	label: "下单用户",
	prop: "customizedCreator",
	ellipsis: true,
	width: 200,
	value: true
}
]
function setSort(list) {
	for(let i = 0;i < list.length;i++) {
		list[i].sort = i+1
	}
}
setSort(xsdd)
setSort(cgdd)
export {
	xsdd,
	cgdd
}
