<!--
 * @Author: sq
 * @Date: 2020-10-22 09:39:41
 * @LastEditTime: 2020-11-26 16:44:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\Tag\index.vue
-->

<template>
  <span class="htag" :style="setStyle()">{{ value }}</span>
</template>

<script>
/**
 @author sq
 @description 标签
 @argument value: 内容            //必填 可以为 急/常/新品/促  或者 自定义标签内容
 @argument borderColor: 边框颜色  //非必填
 @argument Color: 字体颜色        //非必填
 @argument height: 标签高度       默认 xxx
 @demo <h-tag
 borderColor="#ccc"
 Color="#ccc"
 value="急:any"
 ></h-tag>
 */
export default {
  name: "h-tag",
  data() {
    return {};
  },
  props: {
    value: String,
    borderColor: String,
    Color: String,
    height: {
      type:[String, Number],
      default: 18
    }
  },
  watch: {},
  methods: {
    setStyle() {
      const content = this.value.trim();
      let style = {};
      style.background = this.background;
      style.height = this.height + 'px';
      style.lineHeight = this.height - 2 + 'px';
      style.color =
          content == "常"
              ? "#00B7B3"
              : content == "急"
              ? "#FF8F3C"
              : content == "新品"
                  ? "#00AAA6"
                  : content == "促"
                      ? "#D9161C"
                      : content == "热卖"
                          ? "#ff8f3c"
                          : content == "爆款"
                              ? "#d9161c"
                              : content == "返利"
                                ? "#FC5260"
                                : this.Color;
      style.borderColor =
          content == "常"
              ? "#00B7B3"
              : content == "急"
              ? "#FF8F3C"
              : content == "新品"
                  ? "#00AAA6"
                  : content == "促"
                      ? "#D9161C"
                      : content == "热卖"
                          ? "#ff8f3c"
                          : content == "爆款"
                              ? "#d9161c"
                              : content == "返利"
                                ? "#FC5260"
                                : this.borderColor;
      return style;
    }
  }
};
</script>

<style lang="less" scoped>
.htag {
  padding: 0 7px;
  border-radius: 2px;
  border: 1px solid;
  font-size: 12px;
  box-sizing: border-box;
  font-weight: 500;
}
</style>
