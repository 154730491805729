/*
 * @Author: your name
 * @Date: 2022-04-19 15:29:43
 * @LastEditTime: 2022-05-13 10:13:43
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \1015xtwCode\B2B\src\router\modules\marketingActivitiesPage.js
 */
export default [
  {
    path: "/marketingActivitiesGeneral",
    name: "marketingActivitiesGeneral",
    component: () => import("@/views/marketingActivitiesNew/general/general.vue"),
    meta: {
      title: "促销活动", //配置title
      keepAlive: false, //是否缓存
      isBarShow: false,
    },
  },
  {
    path: "/marketingActivitiesPage",
    name: "marketingActivitiesPage",
    component: () => import("@/views/marketingActivitiesNew/index.vue"),
    meta: {
      title: "容声活动", //配置title
      keepAlive: false, //是否缓存
      isBarShow: false,
    },
  },
  {
    path: "/marketingActivitiesRevelry",
    name: "marketingActivitiesRevelry",
    component: () => import("@/views/marketingActivitiesNew/revelry/revelry.vue"),
    meta: {
      title: "9.9嗨购好物节", //配置title
      keepAlive: false, //是否缓存
      isBarShow: false,
    },
  },
  {
    path: "/AugustRefrigerator",
    name: "AugustRefrigerator",
    component: () => import("@/views/marketingActivitiesNew/AugustRefrigerator/index.vue"),
    meta: {
      title: "直播订货会", //配置title
      keepAlive: false, //是否缓存
      isBarShow: false,
    },
  },
  {
    path: "/marketingActivitiesPage2",
    name: "marketingActivitiesPage2",
    // redirect: "/intendedMerchant",
    // component: () => import("@/layout/TopDownLayout/Frame.vue"),
    component: () => import("@/views/marketingActivitiesPage/index.vue"),
    children: [
      {
        path: "/intendedMerchant",
        component: () => import("@/views/marketingActivitiesPage/index.vue")

      }
    ]
  },
];