<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="name"
    :width="460"
    :closable="false"
    centered
    :maskClosable="false"
  >
    <a-icon v-if="type == 'delete'" type="exclamation-circle" />
    <a-icon
      v-if="type == 'confirm'"
      type="check-circle"
      style="color: #1890FF"
    />
    <div class="textContent" v-if="typeA == 'free'">
      <p>
        1、当天截单时间内，同批量订单达到起运量（电视3台，白电或全品类2方），则免配送费！
      </p>
      <p>
        2、当天截单时间内，同批量订单若包含至少1件单价超万元产品或激光，则免配送费！
      </p>
      <p>
        3、若不满足以上条件，将按照统仓统配合同不足起运量收费标准向您收取物流费用！
      </p>
      <p>⭐以上政策仅限开通统仓统配区域商家！！！</p>
    </div>
    <div class="textContent" v-if="typeA == 'ls'">
      <!-- <p>
        1、当前零售订单中，若订单包含至少1件单价超万元产品或激光产品，则免费配送！
      </p>
      <p>
        2、若当前零售订单中，不含1件万元以上产品或激光产品，需与营销中心签订2C物流服务协议，方可开通2C直送用户或送装业务。
      </p>
      <p>
        3、若当前零售订单为工程2B订单（非宅配或送装业务），请选择销售类型为“工程”类别进行下单。
      </p>
      <p>☆以上政策仅限开通统仓统配区域商家！</p> -->
      <p v-html="messageTipls">

      </p>
    </div>
    <div class="textContent" v-if="typeA == 'gc'">
      <!-- <p>
        1、该销售类型为工程的零售订单，为直送工地的2B服务入口，该服务为2B服务，不含宅配、上楼和送装类2C服务，2C服务请选择零售销售类型进行下单。
      </p>
      <p>
        2、当天截单时间内，同批量订单达到起运量（电视3台，冰箱、空调或全品类2方）则免费配送！若不满足以上条件，将按照统仓统配合同不足起运量收费标准向您收取物流费用！
      </p>
      <p>☆以上政策仅限开通统仓统配区域商家！</p> -->
      
       <p v-html="messageTipgc"></p>
    </div>
  </a-modal>
</template>
<script>
export default {
  // 示例
  // <delete-modal
  // :visible.sync="visible"
  // info='删除提示文字'
  // @deleteHandOk="handlerok"
  // ></delete-modal>
  name: "free-modal",
  data() {
    return {
      messageTipls:'',
      messageTipgc:''
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    typeA: {
      type: String,
      default: ""
    },
    info: {
      type: String,
      default: "确认删除商品吗？"
    },
    type: {
      type: String,
      default: "delete"
    }
  },
  mounted() {
    this.getMessage()
    this.getMessage1()
  },
  methods: {
     getMessage() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: '14187496797'
      })
      // this.$message.info(msg && msg.msgDesc);
      this.messageTipls = msg && msg.msgDesc
    },
    getMessage1() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: '14187496805'
      })
      // this.$message.info(msg && msg.msgDesc);
      this.messageTipgc = msg && msg.msgDesc
    },
    handleOk() {
      this.$emit("update:visible", false);
      this.$emit("deleteHandOk");
    },
    cancle() {
      this.$emit("update:visible", false);
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .name {
  .textContent {
    // padding: 0 22px;
    text-align: left;
    line-height: 24px;
    font-size: 12px;
    p {
      font-size: 12px;
      color: #262626;
      line-height: 20px;
    }
  }
  .ant-modal-content {
    text-align: center;
    .ant-modal-body {
      padding: 30px 0 0 0;
      .anticon {
        font-size: 54px;
        color: #ff8f3c;
        padding-bottom: 12px;
      }
      p {
        // height: 34px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 24px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        // padding-top: 10px;
      }
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 24px 0 30px;
      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        font-size: 16px;
        color: #777;
      }
      .ant-btn-primary {
        color: #fff;
        background: #00aaa6;
        margin-left: 24px;
        border: none;
      }
    }
  }
}
</style>


