/*
 * @Author: sqk
 * @Date: 2020-08-07 16:16:59
 * @LastEditTime: 2023-09-01 14:18:54
 * @LastEditors: mayu.ex 15163353598@163.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\api.js
 */
import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
//获取banner轮播
export function getBanner() {
  return http({
    method: "get",
    url: "/index.nd?loginType=CS"
  });
}

//未读公告数量、未处理待办数量、未读消息数量接口
export function getUnTreatNum(data) {
  return http({
    method: "get",
    url: "/priceMessage/getUnTreatNum.nd",
    data
  });
}

// 待办消息接口
export function delegateList(data) {
  return http({
    method: "post",
    url: "/priceMessage/delegateList.nd",
    data
  });
}

// 通知消息接口
export function tzList(params) {
  return http({
    method: "get",
    url: "/priceMessage/list.nd",
    params
  });
}

// 通知消息接口
export function tzRead(params) {
  return http({
    method: "get",
    url: "/priceMessage/read.nd",
    params
  });
}

// 公告消息接口
export function ggList(params) {
  return http({
    method: "get",
    url: "/menu/notice.nd",
    params
  });
}

//商家浏览记录接口
export function saveCustBrowseRecord(data) {
  return http({
    method: "post",
    url: "/priceMessage/saveCustBrowseRecord.nd",
    data
  });
}

//首页商品列表
export function getHomeProductList(params) {
  return http({
    method: "get",
    url: "/index.nd?loginType=CS",
    params
  });
}


//获取抢购商品
export function getMarket(params) {
  return http({
    method: "get",
    url: "/marketActivity/queryList.nd",
    params
  });
}

//
export function getImgInfo(params) {
  return http({
    method: "get",
    url: "/custMenuVisit/homePageMenuList.nd",
    params
  });
}


//核心职能评价
// export function getOperatePlanReach(data) {
//   return http({
//     method: "post",
//     url: "/customer/check.nd",
//     contentType: "application/json;charset=UTF-8",
//     type: 'stringfy',
//     data
//   });
// }

//
//运营数据首页核心职能评价
// export function getOperatePlanReach(params) {
//   return http({
//     method: "get",
//     url: "/evaluation/findComprehensiveEvaluation.nd",
//     // contentType: "application/json;charset=UTF-8",
//     // type: 'stringfy',
//     params
//   });
// }
export function getOperatePlanReach(data) {
  return http({
    method: "get",
    // url: "/coreFunction/findEvaluation.nd",
    url:'/evaluation/findComprehensiveEvaluation.nd',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    params:data
  });
}

// getSuperscriptModuleCount
// 获取dms未读接口
export function getnotRead(data) {
  return dmshttp({
    url: 'getSuperscriptModuleCount',
    data
  })
}

// 获取隐私政策协议

export function getprivacyPolicyInfo(data) {
  return http({
    url: '/fast/report/privacyPolicy.nd',
    params:data
  })
}

// 提货额指标 专卖店
export function getfindDelivery(data) {
  return http({
    method: "get",
    url:'/exclusive/findDelivery.nd',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}
// 毛利指标 专卖店
export function getfindGrossProfit(data) {
  return http({
    method: "get",
    url:'/exclusive/findGrossProfit.nd',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}

// 零售指标 专卖店
export function getfindRetail(data) {
  return http({
    method: "get",
    url:'/exclusive/findRetail.nd',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}
// 零售指标 活动
export function getfindActivity(data) {
  return http({
    method: "get",
    url:'/exclusive/findActivity.nd',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}

// 首页库存-运营商
export function getfindInventory(data) {
  return http({
    method: "get",
    url:'/evaluation/pc/findInventory.nd',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}
// 提货-运营商
export function getpickUpGoods(data) {
  return http({
    method: "get",
    url:'/evaluation/findOperationCenter/pickUpGoods.nd',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}
// 毛利率
// 提货-运营商
export function getgrossMargin(data) {
  return http({
    method: "get",
    url:'/evaluation//findOperationCenter/grossMargin.nd',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}
// 新运营商一张表
export function findComprehensiveEvaluationNew(data) {
  return http({
    method: "get",
    url:'evaluation/findComprehensiveEvaluationNew.nd',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}


export function reportUnionAllMonth(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/userReport/reportUnionAllMonth/export.nd',
      data:data,
      responseType: 'blob'  
  })
}

export function reportUnionAllYear(data) {
  return http({
      // contentType: "application/json;charset=UTF-8",
      // type: 'stringfy',
      method: "post",
      url: '/fast/userReport/reportUnionAllYear/export.nd',
      data:data,
      responseType: 'blob'  
  })
}

// 记录日志
// /evaluation/saveLog
export function saveLog(data) {
  return http({
      method: "get",
      url: '/evaluation/saveLog.nd',
      params:data
  })
}