/*
 * @Author: 马秋顺 1813637761@qq.com
 * @Date: 2022-12-19 08:58:17
 * @LastEditors: 马秋顺 1813637761@qq.com
 * @LastEditTime: 2022-12-19 08:58:41
 * @FilePath: \B2B\src\router\modules\reportOfAnnual.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
    {
      path: "/reportOfAnnual",
      name: "reportOfAnnual",
      component: () => import("@/views/reportOfAnnual/index.vue"),
      meta: {
        title: "年度报告", //配置title
        keepAlive: false, //是否缓存
        isBarShow: false,
      },
    },
  ];