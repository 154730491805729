/*
 * @Author: sunzuoyuan
 * @Date: 2020-09-28 14:26:41
 * @LastEditTime: 2024-08-22 15:58:10
 * @LastEditors: 尚宏浩 aaa@hisense.com
 * @Description: In User Settings Edit
 * @FilePath: B2B\src\router\modules\shoppingCart.js
 */



export default [
    {
      path: "/hisenseReturn",
      name: "hisenseReturn",
      redirect: "/hisenseReturn",
      component: () => import("@/layout/TopDownLayout/Frame.vue"),
      children: [
        {
          path: "/hisenseReturn/defectiveReturnList",
          component: () => import("@/views/hisenseReturns/defectiveReturnList/index.vue"),
          meta:{
            title:"残次退明细（审核）旧",
            keepAlive: false,
            isBarShow:true,
           
          }
        },
        {
            
          path: "/hisenseReturn/hisenseReturnDetail",
          component: () => import("@/views/hisenseReturns/hisenseReturnDetail/index.vue"),
          meta:{
            title:"退货申请详情",
            keepAlive: false,
            isBarShow:true,
           
          }
        },
        {
          path: "/hisenseReturn/hisenseRrturnInput",
          component: () => import("@/views/hisenseReturns/hisenseRrturnInput/sale/index.vue"),
          meta:{
            title:"退货录入",
            keepAlive: false,
            isBarShow:true,
           
          }
        },
        {
          path: "/hisenseReturn/ReturnApplicationDetails",
          component: () => import("@/views/hisenseReturns/ReturnApplicationDetails/index.vue"),
          meta:{
            title:"退货申请详情",
            keepAlive: false,
            isBarShow:true,
           
          }
        },
        
        {
          path: "/hisenseReturn/success",
          component: () => import("@/views/hisenseReturns/components/success.vue"),
          meta:{
            title:"退货申请成功",
            keepAlive: false,
            isBarShow:true,
           
          }
        },
        {
          path: "/hisenseReturn/hisenseRrturnInputNew",
          component: () => import("@/views/defectiveRetirementNew/hisenseRrturnInput/sale/index.vue"),
          meta:{
            title:"退货录入（新）",
            keepAlive: false,
            isBarShow:true,

          }
        },
        {
          path: "/hisenseReturn/defectiveReturnListNew",
          component: () => import("@/views/defectiveRetirementNew/defectiveReturnList/index.vue"),
          meta:{
            title:"残次退列表（新）",
            keepAlive: false,
            isBarShow:true,

          }
        },
        // 海信退货明细
        {
          path: "/hisenseReturn/hisenseReturnList",
          component: () => import("@/views/haixinReturn/defectiveReturnList/index.vue"),
          meta:{
            title:"海信退货明细",
            keepAlive: false,
            isBarShow:true,

          }
        },
        {
          path: "/hisenseReturn/hisenseReturnDetailNew",
          component: () => import("@/views/defectiveRetirementNew/hisenseReturnDetail/index.vue"),
          meta:{
            title:"退货申请详情（新）",
            keepAlive: false,
            isBarShow:true,
          }
        },
        {
          path: "/hisenseReturn/hisenseReturn",
          // component: () => import("@/views/defectiveRetirementNew/haixinReturnDetail/index.vue"),
          component: () => import("@/views/haixinReturn/hisenseReturnDetail/index.vue"),
          meta:{
            title:"海信退货申请详情",
            keepAlive: false,
            isBarShow:true,
          }
        },
      ]
    }
  ];
  