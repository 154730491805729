/*
 * @Author: mayu
 * @Date: 2020-09-28 14:26:41
 * @LastEditTime: 2021-01-31 23:17:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: B2B\src\router\modules\shoppingCart.js
 */



export default [{
  path: "/center",
  name: "center",
  // redirect: "/confirm",
  component: () => import("@/layout/TopDownLayout/Frame.vue"),
  children: [{
      path: "/center/todoList",
      name: 'todoLisWait',
      component: () => import("@/views/center/todoList/index.vue"),
      meta: {
        title: "待办",
        keepAlive: false,
        isBarShow: true,
        filterConfigStr: 'custTaskList'
      }
    },

    {
      path: "/center/tododetail/detail",
      name: 'tododetail',
      component: () => import("@/views/center/todoList/tododetail/index.vue"),
      meta: {
        title: "待办详情",
        isBarShow:true,
        keepAlive: false
      }
    },
    // 公告
    {
      path: "/center/announcement",
      name: 'announcement',
      component: () => import("@/views/center/announcement/index.vue"),
      meta: {
        title: "公告",
        keepAlive: false,
        isBarShow: true,
        filterConfigStr: 'announcementList'
      }
    },
    {
      path: "/center/announcement/detail/",
      name: 'announcementDetail',
      component: () => import("@/views/center/announcement/detail/index.vue"),
      meta: {
        title: "公告详情",
        isBarShow:true,
        keepAlive: false
      },
    },
    {
      path: "/center/consultNewPage",
      name: 'consultNewPage',
      component: () => import("@/views/center/consultNewPage/list/index.vue"),
      meta: {
        title: "问题反馈明细",
        isBarShow:false,
        keepAlive: false
      }
    },
    // 咨询问题
    {
      path: "/center/consult",
      name: 'consult',
      component: () => import("@/views/center/consult/index.vue"),
      meta: {
        title: "咨询问题",
        isBarShow:true,
        keepAlive: false
      }
    },
    {
      path: "/center/operatingManual",
      name: '/center/operatingManual',
      component: () => import("@/views/center/operatingManual/index.vue"),
      meta: {
        title: "操作手册",
        keepAlive: false,
        isBarShow: true,
      }
    },
    // 咨询举报
    {
      path: "/center/consultationComplaint",
      name: 'consultationComplaint',
      component: () => import("@/views/center/consult/consultationComplaint/index.vue"),
      meta: {
        title: "咨询举报",
        keepAlive: false,
        isBarShow: true,
        filterConfigStr: 'questionMineList'
      }
    },
    // 用户日志
    {
      path: "/center/historyList",
      name: 'historyList',
      component: () => import("@/views/historyList/index"),
      meta: {
        title: "用户日志",
        keepAlive: false
      }
    },
    // 通知
    {
      path: "/center/notice",
      name: 'notice',
      component: () => import("@/views/center/noticeList/index.vue"),
      meta: {
        title: "通知",
        keepAlive: false,
        isBarShow: true,
        filterConfigStr: 'custMsgList'
      }
    },
    // 通知详情
    {
      path: "/center/notice/detail",
      name: 'noticeDetail',
      component: () => import("@/views/center/noticeList/detail/index.vue"),
      meta: {
        title: "通知详情",
        isBarShow:true,
        keepAlive: false
      }
    },
    // 寻店查询
    {
      path: "/center/inspection",
      name: 'inspection',
      component: () => import("@/views/shopInspection/index.vue"),
      meta: {
        title: "巡店查询",
        keepAlive: false,
        // isBarShow: true,
      }
    },
    // 我的余额
    {
      path: "/center/balanceQuery",
      name: 'balanceQuery',
      component: () => import("@/views/balanceQuery/index.vue"),
      meta: {
        title: "我的余额",
        keepAlive: false,
        // isBarShow: true,
      }
    },
    // 我的 o2o
    {
      path: "/center/orderoTo",
      name: 'orderoTo',
      component: () => import("@/views/myOTwoO/index.vue"),
      meta: {
        title: "我的O2O",
        keepAlive: false,
        // isBarShow: true,
      }
    },
    // 下载中心
    {
      path: "/center/loadCenter",
      name: 'loadCenter',
      component: () => import("@/views/loadCenter/index.vue"),
      meta: {
        title: "下载中心",
        keepAlive: false,
        // isBarShow: true,
      }
    },
    // 我要退货
    {
      path: "/center/returnGoods",
      name: 'returnGoods',
      component: () => import("@/views/returnGoods/index.vue"),
      meta: {
        title: "退货",
        keepAlive: false,
        // isBarShow: true,
      }
    },
    // 终端巡检记录
    {
      path: "/center/terminalInspectionRecord",
      name: 'terminalInspectionRecord',
      component: () => import("@/views/terminalInspectionRecord/index.vue"),
      meta: {
        title: "终端巡检",
        keepAlive: false,
        // isBarShow: true,
      }
    },
    {
      path: "/center/terminalInspectionRecord/terminalInspectiondetail",
      name: 'terminalInspectiondetail',
      component: () => import("@/views/terminalInspectionRecord/terminalInspectiondetail/index.vue"),
      meta: {
        title: "详情",
        keepAlive: false,
        // isBarShow: true,
      }
    },
    // 资金流水
    {
      path: "/financialModule/capitaFlow",
      name: 'capitaFlow',
      component: () => import("@/views/financialModule/capitaFlow/index.vue"),
      meta: {
        title: "资金流水",
        keepAlive: false,
        // isBarShow: true,
      }
    },

  ]
}];