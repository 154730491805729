<template>
  <div class="ai-address">
    <button v-if="!isOutControl" class="ai-address-btn" @click="isAiAddressVisible = true">
      <i class="icon iconfont icon-zhinengtianxie"></i>
      <span>智能填写</span>
    </button>
    <a-modal
      v-model="isAiAddressVisible_"
      wrapClassName="ai-address-pop"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"
      :footer="null"
    >
      <a-spin :spinning="aiAddressLoadFlag">
        <div class="modal_close" @click="aiAddressClose">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
          <span class="modal_title">
            <i class="modal_title_line"></i>智能填写
          </span>
        </div>
        <div class="textarea">
          <a-textarea
            v-model:value="address"
            :bordered="false"
            allowClear
            placeholder="示例：于丹丹，185********，山东省青岛市市南区xx路xx号
            请粘贴或输入文本，点击“识别”自动识别联系人、联系方式和地址"
          />
          <p>识别后，请检查拆分地址信息是否准确，如有遗漏请及时补充</p>
            <!-- :auto-size="{ minRows: 2, maxRows: 5 }" -->
        </div>
        <div class="footer">
          <h-btn
            class="btn"
            :width="260"
            :height="40"
            content="识别"
            type="primary"
            fontSize="16"
            borderRadius="4"
            :disabled="address.length < 11"
            @click="recognitionFun"
            :loading="aiAddressLoadFlag"
          >
          </h-btn>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import http from "@/utils/request";
import { mapState } from "vuex";
//获取三级联动地区组织
import { addressRecognition } from "./api";
import settlement from "@/views/settlement";

export default {
  name: "ai-address",
  data() {
    return {
      isAiAddressVisible: false, // 智能识别弹窗
      aiAddressLoadFlag: false, // 智能识别loading
      address: '', //地址
    };
  },
  props: {
    // 是否外部控制
    isOutControl: {
      type: Boolean,
      default: false
    },
    // 外部控制是否打开弹窗
    isOutControlVisible: {
      type: Boolean,
      default: false
    },

   

  },
  watch: {
   
  },
  computed: {
    isAiAddressVisible_(){
      if(this.isOutControl){
        return this.isOutControlVisible;
      }else{
        return this.isAiAddressVisible;
      }
    }
  },
  created() {},
  mounted() {
    
  },
  methods: {
    // 智能识别弹窗关闭
    aiAddressClose() {
      this.isAiAddressVisible = false;
      this.address = '';
      this.$emit("update:isOutControlVisible", false);
    },
    // 识别
    recognitionFun() {
      this.aiAddressLoadFlag = true;
      const hide = this.$message.loading({content:'识别中..',key:'ai', duration: 0});
      // 调用接口
      const params = {
        "serviceCode": "addressRecognition",
        "sceneCode":"power_2023083001085279771",
        "data":{"keyword":this.address}
      }
      addressRecognition(params).then(res=>{
        if(res.data.code == 0){
          this.$message.success({content:'识别成功，请核对信息',key:'ai'})
          this.$emit("success", res.data.data);
          this.isAiAddressVisible = false;
          this.address = '';
        }else if(res.data.code == 'fail'){
          this.$message.warning({content:'地址识别失败',key:'ai'})
        }else{
          this.$message.warning({content:'格式错误，请参照示例填写',key:'ai'})
        }
        }).catch(err=>{
          this.$message.warning({content:'系统异常,请稍后重试',key:'ai'})
        }).finally(()=>{
          this.aiAddressLoadFlag = false;
        })
    },
  }
};
</script>

<style lang="less" scoped>
.ai-address{
  display: inline-block;
  .ai-address-btn{
    width: 110px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    display: inline-block;
    background-color: #fff;
    color: #262626;
    // margin-left: 8px;
    &:hover{
      color: #1890FF;
    }
    i.icon{
      font-size:14px;
      margin-right: 4px;
    }
  }
}
/deep/ .ai-address-pop {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px;
      .ant-input-textarea-clear-icon {
          margin: 16px 20px 0 0;
      }
      .textarea{
        margin-top: 30px;
        textarea{
          background: rgb(247, 247, 247);
          border: none !important;
          min-height: 120px !important;
          color: #262626;
          padding: 12px 16px;
          padding-right: 56px;
          resize: none;
          &::placeholder{
            color: #AAAAAA;
          }
        }
        p{
          margin-top: 8px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 20px;
          text-align: left;
        }
      }

      .footer{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 44px;
      }

      .modal_close {

        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: -30px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

  }

}
</style>
