/*
 * @Author: sqk
 * @Date: 2020-08-13 09:22:01
 * @LastEditTime: 2021-03-19 15:10:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\util.js
 */

export default {
  list: [
    {
      id: 14079464568,
      lock_version: null,
      creator: null,
      modifyUser: null,
      createDate: null,
      lastUpdateDate: null,
      isDeleted: false,
      onSaleDate: 1604332800000,
      stopSaleDate: null,
      productCode: "14079464568",
      productName: "HZ55A55",
      b2bName: "HZ55A55",
      brandCode: null,
      brandName: "海信",
      productModel: "HZ55A55",
      origin: null,
      importInfo: "VIDAA AI人工智能电视",
      status: "N",
      unit: null,
      retailPrice: 3000,
      retailPriceL: 200,
      tags: null,
      catalogCode: null,
      catalogName: null,
      frontCatalogCode: null,
      frontCatalogName: null,
      endCatalogCode: null,
      endCatalogName: null,
      upCatalogName: "平板电视",
      htmlUrl: "14079464568.htm",
      image: "F",
      content: "F",
      basic: "F",
      active: null,
      grab: null,
      grabPrice: null,
      grabQty: null,
      packageActive: null,
      color: null,
      cptype: null,
      salesChannel: null,
      productGroup: null,
      materialGroup: "1100101",
      materialGroupName: "电视",
      materialGroupId: 20160119008,
      financing: null,
      machine: null,
      loadVolume: 0.391,
      picture: "14079464568_0.jpg",
      orgName: "股份济南",
      orgId: 196,
      orgCode: "2605",
      often: "N",
      productContent: "<p><br></p>",
      isFenXiao: "N",
      shareFlag: "N",
      agentShareFlag: null,
      agentName: null,
      agentCisCode: null,
      purchaseType: 1,
      zzcpjd: "新品",
    },
    {
      id: 899863253,
      lock_version: null,
      creator: null,
      modifyUser: null,
      createDate: null,
      lastUpdateDate: null,
      isDeleted: false,
      onSaleDate: 1518192000000,
      stopSaleDate: null,
      productCode: "899863253",
      productName: "HZ55A65",
      b2bName: "HZ55A65",
      brandCode: null,
      brandName: "海信",
      productModel: "HZ55A65",
      origin: null,
      importInfo: null,
      status: "N",
      unit: null,
      retailPrice: 3999,
      retailPriceL: 3999,
      tags: null,
      catalogCode: null,
      catalogName: null,
      frontCatalogCode: null,
      frontCatalogName: null,
      endCatalogCode: null,
      endCatalogName: null,
      upCatalogName: "平板电视",
      htmlUrl: "899863253.htm",
      image: "F",
      content: "F",
      basic: "F",
      active: null,
      grab: null,
      grabPrice: null,
      grabQty: null,
      packageActive: null,
      color: "SA 皓月银",
      cptype: null,
      salesChannel: null,
      productGroup: null,
      materialGroup: "1100101",
      materialGroupName: "电视",
      materialGroupId: 20160119008,
      financing: null,
      machine: null,
      loadVolume: 0.164,
      picture: "899863253_0.jpg",
      orgName: "宇恒电器-股份杭州",
      orgId: 176,
      orgCode: "2603",
      often: "N",
      productContent: "<p><br></p>",
      isFenXiao: "Y",
      shareFlag: "N",
      agentShareFlag: "Y",
      agentName: "宇恒电器",
      agentCisCode: "7081115",
      purchaseType: 1,
      zzcpjd: "主销",
    },
    {
      id: 14079464568,
      lock_version: null,
      creator: null,
      modifyUser: null,
      createDate: null,
      lastUpdateDate: null,
      isDeleted: false,
      onSaleDate: 1604332800000,
      stopSaleDate: null,
      productCode: "14079464568",
      productName: "HZ55A55",
      b2bName: "HZ55A55",
      brandCode: null,
      brandName: "海信",
      productModel: "HZ55A55",
      origin: null,
      importInfo: "VIDAA AI人工智能电视",
      status: "N",
      unit: null,
      retailPrice: 3000,
      retailPriceL: 200,
      tags: null,
      catalogCode: null,
      catalogName: null,
      frontCatalogCode: null,
      frontCatalogName: null,
      endCatalogCode: null,
      endCatalogName: null,
      upCatalogName: "平板电视",
      htmlUrl: "14079464568.htm",
      image: "F",
      content: "F",
      basic: "F",
      active: null,
      grab: null,
      grabPrice: null,
      grabQty: null,
      packageActive: null,
      color: null,
      cptype: null,
      salesChannel: null,
      productGroup: null,
      materialGroup: "1100101",
      materialGroupName: "电视",
      materialGroupId: 20160119008,
      financing: null,
      machine: null,
      loadVolume: 0.391,
      picture: "14079464568_0.jpg",
      orgName: "股份济南",
      orgId: 196,
      orgCode: "2605",
      often: "N",
      productContent: "<p><br></p>",
      isFenXiao: "N",
      shareFlag: "N",
      agentShareFlag: null,
      agentName: null,
      agentCisCode: null,
      purchaseType: 1,
      zzcpjd: "新品",
    },
    {
      id: 899863253,
      lock_version: null,
      creator: null,
      modifyUser: null,
      createDate: null,
      lastUpdateDate: null,
      isDeleted: false,
      onSaleDate: 1518192000000,
      stopSaleDate: null,
      productCode: "899863253",
      productName: "HZ55A65",
      b2bName: "HZ55A65",
      brandCode: null,
      brandName: "海信",
      productModel: "HZ55A65",
      origin: null,
      importInfo: null,
      status: "N",
      unit: null,
      retailPrice: 3999,
      retailPriceL: 3999,
      tags: null,
      catalogCode: null,
      catalogName: null,
      frontCatalogCode: null,
      frontCatalogName: null,
      endCatalogCode: null,
      endCatalogName: null,
      upCatalogName: "平板电视",
      htmlUrl: "899863253.htm",
      image: "F",
      content: "F",
      basic: "F",
      active: null,
      grab: null,
      grabPrice: null,
      grabQty: null,
      packageActive: null,
      color: "SA 皓月银",
      cptype: null,
      salesChannel: null,
      productGroup: null,
      materialGroup: "1100101",
      materialGroupName: "电视",
      materialGroupId: 20160119008,
      financing: null,
      machine: null,
      loadVolume: 0.164,
      picture: "899863253_0.jpg",
      orgName: "宇恒电器-股份杭州",
      orgId: 176,
      orgCode: "2603",
      often: "N",
      productContent: "<p><br></p>",
      isFenXiao: "Y",
      shareFlag: "N",
      agentShareFlag: "Y",
      agentName: "宇恒电器",
      agentCisCode: "7081115",
      purchaseType: 1,
      zzcpjd: "主销",
    },
    {
      id: 14079464568,
      lock_version: null,
      creator: null,
      modifyUser: null,
      createDate: null,
      lastUpdateDate: null,
      isDeleted: false,
      onSaleDate: 1604332800000,
      stopSaleDate: null,
      productCode: "14079464568",
      productName: "HZ55A55",
      b2bName: "HZ55A55",
      brandCode: null,
      brandName: "海信",
      productModel: "HZ55A55",
      origin: null,
      importInfo: "VIDAA AI人工智能电视",
      status: "N",
      unit: null,
      retailPrice: 3000,
      retailPriceL: 200,
      tags: null,
      catalogCode: null,
      catalogName: null,
      frontCatalogCode: null,
      frontCatalogName: null,
      endCatalogCode: null,
      endCatalogName: null,
      upCatalogName: "平板电视",
      htmlUrl: "14079464568.htm",
      image: "F",
      content: "F",
      basic: "F",
      active: null,
      grab: null,
      grabPrice: null,
      grabQty: null,
      packageActive: null,
      color: null,
      cptype: null,
      salesChannel: null,
      productGroup: null,
      materialGroup: "1100101",
      materialGroupName: "电视",
      materialGroupId: 20160119008,
      financing: null,
      machine: null,
      loadVolume: 0.391,
      picture: "14079464568_0.jpg",
      orgName: "股份济南",
      orgId: 196,
      orgCode: "2605",
      often: "N",
      productContent: "<p><br></p>",
      isFenXiao: "N",
      shareFlag: "N",
      agentShareFlag: null,
      agentName: null,
      agentCisCode: null,
      purchaseType: 1,
      zzcpjd: "新品",
    },
    {
      id: 14079464568,
      lock_version: null,
      creator: null,
      modifyUser: null,
      createDate: null,
      lastUpdateDate: null,
      isDeleted: false,
      onSaleDate: 1604332800000,
      stopSaleDate: null,
      productCode: "14079464568",
      productName: "HZ55A55",
      b2bName: "HZ55A55",
      brandCode: null,
      brandName: "海信",
      productModel: "HZ55A55",
      origin: null,
      importInfo: "VIDAA AI人工智能电视",
      status: "N",
      unit: null,
      retailPrice: 3000,
      retailPriceL: 200,
      tags: null,
      catalogCode: null,
      catalogName: null,
      frontCatalogCode: null,
      frontCatalogName: null,
      endCatalogCode: null,
      endCatalogName: null,
      upCatalogName: "平板电视",
      htmlUrl: "14079464568.htm",
      image: "F",
      content: "F",
      basic: "F",
      active: null,
      grab: null,
      grabPrice: null,
      grabQty: null,
      packageActive: null,
      color: null,
      cptype: null,
      salesChannel: null,
      productGroup: null,
      materialGroup: "1100101",
      materialGroupName: "电视",
      materialGroupId: 20160119008,
      financing: null,
      machine: null,
      loadVolume: 0.391,
      picture: "14079464568_0.jpg",
      orgName: "股份济南",
      orgId: 196,
      orgCode: "2605",
      often: "N",
      productContent: "<p><br></p>",
      isFenXiao: "N",
      shareFlag: "N",
      agentShareFlag: null,
      agentName: null,
      agentCisCode: null,
      purchaseType: 1,
      zzcpjd: "新品",
    },
    {
      id: 14079464568,
      lock_version: null,
      creator: null,
      modifyUser: null,
      createDate: null,
      lastUpdateDate: null,
      isDeleted: false,
      onSaleDate: 1604332800000,
      stopSaleDate: null,
      productCode: "14079464568",
      productName: "HZ55A55",
      b2bName: "HZ55A55",
      brandCode: null,
      brandName: "海信",
      productModel: "HZ55A55",
      origin: null,
      importInfo: "VIDAA AI人工智能电视",
      status: "N",
      unit: null,
      retailPrice: 3000,
      retailPriceL: 200,
      tags: null,
      catalogCode: null,
      catalogName: null,
      frontCatalogCode: null,
      frontCatalogName: null,
      endCatalogCode: null,
      endCatalogName: null,
      upCatalogName: "平板电视",
      htmlUrl: "14079464568.htm",
      image: "F",
      content: "F",
      basic: "F",
      active: null,
      grab: null,
      grabPrice: null,
      grabQty: null,
      packageActive: null,
      color: null,
      cptype: null,
      salesChannel: null,
      productGroup: null,
      materialGroup: "1100101",
      materialGroupName: "电视",
      materialGroupId: 20160119008,
      financing: null,
      machine: null,
      loadVolume: 0.391,
      picture: "14079464568_0.jpg",
      orgName: "股份济南",
      orgId: 196,
      orgCode: "2605",
      often: "N",
      productContent: "<p><br></p>",
      isFenXiao: "N",
      shareFlag: "N",
      agentShareFlag: null,
      agentName: null,
      agentCisCode: null,
      purchaseType: 1,
      zzcpjd: "新品",
    },
  ],
  dataList: {
    total: 200,
    bannerUrl: require("@/assets/product/llzj.png"),
    cardList: [
      {
        url: require("@/assets/home/1.jpg"),
        type: "0",
        info0: "青岛股份有限公司青岛股份有限",
        info1: "80英寸激光/11111111111/杜比音效/分辨率高/画质好画质好画质好",
        info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
        count: "100",
        amount: "3999元",
        falseAmount: "4999元",
        flagList: [0, 1, 2, 3],
      },
      {
        url: require("@/assets/home/2.jpg"),
        type: "0",
        info0: "股份青岛",
        info1: "80英寸激光/4K超画质/比音效/分辨率高/画质好画质好画质好",
        info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
        count: "999",
        amount: "3999元",
        falseAmount: "4999元",
        flagList: [0, 1, 3],
      },
      {
        url: require("@/assets/home/3.jpg"),
        type: "0",
        info0: "青岛股份有限公司青岛股份有限",
        info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画好画质好画质好",
        info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
        count: "45378",
        amount: "3999元起",
        flagList: [3],
      },
      {
        url: require("@/assets/home/4.jpg"),
        type: "0",
        info0: "青岛股份有限公司青岛股份有限",
        info1: "8英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
        info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
        count: "555",
        amount: "3999元起",
        flagList: [1, 2],
      },
      {
        url: require("@/assets/home/5.jpg"),
        type: "0",
        info0: "股份青岛",
        info1: "80寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
        info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
        count: "100",
        amount: "3999元起",
      },
      {
        url: require("@/assets/home/6.jpg"),
        type: "0",
        info0: "股份青岛",
        info1: "80英激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
        info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
        count: "100",
        amount: "3999元起",
        falseAmount: "4999元",
      },
      {
        url: require("@/assets/home/7.jpg"),
        type: "0",
        info0: "青岛股份有限公司青岛股份有限",
        info1: "80英寸光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
        info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
        count: "335",
        amount: "3999元起",
        flagList: [0, 1, 2, 3],
      },
      {
        url: require("@/assets/home/8.jpg"),
        type: "0",
        info0: "股份青岛",
        info1: "80英寸激/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
        info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
        count: "45",
        amount: "3999元起",
      },
    ],
  },
  sortList: [
    {
      name: "综合优先",
      sortStatus: 1, //0默认 1降 2升序
    },
    {
      name: "新品优先",
      sortStatus: 0, //0默认 1降 2升序
    },
    {
      name: "专供优先",
      sortStatus: 0, //0默认 1降 2升序
    },
    // {
    //   name: "价格",
    //   sortStatus: 0, //0默认 1降 2升序
    // },
  ],
  windowList2: [
    {
      title: "供应商",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "股份临沂",
          id: "2",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "3",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "4",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "5",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "6",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "7",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "1",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "1",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
      ],
    },
    {
      title: "系列",
      list: [
        {
          name: "全部",
          id: "111",
          isActive: true,
        },
        {
          name: "U3",
          id: "222",
          isActive: false,
        },
        {
          name: "U9",
          id: "333",
          isActive: false,
        },
        {
          name: "类目X",
          id: "444",
          isActive: false,
        },
        {
          name: "类目X",
          id: "555",
          isActive: false,
        },
        {
          name: "类目X",
          id: "666",
          isActive: false,
        },
        {
          name: "类目X",
          id: "777",
          isActive: false,
        },
        {
          name: "类目X",
          id: "888",
          isActive: false,
        },
      ],
    },
    {
      title: "生命周期",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "新品",
          id: "1",
          isActive: false,
        },
        {
          name: "主销",
          id: "1",
          isActive: false,
        },
        {
          name: "老品",
          id: "1",
          isActive: false,
        },
        {
          name: "清仓",
          id: "1",
          isActive: false,
        },
        {
          name: "类目X",
          id: "1",
          isActive: false,
        },
        {
          name: "类目X",
          id: "1",
          isActive: false,
        },
      ],
    },
    {
      title: "采购类型",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "常规型号",
          id: "1",
          isActive: false,
        },
        {
          name: "应急型号",
          id: "1",
          isActive: false,
        },
        {
          name: "自定义",
          type: "date",
          dateType: "range",
          id: "1",
          isActive: false,
          open: false,
        },
      ],
    },
    {
      title: "尺寸",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "100’+",
          id: "1",
          isActive: false,
        },
        {
          name: "90’+",
          id: "1",
          isActive: false,
        },
        {
          name: "80’+",
          id: "1",
          isActive: false,
        },
        {
          name: "60’+",
          id: "1",
          isActive: false,
        },
        {
          name: "40’+",
          id: "1",
          isActive: false,
        },
        {
          name: "其他",
          id: "1",
          isActive: false,
        },
      ],
    },
    {
      title: "配色",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "方案一",
          id: "1",
          isActive: false,
        },
        {
          name: "方案二",
          id: "1",
          isActive: false,
        },
        {
          name: "方案三",
          id: "1",
          isActive: false,
        },
        {
          name: "自定义",
          type: "date",
          dateType: "range",
          id: "1",
          isActive: false,
          open: false,
        },
      ],
    },
  ],
  windowList: [
    {
      title: "系列",
      targetId: "xilie",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "U3",
          id: "1",
          isActive: false,
        },
        {
          name: "自定义",
          type: "date",
          dateType: "range",
          id: "1",
          isActive: false,
          open: false,
        },
      ],
    },
    {
      title: "啊啊啊啊",
      targetId: "aaaa",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "U3",
          id: "1",
          isActive: false,
        },
        {
          name: "自定义",
          type: "date",
          dateType: "range",
          id: "1",
          isActive: false,
          open: false,
        },
      ],
    },
    {
      title: "供应商",
      targetId: "gys",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "1",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "1",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "1",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "1",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
      ],
    },
  ],
  windowList3: [
    {
      title: "系列",
      field: "xilie",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "U3",
          id: "1",
          isActive: false,
        },
        {
          name: "自定义",
          type: "date",
          dateType: "range",
          id: "1",
          isActive: false,
          open: false,
        },
      ],
    },
    {
      title: "啊啊啊啊",
      targetId: "aaaa",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "U3",
          id: "1",
          isActive: false,
        },
        {
          name: "自定义",
          type: "date",
          dateType: "range",
          id: "1",
          isActive: false,
          open: false,
        },
      ],
    },
    {
      title: "供应商",
      targetId: "gys",
      list: [
        {
          name: "全部",
          id: "1",
          isActive: true,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "1",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "1",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "1",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
        {
          name: "股份临沂",
          id: "1",
          isActive: false,
        },
        {
          name: "股份长春",
          id: "1",
          isActive: false,
        },
        {
          name: "股份石家庄",
          id: "1",
          isActive: false,
        },
      ],
    },
  ],
  windowInitList: {
    msg: "",
    code: 0,
    systemConfigForm: {
      id: 14177072476,
      formCode: "productList1",
      formDocID: "11",
      formName: "电视产品查询条件",
      formType: "query",
      queryURL: "/",
      saveURL: "/",
      paramList: [
        {
          id: 14177072803,
          inputCode: "orgId",
          formCode: "productList1",
          inputDocID: "orgId",
          name: "销售组织",
          field: "orgId",
          inputType: "checkbox",
          fieldType: "string",
          logic: "in",
          value: null,
          parentInput: "catalogId,keyword",
          dictURL: "/product/productOrg.nd",
          dictType: "2",
          dictCode: null,
          onChangeEnven: null,
          maxLength: null,
          canNull: null,
          inputClass: null,
          orderNo: 0,
          dictList: null,
        },
        {
          id: 14177072409,
          inputCode: "G11",
          formCode: "productList1",
          inputDocID: "G11",
          name: "1Y2Y3Y4Y",
          field: "G11",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: "orgId",
          dictURL: "/product/productOrg.nd",
          dictType: "2",
          dictCode: "G11",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [
            { code: "6.8到32", name: "6.8到32", desc: null },
            { code: "34到55", name: "34到55", desc: null },
            { code: "57到85", name: "57到85", desc: null },
            { code: "85以上", name: "85以上", desc: null },
          ],
        },
        {
          id: 14177072409,
          inputCode: "G11",
          formCode: "productList1",
          inputDocID: "G11",
          name: "有有p",
          field: "G11",
          inputType: "select",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: "orgId,orgId2",
          dictURL: "/product/productOrg.nd",
          dictType: "1",
          dictCode: "G11",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [
            { code: "6.8到32", name: "6.8到32", desc: null },
            { code: "34到55", name: "34到55", desc: null },
            { code: "57到85", name: "57到85", desc: null },
            { code: "85以上", name: "85以上", desc: null },
          ],
        },
        {
          id: 14177072409,
          inputCode: "G11",
          formCode: "productList1",
          inputDocID: "G11",
          name: "无有p",
          field: "timeFrame",
          inputType: "dateGroup",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: "",
          dictURL: null,
          dictType: "",
          dictCode: "G11",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "",
          orderNo: 0,
          dictList: [
            { code: "6.8到32", name: "6.8到32", desc: null },
            { code: "6.8到32", name: "6.8到32", desc: null },
            { code: "6.8到32", name: "6.8到32", desc: null },
            { code: "6.8到32", name: "6.8到32", desc: null },
          ],
        },
        {
          id: 14177072409,
          inputCode: "G11",
          formCode: "productList1",
          inputDocID: "G11",
          name: "有无p",
          field: "G11",
          inputType: "select",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: "",
          dictURL: "/product/productOrg.nd",
          dictType: "",
          dictCode: "G11",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [],
        },
        {
          id: 14177072410,
          inputCode: "G06",
          formCode: "productList1",
          inputDocID: "G06",
          name: "分辨率",
          field: "G06",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "G06",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [
            { code: "HD（1366*768）", name: "HD（1366*768）", desc: null },
            { code: "FHD（1920*1080）", name: "FHD（1920*1080）", desc: null },
            { code: "UD（3840*2160）", name: "UD（3840*2160）", desc: null },
            { code: "其他", name: "其他", desc: null },
          ],
        },
        {
          id: 14177072411,
          inputCode: "G07",
          formCode: "productList1",
          inputDocID: "G07",
          name: "背光类型",
          field: "G07",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "G07",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [
            {
              code: "U-LED（小灯密排）",
              name: "U-LED（小灯密排）",
              desc: null,
            },
            { code: "E-LED", name: "E-LED", desc: null },
            { code: "D-LED", name: "D-LED", desc: null },
            { code: "CRT", name: "CRT", desc: null },
            { code: "LCD", name: "LCD", desc: null },
            { code: "其他LED", name: "其他LED", desc: null },
          ],
        },
        {
          id: 14177072412,
          inputCode: "-G17",
          formCode: "productList1",
          inputDocID: "-G17",
          name: "最佳观看距离(米)",
          field: "-G17",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "-G17",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [{ code: "3.1-3.6米", name: "3.1-3.6米", desc: null }],
        },
        {
          id: 14177072413,
          inputCode: "-G21",
          formCode: "productList1",
          inputDocID: "-G21",
          name: "3D显示",
          field: "-G21",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "-G21",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [{ code: "不支持", name: "不支持", desc: null }],
        },
        {
          id: 14177072420,
          inputCode: "-G68",
          formCode: "productList1",
          inputDocID: "-G68",
          name: "遥控",
          field: "-G68",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "-G68",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [],
        },
        {
          id: 14177072415,
          inputCode: "-G24",
          formCode: "productList1",
          inputDocID: "-G24",
          name: "刷屏率",
          field: "-G24",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "-G24",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [{ code: "60HZ", name: "60HZ", desc: null }],
        },
        {
          id: 14177072416,
          inputCode: "-G35",
          formCode: "productList1",
          inputDocID: "-G35",
          name: "数字电视",
          field: "-G35",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "-G35",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [
            { code: "有线数字电视", name: "有线数字电视", desc: null },
          ],
        },
        {
          id: 14177072417,
          inputCode: "-G39",
          formCode: "productList1",
          inputDocID: "-G39",
          name: "应用商店",
          field: "-G39",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "-G39",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [{ code: "有", name: "有", desc: null }],
        },
        {
          id: 14177072418,
          inputCode: "-G41",
          formCode: "productList1",
          inputDocID: "-G41",
          name: "无线键鼠",
          field: "-G41",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "-G41",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [
            { code: "支持（选配）", name: "支持（选配）", desc: null },
          ],
        },
        {
          id: 14177072419,
          inputCode: "-G70",
          formCode: "productList1",
          inputDocID: "-G70",
          name: "外观",
          field: "-G70",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "-G70",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [
            { code: "D", name: "D", desc: null },
            { code: "D1", name: "D1", desc: null },
          ],
        },
        {
          id: 14177072414,
          inputCode: "-G23",
          formCode: "productList1",
          inputDocID: "-G23",
          name: "屏幕比例",
          field: "-G23",
          inputType: "checkbox",
          fieldType: "String",
          logic: "=",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "1",
          dictCode: "-G23",
          onChangeEnven: null,
          maxLength: null,
          canNull: "Y",
          inputClass: "producttag",
          orderNo: 0,
          dictList: [{ code: "0.672916667", name: "0.672916667", desc: null }],
        },
      ],
    },
  },
};
