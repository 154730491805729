/*
 * @Author: linheng
 * @Date: 2022-03-14
 * @LastEditTime: 
 * @LastEditors: Please set LastEditors
 * @Description: 分销商门店
 * @FilePath: \B2B\src\router\modules\distributionStores.js
 */

export default [
    // 分销商门店
    {
      path: "/distributionStores",
      name: "distributionStores",
      redirect: "/distributionStores",
      component: () => import("@/layout/TopDownLayout/Frame"),
      children: [{
        path: "/distributionStores/addDistributionStore",
        name: "addDistributionStore",
        component: () => import("@/views/distributionStores/addDistributionStore/index.vue"),
        meta: {
          title: "新增/编辑门店", //配置title
          keepAlive: false, //是否缓存
          isBarShow: true,
        },
      },
      {
        path: "/distributionStores/checkDistributionStore",
        name: "checkDistributionStore",
        component: () => import("@/views/distributionStores/checkDistributionStore/index.vue"),
        meta: {
          title: "查看门店", //配置title
          keepAlive: false, //是否缓存
          isBarShow: true,
        },
      },
    ]
    }
  ];