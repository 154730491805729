import http from "@/utils/request";
// 获取验证码
export function getCode(data) {
  return http({
    method: "post",
    contentType: "application/x-www-form-urlencoded",
    // type: 'stringfy',
    url: "/account/sendMsg.nd",
    data: data
  });
}
// 获取原因
export function getReasons(data) {
  return http({
    method: "get",
    url: "/comm/dict.nd?pid=90700",
    data: data
  });
}
// 注销提交
export function logoffSub(data) {
  return http({
    method: "post",
    url: "/account/applyCancelAccount.nd",
    data: data
  });
}
// 注销确认
export function logoffConfirm(data) {
    return http({
      method: "get",
      url: "/fast/report/commPage.nd",
      data: data
    });
  }
// 验证验证码
export function checkCode(data) {
    return http({
      method: "post",
      url: "/account/checkMsg.nd",
      data: data
    });
  }
