<template>
  <div
    class="pagination-wrap"
    ref="pageRef"
    :class="{
      'pagination-wrap-style-2': styleType == 2,
      'pagination-wrap-style-small': size == 'small',
      'border-style': bgType == 2
    }"
    :style="{ 'text-align': textAlign }"
  >
    <a-pagination
      :show-size-changer="styleType != 2"
      :show-quick-jumper="styleType != 2"
      :defaultPageSize="pager.pageSize"
      :current="pager.pageNo"
      :total="pager.count"
      :pageSizeOptions="pageSizeOptions"
      @change="onChange"
      @showSizeChange="onChange"
    />
    <div v-if="styleType != 2" class="action-btn" @click="gotoPage">跳转</div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pager: {
      type: Object, //{pageNo,pageSize,count}
      required: true
    },
    textAlign: {
      type: String,
      default: function() {
        return "right";
      }
    },
    size: {
      type: String,
      default: function() {
        return ""; //''||'small'
      }
    },
    styleType: {
      type: Number,
      default: function() {
        return 1; //''||'bg'//参照设计稿，设计稿的第二种样式传2
      }
    },
    pageSizeOptions: {
      type: Array,
      default: () => ["10", "15", "30", "50"]
    },
    //背景类型 1 填满 2边框
    bgType: {
      type: Number,
      default: 1
    }
  },
  methods: {
    onChange(page, pageSize) {
      this.pager.pageNo = page;
      this.pager.pageSize = pageSize;
      this.$emit("query", page, pageSize);
    },
    gotoPage() {
      this.$nextTick(() => {
        let page = parseInt(
          this.$refs.pageRef.querySelector(".pagination-wrap input").value
        );
        if(!page){
          return
        }
        const maxPage = Math.ceil(this.pager.count / this.pager.pageSize);
        if (page < 1) {
          page = 1;
        } else if (page > maxPage) {
          page = maxPage;
        }
        this.pager.pageNo = page;
        this.$emit("query", page);
        this.$refs.pageRef.querySelector(".pagination-wrap input").value = "";
      });
    }
  }
};
</script>

<style lang="less">
.pagination-wrap {
  width: 100%;
  padding: 8px 10px;
  .ant-pagination {
    width: fit-content;
    display: inline-block;

    .anticon {
      vertical-align: 0;
    }

    .ant-pagination-options {
      margin-left: 10px;

      .ant-pagination-options-quick-jumper {
        font-size: 12px;
        color: #777;

        input {
          border: 1px solid #eee;
          border-radius: 2px;
        }
      }

      .ant-select-selection--single .ant-select-selection__rendered {
        margin-right: 30px;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      margin-right: 4px;
    }

    .ant-select-dropdown-menu-item {
      font-size: 12px;
      color: #777;
      text-align: left;
    }

    .ant-select-selection {
      border: 1px solid #eee;
      border-radius: 2px;
      font-size: 12px;
      color: #777;
    }

    .ant-pagination-item {
      border: 1px solid #eee;
      border-radius: 2px;
      font-size: 12px;
      color: #777;
      margin-right: 6px;
    }
    .ant-pagination-item-active {
      border-color: #00aaa6;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      border: 1px solid #eee;
      border-radius: 2px;
      font-size: 12px;
      color: #777;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: none;
    }
  }

  .action-btn {
    display: inline-block;
    width: 44px;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
    text-align: center;
    margin-left: 6px;
    border: 1px solid #eee;
    border-radius: 2px;
    font-size: 12px;
    color: #777;
    cursor: pointer;
    background: #fff;
  }

  &.border-style .ant-pagination .ant-pagination-item {
    border: 1px solid #eeeeee;
    border-radius: 2px;
    line-height: 30px;
  }
  &.border-style .ant-pagination .ant-pagination-prev,
  &.border-style .ant-pagination .ant-pagination-next,
  &.border-style .ant-pagination .ant-pagination-jump-prev,
  &.border-style .ant-pagination .ant-pagination-jump-next {
    border: 1px solid #eeeeee;
    border-radius: 2px;
    line-height: 30px;
  }
  &.border-style .ant-pagination .ant-pagination-item-active {
    background-color: #fff;
    border: 1px solid #00aaa6;
  }
  &.border-style .ant-pagination .ant-pagination-item:focus,
  &.border-style .ant-pagination .ant-pagination-item:hover {
    background-color: #fff;
    border: 1px solid #00aaa6;
  }
  &.border-style .ant-pagination .ant-pagination-item-active a {
    color: #00aaa6;
  }
  &.border-style .ant-pagination .ant-pagination-item:focus a,
  &.border-style .ant-pagination .ant-pagination-item:hover a {
    color: #00aaa6;
  }
}

.pagination-wrap-style-2 {
  background: #f7f7f7;

  /deep/ .ant-pagination {
    .ant-pagination-options {
      .ant-pagination-options-quick-jumper {
        input {
          border: none;
          border-radius: 2px;
        }
      }
    }

    .ant-select-selection {
      border: none;
    }

    .ant-pagination-item {
      border: none;
      margin-right: 8px;
      border-radius: 0;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      border: none;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: none;
      background: none;
    }
  }

  .action-btn {
    border: none;
  }
}

.pagination-wrap-style-small {
  /deep/ .ant-pagination {
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      min-width: 24px;
      height: 24px;
      line-height: 22px;
    }

    .ant-select-selection__rendered {
      line-height: 22px;
    }

    .ant-pagination-item {
      min-width: 24px;
      height: 24px;
      line-height: 22px;
    }

    .ant-pagination-options {
      height: 24px;
      line-height: 22px;
    }

    .ant-select-selection {
      height: 24px;
      line-height: 22px;
    }

    .ant-pagination-options-quick-jumper {
      height: 24px;
      line-height: 22px;

      input {
        height: 24px;
        line-height: 22px;
      }
    }
  }

  .action-btn {
    width: 44px;
    height: 24px;
    line-height: 22px;
  }
}
</style>
