<!--
 * @Author: sq
 * @Date: 2020-10-23 17:42:18
 * @LastEditTime: 2020-12-03 15:51:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\img\index.vue
-->
<template>
  <span v-if="!isErrorHideFlag">
    <img :src="productImgBaseUrl + newImage" alt @error="errorFun($event)"/>
  </span>
</template>
<script>
import { mapState } from "vuex";
import { defaultImgPath } from "@/common/constant.js";
export default {
  name: "h-img",
  computed: {
    ...mapState({
      productImgBaseUrl: state =>
        state.user.productImgBaseUrl
          ? state.user.productImgBaseUrl
          : localStorage.getItem("productImgBaseUrl")
    })
  },
  // props: ["imgUrl", "errorImg","isUseBasePath"],
  data() {
    return {
      newImage: "",
      errorImgLoadCount: 0, //失败加载次数
      isErrorHideFlag: false //图片挂链是否隐藏
    };
  },
  watch: {
    imgUrl: {
      immediate: true,
      handler(value) {
        this.newImage = value;
      }
    }
  },
  props: {
    imgUrl: {
      //图片路径
      type: String,
      default: ""
    },
    errorImg: {
      //报错后默认图片
      type: String,
      default: ""
    },
    isUseBasePath: {
      //报错后是否使用请求数据的路径当图片头路径
      type: Boolean,
      default: false
    },
    errorHide: {
      //报错后是否隐藏该图片
      type: Boolean,
      default: false
    },
  },
  methods: {
    errorFun(event) {
      if(this.errorHide){
        //报错后是否隐藏该图片
        this.isErrorHideFlag = true;
        this.$emit('errorImgUrl',this.imgUrl);
        return
      }

     this.errorImgLoadCount ++
     if(this.errorImgLoadCount > 1){
       return
     }
      // 图片加载出错
      let img = event.srcElement;
      let errorSrc = "";
      //图片挂链
      if (this.isUseBasePath) {
        //是否使用头路径（error图不是全路径）
        errorSrc = defaultImgPath + this.errorImg;
      } else {
        //error全路径
        errorSrc = this.errorImg;
      }
      img.src = errorSrc;
      img.onerror = null; //防止闪图
    },
  }
};
</script>
<style lang="less" scoped>
span,
img {
  width: 100%;
  height: 100%;
}
</style>
