/*
 * @Author: sqk
 * @Date: 2020-08-13 09:22:01
 * @LastEditTime: 2022-01-10 10:20:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\util.js
 */

export default {
  dataList: {
    msg: "",
    code: 0,
    systemConfigForm: {
      id: 14180615314,
      formCode: "hisenseSalesProgress",
      formDocID: "hisenseSalesProgress",
      formName: "海信采购进度",
      formType: "query",
      queryURL: "/",
      saveURL: "/",
      menuName: null,
      menuId: null,
      paramList: [
        {
          id: 14180615316,
          inputCode: "hisenseSalesProgress_orderCode",
          formCode: "hisenseSalesProgress",
          inputDocID: "orderCode",
          name: "订单码",
          field: "orderCode",
          inputType: "text",
          fieldType: "string",
          logic: "like",
          value: "订单编码默认值",
          parentInput: null,
          dictURL: null,
          dictType: "0",
          dictCode: null,
          onChangeEnven: null,
          maxLength: "输入框",
          canNull: null,
          inputClass: null,
          orderNo: null,
          autoQuery: "0",
          sqlField: "orderCode",
          checkParent: null,
          multiple: "1",
          dictContent: null,
          extSql: null,
          dictList: null,
          synSolr: null,
          productTagType: null,
          djFlag: "1",
        },
        {
          id: 14180621178,
          inputCode: "hisenseSalesProgress_orderType",
          formCode: "hisenseSalesProgress",
          inputDocID: "orderType",
          name: "订单类型",
          field: "matklId",
          inputType: "select",
          fieldType: "string",
          logic: "like",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "3",
          dictCode: null,
          onChangeEnven: null,
          maxLength: "非请求下拉框",
          canNull: null,
          inputClass: null,
          orderNo: 2,
          autoQuery: "1",
          sqlField: "salesOrder.orderType.propertyValue",
          checkParent: null,
          multiple: "1",
          dictContent:
            "[{'name':'应急订单','code':'common'},{'name':'常规订单','code':'routine'},{'name':'工程订单','code':'project'},{'name':'特惠单','code':'level'},{'name':'套购订单','code':'combined'},{'name':'样机订单','code':'sample'},{'name':'活动订单','code':'activity'}]",
          extSql: null,
          dictList: [
            { code: "common", name: "应急订单", desc: null },
            { code: "routine", name: "常规订单", desc: null },
            { code: "project", name: "工程订单", desc: null },
            { code: "level", name: "特惠单", desc: null },
            { code: "combined", name: "套购订单", desc: null },
            { code: "sample", name: "样机订单", desc: null },
            { code: "activity", name: "活动订单", desc: null },
          ],
          synSolr: null,
          productTagType: null,
          djFlag: "1",
          isTrue:true,
        },
        {
          id: 14180621182,
          inputCode: "hisenseSalesProgress_deliverType",
          formCode: "hisenseSalesProgress",
          inputDocID: "deliverType",
          name: "配送方式",
          field: "deliverType",
          inputType: "select",
          fieldType: "long",
          logic: "=",
          value: '502004',
          parentInput: null,
          dictURL: "/comm/dict.nd?pid=50200",
          dictType: "2",
          dictCode: null,
          onChangeEnven: null,
          maxLength: "二次请求下拉框",
          canNull: 'N',
          inputClass: null,
          orderNo: 3,
          autoQuery: "1",
          sqlField: "salesOrder.deliveryType.id",
          checkParent: null,
          multiple: "2",
          dictContent: null,
          extSql: null,
          dictList: null,
          synSolr: null,
          productTagType: null,
          djFlag: "1",
        },
        {
          id: 14180615333,
          inputCode: "hisenseSalesProgress_orgId",
          formCode: "hisenseSalesProgress",
          inputDocID: "orgId",
          name: "供应商",
          field: "orgId",
          inputType: "select",
          fieldType: "long",
          logic: "in",
          value: '',
          parentInput: null,
          dictURL: "/comm/queryOrg.nd",
          dictType: "2",
          dictCode: null,
          onChangeEnven: null,
          maxLength: "二次请求多选下拉",
          canNull: null,
          inputClass: null,
          orderNo: 5,
          autoQuery: "1",
          sqlField: "salesOrder.fwOrg.id",
          checkParent: null,
          multiple: "2",
          dictContent: null,
          extSql: null,
          dictList: null,
          synSolr: null,
          productTagType: null,
          djFlag: "1",
        },
        {
          id: 14180615337,
          inputCode: "hisenseSalesProgress_outQty",
          formCode: "hisenseSalesProgress",
          inputDocID: "outQty",
          name: "出库数量",
          field: "outQty",
          inputType: "innum",
          fieldType: "string",
          logic: "like",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "0",
          dictCode: null,
          onChangeEnven: null,
          maxLength: "数字范围",
          canNull: null,
          inputClass: null,
          orderNo: null,
          autoQuery: "0",
          sqlField: "outQty",
          checkParent: null,
          multiple: "1",
          dictContent: null,
          extSql: null,
          dictList: null,
          synSolr: null,
          productTagType: null,
          djFlag: "1",
        },
        {
          id: 14180624324,
          inputCode: "hisenseSalesProgress_orderDate",
          formCode: "hisenseSalesProgress",
          inputDocID: "orderDate",
          name: "订单日期",
          field: "orderDate",
          inputType: "indate",
          fieldType: "date",
          logic: "like",
          value: '7d',
          parentInput: null,
          dictURL: null,
          dictType: "0",
          dictCode: null,
          onChangeEnven: null,
          maxLength: "日期范围",
          canNull: null,
          inputClass: null,
          orderNo: 13,
          autoQuery: "1",
          sqlField: "salesOrder.createdDate",
          checkParent: null,
          multiple: "1",
          dictContent: null,
          extSql: null,
          dictList: null,
          synSolr: null,
          productTagType: null,
          djFlag: "1",
          isTrue:true,//必填
        },
        {
          id: 14180624330,
          inputCode: "hisenseSalesProgress_sendDate",
          formCode: "hisenseSalesProgress",
          inputDocID: "sendDate",
          name: "发货单日期",
          field: "sendDate",
          inputType: "date",
          fieldType: "date",
          logic: "like",
          value: '1d',
          parentInput: null,
          dictURL: null,
          dictType: "0",
          dictCode: null,
          onChangeEnven: null,
          maxLength: '日期框',
          canNull: 'CHECK',
          inputClass: null,
          orderNo: 16,
          autoQuery: "1",
          sqlField: "salesSapOrder.createdDate",
          checkParent: null,
          multiple: "1",
          dictContent: null,
          extSql: null,
          dictList: null,
          synSolr: null,
          productTagType: null,
          djFlag: "1",
        },
        {
          id: 14180624331,
          inputCode: "hisenseSalesProgress_sendDate1",
          formCode: "hisenseSalesProgress1",
          inputDocID: "sendDate11",
          name: "月份选择",
          field: "sendDate11",
          inputType: "dateMonth",
          fieldType: "dateMonth",
          logic: "like",
          value: 1,
          parentInput: null,
          dictURL: null,
          dictType: "0",
          dictCode: null,
          onChangeEnven: null,
          maxLength: '日期框',
          canNull: null,
          inputClass: null,
          orderNo: 16,
          autoQuery: "1",
          sqlField: "salesSapOrder.createdDate",
          checkParent: null,
          multiple: "1",
          dictContent: null,
          extSql: null,
          dictList: null,
          synSolr: null,
          productTagType: null,
          djFlag: "1",
        },
        {
          id: 14180615372,
          inputCode: "hisenseSalesProgress_status",
          formCode: "hisenseSalesProgress",
          inputDocID: "status",
          name: "状态",
          field: "status",
          inputType: "select",
          fieldType: "string",
          logic: "like",
          value: null,
          parentInput: null,
          dictURL: null,
          dictType: "3",
          dictCode: null,
          onChangeEnven: null,
          maxLength: null,
          canNull: null,
          inputClass: null,
          orderNo: null,
          autoQuery: "0",
          sqlField: null,
          checkParent: null,
          multiple: "1",
          dictContent:
            '[{"code":"1","name":"状态1"},{"code":"2","name":"状态2"}]',
          extSql: null,
          dictList: [
            { code: "1", name: "状态1", desc: null },
            { code: "2", name: "状态2", desc: null },
          ],
          synSolr: null,
          productTagType: null,
          djFlag: "1",
        },
        {
          autoQuery:"0",
          canNull:null,
          checkParent:null,
          dictCode:null,
          dictContent:null,
          dictList:null,
          dictType:"2",
          dictURL:"/comm/getDefevtiveProByMatkl.nd",
          djFlag:"1",
          extSql:null,
          field:"productName",
          fieldType:"string",
          formCode:"channelPriceProduct",
          id:14182951462,
          inputClass:null,
          inputCode:"channelPriceProduct_productName",
          inputDocID:"productName",
          inputType:"selectSearch",
          logic:"like",
          maxLength:null,
          multiple:"1",
          name:"产品型号",
          onChangeEnven:null,
          orderNo:3,
          parentInput:"matklId",
          productTagType:null,
          sqlField:null,
          synSolr:null,
          value:null,
        },
        {
          autoQuery:"0",
          canNull:null,
          checkParent:null,
          dictCode:null,
          dictContent:null,
          dictList:null,
          dictType:"2",
          dictURL:"/comm/getDefevtiveProByMatkl.nd",
          djFlag:"1",
          extSql:null,
          field:"productName22",
          fieldType:"string",
          formCode:"channelPriceProduct",
          id:14182951462,
          inputClass:null,
          inputCode:"channelPriceProduct_productName",
          inputDocID:"productName22",
          inputType:"selectSearch",
          logic:"like",
          maxLength:null,
          multiple:"1",
          name:"产品型号两地分居",
          onChangeEnven:null,
          orderNo:3,
          parentInput:"matklId,deliverType",
          productTagType:null,
          sqlField:null,
          synSolr:null,
          value:null,
        }
        // {
        //   id: 14180615333,
        //   inputCode: "hisenseSalesProgress_orgId",
        //   formCode: "hisenseSalesProgress",
        //   inputDocID: "orgId",
        //   name: "组织",
        //   field: "orgId",
        //   inputType: "select",
        //   fieldType: "string",
        //   logic: "like",
        //   value: null,
        //   parentInput: null,
        //   dictURL: "/comm/queryOrg.nd",
        //   dictType: "2",
        //   dictCode: null,
        //   onChangeEnven: null,
        //   maxLength: null,
        //   canNull: null,
        //   inputClass: null,
        //   orderNo: null,
        //   autoQuery: "0",
        //   sqlField: "orgId",
        //   checkParent: null,
        //   multiple: "2",
        //   dictContent: null,
        //   extSql: null,
        //   dictList: null,
        //   synSolr: null,
        //   productTagType: null,
        //   djFlag: "1",
        // },
        // {
        //   id: 14180615333,
        //   inputCode: "hisenseSalesProgress_orgId",
        //   formCode: "hisenseSalesProgress",
        //   inputDocID: "orgId",
        //   name: "试一下看看啊",
        //   field: "orgId",
        //   inputType: "select",
        //   fieldType: "string",
        //   logic: "like",
        //   value: null,
        //   parentInput: null,
        //   dictURL: "/comm/queryOrg.nd",
        //   dictType: "2",
        //   dictCode: null,
        //   onChangeEnven: null,
        //   maxLength: null,
        //   canNull: null,
        //   inputClass: null,
        //   orderNo: null,
        //   autoQuery: "0",
        //   sqlField: "orgId",
        //   checkParent: null,
        //   multiple: "1",
        //   dictContent: null,
        //   extSql: null,
        //   dictList: null,
        //   synSolr: null,
        //   productTagType: null,
        //   djFlag: "1",
        // },
      ],
    },
  },
};
