/*
 * @Author: sqk
 * @Date: 2020-08-10 17:00:45
 * @LastEditTime: 2022-01-11 16:09:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\global.js
 */

import Util from "./utils/utils";
import "./components/global";

import { writeLog } from "@/utils/api";

import Vue from "vue";
import router from "./router";
import store from "./store";
import { getToken } from "./utils/auth";
// import global class
import "./styles/common.less";

// window.$vue = Vue;
// import ant-design
import Antd from "ant-design-vue";
Vue.use(Antd);
// window.$antd = Antd;
import "ant-design-vue/dist/antd.less";

// import iconfont class
import "./styles/font/iconfont.css";
import { configTotal, formatTotal } from "./utils/configTotal";
import { getAlertInfoByCode  } from "./utils/msgModal";
Vue.prototype.$configTotal = configTotal;
Vue.prototype.$getAlertInfoByCode = getAlertInfoByCode;
// Vue.prototype.$formatTotal = formatTotal;
// import echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

import Meta from "vue-meta";
Vue.use(Meta);

import Logan from 'logan-web';
// 将Logan组件挂载到Vue原型中，方便在组件中使用
Vue.use(Logan) ;
// 初始化Logan组件配置
Logan.initConfig({
  url: 'https://log-receiver.hisense.com/logan-api/logan/web/upload.json', // 日志上传接口的URL
  maxLogSize: 1024 * 1024 * 2, // 单个日志文件的最大大小，默认为2MB
  maxFileNum: 5, // 最多保存的日志文件数量，默认为5个
  errorHandler: function (e) {},
  succHandler: function (logItem) {
    var now = new Date();
    var sevenDaysAgo = new Date(+now - 6 * 24 * 3600 * 1000);
    // 有些情况会有先提交再记录, 将上传方法在此处执行即可
    Logan.report({
      reportUrl: 'https://log-receiver.hisense.com/logan-api/logan/web/upload.json',
      deviceId: store.state.user.userInfo.account.account,  //登录人商家id
      fromDayString: timeFormat2Day(sevenDaysAgo),
      toDayString: timeFormat2Day(now),
      webSource: 'XTWPC', // 错误来源
    }).then(function (result) {
      // console.log('report=', JSON.stringify(result));
    }).catch(function (e) {});
  }
});


function timeFormat2Day(date) {
  var Y = date.getFullYear();
  var M = date.getMonth() + 1;
  var D = date.getDate();
  return (
    Y +
    '-' +
    (M < 10 ? '0' + M : M) +
    '-' +
    (D < 10 ? '0' + D : D)
  );
}

Vue.config.errorHandler = function (err, vm, info) {
  let { message, stack } = err;
  var now = new Date();
  var sevenDaysAgo = new Date(+now - 6 * 24 * 3600 * 1000);
  let errorInfo = JSON.stringify({
    message: message,
    stack: stack,
    info: info,
    url: window.location.href,
    webSource:'XTW',
    deviceId:  store.state.user.userInfo.customer.id,
    // deviceId: plus.storage.getItem('userNameByUpdatePWD'),  //登录人ldapID
    fromDayString: timeFormat2Day(sevenDaysAgo),
    toDayString: timeFormat2Day(now),
    system: store.state.user.osInfo ? store.state.user.osInfo : localStorage.getItem("osInfo"),
    brower: store.state.user.broName ? store.state.user.osInfo : localStorage.getItem("broName"),

    // li: lineno | '', // 报错行数
  });

  Logan.log(errorInfo,3);

};

// import axios
import axios from "axios";
Vue.prototype.$axios = axios;
import { Table, TableColumn, Button, Popover, DatePicker, Upload, Dialog, Autocomplete, Form, FormItem, Image } from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(Popover);
Vue.use(DatePicker);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Image);
// import Swiper
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// // import style
// // import 'swiper/css/swiper.css'
// // If you use Swiper 6.0.0 or higher
// import 'swiper/swiper-bundle.css'
// // import 'vue-awesome-swiper/dist/css/swiper.css'//这里注意具体看使用的版本是否需要引入样式，以及具体位置。
// Vue.use(VueAwesomeSwiper) /* { default global options } */

////jq不依赖vue 无需在此注册
// import $ from "jquery";
// Vue.use($); //注册

import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
// import 'swiper/swiper-bundle.css'
// Vue.use(Swiper)
Vue.prototype.$Swiper = Swiper;

import moment from "moment";
Vue.prototype.$moment = moment;

// import Driver from "driver.js";
// import "driver.js/dist/driver.min.css";
// Vue.prototype.$driver = new Driver({
//   className: "step-cls-box",
//   animate: true, //在切换上一步或下一步时是否使用动画效果
//   opacity: 0.5, //蒙层透明度
//   padding: 10, // Distance of element from around the edges 突出的元素padding值
//   allowClose: true, //禁止点击外部关闭
//   overlayClickNext: true, // Should it move to next step on overlay click 点击蒙层是否跳至下一步
//   doneBtnText: "完成", // 完成按钮标题
//   closeBtnText: "关闭", // 关闭按钮标题
//   stageBackground: "#fff", // 引导对话的背景色
//   nextBtnText: "下一步", // 下一步按钮标题
//   prevBtnText: "上一步", // 上一步按钮标题
//   showButtons: true, // Do not show control buttons in footer 是否显示以上提到的这些按钮
//   keyboardControl: true, // Allow controlling through keyboard (escape to close, arrow keys to move) 是否允许键盘按键控制
//   scrollIntoViewOptions: {}, // We use `scrollIntoView()` when possible, pass here the options for it if you want any 突出区域滚动配置
//   onHighlightStarted: (Element) => {}, // Called when element is about to be highlighted 元素高亮/凸显开始时
//   onHighlighted: (Element) => {}, // Called when element is fully highlighted 元素高亮/凸显时
//   onDeselected: (Element) => {}, // Called when element has been deselected 元素高亮/凸显取消时
//   onReset: (Element) => {
//     // store.commit("user/SET_LOGIN_LATER_FLAG", true);
//   }, // Called when overlay is about to be cleared 元素高亮/凸显关闭时
//   onNext: (Element) => {}, // Called when moving to next step on any step 下一步需执行
//   onPrevious: (Element) => {}, // Called when moving to next step on any step 上一步需执行
// });

// import hover class
import "./styles/hover.css";

// import animate.min class
import "./styles/animate.min.css";

//放大镜
import VuePhotoZoomPro from "vue-photo-zoom-pro";

Vue.use(VuePhotoZoomPro);

//滚动条
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
Vue.component("hScroll", vueCustomScrollbar);
// 自定义指令设置浏览器标题 暂用下面中的
// Vue.directive('title', {
//     inserted: function (el, binding) {
//         console.log(binding)
//         // document.title = el.dataset.title
//         document.title = binding.value;
//     }
// })

/**
 * utils Object don't choose to use blending, directly inject the prototype
 */
//进入页面前判断
router.beforeEach((to, from, next) => {
  if (getToken()) {
    store.state.user.userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : "";
    if(store.state.user.userInfo){
      // 跳转页面 调用待办数量接口 获取最新待办数量
      store.dispatch("user/getUnTreatNum");
    }
  } else {
    localStorage.setItem("userInfo", "");
  }

  // 在路由切换时对上一个路由的请求进行中断
  store.commit('user/clearAxiosArr')   // 取消请求

  //   if (store.state.user.userInfo == "" && getToken()) {
  //     store.state.user.userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : '';
  //   } else {
  //     localStorage.setItem("userInfo", "");
  //   }
  //跳转后将筛选清空
  // store.commit("filtrate/SET_FILLIST", []);

  //将路由信息记录
  store.commit("user/SET_TO_PATH", to);
  store.commit("user/SET_FROM_PATH", from);

  // //缓存记录 start
  // //非（是二级及以上路由且to的路由有相同的根路由） -> 非二级以上路由 或 to路由有相同的根路由
  // if (
  //   !(
  //     from.path.slice(1) &&
  //     from.path.slice(1).indexOf("/") != -1 &&
  //     to.path.indexOf(from.path.slice(1).split("/")[0]) != -1
  //   )
  // ) {
  //   if (to.path.indexOf("detail") == -1) {
  //     //     from.meta.keepAlive = false;
  //     // to.meta.keepAlive = true;
  //     //根据路由信息 获取筛选组件数据接口的入参
  //     store.commit("filtrate/SET_CONFIG_STR", {
  //       toPath: to,
  //     });
  //     store.commit("formFiltrate/SET_CONFIG_STR", {
  //       toPath: to,
  //     });
  //     //请求筛选公共接口 获取表单筛选数据
  //     store.commit("formFiltrate/SET_FILLIST", []);
  //     store.dispatch("formFiltrate/systemConfigFormItem");

  //     store.commit("filtrate/SET_FILLIST", []);
  //     //请求筛选公共接口 获取表单筛选数据
  //     store.dispatch("filtrate/systemConfigFormItem");
  //   }
  // } else {
  //   // store.commit("filtrate/SET_IS_CHANGE", false);
  // }
  // //缓存记录end

  //根据路由信息 获取筛选组件数据接口的入参
  store.commit("filtrate/SET_CONFIG_STR", {
    toPath: to,
  });
  store.commit("formFiltrate/SET_CONFIG_STR", {
    toPath: to,
  });
  //请求筛选公共接口 获取表单筛选数据
  store.commit("formFiltrate/SET_FILLIST", []);
  store.dispatch("formFiltrate/systemConfigFormItem");

  store.commit("filtrate/SET_SIMULATE_DATA", []);
  store.commit("filtrate/SET_FILLIST", []);
  //请求筛选公共接口 获取表单筛选数据
  store.dispatch("filtrate/systemConfigFormItem");

  //安全信息过期是否跳登录页
  if (from.path == "/index") {
    store.commit("user/SET_ISGOLOGIN", false);
  } else {
    store.commit("user/SET_ISGOLOGIN", true);
  }
  //设置标题
  if (to.meta.title) {
    document.title = "信天翁-" + to.meta.title;
  }



  writeLog({
    menuName: to.meta.title,
    routePath: to.path + (to.query.type ? '?type=' + to.query.type : ''),
    system: store.state.user.osInfo ? store.state.user.osInfo : localStorage.getItem("osInfo"),
    brower: store.state.user.broName ? store.state.user.osInfo : localStorage.getItem("broName"),
  });


  if (to.matched.length === 0) {  //如果未匹配到路由
    from.path ? next({ path: from.path }) : next('/');   //如果上级也未匹配到路由则跳转主页面，如果上级能匹配到则转上级路由
  } else {
    next();    //如果匹配到正确跳转
  }
});
Vue.prototype.$util = Util;
window.$util = Util;
