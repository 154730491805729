/*
 * @Author: szy
 * @Date: 2020-09-30 15:25:25
 * @LastEditTime: 2020-09-30 15:42:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath:
 */



export default [
    // DEMO
    {
        path: "/saleZone",
        name: "saleZone",
        redirect: "/saleZone",
        component: () => import("@/layout/TopDownLayout/Frame"),
        children: [
            {
                path: "/saleZone",
                component: () => import("@/views/saleZone/index"),
                meta:{
                    title:"特惠专区",
                    keepAlive: false,
                    isBarShow:true
                }
            },
        ]
    }
];
