<!--
 * @Author: sqk
 * @Date: 2020-08-12 15:51:12
 * @LastEditTime: 2021-01-29 16:33:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\CardItem\CardItem.vue
-->


<template>
  <div class="card-box">
    <h2 class="nav-line" v-show="type == '' ? true : false">
      <div id="rx-nav" class="clearfix">
        <span class="title">热销爆款</span>
        <span class="text-in">主推实力机型、新品上新，应有尽有！</span>
      </div>
      <a-menu v-model="navVal" @click="getMenuItem" mode="horizontal">
        <a-menu-item key="ds">电视</a-menu-item>
        <a-menu-item key="kt">空调</a-menu-item>
        <a-menu-item key="bx">冰箱</a-menu-item>
        <!--        <a-menu-item key="sj">手机</a-menu-item>-->
        <a-menu-item key="xyj">洗衣机</a-menu-item>
        <a-menu-item key="lg">冷柜</a-menu-item>
        <a-menu-item key="cw">厨卫</a-menu-item>
        <a-menu-item key="stcp">生态产品</a-menu-item>
      </a-menu>
    </h2>
    <div class="banner-box" v-show="type == '' ? true : false">
      <!-- <img src="@/assets/home/dianShi.png" alt /> -->
      <!-- <img :src="hotImage ? imgDevSrc + hotImage : require('@/assets/home/dianShi.png')" alt /> -->
      <!-- <img :src="hotImage ? hotImage : require('@/assets/home/dianShi.png')" alt /> -->
      <img class="animated" :class="{'fadeIn' : clsFlag}" :src="hotImage" alt />
      <!-- <img src="require('@/assets/home/dianShi.png')" alt /> -->
      <!-- <img src="@/assets/home/dianShi.png" alt /> -->
    </div>
    <div class="card-list-box">
      <slot />
    </div>
  </div>
</template>

<script>
import { commImgUrl } from "@/common/constant.js";
export default {
  name: "card-box",
  data() {
    return {
      navVal: ["ds"],
      imgDevSrc: "",
      clsFlag:false,
      timer:''
    };
  },
  props: {
    type: {
      type: String,
      default: ""
    },
    hotImage: {
      type: String,
      default: ""
    }
  },
  watch: {
    hotImage(newImg,oldImg){
      //添加切换动画
      if(newImg != oldImg){
        this.clsFlag = true

        this.timer = setTimeout(()=>{
          this.clsFlag = false;
          clearTimeout(this.timer)
        },1000)
      }
    }
  },
  mounted() {
    const comm = commImgUrl;
    this.imgDevSrc = comm;
  },
  methods: {
    //点击切换产品
    getMenuItem(data) {
      const { key } = data;
      this.$emit("tabclick", key);
    }
  }
};
</script>

<style lang="less" scoped>
.card-box {
  // width: 100%;
  width: 1188px;
  margin: 0 auto;
  .fadeInRight {
    animation-duration: 0.6s;
    display: inline-block;
  }
  .nav-line {
    height: 56px;
    line-height: 56px;
    // cursor: pointer;
    .ant-menu {
      float: right;
      font-size: 16px;
    }
    .ant-menu-horizontal {
      border-bottom: none;
      background-color: transparent;
      .ant-menu-item:nth-last-of-type(2) {
        margin-right: 0;
      }
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      margin: 0 14px;
      padding: 0 1px;
      height: 34px;
      line-height: 32px;
      color: #262626;
      font-weight: 600;
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
      border-bottom: 1px solid transparent;
    }
    .ant-menu-horizontal > .ant-menu-item:hover,
    .ant-menu-horizontal > .ant-menu-submenu:hover,
    .ant-menu-horizontal > .ant-menu-item-active,
    .ant-menu-horizontal > .ant-menu-submenu-active,
    .ant-menu-horizontal > .ant-menu-item-open,
    .ant-menu-horizontal > .ant-menu-submenu-open,
    .ant-menu-horizontal > .ant-menu-item-selected,
    .ant-menu-horizontal > .ant-menu-submenu-selected {
      color: #00aaa6;
      border-bottom: 1px solid #00aaa6;
    }
    #rx-nav {
      float: left;
    }
    .title {
      font-size: 24px;
      color: #262626;
      // line-height: 31px;
      text-align: left;
      margin-right: 14px;
      float: left;
    }
    .text-in {
      // width: 400px;
      float: left;
      font-size: 16px;
      color: #00aaa6;
      text-align: left;
    }
  }
  .banner-box {
    width: 100%;
    height: 130px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .card-list-box {
    text-align: left;
    // .card-item-box {
    //   margin-top: 12px;
    //   margin-left: 12px;
    // }
    // .card-item-box:nth-of-type(4n + 1) {
    //   margin-left: 0;
    // }
    .card-ani-box {
      float: left;
    }
  }
}
</style>
