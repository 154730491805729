<template>
  <div class="steps-wrap">
    <div class="step-wrap" v-for="(item,index) in steps" :key="item">
      <div class="step">
        <div class="point" :class="{'point-active':currentStep>index}"/>
        <div class="name" :class="{'name-active':currentStep>index}">{{ item }}</div>
      </div>
      <div class="dashed-line" v-if="index!==steps.length-1" :class="{'dashed-line-active':currentStep>index+1}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step",
  props: {
    steps: {
      type: Array,
      default: function () {
        return []
      }
    },
    currentStep: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="less">
.steps-wrap {
  .step-wrap {
    float: left;
    .step {
      float: left;
      position: relative;

      .point {
        width: 15px;
        height: 15px;
        border: solid 3px #CCCCCC;
        border-radius: 50%;
      }

      .point-active {
        border: solid 3px #00AAA6;
      }

      .name {
        font-size: 12px;
        color: #ccc;
        position: absolute;
        bottom: -23px;
        left: 6px;
        transform: translate(-50%, 0);
        width: 90px;
        text-align: center;
        font-weight: 400;
        line-height: 16px;
      }
      .name-active{
        color:#262626;
      }
    }

    .dashed-line {
      float: left;
      border-bottom: 2px #ccc dashed;
      width: 138px;
      margin: 0 4px;
      height: 8px;
    }

    .dashed-line-active {
      border-bottom: 2px #00AAA6 dashed;
    }
  }


}

</style>