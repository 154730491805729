/*
 * @Author: sunzuoyuan
 * @Date: 2020-12-4 14:26:41
 * @LastEditTime: 2020-11-18 20:17:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath
 */

export default [
  {
    path: "/typing",
    name: "typing",
    redirect: "/cart",
    component: () => import("@/layout/TopDownLayout/Frame.vue"),
    children: [
      {
        path: "/typing/channelorder",
        component: () => import("@/views/channelOrderTyping/index.vue"),
        meta: {
          title: "渠道订单录入",
          keepAlive: false,
          isBarShow: true
        }
      }
    ]
  }
];
