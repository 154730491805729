<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width='648'
    :closable="false"
    centered
    :maskClosable='false'
    :ok-button-props="{ props: { disabled: submitBtn } }"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>请选择收货地址
      </span>
    </div>
    <div class="cancelContent">
      <p class="messageInfo">收货地址省市区数据存在缺失，请补全后再点击“提交订单”</p>
    </div>
    <div class="cancelInfo">
      <span class="cancleZi"><span style="color:#D9161C">*</span>收货地址：</span>
      <address-from width="450" height="32" :addressDftVal="addressDftVal" @onAddressChange="onAddressChange"  />
    </div>
  </a-modal>
</template>
<script>
export default {
  // 示例
  // <cancelreason-modal
  // :visible.sync="visible"
  // info='提示文字'
  // ></message-modal>

  name:'cancel-modal',
  data(){
    return{
      addressDftVal:[]
    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
  },

  methods:{
    // 地址修改
    onAddressChange(addressValArr) {
      this.addressDftVal = addressValArr
      console.log('addressValArr',addressValArr)
      // // 老地址
      // let oldAdress = this.proObj.zCode
      // this.proObj.proviceCode = addressValArr[0];
      // this.proObj.cityCode  = addressValArr[1];
      // this.proObj.townCode = addressValArr[2];
      // this.proObj.zCode  = addressValArr[3];
      // this.startTime = ''
      // this.startShowTime = ''
      // if(oldAdress !== this.proObj.zCode ){
      //   this.userDetailAddress = ''
      // }
      // if(this.proObj.zCode && (this.trans=='04' || this.trans=='08')){
      //   this.getFWF()
      // }

    },

    handleOk(){
      if(this.addressDftVal.length<=0){
        this.$message.warning('请选择完整的四级地址')
        return
      }
      this.$emit('update:visible',false)
      this.$emit('cancelReasonHandOk',this.addressDftVal)
      this.addressDftVal = []

    },
    cancle(){
      this.$emit('update:visible',false)
      this.addressDftVal = []

    }

  }
}
</script>
<style lang="less" scoped>
/deep/ .cancelName{
  .ant-modal-content{
    text-align:center;
    border-radius: 10px;
    .ant-modal-body{
      //padding:30px 0px 0 40px;
      padding:30px 0px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .cancelContent{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        .ant-tree-node-selected {
          background-color: red;
        }
        .ant-form {
          line-height: 32px;
        }
        .ant-form-item-control-wrapper{
          margin-left:16px;
        }
        .ant-form-item-control{
          line-height: 32px;
        }
        .ant-form-item-label{
          line-height: 32px;
        }
        .ant-form-item{
          margin-bottom:0;
        }
        .messageInfo{
          font-size: 12px;
          font-weight: 400;
          color: #FC5260;
          line-height: 12px;
          margin-bottom:16px;
        }
      }
      .cancelInfo{
        display: flex;
        align-items: center;
        text-align: left;
        overflow: hidden;
        margin-bottom:53px;
        .cancleZi{
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          margin-right:9px;
        }
       }
      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        padding-bottom:30px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing:1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }
    p{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }
    .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:0px 0 30px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
      }
    }
  }
}
</style>


