<template>
  <div>
    slideBarItem:{{ slideBarItem }} <br />
    <app-main></app-main>
  </div>
</template>
<script>
import store from "@/store";
import AppMain from "./AppMain";
export default {
  components: { AppMain },
  computed: {
    slideBarItem() {
      return store.getters.permission_routes;
    },
    key() {
      return this.$route.path;
    }
  },
  data() {
    return {};
  }
};
</script>
