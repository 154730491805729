import { mapState } from "vuex";
import http from "@/utils/request";
// import store from "@/store";
export default {
  name: "filtrate",
  data() {
    return {
      chooiseList: [
        // {
        //   title:'我是名字',
        //   targetId:'aa',
        //   id:'bb'
        // }
      ],
      // isView: false, //是否展开
      isEmptyView: false, //清空是否显示
      // isViewBtn: false, //展开按钮是否显示
      emptyCls: "", //清空按钮动画样式
      maxWordSize: "", //标题最大字数对应宽度
      // isLoading:false,
      isFirstLoadFlag: true, //是否首次加载

      // isEmptyBtnCLickFlag: false, //清空按钮是否点击 暂时不用  采用清空筛选保留默认条件的方案
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isChange: {
      //是否不变路由变数据 （切换则重新调接口 但是组件已不是首次加载 所以加此项防止默认选项失效）
      type: Boolean,
      default: false,
    },
    isFirstChecked: {
      //是否默认选中第一项
      type: Boolean,
      default: false,
    },
  },
  //销毁
  // beforeDestroy() {
  //   this.chooiseList = [];
  //   // $('.cus-nav ul').find('.active-left .active-right').removeClass('active-left active-right')
  // },
  watch: {
    filIsChange() {
      //清空已选项
      if (this.isChange) {
        this.chooiseList = [];
        this.isFirstLoadFlag = true;
        this.isEmptyView = false;
        this.isEmptyClsFun(false);
      }
    },
    isChange() {
      //清空已选项
      if (this.isChange) {
        this.chooiseList = [];
        this.isFirstLoadFlag = true;
        this.isEmptyView = false;
        this.isEmptyClsFun(false);
      }
    },
    data: {
      handler() {
        this.$nextTick(() => {
          //无数据时默认跳转页面 首次加载标志重置
          if(this.filList && this.filList.length == 0){
            this.isFirstLoadFlag = true;
            this.chooiseList = [];
          }
          //首次初始化数据
          // if(!this.isEmptyBtnCLickFlag && this.data && this.data.length > 0){
          this.maxWordSize = 0;
          //字体大小font-size->14px 每项右边距margin-right->13px 每项左右内边距padding-l.r->6px-》加得12px；筛选项展示区 -》860px
          // for (const itemL1 of this.data) {
          this.data.forEach((itemL1, idx) => {
            let dataWidth = 0;
            if (itemL1.title.length > this.maxWordSize) {
              this.maxWordSize = itemL1.title.length;
            }
            for (const idx2 in itemL1.list) {
              // dataWidth += itemL1.list[idx2].name && itemL1.list[idx2].name.length * 14 + 13 + 12;

              dataWidth += this.$util.getStrLen(itemL1.list[idx2].name) * 7 + 13 + 12
              //判断是否有默认选项以及 是否首次加载
              if (
                itemL1.defaultValue &&
                itemL1.defaultValue != null &&
                itemL1.defaultValue != "" &&
                !this.filterLoadFlag &&
                (this.isFirstLoadFlag || this.isChange)
              ) {
                // itemL1.list[idx2].id == itemL1.defaultValue.toString()
                //一个默认选项
                if (
                  itemL1.defaultValue.indexOf(",") == -1 &&
                  itemL1.list[idx2].id.toString() == itemL1.defaultValue.toString()
                ) {
                  // debugger
                  itemL1.list[idx2].isActive = true;
                  //'全部'项取消选中
                  if (itemL1.list[0].name == "全部") {
                    itemL1.list[0].isActive = false;
                  }

                  //已显示列表中添加数据
                  this.addChooiseItem(itemL1,idx,idx2);
                  // this.chooiseList.push(itemObj);
                } else {
                  //多个默认选项
                  const dftArr = itemL1.defaultValue.split(",");
                  for (const itemDftId of dftArr) {
                    if (itemL1.list[idx2].id == itemDftId) {
                      itemL1.list[idx2].isActive = true;
                      //'全部'项取消选中
                      if (itemL1.list[0].name == "全部") {
                        itemL1.list[0].isActive = false;
                      }
                      //已显示列表中添加数据
                      this.addChooiseItem(itemL1,idx,idx2);
                    }
                  }
                }

                this.$forceUpdate();
                //显示清空按钮
                this.isEmptyView = true;
                this.isEmptyClsFun(true);
              } else if (
                this.isFirstChecked &&
                !this.filterLoadFlag &&
                this.isFirstLoadFlag
              ) {
                //'全部'项取消选中
                if (itemL1.list[0].name == "全部") {
                  itemL1.list[0].isActive = false;
                }
                itemL1.list[1].isActive = true;
                //已显示列表中添加数据
                this.addChooiseItem(itemL1,idx,idx2);
                this.$forceUpdate();
                //显示清空按钮
                this.isEmptyView = true;
                this.isEmptyClsFun(true);
                break;
              }
              //第一次遍历走完 -》 首次加载完毕 ->根据默认选中 返回回调 请求列表
              if (idx == this.data.length - 1 && this.isFirstLoadFlag && idx2 == itemL1.list.length - 1) {
                this.isFirstLoadFlag = false;
                this.callBack();
              }
            }
            //根据第一项选中的属性  遍历完后查一次
            if (
              this.isFirstChecked &&
              !this.filterLoadFlag &&
              this.isFirstLoadFlag
            ) {
              this.callBack();
            }

            // debugger
            if (dataWidth > 860) {
              itemL1.isShowMoreBtn = true;
            } else {
              itemL1.isShowMoreBtn = false;
            }
            // if(index == this.data.length){
            //   //最后一次遍历
            //   //将筛选条件入参 传出给父页面 paramsObj：{filter:'xx,xx',orgId:'xx,xx'}
            //   if(!this.filterLoadFlag){
            //     this.callBack();
            //     // this.$emit("firstSearch", paramsObj);
            //   }

            // }
          });
          // }
          //加14是冒号宽度
          this.maxWordSize = this.maxWordSize * 14 + 14;

          // this.isFirstLoadFlag = false;
          // }
          // else{
          //   //深度监听 数据变化 无需走初始化
          //   return;
          // }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //是否是分销 -》 渠道采购 -》 以此判断是否改变传出参数格式
      isFenXiao: (state) => state.filtrate.isFenXiao,
      filIsChange: (state) => state.filtrate.filIsChange,
    }),
  },
  mounted() {
  },
  methods: {
    //选择日期变化
    onChange(date, dateString, idx, itemIdx) {
      if (date != "") {
        //写入请求列表所用的入参参数
        // this.data[idx].list[itemIdx].id = JSON.stringify(['',...dateString]);
        //后续在选择后的回调已处理
        this.data[idx].list[itemIdx].id = "," + dateString;
        //自定义 选中
        for (let itemL2 of this.data[idx].list) {
          //取消改筛选项所有选中
          itemL2.isActive = false;
        }
        this.data[idx].list[itemIdx].isActive = true;

        this.filAddChooiseItem(idx, itemIdx);
      } else {
        //除日前框的其他选项选中
        for (const iIdx in this.data[idx].list) {
          if(this.data[idx].list[iIdx].isActive && iIdx != itemIdx){
            return;
           }
        }
        //清空日期 -》 改为全部
        this.data[idx].list[itemIdx].isActive = false;
        this.data[idx].list[0].isActive = true;

        this.filCloseChooiseItem(idx, itemIdx);
      }
      debugger

      //调用父组件请求数据方法
      this.callBack();
      return;
    },
    //单行展开收起
    isLineViewFun(itemL1) {
      itemL1.isShowMoreFlag = !itemL1.isShowMoreFlag;
    },
    //初始化 已选择数组 增加选项
    addChooiseItem(itemL1,idx,idx2){
      let itemObj = {
        title: itemL1.title + "：" + itemL1.list[idx2].name,
        targetId: itemL1.targetId,
        id: itemL1.list[idx2].id,
        parentInput: itemL1.parentInput,
        multiple: itemL1.multiple,
        idx: idx,
      };
      if (this.chooiseList && this.chooiseList.length > 0) {
        if(JSON.stringify(this.chooiseList).indexOf(JSON.stringify(itemObj)) == -1 ){
          this.chooiseList.push(itemObj);
        }
      } else {
        this.chooiseList.push(itemObj);
      }
    },
    setActiveFun(idx, itemIdx) {
      //判断点击时是否是选中状态-》选中|未选中
      if (this.data[idx].list[itemIdx].isActive) {
        //选中状态
        //判断该选中按钮是否是全部选项 是-》不做操作 否-》取消选中
        if (this.data[idx].list[itemIdx].name == "全部") {
          return;
        } else {
          //日期类型 return
          if (
            this.data[idx].list[itemIdx].type &&
            this.data[idx].list[itemIdx].type == "dateGroup"
          ) {
            return;
          }
          //取消选中当前项 当list全部未选中时 选中’全部‘
          this.data[idx].list[itemIdx].isActive = false;
          let count = 0;
          for (let item of this.data[idx].list) {
            if (item.isActive) {
              count++;
            }
          }
          if (!count) {
            this.data[idx].list[0].isActive = true;
          }
          this.filCloseChooiseItem(idx, itemIdx);
        }
      } else {
        //未选中状态
        if (this.data[idx].list[itemIdx].name == "全部") {
          //取消当前list全部选中 再选中全部
          for (let item of this.data[idx].list) {
            item.isActive = false;
          }
          this.data[idx].list[itemIdx].isActive = true;

          this.filCloseChooiseItem(idx, itemIdx, "all");
        } else {
          //其他项
          if (
            this.data[idx].list[itemIdx].type &&
            this.data[idx].list[itemIdx].type == "dateGroup"
          ) {
            //日期类型点击无效 当选择日期后 从其回调改变
            return;
          }
          this.data[idx].list[0].isActive = false;
          if (this.data[idx].multiple == "2") {
            this.data[idx].list[itemIdx].isActive = true;
          } else {
            for (let item of this.data[idx].list) {
              item.isActive = false;
            }
            this.data[idx].list[itemIdx].isActive = true;
          }
          //日期类型 清空
          if (this.data[idx].inputType == "dateGroup") {
            // if(this.data[idx].targetId == '01010-G70'){
            $(".filtrate-box-in > ul > li")
              .eq(idx)
              .find(".anticon-close-circle")
              .trigger("click");
          }
          //选中数项
          this.filAddChooiseItem(idx, itemIdx);
        }
      }
      //联动二次请求
      this.getSyncData(idx);
    },
    //选中选项
    filAddChooiseItem(idx, itemIdx) {
      let itemObj = {
        //判断类型 赋值名称
        title:
          this.data[idx].list[itemIdx].type != "dateGroup"
            ? this.data[idx].title + "：" + this.data[idx].list[itemIdx].name
            : this.data[idx].title + "：" + "自定义",
        targetId: this.data[idx].targetId,
        id: this.data[idx].list[itemIdx].id,
        parentInput: this.data[idx].parentInput,
        multiple: this.data[idx].multiple,
        idx: idx,
      };
      // debugger
      if (this.data[idx].multiple == "2") {
        //多选
        this.chooiseList = this.chooiseList.filter((e) => {
          //去除该筛选组的其他选项 和 该筛选组关联子级的选项
          if (e.parentInput && e.parentInput.indexOf(",") != -1) {
            return (
              //可能多个父关联 判断是否含有子项 而不是等于子项
              e.parentInput.indexOf(this.data[idx].targetId) == -1
            );
          } else {
            return e.parentInput != this.data[idx].targetId;
          }
        });
        this.chooiseList.push(itemObj);
      } else {
        //单选
        this.closeChooseItemFun(idx);
        //添加选项
        this.chooiseList.push(itemObj);
      }

    },
    //取消选中选项
    filCloseChooiseItem(idx, itemIdx, isAll) {
      if (isAll == "all") {
        this.closeChooseItemFun(idx);
      } else {
        //取消选中 该父级筛选条件组下所有筛选条件
        this.chooiseList = this.chooiseList.filter((e) => {
          //去除该筛选组的其他选项 和 该筛选组关联子级的选项
          if (e.parentInput && e.parentInput.indexOf(",") != -1) {
            return (
              e.id != this.data[idx].list[itemIdx].id &&
              //可能多个父关联 判断是否含有子项 而不是等于子项
              e.parentInput.indexOf(this.data[idx].targetId) == -1
            );
          } else {
            return (
              e.id != this.data[idx].list[itemIdx].id &&
              e.parentInput != this.data[idx].targetId
            );
          }
        });
      }
    },

    //联动时公共关闭已选择数组某些项
    closeChooseItemFun(idx) {
      //取消选中 该父级筛选条件组下所有筛选条件
      this.chooiseList = this.chooiseList.filter((e) => {
        //去除该筛选组的其他选项 和 该筛选组关联子级的选项
        if (e.parentInput && e.parentInput.indexOf(",") != -1) {
          return (
            e.targetId != this.data[idx].targetId &&
            //可能多个父关联 判断是否含有子项 而不是等于子项
            e.parentInput.indexOf(this.data[idx].targetId) == -1
          );
        } else {
          return (
            e.targetId != this.data[idx].targetId &&
            e.parentInput != this.data[idx].targetId
          );
        }
      });
    },
    //删除已选中数组选项
    closeChooiseItem(chooiseItem) {
      //清空日期
      $(".filtrate-box-in > ul > li")
        .eq(chooiseItem.idx)
        .find(".anticon-close-circle")
        .trigger("click");

      //取消选中 数组单项
      this.chooiseList = this.chooiseList.filter((e) => {
        //去除该项选中 和 该筛选组关联子级的选项选中

        if (e.parentInput && e.parentInput.indexOf(",") != -1) {
          return (
            e.id != chooiseItem.id &&
            //可能多个父关联 判断是否含有子项 而不是等于子项
            e.parentInput.indexOf(this.data[chooiseItem.idx].targetId) == -1
          );
        } else {
          //有父项联动 或是单选
          if (e.parentInput || e.multiple == "1") {
            if (e.multiple == "2") {
              //多选
              return (
                e.id != chooiseItem.id &&
                e.parentInput != this.data[chooiseItem.idx].targetId
              );
            } else {
              return (
                e.targetId != chooiseItem.targetId &&
                e.parentInput != this.data[chooiseItem.idx].targetId
              );
            }
          } else {
            // return (
            //   // e.targetId != chooiseItem.targetId &&
            //   e.id != chooiseItem.id
            // );
            return e.id != chooiseItem.id;
          }
        }
        // return (
        //   e.id != chooiseItem.id &&
        //   e.parentInput.indexOf(this.data[chooiseItem.idx].targetId) == -1
        // );
      });

      //筛选中联动
      for (const itemL1 of this.data) {
        for (const itemL2 of itemL1.list) {
          // if(itemL2.isActive && itemL2.name != '全部'){
          //   activeCount ++;
          // }
          if (
            itemL2.id == chooiseItem.id &&
            itemL1.targetId == chooiseItem.targetId
          ) {
            itemL2.isActive = false;
            if (itemL1.multiple == "2") {
              //多选
              let activeCount = 0;
              for (const iteml2 of itemL1.list) {
                //统计 该筛选条目 多选情况下 所有已选项的数量
                if (iteml2.isActive && iteml2.name != "全部") {
                  activeCount++;
                }
              }
              if (activeCount == 0) {
                //该筛选组剩最后一个筛选项
                itemL2.isActive = false;
                itemL1.list[0].isActive = true;
              }
            } else {
              //单选
              itemL1.list[0].isActive = true;
            }
          }
        }
      }

      //联动请求
      this.getSyncData(chooiseItem.idx);
    },

    //做点选操作后 联动二次请求
    getSyncData(idx) {
      // this.isLoading = true;
      //idx父级索引 index子级索引 父级项A 子级项B
      /* 
            1.联动请求第一步 遍历筛选条件组集合 判断每一项是否有parentInput（碰到自身项直接跳过）
            2.联动请求第二步 判断parentInput 有几个父级 并为后续请求做准备 -》 一个父级（判断B.parentInput = A.targetId） 多个父级（遍历B.parentInput后再判断B.parentInput = A.targetId）
            3.联动请求第三步 将得到的父级选项做入参 {filter1:xx,filter2:xx} 请求数据
            4.联动请求第四步 将请求回来的数据处理后改变B.list
        */
      //1.联动请求第一步 遍历筛选条件组集合 判断每一项是否有parentInput（碰到自身项直接跳过）
      //定义一个定时器 确保所有请求都返回后再做请求
      let timer;
      for (let index in this.data) {
        //定义二次请求方法的入参
        let getIndex = "",
          dictURL = "",
          params = {};
        //碰到自身项直接跳过
        if (this.data[index].targetId == this.data[idx].targetId) {
          // debugger
          // continue;
        }
        //2.联动请求第二步 判断parentInput 有几个父级 并为后续请求做准备 -》 一个父级（判断B.parentInput = A.targetId） 多个父级（遍历B.parentInput后再判断B.parentInput = A.targetId）

        //判断是否有parentInput 有 -》 联动请求
        // if (this.data[index].parentInput) {
        //根据逗号判断parentInput 是否有多个 true多个 false 一个
        //多个 则拼装入参 {filter1:xx,filter2:xx}
        if (
          this.data[index].parentInput &&
          this.data[index].parentInput.indexOf(",") != -1
        ) {
          //获取url做为方法入参
          dictURL = this.data[index].dictURL;
          getIndex = index;

          //拿到父级targetId数组 遍历父级targetId数组找到父级 并拿到其已选项 作为入参
          let paramArr = this.data[index].parentInput.split(",");
          for (let filterIdx in paramArr) {
            //遍历父级targetId数组找到父级
            let paramItem = "filter" + (parseInt(filterIdx) + 1).toString();
            for (let itemL1 of this.data) {
              if (itemL1.targetId == paramArr[filterIdx]) {
                //找到父级
                let activeArr = [];
                for (let itemL2 of itemL1.list) {
                  //获得已选项
                  if (itemL2.isActive) {
                    //作为入参
                    activeArr.push(itemL2.id);
                  }
                }
                //3.联动请求第三步 将得到的父级选项做入参 {filter1:xx,filter2:xx} 请求数据
                params[paramItem] = activeArr.join(",");
              }
            }
          }
        } else if (
          this.data[index].parentInput &&
          this.data[index].parentInput.length > 0
        ) {
          //一个 filter1 遍历
          if (this.data[index].parentInput == this.data[idx].targetId) {
            //遍历 相等的targetId 对应的筛选条件组 获得 已选项 作为请求的入参字符串
            //获取url做为方法入参
            dictURL = this.data[index].dictURL;
            getIndex = index;
            let activeArr = [];
            for (let itemL2 of this.data[idx].list) {
              //获得已选项
              if (itemL2.isActive) {
                activeArr.push(itemL2.id);
              }
            }
            //3.联动请求第三步 将得到的父级选项做入参 {filter1:xx,filter2:xx} 请求数据
            params = {
              //拼装入参
              filter1: activeArr.join(","),
            };
            //不会出现多个相等的情况 故出现一次就终止
            // break;
          }
        }
        //二次请求 并拼装数据 防止父级筛选条件组 也有parentInput 从而造成干扰
        if (
          JSON.stringify(params) != "{}" &&
          dictURL &&
          getIndex &&
          this.data[index].targetId != this.data[idx].targetId
        ) {
          //二次请求开始 开启loadding
          this.$store.commit("filtrate/SET_FILLOADING", true);
          //4.联动请求第四步 将请求回来的数据处理后改变B.list
          this.getNewItemGroupList(params, dictURL, getIndex).then((arr) => {
            //合并 （没有可以不用）
            // this.windowList = this.windowList.concat(arr);
            this.data[getIndex].list = arr;
            //   debugger;

            //有二次请求
            //组装数据 调用父页面方法
            clearTimeout(timer);
            timer = setTimeout(() => {
              this.callBack();
              // this.isLoading = false;
              this.$store.commit("filtrate/SET_FILLOADING", false);
            }, 500);
          });
        } else {
          //无二次请求
          //组装数据 调用父页面方法
          clearTimeout(timer);
          timer = setTimeout(() => {
            this.callBack();
            // this.isLoading = false;
            this.$store.commit("filtrate/SET_FILLOADING", false);
          }, 500);
        }
        // }
      }
    },

    //获得联动条件后二次请求 筛选条件组List数据 并格式化拼装return
    async getNewItemGroupList(params, url) {
      // let params = params;
      let newGroupList = [];
      let res = await http({
        method: "get",
        url: url,
        params,
      });
      // if (res.status == 200 && res.data.code == 0) {
      //可选项list拼装
      newGroupList = this.getNewList(res.data.list);
      // }
      return newGroupList;
    },
    //获得新格式的 dictList 数组
    getNewList(paramList) {
      let newArr = [],
        data = {
          id: "",
          name: "全部",
          isActive: true,
        };
      for (let key in paramList) {
        newArr.push({
          id: paramList[key].code,
          name: paramList[key].name,
          isActive: false,
        });
      }
      newArr.unshift(data);
      return newArr;
    },
    //获得该筛选条件组 全部筛选条件 return arr
    // getItemGroupCkArr(idx, itemIdx) {
    //   let ckArr = [];
    //   for (let key in this.data[idx].list) {
    //     if (this.data[idx].list[itemIdx].isActive) {
    //       ckArr.push(this.data[idx].list[itemIdx].id);
    //     }
    //   }
    //   return ckArr;
    // },

    //点选单个项之后拿到该项对应的list的tartgetId 与 该项值（数组）
    getItemInfo() {},
    isEmptyClsFun(isEmptyView) {
      if (!isEmptyView || this.chooiseList.length == 0) {
        this.emptyCls = "fadeOutRight";
      } else {
        this.emptyCls = "fadeInRight";
      }
    },
    //清空筛选
    emptyFun() {
      this.isEmptyView = false;
      this.isEmptyClsFun(false);

      //清空已选择数组
      this.chooiseList = [];

      //增加标志 保证加载默认项
      // this.isFirstLoadFlag = true;
      //清空日期
      // $(".anticon-close-circle").trigger("click");
      $(".filtrate-box-in > ul > li")
        .find(".anticon-close-circle")
        .trigger("click");

      // for (const itemL1 of this.data) {
      //   for (const itemL2 of itemL1.list) {
      //     if(itemL2.name == '全部'){
      //       itemL2.isActive = true;
      //     }else{
      //       itemL2.isActive = false;
      //     }
      //   }
      // }

      // this.callBack();

      this.$store.dispatch("filtrate/systemConfigFormItem").then((res) => {
        this.callBack();
      });

      //调用父组件请求数据方法
      // this.$emit("empty");
    },
    //展开
    isViewFun() {
      this.isView = !this.isView;
    },
    //选择后 回调
    callBack() {
      let arg = [];
      // alert(1)
      let count = 0;
      // if (this.isFenXiao == "N") {
      //海信采购
      //定义return 到父页面的查询入参对象 paramsObj.filter paramsObj.objId 等
      let paramsObj = {};
      //定义一个新数组 接收filter混入数据
      let newFilter = [];
      for (let itemL1 of this.data) {
        if (itemL1.inputClass == "producttag") {
          //根据inputClass = producttag 是否混入filter入参字段当入参
          for (let idx in itemL1.list) {
            if (itemL1.list[idx].isActive && itemL1.list[idx].name != "全部") {
              //拼装
              newFilter.push(itemL1.targetId + ":" + itemL1.list[idx].id);

              //混入filter参数的活动项不是 ”全部“ 项
              if (idx != 0) {
                count++;
              }
            }
          }
        } else {
          //各字段独自传值 比如 paramsObj.orgId:"123,567"
          let targetId = itemL1.targetId;
          let otherArr = [];
          // paramsObj[targetId] = [];
          //拼装数据
          for (let idx in itemL1.list) {
            if (itemL1.list[idx].isActive) {
              // debugger
              //筛选条件组是日期类型
              if (itemL1.inputType == "dateGroup") {
                // debugger
                //非日期类型的筛选项时 拼接逗号
                if (
                  !itemL1.list[idx].type ||
                  itemL1.list[idx].type != "dateGroup"
                ) {
                  otherArr = [itemL1.list[idx].id, "", ""];
                } else {
                  otherArr.push(itemL1.list[idx].id);
                }
                // otherArr = [itemL1.list[idx].id,',',',']
              } else {
                otherArr.push(itemL1.list[idx].id);
              }

              //混入filter参数的活动项不是 ”全部“ 项
              if (idx != 0) {
                count++;
              }
            }
          }
          paramsObj[targetId] = otherArr.join(",");
        }
      }
      paramsObj.filter = newFilter.join(",");

      //将筛选条件入参 传出给父页面 paramsObj：{filter:'xx,xx',orgId:'xx,xx'}
      // this.$emit("click", paramsObj);
      arg[0] = paramsObj;
      // } else if (this.isFenXiao == "Y") {
      //渠道采购
      // [
      //   {name:'销售组织',field:'orgId',value:"321,123"}
      // ]
      //定义return 到父页面的查询入参对象
      paramsObj = {};
      //定义一个新数组 格式化成如上数据
      let newFilterArr = [];
      for (let itemL1 of this.data) {
        if (itemL1.autoQuery == "1") {
          //需拼接paramList 到入参中
          // for (let itemL1 of this.data) {
          let targetObj = {};
          //该筛选条件组的已选项
          let otherArr = [];
          itemL1.list.forEach((itemL2, idx) => {
            if (itemL2.isActive) {
              //筛选条件组是日期类型
              if (itemL1.inputType == "dateGroup") {
                //非日期类型的筛选项时 拼接逗号
                if (!itemL2.type || itemL2.type != "dateGroup") {
                  otherArr = [itemL2.id, "", ""];
                } else {
                  otherArr.push(itemL2.id);
                }
                // otherArr = [itemL1.list[idx].id,',',',']
              } else {
                otherArr.push(itemL2.id);
              }

              //混入filter参数的活动项不是 ”全部“ 项
              if (idx != 0) {
                count++;
              }
            }
          });

          targetObj = {
            inputType: itemL1.inputType,
            field: itemL1.targetId,
            sqlField: itemL1.sqlField,
            fieldType: itemL1.fieldType,
            logic: itemL1.logic,
            value: otherArr.join(","),
            autoQuery: itemL1.autoQuery, //是偶拼接参数，1拼接，0 放到原来的参数
            multiple: itemL1.multiple,
            extSql: itemL1.extSql,
          };
          newFilterArr.push(targetObj);
          // }
        } else {
          //同海信采购
          //各字段独自传值 比如 paramsObj.orgId:"123,567"
          let targetId = itemL1.targetId;
          let otherArr = [];
          // paramsObj[targetId] = [];
          //拼装数据
          for (let idx in itemL1.list) {
            if (itemL1.list[idx].isActive) {
              // debugger
              //筛选条件组是日期类型
              if (itemL1.inputType == "dateGroup") {
                // debugger
                //非日期类型的筛选项时 拼接逗号
                if (
                  !itemL1.list[idx].type ||
                  itemL1.list[idx].type != "dateGroup"
                ) {
                  otherArr = [itemL1.list[idx].id, "", ""];
                } else {
                  otherArr.push(itemL1.list[idx].id);
                }
                // otherArr = [itemL1.list[idx].id,',',',']
              } else {
                otherArr.push(itemL1.list[idx].id);
              }

              //混入filter参数的活动项不是 ”全部“ 项
              if (idx != 0) {
                count++;
              }
            }
          }
          paramsObj[targetId] = otherArr.join(",");
        }
      }

      paramsObj = { ...paramsObj, paramList: newFilterArr };

      //将筛选条件入参 传出给父页面 paramsObj：{filter:'xx,xx',orgId:'xx,xx'}
      // this.$emit("click", paramsObj);
      arg[1] = { paramList: newFilterArr };
      // }
      this.$emit("click", arg);
      //清空筛选按钮显示隐藏
      if (count > 0) {
        this.isEmptyView = true;
        this.isEmptyClsFun(true);
      } else {
        this.isEmptyView = false;
        this.isEmptyClsFun(false);
      }
    },

    //选择第一项
    firstCheckFun(index) {
      for (let idx in this.data[index].list) {
        this.data[index].list[idx].isActive = false;
      }
      this.data[index].list[0].isActive = true;
    },
  },
};
