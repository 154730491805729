/*
 * @Author: sqk
 * @Date: 2020-08-07 16:16:59
 * @LastEditTime: 2022-08-30 12:09:29
 * @LastEditors: xiaojie 1264801634@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\api.js
 */
import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";

// post
export function demo_post(data) {
  return http({
    method: "post",
    url: "/demo",
    data
  });
}
// 聊天接口
export function getss() {
  return http({
    method: 'get',
    url: '/im/getUserAccount.nd'
  })
}
// get
export function demo_get(data) {
  return http({
    method: "get",
    url: "/demo",
    data
  });
}

//跳转路由访问记录接口
export function writeLog(params) {
  return http({
    method: "get",
    url: "/log/writeLog.nd",
    params
  });
}


//login
export function login(data) {
  return http({
    method: "post",
    url: "/webLogin.nd",
    data
  });
}

//login
export function logout(data) {
  return http({
    method: "post",
    url: "/logout.nd",
    data
  });
}


//login
export function getInfo(data) {
  return http({
    method: "get",
    url: "/demo",
    data
  });
}

//getRoutes
export function getRoutes(data) {
  return http({
    method: "get",
    url: "/demo",
    data
  });
}

//登陆
// export function login(data) {
//   return http({
//     method: "post",
//     url: "/login.nd",
//     data
//   });
// }

//信天翁导航
export function menuList(data) {
  return http({
    method: "get",
    url: "/menu/list.nd",
    params: data
  });
}

// 保存信天翁自定义查询条件排序
export function saveSelfQueryConfig(data) {
  return http({
    method: "post",
    url: "/custbase/saveSelfQueryConfig.nd",
    data,
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
  });
}

// 根据账户查询自定义查询条件顺序
export function querySelfQueryConfig(data) {
  return http({
    method: "post",
    url: "/custbase/querySelfQueryConfig.nd",
    data
  });
}

//产品分类接口
export function custMenuTag(data) {
  return http({
    method: "get",
    url: "/comm/custMenuTag.nd",
    data
  });
}

//通用页面筛选条件配置功能 筛选组件条件
export function systemConfigFormItem(data) {
  return http({
    method: "get",
    url: "/comm/systemConfigFormItem.nd",
    params: data
  });
}

//获取组织 pCode	是	上级组织，0是根目录
export function getRegin(data) {
  return http({
    method: "post",
    url: "/customer/getRegin.nd",
    data
  });
}

export function getReginById(data) { 
  return http({
    method: "get",
    url: "/customer/getReginById.nd",
    params:data
  });
}

//初始化橱窗列表页面接口
export function initProductListPage(params) {
  return http({
    method: "get",
    url: "/product/product.nd",
    params
  });
}

//获取用户供应商（组织）列表接口 -》新 
export function productOrg(params) {
  return http({
    method: "get",
    url: "/product/productOrg.nd",
    params
  });
}

//获取用户供应商列表接口
export function getOrganizationList(params) {
  return http({
    method: "get",
    url: "/product/getOrganizationList.nd",
    params
  });
}

//获取产品价格接口
export function getPrices(data) {
  return http({
    method: "post",
    url: "/product/getPrices.nd",
    data
  });
}

//获取产品库存接口
export function getStocks(data) {
  return http({
    method: "post",
    url: "/product/getStocks.nd",
    data
  });
}

//渠道采购价格接口
export function getChannelPrice(data) {
  return http({
    method: "post",
    url: "/product/fxPrice.nd",
    data
  });
}
//渠道采购库存接口
export function getChannelStock(data) {
  return dmshttp({
    url: "hasProductInventory",
    data
  });
}

//规格属性接口
export function product(params) {
  return http({
    method: "get",
    url: "/product/product.nd",
    params
  });
}

//规格属性接口
export function showProductDesc(productCode) {
  return http({
    method: "get",
    url: "/product/showProductDesc" + productCode + ".nd",
  });
}

//商家浏览记录接口
export function saveCustBrowseRecord(data) {
  return http({
    method: "post",
    url: "/priceMessage/saveCustBrowseRecord.nd",
    data
  });
}

//商家浏览记录列表接口
export function queryCustBrowseRecord(params) {
  return http({
    method: "get",
    url: "/priceMessage/queryCustBrowseRecord.nd",
    params
  });
}


//橱窗列表数据查询接口
export function productSearch(data) {
  return http({
    method: "post",
    url: "/product/search.nd",
    data
  });
}

//根据产品关键字搜索
export function wordSearch(data) {
  return http({
    method: "post",
    url: "/rest/productKeywords.nd",
    data
  });
}
export function wordSearchNew(data) {
  return http({
    method: "post",
    url: "/rest/productKeywordsNew.nd",
    data
  });
}
// 获取商城门店
export function getshopStore(cisCustomerId) {
  return http({
    method: "post",
    url: `/shopapi/hisense/xtw/o2o/center/queryCustShopAccountList?cisCustomerId=${cisCustomerId}`,
    data: {}
  });
}
// 商城登录
export function getshopLogin(cisCustomerId, cisShopId, account) {
  return http({
    method: "get",
    url: `/shopapi/hisense/user/login/v3?cisCustomerId=${cisCustomerId}&cisShopId=${cisShopId}&cisAccount=${account}`,
  });
}
// 信天翁登录商城发起验证码
export function getShopLoginCode(mobilePhoneNumber) {
  return http({
    method: "post",
    url: `/shopPermission/sendCaptcha.nd?mobilePhoneNumber=${mobilePhoneNumber}`,
    data: {}
  });
}
// 校验手机号
export function checkShopLoginCode(mobilePhoneNumber, captcha) {
  return http({
    method: "get",
    url: `/shopPermission/judgeCaptcha.nd?mobilePhoneNumber=${mobilePhoneNumber}&captcha=${captcha}`,
  });
}
//工程专区列表接口
export function getEngineerOrder(data) {
  return http({
    method: "post",
    url: "/engineering/getEngineerOrder.nd",
    data
  });
}

//工程专区详情
export function getEngineerOrderDetail(data) {
  return http({
    method: "post",
    url: "engineering/engineerOrderDetail.nd",
    data
  });
}

//特惠专区列表接口
export function queryByProduction(data) {
  return http({
    method: "post",
    url: "/preferential/queryByProduction.nd",
    data
  });
}
// 样机专区
export function findPurchaseProductPage(data) {
  return http({
    method: "post",
    url: "/prototype/findPurchaseProductPage.nd",
    data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
  });
}
//套购专区列表接口
export function queryActivityList(params) {
  return http({
    method: "get",
    url: "/packageActivity/queryActivityList.nd",
    params
  });
}

//获取提示信息接口
export function getAlert() {
  return http({
    method: "get",
    url: "/msg/getFrontMsg.nd",
  });
}

//获取组织
export function queryOrg(data) {
  return http({
    method: "post",
    url: "/comm/queryOrg.nd",
    data
  });
}

//获取物料组
export function queryMatkl(data) {
  return http({
    method: "post",
    url: "/comm/queryMatkl.nd",
    data
  });
}

//获取组织和物料组
export function queryOrgAndMatkl(data) {
  return http({
    method: "post",
    url: "/comm/queryOrgAndMatkl.nd",
    data
  });
}
//获取购物车数量
export function getCartNum() {
  return http({
    method: "get",
    url: "/cart/getCartNum.nd"
  });
}
//加入购物车
export function addToCart(data) {
  return http({
    method: "post",
    url: "/cart/addToCartNew.nd",
    data
  });
}

//购物车右上列表
export function getCartInfoList() {
  return http({
    method: "get",
    url: "/cart/getCartInfoList.nd",
  });
}

//购物车删除产品
export function deleteProduct(data) {
  return http({
    method: "post",
    url: "/cart/deleteProduct.nd",
    data
  });
}


//加入收藏
export function addOftenProduct(data) {
  return http({
    method: "post",
    url: "/oftenProduct/addOftenProduct.nd",
    data
  });
}

//取消收藏
export function deleteOftenProduct(data) {
  return http({
    method: "post",
    url: "/oftenProduct/deleteOftenProduct.nd",
    data
  });
}



//未读公告数量、未处理待办数量、未读消息数量接口
export function getUnTreatNum(data) {
  return http({
    method: "get",
    url: "/priceMessage/getUnTreatNum.nd",
    data
  });
}
//采购报表接口
export function homepageSalesReport(data) {
  return dmshttp({
    url: "homepageSalesReport",
    data
  });
}
//渠道采购报表接口
export function homepageNormalSalesReport(data) {
  return dmshttp({
    url: "homepageNormalSalesReport",
    data
  });
}
//库存报表
// export function homepageInventoryReport(data) {
//   return dmshttp({
//     url: "/report/inventoryTurnover.nd",
//     data
//   });
// }
export function homepageInventoryReport(data) {
  return http({
      method: "post",
      url: "/report/inventoryReports.nd",
      contentType: "application/json;charset=UTF-8",
      type: 'stringfy',
      data
  });
}
//销售报表
export function reportCustSales(data) {
  return http({
    method: "post",
    url: "/report/custSales.nd",
    data
  });
}
// 账号菜单浏览记录-新增
export function addBrowsing(data) {
  return http({
    method: "post",
    url: "/custMenuVisit/addOrUpdate.nd",
    data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
  });
}
export function useListA() {
  return http({
    method: "get",
    url: "/custMenuVisit/page.nd?clientPlatform=pc",

  });
}
