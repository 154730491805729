/*
 * @Author: sqk
 * @Date: 2020-09-02 10:26:41
 * @LastEditTime: 2021-01-12 15:25:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\product.js
 */

export default [
	{
		path: "/questionnaireEditor",
		name: "questionnaireEditor",
		redirect: "/questionnaireEditor",
		component: () => import("@/views/questionnaire/editor/editor.vue"),
		children: [{
				path: "/",
				component: () => import("@/views/questionnaire/editor/editor.vue"),
				meta: {
					title: "问卷编辑",
					keepAlive: false
				}
			}
		]
	},
    {
		path: "/questionnaireEditorPreview",
		name: "questionnaireEditorPreview",
		redirect: "/questionnaireEditorPreview",
		component: () => import("@/views/questionnaire/questionnaireEditorPreview/index.vue"),
		children: [{
				path: "/",
				component: () => import("@/views/questionnaire/questionnaireEditorPreview/index.vue"),
				meta: {
					title: "问卷预览",
					keepAlive: false
				}
			}
		]
	},
    {
		path: "/questionnaire/noLoginanswer",
		name: "noLoginanswer",
		redirect: "/questionnaire/noLoginanswer",
		component: () => import("@/views/questionnaire/answerNoLogin/index.vue"),
		children: [{
				path: "/",
				component: () => import("@/views/questionnaire/answerNoLogin/index.vue"),
				meta: {
					title: "问卷作答",
					keepAlive: false
				}
			}
		]
	},
];
