import http from "@/utils/request";

// post
// 保存意向用户
export function saveShopPotentialUser(data) {
  return http({
    method: "post",
    url: "/shopPotentialUser/saveShopPotentialUser.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
// 保存用户来源
export function saveInfo(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/shopPotentialSource/saveInfo.nd",
    data
  });
}
// 保存用户标签
export function saveInfoLabel(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/shopPotentialLabel/saveInfo.nd",
    data
  });
}
// /shopPotentialLabel/saveInfo.nd
// 获取用户来源数据
export function findList(data) {
  return http({
    method: "post",
    url: "/shopPotentialSource/findList.nd",
    data
  });
}
// 获取用户标签
export function findListLabel(data) {
  return http({
    method: "post",
    url: "/shopPotentialLabel/findList.nd",
    data
  });
}
export function potentialSpart(data) {
  return http({
    method: "post",
    url: "/fast/userReport/potentialSpart.nd",
    data
  });
}

//获取产品接口
export function getDefevtiveProByMatkl(data) {
  return http({
    url: '/comm/getDefevtiveProByMatkl.nd',
    method: 'get',
    params:data
  })
}