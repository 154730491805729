/*
 * @Author: sqk
 * @Date: 2020-08-07 14:38:39
 * @LastEditTime: 2021-01-21 10:28:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\request.js
 */
import axios from "axios";
import router from "../router";
import store from "@/store";
import { notification } from "ant-design-vue"; //ui库的消息提示
import { dmsPublicPath } from "@/common/constant.js";
import { removeJession, removeToken } from "./auth";
import Logan from 'logan-web';
const BASE_API = dmsPublicPath; // 地址
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["loginplant"] = "front_new_pc";
//请求的header里增加一个参数appversion，pc端直接固定写死 2.0
axios.defaults.headers.common["appversion"] = "2.0";

axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=UTF-8";
const service = axios.create({
  mute: false,
  method:'post',
  timeout: 120 * 1000, // request overtime time
  withCredentials: true,
});
//请求拦截
service.interceptors.request.use(
    (config) => {
      let account = ''
      if(config.data.account) {
        account = config.data.account
      } else {
        account = store.state.user.userInfo ? store.state.user.userInfo.account.account : JSON.parse(localStorage.getItem("userInfo")).account.account;
      }
      //设置请求头 -》 记录请求url
      config.headers.common['apiUrl'] = config.url;
      //dms 固定格式地址
      let cisCode = store.state.user.userInfo ? store.state.user.userInfo.customer.cisCode : JSON.parse(localStorage.getItem("userInfo")).customer.cisCode;
      config.url = BASE_API + '/wechatEntrance/entrance.do' + '?account=' + account + '&method=' + config.url;
      config.transformRequest = [
        function(data) {
          // let ret = "";
          let dmsData = {...data,cisCode}
          return JSON.stringify(dmsData)
          // for (let it in dmsData) {
          //   ret +=
          //     encodeURIComponent(it) + "=" + encodeURIComponent(dmsData[it]) + "&";
          // }
          // return ret;
        },
      ];
      return config;
    },
    (error) => {
      // Do something with request error
      console.log(error); // for debug
      Promise.reject(error);
    }
);
//响应拦截
service.interceptors.response.use(
    (response) => {
      if (response.status === 200) {
        return response;
      } else {
        // token过期 --> 清除登陆的信息，提示用户重新登陆
        if (response.data.status == "401") {
          localStorage.setItem("userInfo", "");
          removeToken();
          removeJession();
          store.commit("user/SET_USERINFO", "");
          notification.warning({
            message: "安全信息已过期，请重新登陆",
            duration: 3, //延时2.5s
            onClose: function() {
              router.push("/login");
            },
          });
        }
      }
    },
    /**
     * error callback change callback by judge status code
     */
    (error) => {
      console.error("err" + error); // for debug
      if (!error.response) {
        return;
      }
      if (error.response.status === 400) {
        if (error.response.data.code == 400) {

          if (!store.state.user.isGoLogin) {
            localStorage.setItem("userInfo", "");
            removeToken();
            removeJession();
            // store.state.user.userInfo = "";
            store.commit("user/SET_USERINFO", "");
            return;
          } else {
            notification.warning({
              message: "安全信息已过期，请重新登陆",
              // description: response.data.message,
              duration: 3, //延时2.5s
              key: "one",
            });
          }
          store
              .dispatch("user/logout")
              .then(() => {
                // notification.success("退出成功!");
              })
              .catch((err) => {
                console.error(err);
              });
          return;
        }
      } else if (error.response.status === 401) {
        notification.warning({
          message: "安全信息已过期，请重新登陆",
          duration: 3, //延时2.5s
          onClose: function() {
            router.push("/login");
          },
        });
      } else if (error.response.status === 500) {
        // 服务器断开
        notification.error({
          message: "服务器断开,请重试",
        });
      } else if (error.response.status === 403) {
        //无auth授权，后台不允许访问
        notification.error({
          message: "无auth授权，后台不允许访问",
        });
      }else{
        notification.error({
          message: "服务器繁忙，请稍后重试！",
          key: "two",
        });
      }
      Logan.log(error,3)
      return Promise.reject(error);
    }
);

export default service;
