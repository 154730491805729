/*
 * @Author: sqk
 * @Date: 2020-09-02 10:26:41
 * @LastEditTime: 2021-01-12 15:25:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\product.js
 */
let hash = window.location.hash;
let flag = navigator.userAgent.match(
	/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
let isPhone = flag && (hash.indexOf('questionnaire/answer') != -1 || hash.indexOf(
	'questionnaire/checkAnswer') != -1)
export default [
	// DEMO
	{
		path: "/questionnaire",
		name: "questionnaire",
		redirect: "/questionnaire/toBeAswered",
		// component: () => import("@/views/questionnaire/index.vue"),
		component: () => import("@/layout/TopDownLayout/Frame"),
		children: [
			// {
			// 	path: "/",
			// 	component: () => import("@/views/questionnaire/index.vue"),
			// 	meta: {
			// 		title: "问卷调查",
			// 		keepAlive: false,

			// 	}
			// },
			{
				path: "toBeAswered",
				component: () => import("@/views/questionnaire/toBeAswered/index.vue"),
				meta: {
					title: "待答问卷",
					keepAlive: false,
					isMenuHide: isPhone,
					isBarShow: !isPhone,
					isFooterShow: !isPhone
				}
			},
			{
				path: "answered",
				component: () => import("@/views/questionnaire/answered/index.vue"),
				meta: {
					title: "已答问卷",
					keepAlive: false,
					isMenuHide: isPhone,
					isBarShow: !isPhone,
					isFooterShow: !isPhone
				}
			},
			{
				path: "answer",
				component: () => import("@/views/questionnaire/answer/index.vue"),
				meta: {
					title: "问卷作答",
					keepAlive: false,
					isMenuHide: isPhone,
					isBarShow: !isPhone,
					isFooterShow: !isPhone
				}
			},
            // {
			// 	path: "answerNoLogin",
			// 	component: () => import("@/views/questionnaire/answerNoLogin/index.vue"),
			// 	meta: {
			// 		title: "问卷作答",
			// 		keepAlive: false,
			// 		isMenuHide: isPhone,
			// 		isBarShow: !isPhone,
			// 		isFooterShow: !isPhone
			// 	}
			// },
			{
				path: "checkAnswer",
				component: () => import("@/views/questionnaire/checkAnswer/index.vue"),
				meta: {
					title: "查看问卷",
					keepAlive: false,
					isMenuHide: isPhone,
					isBarShow: !isPhone,
					isFooterShow: !isPhone
				}
			}
		]
	}
];
