
export default [{
    path: "/threeQualityManagement",
    name: "threeQualityManagement",
    // redirect: "/confirm",
    component: () => import("@/layout/TopDownLayout/Frame.vue"),
    children: [
      {
        path: "/threeQualityManagement/thirdQradeReport",
        name: 'thirdQradeReport',
        component: () => import("@/views/threeQualityManagement/thirdQradeReport/index.vue"),
        meta: {
          title: "终包收货提报",
          keepAlive: false,
          // isBarShow: true,
        }
      },
      {
        path: "/threeQualityManagement/thirdQradeReportDetail",
        name: 'terminalInspectiondetail',
        component: () => import("@/views/threeQualityManagement/thirdQradeReportDetail/index.vue"),
        meta: {
          title: "终包收货提报详情",
          keepAlive: false,
          // isBarShow: true,
        }
      },

    //   三品收货列表Third class goods receiving
    {
        path: "/threeQualityManagement/thirdQradeReceiving",
        name: 'thirdQradeReport',
        component: () => import("@/views/threeQualityManagement/thirdQradeReceiving/index.vue"),
        meta: {
          title: "终包采购计划提报",
          keepAlive: false,
          // isBarShow: true,
        }
      },
      {
        path: "/threeQualityManagement/thirdQradeReceivingDetail",
        name: 'terminalInspectiondetail',
        component: () => import("@/views/threeQualityManagement/thirdQradeReceivingDetail/index.vue"),
        meta: {
          title: "终包采购计划提报详情",
          keepAlive: false,
          // isBarShow: true,
        }
      },
    ]
  }];