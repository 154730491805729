import dmshttp from "@/utils/dmsrequest";
import http from "@/utils/request";

// 查看商品列表
export function getProductList(data) {
  return dmshttp({
    url: "findInventoryProductList", //findInventoryProductList
    data
  });
}
export function getProductListlr(data) {
  return dmshttp({
    url: "findMaterialInventoryList", //findInventoryProductList
    data
  });
}
//获取零售价格
export function getRetailPrice(data) {
  return http({
    url: '/product/retailPrice.nd',
    method: 'post',
    data
  })
}
// 查看商品列表
export function getInvQty(data) {
  return dmshttp({
    url: "getInvQty", //findInventoryProductList
    data
  });
}
// 查看商品列表
export function findProductListLikeCode(data) {
  return dmshttp({
    url: "findProductListLikeCode", //findInventoryProductList
    data
  });
}
// 获取库存状态列表
export function getInvStatus(data) {
  return dmshttp({
    url: "getInvStatus",
    data
  });
}
// 获取补差状态列表
export function getInvStatusTypeApi(data) {
  return dmshttp({
    url: "getInvStatusType",
    data
  });
}
//获取分销价格
export function getfxPrice(data) {
  return http({
    url: '/product/fxPrice.nd',
    method: 'post',
    data
  })
}