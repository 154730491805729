<!--
 * @Author: sunqingkun
 * @Date: 2020-12-09 10:37:26
 * @LastEditTime: 2021-01-18 09:51:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\CustomizeNav\CustomizeNav.vue
-->

<template>
  <div class="cus-nav">
    <ul>
      <li
        v-for="(item, index) in selfNavList"
        :key="index"
        :class="navCls(index)"
        @click="navClickFun(item)"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { addBrowsing } from "@/utils/api";

export default {
  name: "cusNav",
  data() {
    return {
      selfNavList: []
    };
  },
  props: {
    navList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    $route: {
      handler() {
        this.$nextTick(() => {
          const menuList = JSON.parse(localStorage.getItem("menuList") || "[]");
          const thisName = this.$route.fullPath;
          const thisName1 = this.$route.path;
          let aimMenu = this.getAimMenu(menuList, thisName,thisName1);
          if(!Array.isArray(aimMenu.menuList)){
            return
          }
          aimMenu.menuList = aimMenu.menuList.filter(it=>{
            return it.isTab === 0
          })
          this.selfNavList = aimMenu.menuList.map(it=>{
            return {
              id:it.id,
              title: it.sourceName,
              path: it.vuePath,
              active: false
            }
          });
          if (this.selfNavList) {
            this.hideNavItem();
            // for (const idx in this.selfNavList) {
            //   this.selfNavList[idx].active = false;
            // }
            for (const idx in this.selfNavList) {
              if (this.selfNavList[idx].path == this.$route.path||this.selfNavList[idx].path == this.$route.fullPath) {
                this.selfNavList[idx].active = true;
                // this.navCls(idx);
                return;
              }
            }
          }
        });
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState({
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
      toPath: state => state.user.toPath
    })
  },
  // beforeDestroy(){
  //   $('.cus-nav ul').find('.active-left .active-right').removeClass('active-left active-right')
  // },
  mounted() {
    this.hideNavItem();
    // this.navList[0].active =true;
  },
  methods: {
    getAimMenu(menuList, thisName,thisName1) {
      if (Array.isArray(menuList)) {
        for (let item of menuList) {
          if (item.vuePath == thisName||item.vuePath == thisName1) {
            return {
              item:item,
              menuList:menuList
            };
          } else {
            const resu = this.getAimMenu(item.subMenuList, thisName,thisName1);
            if (resu) {
              return resu;
            }
          }
        }
      }
    },
    //初始化nav选项 隐藏部分
    hideNavItem() {
      // 销售订单、销售订单进度显示
      if (
        this.$route.path == "/channelOrder/orderList" ||
        this.$route.path == "/channelOrder/salesProgress"
      ) {
        this.selfNavList = this.selfNavList.filter(
          it => it.title.indexOf("分销直采订单") == -1
        );
      }
      // 销售订单、分销直采订单显示
      if (
        this.$route.path == "/channelOrder/orderReview" ||
        this.$route.path == "/channelOrder/distributorPurchase"
      ) {
        this.selfNavList = this.selfNavList.filter(
          it => it.title.indexOf("销售进度查询") == -1
        );
      }
      //  if (
      // //海信
      //   (this.isFenxiaoOrder.indexOf("17452") !== -1 ||
      //     this.isFenxiaoOrder.indexOf("17453") !== -1) &&
      //   this.isFenxiaoOrder.indexOf("17451") == -1
      // ) {
      //   this.selfNavList = this.selfNavList.filter(it => it.title.indexOf("渠道") == -1);
      // } else if (
      //   //渠道
      //   this.isFenxiaoOrder.indexOf("17451") !== -1 &&
      //   this.isFenxiaoOrder.indexOf("17452") == -1 &&
      //   this.isFenxiaoOrder.indexOf("17453") == -1
      // ) {
      //   this.selfNavList = this.selfNavList.filter(it => it.title.indexOf("海信") == -1);
      // } else if (
      //   //共存
      //   (this.isFenxiaoOrder.indexOf("17451") !== -1 &&
      //     this.isFenxiaoOrder.indexOf("17452") !== -1) ||
      //   this.isFenxiaoOrder.indexOf("17453") !== -1
      // ) {
      //   //   this.isFenxiaoSign = "3";
      //   //   this.isFenxiaoSignActive = "2";
      // }
    },
    //赋值 样式class
    navCls(idx) {
      let cls;
      // let index;
      // if(this.navList[idx].path == this.$route.path){
      //   cls = "active";
      //   index = idx
      // }

      // if(idx == index - 1){
      //   //左
      //   cls = "active-left";
      // }else if(idx == index + 1){
      //   //右
      //   cls = "active-right";
      // }
      if (this.selfNavList[idx].active) {
        cls = "active";
        if(idx==0){
          cls = "active active_one";
        }
      }
      if (
        idx < this.selfNavList.length - 1 &&
        this.selfNavList[Number(idx) + 1].active
      ) {
        cls = "active-left";
      }
      if (idx > 0 && this.selfNavList[Number(idx) - 1].active) {
        cls = "active-right";
      }
      return cls;
    },
    //菜单点击
    navClickFun(item) {
      // 最近使用记录
      addBrowsing({
        menuId:item.id,
        clientPlatform:'pc'
      }).then(res => {})
      if (typeof window._paq !== 'undefined' ) {
        window._paq.push(['setCustomUrl', item.path]);
        window._paq.push(['setDocumentTitle', item.title]);
        window._paq.push(['trackPageView']);
      }
      if (item.path.length < 3) {
        this.$message.info("该模块正在开发");
        return;
      }
      if (item.active) {
        return;
      }
      for (const e of this.selfNavList) {
        e.active = false;
      }

      item.active = true;

      this.$router.push({
        path: item.path
      });
    }
  }
};
</script>

<style lang="less" scoped>
.cus-nav {
  width: 1188px;
  margin: 0 auto;
  height: 44px;
  margin-top: -10px;
  ul {
    //background: #ffffff;
    //border-radius: 14px 14px 0px 0px;
    padding: 2px 2px 0;
    float: left;

    li {
      width: 160px;
      height: 38px;
      background: url("~@/assets/common/tab_bg_new.png") no-repeat center;
      background-size: 100% 100%;
      color: #fff;
      float: left;
      font-size: 14px;
      //color: #262626;
      line-height: 38px;
      position: relative;
      top: 6px;
      cursor: pointer;

      &:first-child {
        //border-top-left-radius: 12px;
        overflow: hidden;
        //margin-left: 0!important;
        //margin-right: 0!important;
      }

      &:last-child {
        //border-top-right-radius: 12px;
        overflow: hidden;
      }

      & + li {
        margin-left: 2px;
      }

      &.active {
         background:url('~@/assets/common/tab_bg_normal.png') no-repeat center;
        background-size: 100% 100%;
        position: relative;
        color: #00aaa6;
        //background: #fff;
        height: 44px;
        line-height: 48px;
        width: 168px;
        position: relative;
        top:0;
        margin-left: -3px;
        margin-right: -4px;

        &::after {
          content: "";
          position: absolute;
          width: 44px;
          height: 2px;
          background: #00aaa6;
          left: calc(50% - 22px);
          bottom: 0;
        }
      }
      &.active_one {
        position: relative;
        left: -3px;
        margin-right: -6px!important;
      }

      &.active-left {
        background: url("~@/assets/common/tab_bg_new.png") no-repeat center;
        background-size: 100% 100%;
        color: #fff;
      }

      &.active-right {
        background: url("~@/assets/common/tab_bg_new.png") no-repeat center;
        background-size: 100% 100%;
        color: #fff;
      }
    }
  }
}
</style>
