<!-- 配置需要显示的列 -->
<template>
	<el-popover placement="bottom-end" trigger="click" v-model='setShow'>
		<div class="list">
			<div class="left">
				<div class="title">
					未显示的列
				</div>
				<div class="group">
					<a-checkbox @change="hideChange(idx)" v-for="(item,idx) in hideList" :key='idx'
						:checked='item.value'>
						{{item.label}}
					</a-checkbox>
				</div>
			</div>
			<div class="right">
				<div class="title">
					显示的列
				</div>
				<div class="group">
					<a-checkbox @change="showChange(idx)" v-for="(item,idx) in showList" :key='idx'
						:checked='item.value'>
						{{item.label}}
					</a-checkbox>
				</div>
			</div>

		</div>
		<div class="subCon">
			<el-button class='btn sub' @click='sub()'>确定</el-button>
		</div>

		<el-button class='btn' slot="reference">调整列</el-button>
	</el-popover>

</template>

<script>
	import {
	  setRows,
	  getRows
	} from './api'
    import {
	  xsdd,
      cgdd
	} from './list'
	import {
		mapState
	} from "vuex";
	export default {
		name: "set-table-row",
		data() {
			return {
				setShow: false,
				hideList: [],
				showList: []
			};
		},
		props: {
			type: {
				type: String //xsdd 销售订单 cgdd采购订单
			}
		},
		watch: {},
		computed: {},
		mounted() {
			this.setList();
			this.getRows();
		},
		methods: {
			hideChange(idx) {
				this.hideList[idx].value = true;
				let item = this.hideList[idx];
				this.hideList.splice(idx, 1);
				this.showList.push(item)
				this.sort(1)
			},
			showChange(idx) {
				this.showList[idx].value = false;
				let item = this.showList[idx];
				this.showList.splice(idx, 1);
				this.hideList.push(item)
				this.sort(2)
			},
			sort(type) {
				if (type == 1) {
					this.showList.sort((a, b) => a.sort - b.sort)
				}
				if (type == 2) {
					this.hideList.sort((a, b) => a.sort - b.sort)
				}
			},
			sub() {
				this.setShow = false;
				if(this.type == 'cgdd') {
					for(let item of this.showList) {
						if(item.label == '预计送达日期') {
							item.prop = "eDate"
						}
					}
				}
				this.$emit('listChange',JSON.parse(JSON.stringify(this.showList)))
				let list = [this.showList,this.hideList]
				let data = {
					key: this.type,
					userDate: JSON.stringify(list)
				}
				setRows(data).then(res => {
				})
			},
			setList() {
				switch (this.type) {
					case 'xsdd':
						this.showList = xsdd;
						break;
					case 'cgdd':
						this.showList = cgdd;
						break;
				}
				
			},
			getRows() {
				let data = {
					key: this.type
				}
				getRows(data).then(res => {
					if(res.data.data) {
						let list = JSON.parse(res.data.data.userData);
                        let listEmp = [];
                        listEmp = listEmp.concat(list[0])
                        listEmp = listEmp.concat(list[1])
                        if(this.type == 'xsdd') {
                            for(let item of xsdd) {
                                let has = false;
                                for(let it of listEmp) {
                                    if(item.label == it.label) {
                                        has = true
                                    }
                                };
                                if(!has) {
                                    list[0].push(item);
                                }
                            }
                        }
                        if(this.type == 'cgdd') {
                            for(let item of cgdd) {
                                let has = false;
                                for(let it of listEmp) {
                                    if(item.label == it.label) {
                                        has = true
                                    }
                                };
                                if(!has) {
                                    list[0].push(item);
                                }
                            }
                        }
						this.showList = list[0];
						this.hideList = list[1];
						if(this.type == 'cgdd') {
							for(let item of this.showList) {
								if(item.label == '预计送达日期') {
									item.prop = "expectedDeliveryDate"
								}
							}
						}
						this.$emit('listChange',JSON.parse(JSON.stringify(this.showList)))
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.btn {
		text-align: center;
		padding: 0 12px;
		background-color: #00AAA6;
		color: #fff;
		box-sizing: border-box;
		width: 66px;
		height: 28px;
		line-height: 26px;
		border-radius: 4px;
		font-size: 14px;
		border: 1px solid #00AAA6;
		text-align: center;
	}

	.btn:hover {
		background-color: #1db8b0;
		border-color: #1db8b0;
	}

	.btn:active {
		background-color: #1db8b0;
		border-color: #1db8b0;
	}

	.subCon {
		padding-right: 16px;
		box-sizing: border-box;
		display: flex;
		justify-content: flex-end;
		margin-top: 12px;
	}

	.list {
		display: flex;
		justify-content: space-between;
		padding: 5px;
		box-sizing: border-box;
		width: 450px;

		.left,
		.right {
			width: 210px;
		}

		.title {
			font-size: 16px;
			font-family: MicrosoftYaHei;
			color: #262626;
			line-height: 16px;
			margin-bottom: 12px;
		}

		.group {
			width: 210px;
			height: 338px;
			overflow-x: hidden;
			overflow-y: scroll;
			border-radius: 4px;
			border: 1px solid #EEEEEE;
			padding: 16px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			.ant-checkbox-wrapper {
				margin-left: 0;
				font-size: 16px;
				margin-bottom: 8px;

				.ant-checkbox-wrapper .ant-checkbox+span {
					display: inline-block;
					width: 150px;
				}
			}
		}
	}

	::-webkit-scrollbar {
		display: none;
	}
</style>
