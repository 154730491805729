export default [
    {
        path: "",
        name: "productChannelWholesalePrice",
        redirect: "",
        component: () => import("@/layout/TopDownLayout/Frame"),
        children: [
            {
                path: "/productChannelWholesalePrice/productChannelPriceList",
                name: 'productChannelPriceList',
                component: () => import("@/views/productChannelWholesalePrice/productChannelPriceList/index.vue"),
                meta: {
                    title: "产品渠道批发价格",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            {
                path: "/productChannelWholesalePrice/productChannelPriceadd",
                name: 'productChannelPriceadd',
                component: () => import("@/views/productChannelWholesalePrice/productChannelPriceadd/index.vue"),
                meta: {
                    title: "新增产品渠道批发价格",
                    keepAlive: false,
                    isBarShow: true
                }
            },

        ]
    }
]