export default [
    {
        path: "",
        name: "distributorReturnList",
        redirect: "",
        component: () => import("@/layout/TopDownLayout/Frame"),
        children: [
            {
                path: "/channelReturns/distributorReturnList",
                name: 'distributorReturnList',
                component: () => import("@/views/channelReturns/distributorReturnList/index.vue"),
                meta: {
                    title: "分销商退货列表",
                    keepAlive: false,
                        isBarShow: true
                }
            },
            {
                path: "/channelReturns/distributorReturnList/distributorprocess",
                name: 'distributorprocess',
                component: () => import("@/views/channelReturns/distributorprocess/index.vue"),
                meta: {
                    title: "分销商退货详情",
                    keepAlive: false,
                        isBarShow: true
                }
            },
            {
                path: "/channelReturns/distributorReturnList/distributordetail",
                name: 'distributordetail',
                component: () => import("@/views/channelReturns/distributordetail/index.vue"),
                meta: {
                    title: "退货出库",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            {
                path: "/channelReturns/agentWarehousingList",
                name: 'agentWarehousingList',
                component: () => import("@/views/channelReturns/agentWarehousingList/index.vue"),
                meta: {
                    title: "代理商入库列表",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            {
                path: "/channelReturns/agentWarehousingList/agentWareprocess",
                name: 'agentWareprocess',
                component: () => import("@/views/channelReturns/agentWareprocess/index.vue"),
                meta: {
                    title: "代理商入库详情",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            {
                path: "/channelReturns/agentWarehousingList/agentWaredetail",
                name: 'agentWaredetail',
                component: () => import("@/views/channelReturns/agentWaredetail/index.vue"),
                meta: {
                    title: "退货入库",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            {
                path: "/channelReturns/returnEntry",
                name: 'returnEntry',
                component: () => import("@/views/channelReturns/returnEntry/index.vue"),
                meta: {
                    title: "退货录入",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            {
                path: "/channelReturns/returnSubmit",
                name: 'returnSubmit',
                component: () => import("@/views/channelReturns/returnSubmit/index.vue"),
                meta: {
                    title: "退货结果页",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            // 销售退货 （新 2023/11/2）
            // 退货入库新(新的页面) 以下路由都是新的
            {
                path: "/salesReturn/returnReceiptList",
                name: 'returnReceiptList',
                component: () => import("@/views/returnModule/salesReturn/returnReceiptList/index.vue"),
                meta: {
                    title: "退货入库",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            // 正品退货入库
            {
                path: "/salesReturn/authenticReturnEntry",
                name: 'authenticTreturnEntry',
                component: () => import("@/views/returnModule/salesReturn/authenticReturnEntry/index.vue"),
                meta: {
                    title: "零售订单退货",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            // 正品退货入库
            {
                path: "/salesReturn/genuineReturnList",
                name: 'genuineReturnDetails',
                component: () => import("@/views/returnModule/salesReturn/genuineReturnList/index.vue"),
                meta: {
                    title: "正品退货明细",
                    keepAlive: false,
                    isBarShow: true
                }
            },
             //正品退货详情
            {
                path: "/salesReturn/genuineReturnDetails",
                name: 'genuineReturnDetails',
                component: () => import("@/views/returnModule/salesReturn/genuineReturnDetails/index.vue"),
                meta: {
                    title: "正品退货详情",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            // 退货成功页面 salesReturn\components
            {
                path: "/salesReturn/success",
                name: 'success',
                component: () => import("@/views/returnModule/salesReturn/components/success.vue"),
                meta: {
                    title: "退货成功",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            //  采购退货列表
            {
                path: "/purchaseReturn/purchaseReturnList",
                name: '/purchaseReturn/purchaseReturnList',
                component: () => import("@/views/returnModule/purchaseReturn/purchaseReturnList/index.vue"),
                meta: {
                    title: "采购退货",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            //采购退货页面
            {
                path: "/purchaseReturn/purchaseReturnEntry",
                name: '/purchaseReturn/purchaseReturnEntry',
                component: () => import("@/views/returnModule/purchaseReturn/purchaseReturnEntry/index.vue"),
                meta: {
                    title: "渠道订单退货",
                    keepAlive: false,
                    isBarShow: true
                }
            },
    //       采购正品退货列表 \purchaseGenuineList
            {
                path: "/purchaseReturn/purchaseGenuineList",
                name: '/purchaseGenuineList',
                component: () => import("@/views/returnModule/purchaseReturn/purchaseGenuineList/index.vue"),
                meta: {
                    title: "采购正品退货明细",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            {
                path: "/purchaseReturn/purchaseReturnDetails",
                name: '/purchaseReturnDetails',
                component: () => import("@/views/returnModule/purchaseReturn/purchaseReturnDetails/index.vue"),
                meta: {
                    title: "退货详情",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            {
                path: "/defectiveRegression/defectiveInput",
                name: '/defectiveInput',
                component: () => import("@/views/returnModule/defectiveRegression/sale/index.vue"),
                meta: {
                    title: "发起残次退",
                    keepAlive: false,
                    isBarShow: true
                }
            },
            //
            
            
    ]
}]