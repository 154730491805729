/*
 * @Author: sqk
 * @Date: 2020-08-25 14:01:49
 * @LastEditTime: 2020-08-25 16:10:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\store\modules\historyList.js
 */
const state = {
    list: [
        // {
        //     time:'',
        //     name:'',
        //     path:'',
        // }
    ],

  };
  
  const mutations = {
  };
  
  const actions = {
      
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  };