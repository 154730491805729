<template>
  <a-modal :footer="null" :visible="visible" @ok="handleOk" @cancel="cancle" wrapClassName="cancelName" :width='width'
    :closable="false" centered :maskClosable='false' :ok-button-props="{ props: { disabled: submitBtn } }">
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>{{title}}
      </span>
    </div>
    <div class="weaper">
      <slot></slot>
    </div>
  </a-modal>
</template>
<script>


export default {
  name: 'normal-modal',
  data() {
    return {
      
    }
  },
  props: {
    width: {
      type: Number,
      default: 678
    },
    title: {
      type: String,
      default: '提示'
    },
    visible: {
      type: Boolean,
      default: false
    },
  },
  watch: {

  },
  mounted() {
    
  },
  methods: {
    cancle(){
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 24px;

      .weaper {
        .ant-table-wrapper {
          .ant-spin-nested-loading {
            .ant-spin-container {
              .ant-table-default {
                .ant-table-content {

                  .ant-table-body {
                    table {
                      .action {
                        a{
                          font-size: 14px;
                          color: #1890FF;
                        }

                        .ant-divider {
                          visibility: hidden;
                        }
                      }
                    }

                  }
                }
              }
            }
          }
        }
      }

      .cancelContent {
        text-align: left;
        padding: 0 40px 0 0;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;

        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }

      .cancelInfo {
        padding: 24px 0 50px 0px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 78px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          float: left;
          padding-left: 11px;
          margin-right: 9px;
        }

        .ant-input {
          width: 400px;
          height: 88px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 24px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 0px 0 30px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


