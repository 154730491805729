/*
 * @Author: sunzuoyuan
 * @Date: 2020-12-10 20:05:03
 * @LastEditTime: 2021-06-07 16:44:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath:
 */

export default [
  {
    path: "/stockCenter",
    name: "order",
    redirect: "/stockCenter/exceedStock",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/stockCenter/myStock",
        component: () => import("@/views/stockCenter/myStock/index.vue"),
        meta:{
          title:"我的库存",
          keepAlive: false,
          isBarShow:true
        },
      },

      {
        path: "/stockCenter/inventoryLog",
        component: () => import("@/views/stockCenter/inventoryLog/index.vue"),
        meta:{
          title:"库存流水",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/inventoryLogNew",
        component: () => import("@/views/stockCenter/inventoryLogNew/index.vue"),
        meta:{
          title:"库存流水",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/exceedStock",
        component: () => import("@/views/stockCenter/exceedStock/index.vue"),
        meta:{
          title:"可共享库存明细",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/adjustmentSucces",
        component: () => import("@/views/stockCenter/adjustmentApply/succes/index.vue"),
        meta:{
          title:"申请成功！",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/applySucces",
        component: () => import("@/views/stockCenter/exceedStock/succes/index.vue"),
        meta:{
          title:"申请成功！",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/shareLog",
        component: () => import("@/views/stockCenter/exceedStockApplyLog/index.vue"),
        meta:{
          title:"共享申请记录",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/distributorStock",
        component: () => import("@/views/stockCenter/distributorStock/index.vue"),
        meta:{
          title:"分销商库存",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/stockAdjustment",
        component: () => import("@/views/stockCenter/stockAdjustment/index.vue"),
        meta:{
          title:"库存调整查询",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/adjustmentApply",
        component: () => import("@/views/stockCenter/adjustmentApply/index.vue"),
        meta:{
          title:"发起库存调整",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/unsettledReservation",
        component: () => import("@/views/stockCenter/unsettledReservation/index.vue"),
        meta:{
          title:"未结预留",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/stockAgeQuery",
        component: () => import("@/views/stockCenter/stockAgeQuery/index.vue"),
        meta:{
          title:"库龄查询",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/inventorySnapshot",
        component: () => import("@/views/stockCenter/inventorySnapshot/index.vue"),
        meta:{
          title:"库存快照",
          keepAlive: false,
          isBarShow:true
        },
      },
      {
        path: "/stockCenter/issueDetails",
        component: () => import("@/views/stockCenter/issueDetails/index.vue"),
        meta:{
          title:"收发汇总",
          keepAlive: false,
          isBarShow:true
        },
      },
    ]
  }
];
