<!--
 * @Author: 尚宏浩 aaa@hisense.com
 * @Date: 2024-08-24 13:39:33
 * @LastEditors: 尚宏浩 aaa@hisense.com
 * @LastEditTime: 2024-08-28 19:25:33
 * @FilePath: \B2B\src\components\global\CopyText\CopyText.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <normal-modal title="查看发票" :visible="visibleTicket" @cancel="visibleTicket = false">
        <a-table class="ticket-table" rowKey="id" bordered :pagination="false" :dataSource="dataSource" :columns="columns">
            <span class="action" slot="action" slot-scope="text, record">
                <a @click="seeTicketPdf(record)">下载</a>
            </span>
        </a-table>
    </normal-modal>
</template>
  
<script>
// 导入api接口
import { getTicketEcpx } from "@/views/order/api";
export default {
    name: 'TicketModal',
    props: {

    },
    data() {
        return {
            visibleTicket: false,
            dataSource: [],
            columns: [
                {
                    title: '发票类型',
                    dataIndex: 'invoiceType',
                    key: 'invoiceType',
                    width: 100
                },
                {
                    title: '开票日期',
                    dataIndex: 'invoiceDate',
                    key: 'invoiceDate',
                    width: 194
                },
                {
                    title: '发票号',
                    dataIndex: 'invoiceNo',
                    key: 'invoiceNo',
                    width: 137
                },
                {
                    title: '操作',
                    scopedSlots: { customRender: 'action' },
                    key: 'action',
                    // width: 137
                },
            ],
        }
    },
    methods: {
        async show(orderCode) {
            let res = await getTicketEcpx({ orderCode })
            console.log(123, res)
            if (res.data.code === 0) {
                this.dataSource = res.data.data
                this.visibleTicket = true
            } else if (res.data.code === 1) {
                this.$message.info(res.data.msg);
            } else {
                this.$message.info('服务异常');
            }

        },
        seeTicketPdf(item) {
            console.log('data', item)
            window.open(item.pdfUrl, '_blank')
        },
    }
}
</script>
  
<style lang="less" scoped>

</style>
  