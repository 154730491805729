export default [
  {
    path: "/serviceFeeModule",
    name: "serviceFreeList",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/serviceFeeModule/serviceFreeList",
        component: () => import("@/views/serviceFeeModule/serviceFeeList/index.vue"),
        meta: {
          title: "服务商单据", //配置title
          keepAlive: false, //是否缓存
          isBarShow: true
        }
      },
      {
        path: "/serviceFeeModule/serviceDetail",
        component: () => import("@/views/serviceFeeModule/serviceDetail/index.vue"),
        meta: {
          title: "单据详情", //配置title
          keepAlive: false, //是否缓存
          isBarShow: true
        }
      },
      {
        path: "/serviceFeeModule/pay",
        component: () => import("@/views/serviceFeeModule/payOrderPage/index.vue"),
        meta: {
          title: "支付", //配置title
          keepAlive: false, //是否缓存
          isBarShow: false
        }
      },
      {
        path: "/serviceFeeModule/payDetail",
        component: () => import("@/views/serviceFeeModule/payDetail/index.vue"),
        meta: {
          title: "支付详情", //配置title
          keepAlive: false, //是否缓存
          isBarShow: false
        }
      },
      // 订单提交成功
      {
        path: "/serviceFeeModule/servicePaySuccess",
        component: () => import("@/views/serviceFeeModule/successPage/successPay.vue"),
        meta: {
          title: "提交成功",
          keepAlive: false,
          isBarShow: true
        }
      },
      {
        path: "/serviceFeeModule/logisicsAgreent",
        component: () => import("@/views/serviceFeeModule/logisicsAgreent/index.vue"),
        meta: {
          title: "物流协议",
          keepAlive: false,
          isBarShow: true
        }
      },
    ]
  },
];

