/*
 * @Author: sqk
 * @Date: 2020-08-10 15:46:45
 * @LastEditTime: 2021-06-11 16:04:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\home.js
 */

export default [
  {
    path: "/",
    redirect: '/index',
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [{
      path: "/index",
      component: () => import("@/views/home/index.vue"),
      meta: {
        title: "首页", //配置title
        keepAlive: false, //是否缓存
        isBarShow: true,
        isKeepBar: true, // 保持bar常显
      },
    }, ],
  },
  {
    path: "",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [{
      path: "",
      component: () => import("@/views/home/index.vue"),
      meta: {
        title: "首页", //配置title
        keepAlive: false, //是否缓存
        isBarShow: true,
        isKeepBar: true, // 保持bar常显
      },
    }, ],
  },
  
  // 首页
  // {
  //   path: "/index",
  //   name: "index",
  //   redirect: "/index",
  //   component: () => import("@/layout/TopDownLayout/Frame"),
  //   children: [{
  //     path: "/index",
  //     component: () => import("@/views/home/index.vue"),
  //     meta: {
  //       title: "首页", //配置title
  //       keepAlive: false, //是否缓存
  //       isBarShow: true,
  //     },
  //   }, ],
  // },
  // 登陆
  {
    path: "/login",
    name: "login",
    redirect: "",
    component: () => import("@/views/login/index.vue"),
    children: [{
      path: "",
      component: () => import("@/views/home/index.vue"),
      meta: {
        title: "登录页",
        keepAlive: false,
      },
    }, ],
  },
  // 忘记密码
  {
    path: "/findePassword",
    name: "findePassword",
    redirect: "",
    component: () => import("@/views/findePassword/index.vue"),
    children: [{
      path: "",
      component: () => import("@/views/home/index.vue"),
      meta: {
        title: "忘记密码",
        keepAlive: false,
      },
    }],
  },
  // 找回密码下一步 输入短信验证码
  {
    path: "/getCode",
    name: "getCode",
    redirect: "",
    component: () => import("@/views/getCode/index.vue"),
    children: [{
      path: "",
      component: () => import("@/views/home/index.vue"),
      meta: {
        title: "忘记密码",
        keepAlive: false,
      },
    }],
  },
  // 重置密码
  {
    path: "/reset",
    name: "reset",
    redirect: "",
    component: () => import("@/views/resetPassword/index.vue"),
    children: [{
      path: "",
      component: () => import("@/views/home/index.vue"),
      meta: {
        title: "忘记密码",
        keepAlive: false,
      },
    }],
  },
  {path:'*',redirect:'/'},
];