/*
 * @Author: sqk
 * @Date: 2020-08-25 15:27:46
 * @LastEditTime: 2020-08-25 17:43:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\historyList\api.js
 */


import http from "@/utils/request";


// 存
export function setHistoryList(data) {
  return http({
    method: "get",
    url: "/demo",
    data
  });
}

// 取
export function getHistoryList(data) {
  return http({
    method: "get",
    url: "/demo",
    data
  });
}
