/*
 * @Author: HIDESK\yanbenrong.ex ybr15650585591@163.com
 * @Date: 2024-01-02 11:16:13
 * @LastEditors: HIDESK\yanbenrong.ex ybr15650585591@163.com
 * @LastEditTime: 2024-01-02 11:19:49
 * @FilePath: \B2B\src\router\modules\engineeringPrcment.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
    // 工程采购
    {
      path: "/engineeringPrcment",
      name: "engineeringPrcment",
      redirect: "/engineeringPrcment",
      component: () => import("@/layout/TopDownLayout/Frame"),
      children: [
        {
          path: "/engineeringPrcment",
          component: () => import("@/views/engineeringPrcment/index.vue"),
          meta: {
            title: "工程采购",
            keepAlive: false,
            isBarShow:true,
          }
        }
      ]
    }
  ];
  