/*
 * @Author: mayu
 * @Date: 2020-09-28 14:26:41
 * @LastEditTime: 2021-01-31 23:17:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: B2B\src\router\modules\shoppingCart.js
 */



export default [{
    path: "/customer360",
    name: "customer360",
    // redirect: "/confirm",
    component: () => import("@/layout/TopDownLayout/Frame.vue"),
    children: [
      {
        path: "/customer360",
        name: 'customer360',
        component: () => import("@/views/reports/customer360/index.vue"),
        meta: {
          title: "综合评价",
          keepAlive: false,
          isBarShow: true,
        }
      },
      {
        path: "/specialStoreCE",
        name: 'specialStoreCE',
        component: () => import("@/views/reports/specialStoreCE/index.vue"),
        meta: {
          title: "专卖店综合评价",
          keepAlive: false,
          isBarShow: true,
        }
      },
      {
        path: "/specialStoreCEDLS",
        name: 'specialStoreCEDLS',
        component: () => import("@/views/reports/specialStoreCEDLS/index.vue"),
        meta: {
          title: "专卖店综合评价(代理商)",
          keepAlive: false,
          isBarShow: true,
        }
      },
      
    ]
  }];