<!--
 * @Description: 
 * @Author: sqk
 * @Date: 2020-08-05 14:02:37
 * @LastEditors: chuzhengyang.ex chuzhengyang.ex@hx-partners.com
 * @LastEditTime: 2023-12-19 10:35:13
 * @FilePath: /vue-frame/src/App.vue
-->
<template>
	<a-config-provider :locale="locale">
		<div id="app" v-if="flag">
			<div class="code-down-box">
				<div class="code-item">
					<h1>Hisense信天翁微信小程序</h1>
					<div class="c-box">
						<div class="img-box">
							<img style="width:282px" :src="wxsrc" />
							<p><i>微信扫一扫</i>进入小程序</p>
						</div>
					</div>
				</div>
				<div class="code-item type2">
					<h1>Hisense信天翁APP</h1>
					<div class="c-box">
						<div class="img-box">
							<img :src="ansrc" />
							<p>安卓客户端</p>
						</div>
						<div class="img-box">
							<img :src="IOSsrc" />
							<p>iOS客户端</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="app" v-else>
			<!-- <button @click="changeLocale('english')" >English</button >
      <button @click="changeLocale('zh_CN')" >中文</button >-->
			<router-view />
		</div>
	</a-config-provider>
</template>

<script>
	// import { logout } from "@/utils/api";
	// import { publicPath } from "@/common/constant.js";
	// 国际化
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	// import english from 'ant-design-vue/lib/locale-provider/default';
	import moment from "moment";
	import "moment/locale/zh-cn";
	import {
		env
	} from "@/common/constant";
	moment.locale("zh-cn");
	// import { getToken } from "@/utils/auth";
	// import {
	//   getAlert,//获取提示信息接口
	// } from "@/utils/api.js";
	export default {
		data() {
			return {
				locale: zh_CN,
				beforeUnload_time: "",
				gap_time: "",
				flag: undefined
			};
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo ?
					this.$store.state.user.userInfo :
					localStorage.getItem("userInfo") ?
					JSON.parse(localStorage.getItem("userInfo")) :
					"";
			}
		},
		created() {
			//获取提示信息数组 存储到local中 后续判断取用信息
			// if(!localStorage.getItem('msgArr')){
			//   getAlert().then((res)=>{
			//     localStorage.setItem('msgArr',JSON.stringify(res.data.list));
			//   })
			// }
		},
		mounted() {
			this._isMobile();
			if (env == "dev" || env == "test" || env == "uat") {
				this.wxsrc = require("./assets/common/ceshxcx.jpg");
				this.ansrc = require("./assets/common/azceshi.png");
				this.IOSsrc = require("./assets/common/iosceshi.png");
			} else {
				// 如果是正式展示正式
				this.wxsrc = require("./assets/common/toolbar-code-wx2.png");
				this.ansrc = require("./assets/common/ercode_prd.png");
				this.IOSsrc = require("./assets/common/toolbar-code-ios2.png");
			}

			//绑定页面事件 判断是否关闭浏览器 -》 调用登出方法
			window.addEventListener("beforeunload", () => this.beforeunloadHandler());
			window.addEventListener("unload", () => this.unloadHandler());
		},
		destroyed() {
			window.removeEventListener("beforeunload", e =>
				this.beforeunloadHandler(e)
			);
			window.removeEventListener("unload", e => this.unloadHandler(e));
		},
		methods: {
			_isMobile() {
				let hash = window.location.hash;
				if ( hash.indexOf('/submitEndActivitySuccess') == -1 && hash.indexOf('questionnaire/answer') == -1 && hash.indexOf('questionnaire/noLoginanswer') == -1 && hash.indexOf('questionnaire/checkAnswer') == -1 && hash.indexOf('/privacy') == -1 && hash.indexOf('/personalInfo') == -1 && hash.indexOf('/thirdPartyInfo') == -1 && hash.indexOf('/questionnaireEditorPreview') == -1 && hash.indexOf('/annualReport') == -1 && hash.indexOf('/mobilePayment') == -1 && hash.indexOf('/productDetailH5') == -1&& hash.indexOf('/operatingManualH5') == -1 && hash.indexOf('/yearScanResultH5') == -1 && hash.indexOf('/reportOfAnnual') == -1 && hash.indexOf('/oldMachineCouponList') == -1 && hash.indexOf('/shopPersonalInfo') == -1 && hash.indexOf('/shopThirdPartyInfo') == -1 && hash.indexOf('/positionInformation') == -1 && hash.indexOf('/telephonePermissions') == -1 && hash.indexOf('/accessingPhotoAlbums') == -1 && hash.indexOf('/accessCamera') == -1) {
                    let flag = navigator.userAgent.match(
						/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					);
					this.flag = flag;
				}
                if(hash.indexOf('/correctionError') > -1) {
                    this.flag = null;
                }
			},
			getQueryValue(queryName) {
				let reg = `(^|&)${queryName}=([^&]*)(&|$)`
				let r = window.location.hash.substr(22).match(reg);
				if (r != null) return unescape(r[2]);
				return null;
			},
			beforeunloadHandler() {
				this.beforeUnload_time = new Date().getTime();
			},
			unloadHandler() {
				this.gap_time = new Date().getTime() - this.beforeUnload_time;
				//判断是窗口关闭还是刷新 原来是5
				if (this.gap_time <= 6) {
					//如果是登录状态，关闭窗口前，移除用户
					// if (this.userInfo && publicPath != '/api') {
					//   logout();
					//   $.ajax({
					//     url: publicPath + "/logout.nd",
					//     type: "post",
					//     async: false, //或false,是否异步
					//     success: function(res) {
					//       localStorage.setItem("ff", res);
					//     },
					//     error: function(res) {
					//       localStorage.setItem("hh", res);
					//     }
					//   });
					// }
				}
			},
		}
	};
</script>
<style>
	* {
		margin: 0px;
		padding: 0px;
	}

  #app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #262626;
	}

	html {
	    font-size : 8px;
		/* min-width: 1188px; */
		
	}
	@media only screen and (min-width: 401px){
	    html {
	        font-size: 10px !important;
	    }
	}
	@media only screen and (min-width: 428px){
	    html {
	        font-size: 10.7px !important;
	    }
	}
	@media only screen and (min-width: 481px){
	    html {
	        font-size: 12px !important; 
	    }
	}
	@media only screen and (min-width: 569px){
	    html {
	        font-size: 14px !important; 
	    }
	}
	@media only screen and (min-width: 641px){
	    html {
	        font-size: 16px !important; 
	    }
	}
</style>
