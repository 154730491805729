<!--
 * @Author: sqk
 * @Date: 2020-08-12 15:51:12
 * @LastEditTime: 2021-05-11 11:11:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\CardItem\CardItem.vue
-->


<template>
  <div
    @click="goDetail(data)"
    class="card-ani-box animated fadeInRight"
    :style="{ animationDelay: (itemIndex + 1) * .05 + 's' }"
  >
    <div
      class="card-item-box box-shadow"
      :class="{'is-more-info' : retailPrice}"
      :style="borderColor ? 'border-top:1px solid' + borderColor : ''"
    >
      <div class="to-car-box">
        <span class="car animated fadeInRight" title="加入购物车" @click.stop="addToCart">
          <i class="iconfont icon-shiwu-gouwuche"></i>
        </span>
        <!-- Y-》已收藏 -->
        <span
          class="collect animated fadeInRight"
          :title="(often || data.often) == 'Y' ? '取消收藏' : '加入收藏'"
          @click.stop="(often || data.often) == 'Y' ? deleteOftenProduct() : addOftenProduct()"
        >
<!--          <i-->
<!--            class="iconfont"-->
<!--            :class="(often || data.often) == 'Y' ? 'icon-yishoucang animated bounceIn' : 'icon-weishoucang'"-->
<!--          ></i>-->
          <img v-if="(often || data.often)  == 'Y'" src="./../../../assets/icon/2.png" alt="">
          <img v-if="(often || data.often) !== 'Y'" src="./../../../assets/icon/2-1.png" alt="">
        </span>
      </div>
      <span class="flag" :class="getFlagCls">{{ getFlagTitle }}</span>
      <p class="info-p">
        <span class="ghs" :title="orgName">{{ orgName }}</span>
      </p>
      <div class="img-box">
        <!-- <div class="img-box-in" :style="{ backgroundImage: 'url(' + imgUrl + picture + ')' }"></div> -->
        <div class="img-box-in">
          <h-img :imgUrl="picture" :errorImg="errorImg" isUseBasePath></h-img>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <span class="tag-box" v-if="isFenXiao == 'N' || isRebate">
            <h-tag v-if="(purchaseType =='2' || purchaseType =='3') && isFenXiao == 'N'" value="常"></h-tag>
            <h-tag v-if="(purchaseType =='1' || purchaseType =='3') && isFenXiao == 'N'" value="急"></h-tag>
            <h-tag v-if="isRebate" value="返利"></h-tag>
          </span>
          <p class="lsj" v-show="retailPrice && parseFloat(retailPrice).toString() != 'NaN'">
            建议零售价
            <span>￥{{retailPrice}}</span>
          </p>
        </div>
        <div class="right">
          <h5 class="amount">
            <span v-if="price  && price.toString().includes('.')" class="trueAmount">
              <i class="fh">￥</i>
              <span class="before">{{ price ? price.toString().split('.')[0] : 0 }}</span>
              <i class="dian">.</i>
              <span class="after">{{ price ? price.toString().split('.')[1] : 0 }}</span>
            </span>
            <span
              v-if="standardPrice && standardPrice != price && isFenXiao == 'N'"
              class="falseAmount"
            >￥{{ standardPrice }}</span>
          </h5>
        </div>
      </div>
      <div class="line">
        
        <p class="top" :title="productName">
          <span class="freeShipping" v-if="productLabel && productLabel.indexOf('15691143850') !== -1 ">免运费</span>
          <span class="bright" v-if="productLabel && productLabel.indexOf('15691143849') !== -1 ">璀璨</span>

          【{{ productName }}】</p>
        <p class="btm" v-show="importInfo" :title="importInfo">{{ importInfo }}</p>
      </div>
      <h6 class="num" v-if="isFenXiao == 'Y'">
        <!-- CIS-9899商品列表页面，分销商目前可以看到代理运营商的共享仓库存和原仓库存，改为只能看到共享仓库存-->
        <span v-if="(gicInvQty || gicInvQty == 0)&&data.agentShareFlag == 'Y'">共享仓{{gicInvQty}}</span>
        <span v-if="invQty || invQty == 0">{{data.agentShareFlag == 'Y' ? '自有仓' : '库存'}}{{invQty}}</span>
      </h6>
      <h6 class="num" v-else>
        <span v-if="inventory || inventory === 0">海信{{inventory}}</span>
        <span>我的库存{{Number(gicInvQty) + Number(invQty) || 0}}</span>
      </h6>
    </div>
  </div>
</template>

<script>
import { commImgUrl } from "@/common/constant.js";
// 导入api接口
import {
  saveCustBrowseRecord, //商家浏览记录接口
  // addToCart, //获取产品库存接口
  addOftenProduct, //加入购物车
  deleteOftenProduct //取消收藏
  // getCartNum,//获取购物车数量
  // getCartInfoList, //获取购物车列表接口
} from "@/utils/api.js";
// import {mapState} from 'vuex'
// import { number } from 'echarts/lib/export';
export default {
  name: "card-item-two",
  data() {
    return {
      imgUrl: "",
      often: "",
      defaultImg: 'this.src="' + require("@/assets/home/7.jpg") + '"'
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    orgName: {
      //供应商名称
      type: String,
      default: ""
    },
    productName: {
      //产品名称
      type: String,
      default: ""
    },
    importInfo: {
      //卖点信息
      type: String,
      default: ""
    },
    picture: {
      //图片地址
      type: String,
      default: ""
    },
    price: {
      //正常价 另外调用接口
      type: [String, Number]
    },
    standardPrice: {
      //划线价 另外调用接口
      type: [String, Number]
    },
    retailPrice: {
      //建议零售价 另外调用接口
      type: [String, Number]
    },
    //是否是分销商
    isFenXiao: {
      type: String,
      default: ""
    },
    inventory: {
      //海信库存 另外调用接口
      type: [String, Number]
    },
    sharedInv: {
      //海信共享库存 另外调用接口
      type: [String, Number]
    },
    invQty: {
      //代理商库存 自有仓 另外调用接口
      type: [String, Number]
    },
    gicInvQty: {
      //代理商库存 共享仓 另外调用接口
      type: [String, Number]
    },
    purchaseType: {
      //采购类型名称 常规采购（常）2||3 应急采购（急）1||3
      type: [String, Number]
    },
    active: {
      //新品 热卖 爆款
      type: String,
      default: ""
    },
    errorImg: {
      //报错图片
      type: String,
      default: ""
    },
    // retailPrice: {
    //   type: [String, Number],
    //   default: null
    // },
    itemIndex: {
      type: Number,
      default: 0
    },
    borderColor: {
      //头部边框颜色
      type: String,
      default: ""
    },
    // 免运费标识
    productLabel:{
      type: String,
      default: ""
    },
    // 是否显示返利标识
    isRebate: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.imgUrl = commImgUrl;
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    },
    getFlagCls: function() {
      let cls = "";
      if (this.active == "popularProduct") {
        cls = "bao";
      } else if (this.active == "hotProduct") {
        cls = "re";
      } else if (this.active == "newProduct") {
        cls = "xin";
      }
      return cls;
    },
    getFlagTitle: function() {
      let title = "";
      if (this.active == "popularProduct") {
        title = "爆款";
      } else if (this.active == "hotProduct") {
        title = "热卖";
      } else if (this.active == "newProduct") {
        title = "新品";
      }
      return title;
    }
  },
  methods: {
    setFlagStyle(idx) {
      let style = {};
      let colorList = ["#4285FF", "#FF8F3C", "#00AAA6", "#D9161C"];
      style.borderColor = colorList[idx];
      style.color = colorList[idx];
      return style;
    },
    flagWord: function(item) {
      let word = "";
      if (item == "0") {
        word = "常";
      } else if (item == "1") {
        word = "急";
      } else if (item == "2") {
        word = "新品";
      } else if (item == "3") {
        word = "促";
      }
      return word;
    },
    //点击跳转
    goDetail(data) {
      if (this.userInfo) {
        this.doSaveCustBrowseRecord(data);
      }
      // this.$router.push({
      //   path: "/product/detail",
      //   query: { productCode: data.productCode || data.productId, orgId: data.orgId }
      // });
      let routeUrl = this.$router.resolve({
        path: "/product/detail",
        query: {
          productCode: data.productCode || data.productId,
          orgId: data.orgId
        }
      });
      window.open(routeUrl.href, "_blank");
    },

    //商家浏览接口
    doSaveCustBrowseRecord(data) {
      let params = {
        productInfoId: data.productCode, //产品id
        custAccountId: this.userInfo.account.id, //商家账号id
        custInfoId: this.userInfo.customer.id, //商家id
        orgId: data.orgId, //组织id
        type: 0 //0 pc端 1 小程序
      };
      saveCustBrowseRecord(params)
        .then(res => {

        })
        .catch(error => {
          console.log(error);
        });
    },
    // //获取购物车数量
    // async getCart() {
    //   const cartNum = await getCartNum();
    //   this.$store.dispatch("user/cart", cartNum.data);
    // },
    // //获取购物车列表
    // async getCartInfoList() {
    //   try {
    //     const res = await getCartInfoList();
    //     console.log(res);
    //     if (res.data.code == "success") {
    //       this.$store.dispatch("user/cartObj", res.data);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    //加入购物车
    addToCart() {
      let params = {
        productId: this.data.productCode || this.data.productId,
        orgId: this.data.orgId,
        num: 1
      };
      this.$store.dispatch("user/addToCart", params);
      // addToCart(params)
      //   .then(res => {
      //     console.log(res);
      //     if (res.data) {
      //       this.$message.success("加入购物车成功");
      //       this.getCart();
      //       this.getCartInfoList()
      //     } else {
      //       this.$message.warning("加入购物车失败");
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     this.$message.warning("加入购物车失败");
      //   });
    },
    //加入收藏
    addOftenProduct() {
      let params = {
        id: this.data.productCode || this.data.productId,
        orgId: this.data.orgId
      };
      addOftenProduct(params)
        .then(res => {
          if (res.data == "Y") {
            this.often = "Y";
            this.$message.success("加入收藏成功");
          } else if (res.data == "N") {
            this.$message.info("您已收藏");
          }
        })
        .catch(error => {
          console.log(error);
          this.$message.warning("加入收藏失败");
        });
    },
    //取消收藏
    deleteOftenProduct() {
      let params = {
        id: this.data.productCode || this.data.productId,
        orgId: this.data.orgId
      };
      deleteOftenProduct(params)
        .then(res => {
          if (res.data.status == "true") {
            this.often = "N";
            this.$message.success("取消收藏成功");
          } else {
            this.$message.info("取消收藏失败");
          }
        })
        .catch(error => {
          console.log(error);
          this.$message.warning("取消收藏失败");
        });
    }
  }
};
</script>

<style lang="less" scoped>
// .hvr-float-shadow:hover {
//   &.animated {
//     transform: translateY(-5px) !important;
//   }
// }
.card-ani-box {
  margin-top: 12px;
  margin-left: 12px;
  float: left;
}
.card-ani-box:nth-of-type(4n + 1) {
  margin-left: 0;
}
.card-item-box {
  float: left;
  width: 288px;
  height: 390px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  text-align: center;
  .flag {
    position: absolute;
    top: 0;
    font-size: 12px;
    left: 12px;
    width: 48px;
    height: 20px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: none;
    color: #fff;
    &.bao {
      background-color: #fd6a6e;
      display: block;
    }
    &.re {
      background-color: #ff8f3c;
      display: block;
    }
    &.xin {
      background-color: #00aaa6;
      display: block;
    }
  }
  .to-car-box {
    position: absolute;
    width: 44px;
    right: 0;
    bottom: 46px;
    display: none;
    overflow: hidden;
    span {
      width: 44px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-radius: 22px 0 0 22px;
      float: left;
      padding-left: 6px;
      animation-duration: 0.3s;
      i {
        font-size: 22px;
      }
      &.car {
        background-color: #ecf8f8;
        padding-left: 4px;
        i {
          color: #00aaa6;
        }
      }
      &.collect {
        background-color: #fff0e6;
        margin-top: 16px;
        animation-delay: 0.1s;
        i {
          color: #ff8f3c;
          animation-duration: 0.6s;
        }
        img {
          width: 22px;
          height: 22px;
        }
      }
      &:hover {
        &.car i {
          color: #00b7b3;
        }
        &.collect i {
          color: #fb9a53;
          animation-duration: 0.6s;
        }
      }
    }
  }
  &:hover {
    .to-car-box {
      display: block;
      .collect i {
        animation-duration: 0s;
      }
    }
  }
  .img-box {
    width: 100%;
    height: 210px;
    overflow: hidden;
    .img-box-in {
      width: 210px;
      height: 210px;
      margin: 7px auto 0;
      // background: url("../../../assets/home/demo (1).png") no-repeat center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      // border: 1px solid #efefef;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .box{
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    margin-top: 4px;
    .left{
      display: flex;
      flex-direction: column;
      justify-content:space-between;
      align-items: flex-start;
      .tag-box {
        .htag {
          margin-right: 4px;
        }
        & + .lsj{
          margin-top: 8px;
        }
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      justify-content:space-between;
      align-items: flex-end;
    }
  }
  .line{
    margin-top: 12px;
    padding: 0 24px;
    line-height: 20px;
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: left;
     .top {
      height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: #1e1e1e;
      display: inline;
      margin-left: -6px;
      .freeShipping {
        margin-left: 6px;
        font-size: 12px;
        color: #fff;
        width: 42px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
        border-radius: 2px;
        font-weight: 400;
      }
       .bright {
         font-size: 12px;
         color: #433538;
         width: 30px;
         height: 16px;
         text-align: center;
         line-height: 16px;
         background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
         border-radius: 2px;
         margin-left: 4px;
         font-weight: 400;
       }
    }
    .btm {
      height: 12px;
      font-size: 12px;
      color: #777;
      margin-top: 8px;
      display: inline;
    }
  }
  p {
    // text-align: left;
    // width: calc(100% - 48px);
    // margin: 0 auto;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // text-align: left;
    &.info-p {
      display: block;
      // font-size: 12px;
      // line-height: 12px;
      // margin-top: 4px;
      color: #aaaaaa;
      width: calc(100% - 24px);
      // height: 30px;
      height: 16px;
      display: flex;
      // justify-content: space-between;
      justify-content: flex-end;
      align-items: center;
      margin-top: 16px;
      overflow: visible;
      .ghs {
        float: left;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        color: #00aaa6;
        line-height: 16px;
        margin-left: 16px;
      }
      .num {
        max-width: calc(100% - 100px);
        float: right;
        text-align: right;
        // line-height: 46px;
        font-size: 12px;
        height: 20px;
        line-height: 20px;
        border-radius: 2px;
        // padding: 0 4px;
        background: #f7f7f7;
        color: #aaa;

        span {
          padding-left: 4px;
          padding-right: 4px;
          + span {
            padding-left: 6px;
            padding-right: 4px;
          }
        }
      }
    }
   
    &.lsj {
      height: 12px;
      font-size: 12px;
      color: #777;
      line-height: 12px;
      // margin-top: 8px;
      overflow: visible;
      span {
        transform: translateY(1px);
      }
    }
  }

  h5.amount {
    // width: calc(100% - 48px);
    // height: 28px;
    font-size: 16px;
    font-weight: 500;
    // line-height: 28px;
    // height: 18px;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    .trueAmount {
      color: #d9161c;
      font-size: 16px;
      // font-weight: 600;
      height: 18px;
      display: flex;
      // justify-content: flex-end;
      align-items: flex-end;
      i,
      span {
        // float: left;
      }
      i.fh {
        font-size: 12px;
        width: 12px;
        line-height: 16px;
        // line-height: 30px;
        // height: 28px;
        // margin-right: 2px;
      }
      i.dian {
        display: flex;
        align-items: flex-end;
        // height: 29px;
      }
      span.before {
        font-size: 18px;
        line-height: 18px;
      }
      i.dian {
        line-height: 16px;
      }
      span.after {
        font-size: 12px;
        line-height: 14px;
        // height: 28px;
      }
    }
    .falseAmount {
      // margin-left: 8px;
      color: #7777;
      font-size: 12px;
      height: 14px;
      line-height: 14px;
      text-decoration: line-through;
      margin-top: 7px;
    }
  }

  h6.num {
    width: calc(100% - 48px);
    height: 12px;
    margin: 14px auto 0;
    text-align: left;
    font-size: 12px;
    line-height: 12px;
    color: #aaa;
    span {
      + span {
        padding-left: 8px;
      }
    }
  }
  .flag-box {
    height: 15px;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: inline-block;
      justify-content: center;
      align-items: center;
      height: 18px;
      line-height: 16px;
      box-sizing: border-box;
      padding: 0 7px;
      border-radius: 2px;
      border: 1px solid transparent;
      font-size: 12px;
      // float: left;
      + span {
        margin-left: 12px;
      }
      &.chang {
        color: #4285ff;
        border-color: #4285ff;
      }
      &.ji {
        color: #ff8f3c;
        border-color: #ff8f3c;
      }
      &.xin {
        color: #00aaa6;
        border-color: #00aaa6;
      }
      &.re {
        color: #f6bd16;
        border-color: #f6bd16;
      }
      &.bao {
        color: #fd6a6e;
        border-color: #fd6a6e;
      }
      &.cu {
        color: #ff8f3c;
        border-color: #ff8f3c;
      }
    }
  }
  // &.is-more-info {
  //   p {
  //     &.top {
  //       height: 14px;
  //       line-height: 14px;
  //       .htag {
  //         padding: 0 2px;
  //       }
  //     }
  //   }
  //   h5.amount {
  //     height: 18px;
  //     line-height: 18px;
  //     .trueAmount {
  //       color: #d9161c;
  //       i,
  //       span {
  //         float: left;
  //       }
  //       i.fh {
  //         font-size: 12px;
  //         width: 12px;
  //         line-height: 20px;
  //         height: 18px;
  //       }
  //       i.dian {
  //         height: 19px;
  //       }
  //       span.after {
  //         font-size: 12px;
  //         line-height: 22px;
  //         height: 18px;
  //       }
  //     }
  //     .falseAmount {
  //       margin-left: 8px;
  //       color: #aaa;
  //       font-size: 12px;
  //       text-decoration: line-through;
  //       margin-top: 2px;
  //     }
  //   }
  // }
}
</style>
