export default [
  {
    path: "/prototyZone",
    name: "prototyZone",
    redirect: "/PrototypeZone",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/prototyZone",
        component: () => import("@/views/prototyZone/index.vue"),
        meta: {
          title: "样机专区",
          keepAlive: false,
          isBarShow:true,
        }
      },
      // 确认订单页面
      {
        path: "/prototyZone/sumbitOrder",
        component: () => import("@/views/prototyZone/sumbitOrder/index.vue"),
        meta: {
          title: "确认订单",
          keepAlive: false,
          isBarShow:true,
        }
      },

      // 渠道样机订单详情
      {
        path: "/prototyZone/detailQD",
        component: () => import("@/views/prototyZone/orderDetailQD/index.vue"),
        meta: {
          title: "订单详情",
          keepAlive: false,
          isBarShow:true,
        }
      },
      // 海信订单明细-样机订单详情
      {
        path: "/prototyZone/detailHX",
        component: () => import("@/views/prototyZone/orderDetailHX/index.vue"),
        meta: {
          title: "订单详情",
          keepAlive: false,
          isBarShow:true,
        }
      },
      // 海信订单明细-样机订单详情
      {
        path: "/prototyZone/detailXS",
        component: () => import("@/views/prototyZone/orderDetailXS/index.vue"),
        meta: {
          title: "订单详情",
          keepAlive: false,
          isBarShow:true,
        }
      },
      {
        path: "/prototyZone/success",
        component: () => import("@/views/prototyZone/successPage/index.vue"),
        meta: {
          title: "提交成功页面",
        }
      }
    ]
  }
];
