<template>
  <div>
    <a-modal
      v-model="visible"
      wrapClassName="powerAttorneyOperation"
      :width="460"
      :closable="false"
      centered
      :maskClosable="false"
      :footer="null"
    >
      <a-spin :spinning="isLoading">
        <div class="cancelContent">
              <p class="text">本次为您首次签署电子版自提委托书，尚未授权上上签系统自动签章电子版委托书模板，上上签将发送签署短信，
                请您查阅短信进行签署。 为提升签署效率，建议您点击【去授权】进行自动签章授权，
                授权后再发起电子版自提委托书签署将由系统调用上上签进行自动签章。
              </p>
        </div>
        <div class="footer">
          <h-btn
            class="fristBtn btn"
            :width="120"
            :height="40"
            content="不授权签署"
            type="primary"
            fontSize="16"
            borderRadius="4"
            @click="gotoSign('0')"
          >
          </h-btn>
          <h-btn
            class="btn"
            :width="120"
            :height="40"
            content="去授权"
            type="primary"
            fontSize="16"
            borderRadius="4"
            @click="gotoSign('1')"
          >
          </h-btn>
        </div>
      </a-spin>

    </a-modal>
  </div>
</template>
<script>
export default {
  name: "powerAttorneyOperationModal",
  components: {},
  data() {
    return {
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
  },

  watch: {



  },
  mounted() {

  },
  methods: {
    gotoSign(data) {
      this.$emit("update:visible", false);
      this.$emit("cancelReasonHandOk",data );
    },
  }
};
</script>
<style lang="less" scoped>
/deep/ .powerAttorneyOperation {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;
    .ant-modal-body {
      padding: 57px 30px 40px 30px;
      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        .text {
          width: 400px;
          height: 174px;
          font-size: 18px;
          font-weight: 400;
          color: #262626;
          line-height: 30px;
          margin-bottom: 33px;
          text-align: center;
        }
      }
      .footer {
        .fristBtn {
          width: 120px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #00AAA6;
          color: #00AAA6;
          background: #fff;
          text-shadow: none;
          margin-right: 24px;
        }
      }
    }


  }
}
</style>


