/*
 * @Author: sq
 * @Date: 2020-10-15 13:42:38
 * @LastEditTime: 2020-11-18 18:13:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\discountsZone.js
 */

export default [
  // 特惠专区
  {
    path: "/preferential",
    name: "preferential",
    redirect: "/preferential",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/preferential",
        component: () => import("@/views/preferential/index.vue"),
        meta: {
          title: "特惠专区",
          keepAlive: false,
          isBarShow:true,
          filterConfigStr:'preferentialList'
        }
      }
    ]
  }
];
