<!--
 * @Author: sqk
 * @Date: 2020-09-16 10:11:15
 * @LastEditTime: 2024-05-09 09:05:46
 * @LastEditors: HIDESK\yanbenrong.ex ybr15650585591@163.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\Magnifying\Magnifying.vue
-->
<template>
  <div class="magnifying-box">
    <!-- <pic-zoom url="@/assets/home/banner.jpg" :scale="3"></pic-zoom> -->
    <div style="width: 550px;height: 555px;position: relative" v-if="activeSlideIdx == 0 && videoSrc" v-show="!isplayerShow">
      <video style="width: 100%;height: 100%;" :src="activeSlideUrl"></video>
      <img class="playButton" v-show="videoSrc && !isplayerShow" @click="play" src="https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/app/img/Image_20240523152358.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=38889001716382500&Signature=d4iZx2S8E2iTpUyQWIh4%2BOIiWTM%3D" alt />
    </div>
    <vue-photo-zoom-pro v-else v-show="!isplayerShow" :style="{ backgroundImage:'url(' + dftImg + ')'}" :out-zoomer="true" :url="activeSlideUrl" :scale="3">
    </vue-photo-zoom-pro>
    <div v-if="isplayerShow" class="video-player-box">
      <video  class="video-player" :src="videoSrc"  autoplay playsinline controls muted></video>
      <i class="el-icon-close close-player" @click="isplayerShow = false"></i>
    </div>
    
    <div class="swiper-box">
      <div class="swiper-container" ref="magnifyingSwiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            :class="activeSlideIdx == index ? 'active' : ''"
            v-for="(item,index) in swiperData"
            :key="index"
            @mouseenter="activeSlideFun(index,item)"
            @mouseleave="activeSlideOutFun"
          >
            <video v-if="typeof item == 'string'" class="video-player" :src="videoSrc"></video>
            <h-img
              v-else
              :imgUrl="item.imgUrl"
              :errorImg="item.defaultImgUrl"
              isUseBasePath
              errorHide
              @errorImgUrl="hideImg"
            ></h-img>
            <!-- <img :src="data[index]" alt /> -->
            <!-- <card-item :data="item" type="type2"></card-item> -->
          </div>
        </div>
        <!--箭头。如果放置在swiper-container外面，需要自定义样式。-->
      </div>
      <div class="swiper-button-prev" ref="prevEl"></div>
      <div class="swiper-button-next" ref="nextEl"></div>
    </div>
  </div>
  <!-- <div class="list">
          <ul>
            <li v-for="(item,index) in imagesList" 
                :key="index" 
                @mouseenter="changeImage(index)"
            >
                 <img :src="item" />
            </li>
          </ul>
  </div>-->
</template>

<script>
// import PicZoom from "vue-piczoom";
// import VuePhotoZoomPro from "vue-photo-zoom-pro";
import { mapState } from "vuex";
export default {
  name: "magnifying",
  components: {
    // PicZoom
    // VuePhotoZoomPro
  },
  data() {
    return {
      // activeSlideUrl: require("@/assets/home/banner.jpg"),
      activeSlideUrl: '',
      activeSlideIdx: 0,
      timer: "", //定时器 延时展示图片 避免误操作
      isplayerShow: false
    };
  },
  computed: {
    ...mapState({
      productImgBaseUrl: state =>
        state.user.productImgBaseUrl
          ? state.user.productImgBaseUrl
          : localStorage.getItem("productImgBaseUrl")
    }),
    swiperData(){
      let arr;
      if(this.videoSrc){
        arr = [this.videoSrc, ...this.data];
      }else{
        arr = this.data;
      }
      this.$nextTick(() => {
        this.activeSlideUrl = arr
          ? this.videoSrc ? this.videoSrc : this.productImgBaseUrl + arr[0].imgUrl
          : require("@/assets/home/banner.jpg");
      });
      return arr;
    }
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    dftImg: {
      type: String,
      default: ''
    },
    videoSrc: {
      type: String,
      default: ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.swiperInit();
        this.activeSlideUrl = this.swiperData
          ? this.videoSrc ? this.videoSrc : this.productImgBaseUrl + this.swiperData[0].imgUrl
          : require("@/assets/home/banner.jpg");
      });
    }, 500);
  },
  methods: {
    //隐藏不展示的图片
    hideImg(errorImg) {
      for (const idx in this.swiperData) {
        if (this.swiperData[idx].imgUrl == errorImg) {
          this.swiperData.splice(idx, 1);
        }
      }
    },
    // 播放视频
    play(){
      this.isplayerShow = true
    },
    activeSlideFun(idx, item) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.activeSlideIdx = idx;
        this.activeSlideUrl = typeof item == "string" ? item : this.productImgBaseUrl + item.imgUrl;
        clearTimeout(this.timer);
      }, 300);
    },
    activeSlideOutFun() {
      clearTimeout(this.timer);
    },
    //缩略图初始化
    swiperInit() {
      let this_ = this;
      new this.$Swiper(this.$refs.magnifyingSwiper, {
        on: {
          slideChange: function() {
          },
          click: () => {
            // console.log(mySwiper.clickedSlide.getAttribute("data-idx"));
            // alert("你点了Swiper");
          }
        },
        onlyExternal: true,
        // noSwiping : true,
        // simulateTouch: false,//鼠标模拟手机触摸。默认为true，Swiper接受鼠标点击、拖动。
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 8,
        // preventLinksPropagation:false,//防止冒泡。
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        navigation: {
          nextEl: this_.$refs.nextEl,
          prevEl: this_.$refs.prevEl
        }
      });
    }
  }
};
</script>

<style lang="less">
.magnifying-box {
  position: relative;
  .video-player{
      width: 100%;
      height: 100%;
    }
  .photo-zoom-pro {
    width: 550px;
    // height: 565px;
    height: 555px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .playButton{
    width: 54px;
    height: 54px;
    position: absolute;
    left: 250px;
    top: 250px;
    cursor: pointer;
  }
  .video-player-box{
    width: 550px;
    height: 555px;
    position: relative;
    background-color: #000;
    .close-player{
      position: absolute;
      top: 20px;
      right: 20px;
      color: #fff;
      cursor: pointer;
    }
  }
  .img-out-show {
    border: none;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 999;
  }
  .swiper-box {
    position: relative;
    overflow: hidden;
    .swiper-container {
      // margin-left: 12px;
      width: 472px;
      height: 92px;
      float: left;
      // padding: 0px 0 28px;
      box-sizing: content-box;
      margin-top: 29px;
      margin-left: 39px;
      .swiper-slide {
        width: 88px;
        height: 92px;
        background: #ffffff;
        // border: 1px solid #eee;
        cursor: pointer;
        &.active {
          img,.video-player {
            border: 1px solid #00aaa6;
          }
        }
        img,.video-player {
          width: 100%;
          height: 100%;
          border: 1px solid #eee;
        }
      }
    }
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 0.2 !important;
    }
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 50%;
      width: 32px;
      height: 48px;
      margin-top: -24px;
      z-index: 10;
      cursor: pointer;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      outline: none;
      transition: all 0.25s;
      display: block;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      background-image: url("./arrow-left.png");
      left: 0px;
      top: auto;
      right: auto;
      bottom: 22px;
      background-size: 9px 17px;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      background-image: url("./arrow-right.png");
      right: 0px;
      top: auto;
      top: auto;
      bottom: 22px;
      background-size: 9px 17px;
    }
  }
}
</style>
