/*
 * @Author: sunqingkun
 * @Date: 2020-11-17 16:08:23
 * @LastEditTime: 2024-08-20 15:49:21
 * @LastEditors: 尚宏浩 aaa@hisense.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\store\modules\filtrate.js
 */

import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
// import router from "@/router";
import store from "@/store";

import util from "@/views/product/components/util";
import {
  systemConfigFormItem, //获取筛选列表接口
} from "@/utils/api.js";
const state = {
  isFenXiao: "N", //是否是分销商 Y-》是-》渠道采购    N-》否-》海信采购
  filterConfigStr: "", //调用筛选接口的入参
  filList: [], //筛选组件数据
  isLoading: false, //筛选组件loading状态
  filIsChange: false,
  simulateData: [], //模拟假数据
  // productDftFilList:[],//商品橱窗 默认选中列表
};

const mutations = {
  SET_IS_CHANGE: (state, filIsChange) => {
    state.filIsChange = filIsChange;
  },
  SET_CONFIG_STR: (state, params) => {
    console.log('SET_CONFIG_STR', params)
    let filterConfigStr = "";
    state.filterConfigStr = "";
    //获取用户信息
    let userInfo = store.state.user.userInfo
      ? store.state.user.userInfo
      : localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : "";
    // let userInfo = store.state.user.userInfo
    // ? store.state.user.userInfo
    // : JSON.parse(localStorage.getItem("userInfo"));
    //渠道采购海信采购编号字符串 17451是渠道采购，17452或者17453 海信采购 （可能同时存在）.parentInput.indexOf(",") != -1
    console.log('userInfo', userInfo)
    if (!userInfo) {
      return;
    }
    let marketModels;

    if (params.marketModels) {
      marketModels = params.marketModels;
    } else {
      marketModels = userInfo.account.marketModels;
    }
    //海信采购
    if (
      marketModels.indexOf("17452") != -1 ||
      marketModels.indexOf("17453") != -1
    ) {
      state.isFenXiao = "N";

      //       ordersCenter    订单中心
      // cartList    购物车
      // enginerList    工程订单
      // preferentialList    特惠订单
      // activityList    活动订单
      // packageList        套购订单
      // custMsgList    通知列表
      // custTaskList    待办列表
      // productList+catalogId 产品列表
      // announcementList 公告列表
      // questionMineList 我的咨询

      //常规应急占比 statisticsProportion
      switch (params.toPath.path) {
        case "/order/cis":
          //订单中心
          filterConfigStr = "ordersCenter";
          break;
        case "/channelOrder/distributorPurchase":
          //分销直采订单
          filterConfigStr = "ordersCenter";
          break;
        case "/order/dms":
          //订单中心
          filterConfigStr = "dmsOrderList";
          break;
        case "/cart":
          filterConfigStr = "cartList";
          break;
        case "/engineering":
          filterConfigStr = "enginerList";
          break;
        case "/preferential":
          filterConfigStr = "preferentialList";
          break;
        case "/activity":
          filterConfigStr = "activityList";
          break;
        case "/package":
          filterConfigStr = "packageList";
          break;
        case "/center/notice":
          filterConfigStr = "custMsgList";
          break;
        case "/center/todoList":
          filterConfigStr = "custTaskList";
          break;
        case "/product":
          filterConfigStr = "productList";
          break;
        case "/center/announcement":
          filterConfigStr = "announcementList";
          break;
        case "/center/consultationComplaint":
          filterConfigStr = "questionMineList";
          break;
        case "/statistics":
          filterConfigStr = "statisticsProportion";
          break;
        // 收藏夹
        case "/favorites":
          filterConfigStr = "cartOftenProductList";
          break;
        // 下载中心
        case "/center/loadCenter":
          // filterConfigStr = "cartList";
          break;
        case "/reports/operation":
          //运营商结构报表
          filterConfigStr = "reportStructure";
          break;
        case "/reports/purchase":
          //采购报表
          filterConfigStr = "salesReportList";
          break;
        case "/center/consultNewPage":
          filterConfigStr = "custProblem";
          break;
        default:
          break;
      }
      state.filterConfigStr = filterConfigStr;
    } else {
      //渠道采购
      state.isFenXiao = "Y";
      switch (params.toPath.path) {
        case "/order/cis":
          //订单中心
          filterConfigStr = "ordersCenter";
          break;
        case "/channelOrder/distributorPurchase":
          //分销直采订单
          filterConfigStr = "ordersCenter";
          break;
        case "/order/dms":
          //订单中心
          filterConfigStr = "dmsOrderList";
          break;
        case "/cart":
          filterConfigStr = "cartList";
          break;
        case "/engineering":
          filterConfigStr = "enginerList";
          break;
        case "/preferential":
          filterConfigStr = "preferentialList";
          break;
        case "/activity":
          filterConfigStr = "activityList";
          break;
        case "/package":
          filterConfigStr = "packageList";
          break;
        case "/center/notice":
          filterConfigStr = "custMsgList";
          break;
        case "/center/todoList":
          filterConfigStr = "custTaskList";
          break;
        case "/product":
          filterConfigStr = "productList";
          break;
        case "/center/announcement":
          filterConfigStr = "announcementList";
          break;
        case "/center/consultationComplaint":
          filterConfigStr = "questionMineList";
          break;
        case "/statistics":
          filterConfigStr = "statisticsProportion";
          break;
        // 收藏夹
        case "/favorites":
          filterConfigStr = "cartOftenProductList";
          break;
        // 下载中心
        case "/center/loadCenter":
          // filterConfigStr = "cartList";
          break;
        case "/reports/operation":
          //运营商结构报表
          filterConfigStr = "reportStructure";
          break;
        case "/reports/purchase":
          //采购报表
          filterConfigStr = "salesReportList";
          break;
        case "/center/consultNewPage":
          filterConfigStr = "custProblem";
          break;
        default:
          break;
      }
      state.filterConfigStr = filterConfigStr;
    }
  },
  SET_FILLIST: (state, filList) => {
    state.filList = filList;
  },
  SET_SIMULATE_DATA: (state, simulateData) => {
    state.simulateData = simulateData;
  },
  SET_FILLOADING: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  // SET_PRODUCT_DFT_LIST: (state, productDftFilList) => {
  //   state.productDftFilList = productDftFilList;
  // },
};

const actions = {
  systemConfigFormItem({ dispatch, commit, state }) {
    console.log('state', state)
    if (!state.filterConfigStr) {
      if (state.simulateData && state.simulateData.length > 0) {
        dispatch("getNewArr", state.simulateData).then((arr) => {
          commit("SET_FILLIST", arr);
          commit("SET_FILLOADING", false);
          // resolve(arr);
        });
      }
    } else {
      //筛选请求开始 -> 开启loadidng
      commit("SET_FILLOADING", true);
      //商品橱窗的筛选需要加上catalogId -》 productList+catalogId 产品列表 其他无需多加
      let catalogId =
        store.state.user.toPath.path == "/product"
          ? store.state.user.toPath.query.catalogId
            ? store.state.user.toPath.query.catalogId
            : ""
          : "";
      let param = {
        //   formCode: "productList1",
        // formCode: store.state.user.toPath.meta.filterConfigStr + catalogId,
        formCode: state.filterConfigStr + catalogId,
        filter: store.state.user.toPath.query.filter
          ? store.state.user.toPath.query.filter
          : "",
      };
      return new Promise((resolve) => {
        systemConfigFormItem(param)
          .then((res) => {
            if (res.status == 200 && res.data.code == 0) {
              //异步获取二次请求数据后拼接列表数据


              dispatch("getNewArr", res.data.systemConfigForm.paramList)
                // dispatch('getNewArr',util.windowInitList.systemConfigForm.paramList)
                .then((arr) => {
                  // commit("SET_FILLIST", []);
                  // dispatch('getNewArr',util.windowInitList.systemConfigForm.paramList).then((arr) => {

                  commit("SET_FILLIST", arr);
                  commit("SET_FILLOADING", false);
                  // setTimeout(()=>{
                  //   commit("SET_FILLIST", arr);
                  // },500)

                  //返回res
                  resolve(res);
                })
                .catch((error) => {
                  console.log(error);
                  commit("SET_FILLOADING", false);
                });
            } else {
              //关闭loadidng
              commit("SET_FILLOADING", false);
            }
          })
          .catch(() => {
            commit("SET_FILLOADING", false);
          });
      });
    }
  },

  // 筛选数据重构
  async getNewArr(content, paramList) {
    //新格式数组拼装
    let paramListNewArr = [];
    for (const key in paramList) {
      //判断是否有可选项
      // if (paramList[key].dictList && paramList[key].dictList.length > 0) {
      let paramListItemObj = {};
      //DMS接口传参使用 Gxx 或 orgId 等
      paramListItemObj.targetId = paramList[key].field;
      //DMS接口传参使用 sqlField
      paramListItemObj.sqlField = paramList[key].sqlField;
      //DMS接口传参使用 fieldType
      paramListItemObj.fieldType = paramList[key].fieldType;
      //DMS接口传参使用 logic
      paramListItemObj.logic = paramList[key].logic;
      //DMS接口传参使用 value  or  默认值 初始化筛选用
      paramListItemObj.defaultValue = paramList[key].value;
      //DMS接口传参使用 autoQuery  用于dms反参是哪种格式 1-》拼接 （将DMS接口传参拼入paramList中传出） 2-》带入 与cis规则相同 放入到整体入参中
      paramListItemObj.autoQuery = paramList[key].autoQuery;
      //DMS接口传参使用 multiple or 是否多选 2 多选   1 单选
      paramListItemObj.multiple = paramList[key].multiple;
      //DMS接口传参使用 extSql
      paramListItemObj.extSql = paramList[key].extSql;

      //筛选条件组名称
      paramListItemObj.title = paramList[key].name;
      //根据inputClass = producttag 是-》混入filter入参字段当入参 否-》单个 入参
      paramListItemObj.inputClass = paramList[key].inputClass;
      //文本框类型 hidden隐藏 其他暂不使用
      paramListItemObj.inputType = paramList[key].inputType;

      //请求数据的入参 - 》上一层级字段 -》 无则直接请求接口 有则当作get请求入参
      paramListItemObj.parentInput = paramList[key].parentInput;
      //选项之间发生联动后 二次请求的路径
      paramListItemObj.dictURL = paramList[key].dictURL;
      //根据dictType 判断是否二次请求 2 -》 二次请求
      if (paramList[key].dictType == "2") {
        let params = {};
        //如果parentInput有值则参数拼装并传空 然后空查
        if (paramList[key].parentInput) {
          // 如果是商品橱窗
          if (store.state.user.toPath.path == "/product") {
            //如果页面是商品橱窗列表 则从页面中获取到catalogId和keyword 如果是供应商 入参为catalogId和keyword
            if (paramList[key].field == "orgId") {
              params.catalogId = store.state.user.toPath.query.catalogId || "";
              params.keyword = store.state.user.toPath.query.keyword || "";
            } else {
              //如果页面不是商品橱窗列表 则拼装空参
              let paramArr = paramList[key].parentInput.split(",");
              for (const key in paramArr) {
                let paramItem = "filter" + (parseInt(key) + 1).toString();
                //入参赋空值 {filter1:'',filter2:''}
                params[paramItem] = "";
              }
            }
          } else {
            //如果页面不是商品橱窗列表 则拼装空参
            let paramArr = paramList[key].parentInput.split(",");
            for (const key in paramArr) {
              let paramItem = "filter" + (parseInt(key) + 1).toString();
              //入参赋空值 {filter1:'',filter2:''}
              params[paramItem] = "";
            }
          }
        }
        //二次请求 并拼装数据
        let res = await http({
          method: "get",
          url: paramList[key].dictURL,
          params,
        });
        // if (res.status == 200 && res.data.code == 0) {
        paramList[key].dictList = res.data.list;
        // }
      }else if (paramList[key].dictType == "4") {
        let params = {
          formCode: state.filterConfigStr,
          field: paramList[key].field
        };
        //二次请求 并拼装数据
        let res = await dmshttp({
          url: 'getConditionOptions',
          data: params,
        })
        if (res) {
          paramList[key].dictList = JSON.parse(res.data).data;
          // if(res && res.data&& res.data.code == 1){
          //   this.$message.error(res.msg )
          // }else{
          //   if(res.data && JSON.parse(res.data).code == 0){
          //     paramList[key].dictList = JSON.parse(res.data).data;
          //   }
          // }
        }
      }

      //排序 暂不用
      paramListItemObj.sortNo = paramList[key].orderNo;
      //是否展示更多按钮
      paramListItemObj.isShowMoreBtn = false;
      //是否展示更多
      paramListItemObj.isShowMoreFlag = false;

      //可选项list拼装
      // if(paramListItemObj.targetId == '01010-G70'){
      // if(paramListItemObj.inputType != 'dateGroup'){
      //   paramListItemObj.list = actions.getNewList(paramList[key].dictList);
      // }else{
      //   paramListItemObj.list = actions.getNewList(paramList[key].dictList,paramListItemObj.targetId);
      // }

      paramListItemObj.list = actions.getNewList(
        paramList[key].dictList,
        paramListItemObj.inputType
      );

      paramListNewArr.push(paramListItemObj);
      // }
    }
    return paramListNewArr;
  },
  //获得新格式的 dictList 数组
  getNewList(paramList, inputType) {
    let newArr = [],
      data = {
        id: "",
        name: "全部",
        isActive: true,
      };
    for (let key in paramList) {
      //去除无名项
      if (!paramList[key].name) {
        continue;
      }
      newArr.push({
        id: paramList[key].code,
        name: paramList[key].name,
        isActive: false,
      });
    }

    if (inputType == "dateGroup") {
      // if(inputType == '01010-G70' || inputType == '0101-G11' ){
      let obj = {
        id: "",
        name: "自定义",
        isActive: false,
        type: "dateGroup",
      };
      newArr.push(obj);
    }
    // if(state.filterConfigStr != 'statisticsProportion'){
    newArr.unshift(data);
    // }
    return newArr;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
