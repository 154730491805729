/*
 * @Author: your name
 * @Date: 2020-11-04 22:09:18
 * @LastEditTime: 2022-08-11 22:06:56
 * @LastEditors: xiaojie 1264801634@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\activity.js
 */
export default [
  {
    path: "/activity",
    name: "activity",
    redirect: "/activity",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/activity",
        component: () => import("@/views/activity/index.vue"),
        meta:{
          title:"活动专区", 
          keepAlive: false,
          isBarShow:true,
          filterConfigStr:'activityList'
        },
      },
     
    ]
  },
  {
    path: "/logins",
    name: "logins",
    redirect: "/logins",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/logins",
        component: () => import("@/views/im/login.vue"),
        meta:{
          title:"活动专区", 
          keepAlive: false
        },
      },
    ]
  },
  {
    path: "/activityConfigurationList",
    name: "activityConfigurationList",
    redirect: "/activityConfigurationList",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/activityConfigurationList",
        component: () => import("@/views/activityConfigurationList/index.vue"),
        meta:{
          title:"营销活动列表", 
          keepAlive: false,
          filterConfigStr:'activityConfigurationList'
        },
      },
      {
        path: "/activityConfigurationList/add",
        component: () => import("@/views/activityConfigurationList/add/index.vue"),
        meta:{
          title:"新增活动", 
          keepAlive: false,
        },
      },
      {
        path: "/activityConfigurationList/edit",
        component: () => import("@/views/activityConfigurationList/edit/index.vue"),
        meta:{
          title:"编辑活动", 
          keepAlive: false,
        },
      },
      {
        path: "/activityConfigurationList/openView",
        component: () => import("@/views/activityConfigurationList/openView/index.vue"),
        meta:{
          title:"查看详情", 
          keepAlive: false,
        },
      },
    ]
  }
];
