import http from "@/utils/request";
/**
 * 配置显示列
 * @param {
	 key: string
	 userDate: string
 } data
 */
export function setRows(data) {
    return http({
		method: "post",
        url: '/custbase/saveSelfConfig.nd',
        data
    })
}

/**
 * 获取配置的显示列
 * @param {key} data
 */
export function getRows(data) {
    return http({
		method: "post",
        url: '/custbase/querySelfConfig.nd',
        data
    })
}