<!--
 * @Author: sq
 * @Date: 2020-10-23 17:42:18
 * @LastEditTime: 2021-01-21 14:49:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\img\index.vue
-->
<template>
  <div class="no-data-box" :class="size">
    <img src="@/assets/order/noList.png" alt="">
    <p>
      {{content}}
    </p>
  </div>
</template>
<script>
// import { mapState } from "vuex";
import { defaultImgPath } from "@/common/constant.js";
export default {
  name: "no-data-panel",
  // computed: {
  //   ...mapState({
  //     productImgBaseUrl: state =>
  //       state.user.productImgBaseUrl
  //         ? state.user.productImgBaseUrl
  //         : localStorage.getItem("productImgBaseUrl")
  //   })
  // },
  data() {
    return {
    };
  },
  watch: {
  },
  props: {
    content: {
      //无数据提示文字
      type: String,
      default: "暂无数据"
    },
    size: {
      //无数据提示文字
      type: String,
      default: "small"
    },
  },
  methods: {
  }
};
</script>
<style lang="less" scoped>
.no-data-box{
  width: 100%;
  height:100%;
  background: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.mid{
    img{
      width: 160px;
      height: 120px;
      // margin: 48px auto 16px;
    }
    p{
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #777777;
      line-height: 20px;
      margin-top: 20px;
    }
  }
  img{
    display: block;
    width: 100px;
    height: 74px;
  }
  p{
    height: 18px;
    font-size: 12px;
    font-weight: 400;
    color: #aaa;
    line-height: 18px;
    margin-top: 8px;
  }
}
</style>
