<template>
  <a-modal v-model="isVisible" title class="table-model" @cancel="cancel" :footer="null">
    <div class="close-icon" slot="closeIcon" title="关闭">
      <i class="iconfont icon-danchuangguanbi-anniu"></i>
    </div>
    <a-spin :spinning="isLoading" size="large">
      <slot name="heade-top" />
<!--      <a-table-->
<!--        class="my-table"-->
<!--        rowKey="uid"-->
<!--        :scroll="{ y: 530 }"-->
<!--        :columns="columns"-->
<!--        :data-source="filterData"-->
<!--        :pagination="false"-->
<!--        :row-selection="rowSelection"-->
<!--        @change="onTableChange"-->
<!--      >-->
<!--        <span slot="count" slot-scope="borderedQty, row">-->
<!--          <number-box-->
<!--            class="number-box"-->
<!--            nouse="borderedQty"-->
<!--            size="small"-->
<!--            :curVal.sync="row.borderedQty"-->
<!--            :maxVal="row.bigQty"-->
<!--          />-->
<!--        </span>-->
<!--      </a-table>-->
      <hi-table
          ref="tb"
          :data="filterData"
          :header="columns"
          border
          :showCheck="true"
          :autoWidth="false"
          fit
          :checkSelectable="checkSelectable"
          highlight-current-row
          @query="onTableChange"
          @selection-change="selectionChange"
      >
        <template slot="count" slot-scope="scope">
          <number-box
              class="number-box"
              nouse="borderedQty"
              size="small"
              :curVal.sync="scope.row.borderedQty"
              :maxVal="scope.row.bigQty"
          />
        </template>
        <!--        <span slot="btnGroup" slot-scope="scope"> {{ scope.$index }}</span>-->
      </hi-table>
      <div class="page-check-wrap clearfix">
        <div class="check-wrap">
          <a-checkbox @change="onlyShowCheckedChange" :checked="onlyShowChecked">
            <span style="padding-left:8px">仅查看已选</span>
          </a-checkbox>
        </div>
        <div class="footer-pager">
          <!-- <slot name="pagination" /> -->
          <pagination :pager="pager" :bgType="2" @query="getData" />
        </div>
      </div>
      <div class="footer-btn-wrap">
        <h-btn class="h-btn" :fontSize="16" :width="188" :height="48" @click="submit">确&nbsp;&nbsp;认</h-btn>
        <h-btn
          class="h-btn"
          :fontSize="16"
          :width="188"
          :height="48"
          customType="3"
          @click="cancel"
        >取&nbsp;&nbsp;消</h-btn>
      </div>
    </a-spin>

    <!-- <div style="clear:both;width:100%;"></div> -->
  </a-modal>
</template>

<script>
// import { getProductList } from "./api";

export default {
  name: "tableModal",
  props: {
    //列表表头数据
    columns: {
      type: Array,
      default: () => []
    },
    //列表内容
    dataList: {
      type: Array,
      default: () => []
    },
    //是否显示
    isVisible: {
      type: Boolean,
      default: false
    },

    //加载状态
    isLoading: {
      type: Boolean,
      default: false
    },
    rowSelectionType: {
      type: String,
      default: "checkbox" //radio
    },
    sumKey: {
      type: String,
      default: "" //radio
    },
    sumWord: {
      type: String,
      default: "总计" //radio
    },
    //页
    pager: {
      type: Object,
      default: () => {}
    },
    //页
    type: {
      type: String
    }
  },
  data() {
    return {
      //显示已选
      onlyShowChecked: false
    };
  },
  computed: {
    //表格数据/切换只显示勾选使用
    filterData() {
      if (this.onlyShowChecked) {
        const productCodes = this.selRows.map(it => it.uid);
        const resu = this.dataList.filter(it => {
          return productCodes.includes(it.uid);
        });
        return resu;
      } else {
        return this.dataList;
      }
    },
    rowSelection() {
      return {
        type: this.rowSelectionType,
        fixed: true,
        columnWidth:74,
        onChange: (selectedRowKeys, selectedRows) => {

          this.selRows = selectedRows;
        },
        getCheckboxProps: record => ({
          props: {
            // disabled: record.borderedQty == 0, // Column configuration not to be checked
            // disabled: record.model === 'LED50K310X3D', // Column configuration not to be checked
            disabled:
              record.borderedQty < 1 || record[this.sumKey] == this.sumWord, // Column configuration not to be checked
            name: record.name
          }
        })
        //  getCheckboxProps: record => {
        //  },
      };
    }
  },
  watch: {},
  methods: {
    selectionChange(selection){
      if(this.rowSelectionType=='checkbox'){
        this.selRows = selection
      }else{
        if(selection.length>1){
          this.$refs.tb.$refs.tabInst.clearSelection()
          this.$refs.tb.$refs.tabInst.toggleRowSelection(selection.pop())
        }
        this.selRows = selection
      }
    },
    checkSelectable(row) {
      // return true
      return row.invStatusName != '正品'||this.type == 'sale'
    },
    //取消或者关闭
    cancel() {
      this.$emit("update:isVisible", false);
    },
    //行选中，取消
    onChange(selKeys, selRows) {
      this.selRows = selRows;
    },
    //排序change事件
    onTableChange(pagination, filters, sorter, currentDataSource) {
      this.$emit("change", [pagination, filters, sorter, currentDataSource]);
    },
    //只显示勾选
    onlyShowCheckedChange(e) {
      this.onlyShowChecked = e.target.checked;
    },
    getData() {
      this.onlyShowChecked = false;
      this.$emit("search", this.pager);
    },
    //确认按钮
    submit() {
      if (this.selRows.length == 0) {
        this.$message.warning("请选择产品!");
        return;
      }
      const selRows = JSON.parse(JSON.stringify(this.selRows));
      this.$emit("submit", selRows);
      this.$emit("update:isVisible", false);
    }
  }
};
</script>

<style lang="less">
.table-model {
  .ant-table-body{
    overflow: auto !important;
  }
  .ant-modal-body {
    padding: 30px 24px;
  }
  .ant-modal-close-x {
    width: 42px;
    height: 42px;
    border-top-right-radius: 10px;
    .close-icon {
      width: 42px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      i {
        //color: #00aaa6;
        font-size: 16px;
      }
    }
    &:hover {
      background: #e4f8f7;
      i {
        color: #00aaa6;
      }
    }
  }

  .ant-modal-content {
    border-radius: 10px;
  }
  .heade-top {
    margin-bottom: 20px;

    span {
      font-size: 14px;
      font-weight: 500;
      color: #262626;
      line-height: 20px;
    }
    .count-span {
      font-size: 14px;
      font-weight: 400;
      color: #777777;
      line-height: 20px;
      margin-left: 24px;
    }
    .ant-input-affix-wrapper {
      margin-left: 12px;
      width: 270px;
      height: 32px;
      .ant-input-suffix {
        width: 32px;
        height: 32px;

        right: 4px;
        i {
          width: 100%;
          color: #aaa;
          text-align: center;
        }
      }
    }

     .ant-input {
      width: 270px;
      height: 32px;
      display: inline-block;

      border: 1px solid #eee;
    }

     .ant-input:hover {
      box-shadow: 0px 0px 3px 2px rgba(152, 251, 248, 0.34);
      border-radius: 4px;
      border: 1px solid #00aaa6;
    }
  }

   .ant-modal {
    width: 1188px !important;
    margin: 0 auto !important;

    .ant-modal-title {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: #262626 !important;
      line-height: 20px !important;
    }

    .ant-modal-body .ant-table-column-title {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .page-check-wrap {
    margin-top: 24px;
    margin-bottom: 30px;
    height: 32px;
    .check-wrap {
      float: left;
      padding-top: 6px;
    }
    .footer-pager {
      float: right;
      .pagination-wrap {
        padding: 0;
      }
    }
  }
  .footer-btn-wrap {
    display: flex;
    justify-content: center;

    .h-btn {
      margin: 0 15px;
    }
  }

   .ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
}
</style>
