
<template>
  <div class="tool-table-box">
    <div class="topTitle">
      <div>
        <a-button
          type="primary"
          v-for="item in oneOperation"
          :key="item.text"
          @click="item.func(item.bind)"
        >{{item.text}}</a-button>
        <a-select
          v-if="false"
          class="buttonSelect"
          value="批量操作"
          style="width: 160px; padding-right:10px"
        >
          <a-select-option
            :key="item.text"
            :value="item.text"
            v-for="item in batchOperation"
            @click="item.func(item.bind)"
          >{{item.text}}</a-select-option>
        </a-select>
        <a-tooltip placement="top">
          <!-- <template slot="title">
            <span>列设置</span>
          </template>-->

          <a-popover
            placement="bottomRight"
            overlayClassName="table-tool-box"
            trigger="click"
            v-if="isToolView"
          >
            <!-- <a-icon class="topIcon" type="setting"></a-icon> -->
            <h-btn class="auto-btn" :width="110" :height="28">调整显示的列</h-btn>
            <!-- <a-icon class="topIcon" type="setting"></a-icon> -->
            <template slot="content">
              <h-scroll class="checkGroupBox" :settings="scrollSettings">
                <a-checkbox-group
                  class="popoCheckBox"
                  v-model="relShowColumns"
                  @change="changeColumn"
                >
                  <a-row v-if="haveLeft">
                    <a-col class="moveParent padBot">固定在左侧</a-col>
                  </a-row>
                  <a-row
                    v-for="col in editColumns"
                    :key="col.dataIndex +'left'"
                    v-show="col.optionType == 'left'"
                  >
                    <a-col
                      class="moveParent"
                      draggable="true"
                      @dragstart="dragstartItem(col,'left')"
                      @dragover="dragoverItem(col,$event)"
                      @drop.stop="dropItem(col,'left')"
                    >
                      <a-checkbox :value="col.dataIndex"></a-checkbox>
                      {{col.title}}
                      <span class="ding" style="float: right">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span style="font-size: 12px">取消固定</span>
                          </template>
                          <a-icon
                            @click="leftOrRight('cancel',col.dataIndex)"
                            type="vertical-align-middle"
                            style="transform: rotate(-90deg);"
                          ></a-icon>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span style="font-size: 12px">固定到右边</span>
                          </template>
                          <a-icon @click="leftOrRight('right',col.dataIndex)" type="pushpin"></a-icon>
                        </a-tooltip>
                      </span>
                    </a-col>
                  </a-row>
                  <a-row v-if="haveLeft || haveRight">
                    <a-col class="moveParent paddtop padBot">不固定</a-col>
                  </a-row>
                  <a-row
                    v-for="col in editColumns"
                    :key="col.dataIndex +'middle'"
                    v-show="!col.optionType"
                  >
                    <a-col
                      class="moveParent"
                      draggable="true"
                      @dragstart="dragstartItem(col,'main')"
                      @dragover="dragoverItem(col,$event)"
                      @drop.stop="dropItem(col,'main')"
                    >
                      <a-checkbox :value="col.dataIndex"></a-checkbox>
                      {{col.title}}
                      <span class="ding" style="float: right">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span style="font-size: 12px">固定到左边</span>
                          </template>
                          <a-icon
                            @click="leftOrRight('left',col.dataIndex)"
                            type="pushpin"
                            style="transform: rotate(-90deg);"
                          ></a-icon>
                        </a-tooltip>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span style="font-size: 12px">固定到右边</span>
                          </template>
                          <a-icon @click="leftOrRight('right',col.dataIndex)" type="pushpin"></a-icon>
                        </a-tooltip>
                      </span>
                    </a-col>
                  </a-row>
                  <a-row v-if="haveRight">
                    <a-col class="moveParent paddtop padBot">固定在右侧</a-col>
                  </a-row>
                  <a-row
                    v-for="col in editColumns"
                    :key="col.dataIndex +'bottom'"
                    v-show="col.optionType == 'right'"
                  >
                    <a-col
                      class="moveParent"
                      draggable="true"
                      @dragstart="dragstartItem(col,'left')"
                      @dragover="dragoverItem(col,$event)"
                      @drop.stop="dropItem(col,'left')"
                    >
                      <a-checkbox :value="col.dataIndex"></a-checkbox>
                      {{col.title}}
                      <span class="ding" style="float: right">
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span style="font-size: 12px">固定到左边</span>
                          </template>
                          <a-icon
                            @click="leftOrRight('left',col.dataIndex)"
                            type="pushpin"
                            style="transform: rotate(-90deg);"
                          ></a-icon>
                        </a-tooltip>
                        <a-tooltip placement="topRight">
                          <template slot="title">
                            <span style="font-size: 12px">取消固定</span>
                          </template>
                          <a-icon
                            @click="leftOrRight('cancel',col.dataIndex)"
                            type="vertical-align-middle"
                          ></a-icon>
                        </a-tooltip>
                      </span>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </h-scroll>
            </template>
            <template slot="title">
              <a-row>
                <a-col>
                  <a-checkbox
                    :indeterminate="!!relShowColumns.length && relShowColumns.length<editColumns.length"
                    :checked="editColumns.length == relShowColumns.length && relShowColumns.length !=0"
                    @change="onCheckAllChange"
                  ></a-checkbox>调整显示的列及顺序
                  <span
                    style="float: right"
                    class="activeLikeButton"
                    @click="resetColumn"
                  >重置</span>
                </a-col>
              </a-row>
            </template>
          </a-popover>
        </a-tooltip>
      </div>
    </div>
    <div>
      <!-- <div class="paddingBo">
        <a-alert type="info" show-icon>
          <template slot="message">
            <span v-if="mRowSelection">已选择 {{mRowSelection.selectedRowKeys.length}} 项:</span>
            当前页共 {{dataSource.length}} 项
          </template>
        </a-alert>
      </div>-->
      <!--   -->
      <!-- <a-table
        class="myTable"
        :columns="relColumns"
        :data-source="dataSource"
        :size="tableSetting.size"
        :loading="loading"
        :scroll="tableSetting.scroll"
        :pagination="defaultPagination"
        :row-selection="mRowSelection"
        @change="change"
        v-bind="$attrs"
        :row-key="rowKey"
        :custom-row="customRow"
        :row-class-name="rowClassName"
      >-->
      <!-- <a-table
        class="my-table"
        :columns="relColumns"
        :data-source="dataSource"
        :loading="loading"
        :scroll="tableSetting.scroll"
        :pagination="defaultPagination"
        :row-selection="mRowSelection"
        @change="change"
        v-bind="$attrs"
        :row-key="rowKey"
        :custom-row="customRow"
        :row-class-name="rowClassName"
      >-->
      <a-table
        class="my-table"
        :columns="relColumns"
        :data-source="dataSource"
        :loading="loading"
        :scroll="tableSetting.scroll"
        :pagination="false"
        :row-selection="isSelection ? rowSelection() : null"
        v-bind="$attrs"
        :row-key="rowKey"
        @change="onTableChange"
      >
        <a
          class="jump_a"
          slot="jump"
          slot-scope="text,index,record,expanded"
          @click="toPath(index,record,expanded)"
        >{{ text }}</a>
        <!-- <template
          v-for="column in columns"
          :slot="column.scopedSlots?column.scopedSlots.customRender:''"
          slot-scope="text,record,index"
        >
          <slot
            :name="column.scopedSlots?column.scopedSlots.customRender:''"
            :record="record"
            :text="text"
            :index="index"
          ></slot>
        </template>-->
        <span slot="btnGroup" slot-scope="text,index,record">
          <a
            class="jump_a"
            v-for="item in index.btnGroup"
            :key="item.title"
            @click="btnClickFun(text,index,record)"
          >{{item.title}}</a>
        </span>
      </a-table>
      <pagination :pager="pagination" :bgType="2" @query="getData" />
    </div>
  </div>
</template>

<script >
export default {
  name: "toolTable",
  props: {
    //是否有选中框
    isSelection: {
      type: Boolean,
      default: false
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Object,
      default: () => {}
    },
    pagination: {
      type: Object,
      default: () => {}
    },
    // size:String,
    batchOperation: {
      type: Array,
      default: () => []
    },
    oneOperation: {
      type: Array,
      default: () => []
    },
    rowKey: {
      type: [String, Function],
      default: "id"
    },
    sumKey: {
      type: String,
      default: "orgCode" //radio
    },
    sumWord: {
      type: String,
      default: "合计" //radio
    },
    disabledKey: {
      type: String,
      default: ""
    },
    disabledVal: {
      type: [String, Number],
      default: ""
    },
    isToolView: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      editColumns: [],
      relColumns: [],
      relShowColumns: [],
      haveLeft: false,
      haveRight: false,
      defaultPagination: {
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30"],
        pageSize: 10,
        // size: "small",
        showQuickJumper: true,
        total: 0
        // showTotal: function(total, range) {
        //   return "第" + range[0] + "条-" + range[1] + "条  共" + total + "条";
        // }
      },
      selectedRowKeys: [],
      selections: [],
      mRowSelection: { selectedRowKeys: [] },
      tableSetting: {
        // size: "small",
        scroll: {
          // x: true,
          y: "calc(100vh - 260px)"
        }
      },
      clickRecord: {},

      dragStartItem: {},
      // dropItem:{},
      scrollSettings: {
        //如果此选项为 true，则当滚动到达边的末尾时，mousewheel 事件将传播到父元素。
        wheelPropagation: false
      }
    };
  },
  watch: {},
  methods: {
    //排序change事件
    onTableChange(pagination, filters, sorter, currentDataSource) {
      this.$emit("change", pagination, filters, sorter, currentDataSource);
    },
    //点击链接回调
    toPath(rowData, idx, columnData) {
      if (!rowData[this.sumKey]) {
        let params = {
          key: columnData.dataIndex,
          data: rowData
        };
        this.$emit("linkClick", params);
      }
    },
    btnClickFun(data) {
      this.$emit("btnClick", data);
    },
    //选中回调
    // onSelectChange(selectedRowKeys, selectedRows) {
    //   this.selectedRowKeys = selectedRowKeys;
    //   this.$emit("rowClick", selectedRows);
    // },
    rowSelection() {
      return {
        type: "checkbox",
        fixed: true,
        columnWidth: 74,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.$emit("rowClick", selectedRows);
        },
        getCheckboxProps: record => ({
          props: {
            // disabled: record.borderedQty == 0, // Column configuration not to be checked
            // disabled: record.model === 'LED50K310X3D', // Column configuration not to be checked
            disabled:
              record[this.sumKey] == this.sumWord ||
              record[this.disabledKey] == this.disabledVal, // Column configuration not to be checked
            name: record.name
          }
        })
        //  getCheckboxProps: record => {
        //      console.log(record)
        //  },
      };
    },
    customRow: function(record) {
      var _that = this;
      return {
        on: {
          // 事件
          click: function() {
            _that.$emit("row-click", record);
          } // 点击行
          // dblclick: (event) => {},
          // contextmenu: (event) => {},
          // mouseenter: (event) => {},  // 鼠标移入行
          // mouseleave: (event) => {}
        }
      };
    },
    dragoverItem: function(s, e) {
      e.preventDefault();
    },
    dragstartItem: function(item) {
      this.dragStartItem = item;
    },
    dropItem: function(item) {
      if (item.optionType != this.dragStartItem.optionType) {
        return;
      }
      var tempIndex = item.index;
      item.index = this.dragStartItem.index;
      this.dragStartItem.index = tempIndex;
      var newData = this.editColumns;
      newData.sort(function(a, b) {
        if (a.optionType == "left" && b.optionType == "left") {
          // _this.haveLeft=true;
          return a.index - b.index;
        }
        if (a.optionType == "right" && b.optionType == "right") {
          // _this.haveRight=true;
          return a.index - b.index;
        }

        if (a.optionType == "left") {
          // _this.haveLeft=true;
          return -1;
        }
        if (a.optionType == "right") {
          // _this.haveRight=true;
          return 1;
        }
        if (b.optionType == "left") {
          // _this.haveLeft=true;
          return 1;
        }
        if (b.optionType == "right") {
          // _this.haveRight=true;
          return -1;
        }

        return a.index - b.index;
      });
      this.editColumns = newData;
      var list = this.relShowColumns;
      var newShowColumns = this.editColumns.filter(function(item) {
        for (var i = 0; i < list.length; i++) {
          if (item.dataIndex == list[i]) {
            return item;
          }
        }
      });
      this.relColumns = newShowColumns;
      this.$emit("tableChange", this.relColumns);
      localStorage.setItem(
        this.$route.path + "TableColumns",
        JSON.stringify(this.relColumns)
      );
    },
    rowClassName: function(record) {
      var rowKeys = "id";
      if (typeof this.rowKey == "string") {
        rowKeys = this.rowKey;
      } else if (typeof this.rowKey == "function") {
        rowKeys = this.rowKey();
      }
      if (record[rowKeys] == this.clickRecord[rowKeys]) {
        return "heightLight";
      }
    },
    toggleRowSelection: function(item, flag) {
      var rowKeys, relItem;

      if (typeof this.rowKey == "string") {
        rowKeys = this.rowKey;
      } else if (typeof this.rowKey == "function") {
        rowKeys = this.rowKey();
      }
      if (typeof item == "string") {
        relItem = item;
      } else {
        relItem = item[rowKeys];
      }

      if (flag) {
        // true  复选框中存在则存在，不存在则添加

        if (!this.mRowSelection.selectedRowKeys.includes(relItem)) {
          // 不存在添加
          this.mRowSelection.selectedRowKeys.push(relItem);
          var target = this.dataSource.filter(function(item) {
            return item[rowKeys] == relItem;
          });
          this.mRowSelection.selections.push(target[0]);
        }
      } else {
        // true  复选框中存在则删除，不存在则不存在
        if (this.mRowSelection.selectedRowKeys.includes(relItem)) {
          // 存在删除
          var index = this.mRowSelection.selectedRowKeys.indexOf(relItem);
          var indexSelection = 0;
          this.mRowSelection.selectedRowKeys.splice(index, 1);
          for (var i = 0; i < this.mRowSelection.selections.length; i++) {
            if (this.mRowSelection.selections[i][rowKeys] == relItem) {
              indexSelection = i;
              break;
            }
          }
          this.mRowSelection.selections.splice(indexSelection, 1);
        }
      }
    },
    // change: function(page) {
    //   this.$emit("change", page.current, page.pageSize);
    //   // app.handleSizeChange(page)
    // },
    getData() {
      this.$emit("query", this.pagination);
    },
    reloadFunc: function() {
      this.$emit("refresh");
    },

    //表格复选框
    selectChange: function(selectedRowKeys, selectedRows) {
      this.$emit("selection-change", selectedRows, selectedRowKeys);
      this.mRowSelection.selectedRowKeys = selectedRowKeys;
      this.mRowSelection.selectedRows = selectedRows;
    },
    //修改表格紧凑程度 default | middle | small
    changeTableSize: function(size) {
      this.tableSetting.size = size;
    },
    dealScrollX: function() {
      if (this.scroll) {
        // let tempWidth = this.relColumns.reduce((total,item)=>total+(item.width || 100),0)
        var tempWidth = this.relColumns.reduce(function(total, item) {
          return total + (item.width || 100);
        }, 0);
        if (tempWidth < this.$el.clientWidth) {
          //剩余的列的宽度不够滚动则取消滚动
          this.tableSetting.scroll.x = false;
          //清掉fiexd
          // this.relColumns.forEach(item=>item.fixed = false)
          this.relColumns.forEach(function(item) {
            item.fixed = false;
          });
        } else {
          this.tableSetting.scroll.x = tempWidth;
          // this.relColumns.forEach(item=>item.fixed = item.optionType)
          this.relColumns.forEach(function(item) {
            item.fixed = item.optionType;
          });
        }

        this.$emit("tableChange", this.relColumns);
        localStorage.setItem(
          this.$route.path + "TableColumns",
          JSON.stringify(this.relColumns)
        );
      }
    },
    changeColumn: function(list) {
      this.relShowColumns = list;
      var newData = this.editColumns.filter(function(item) {
        for (var i = 0; i < list.length; i++) {
          if (item.dataIndex == list[i]) {
            return item;
          }
        }
      });
      this.relColumns = newData;
      this.dealScrollX();
      this.$emit("tableChange", this.relColumns);
      localStorage.setItem(
        this.$route.path + "TableColumns",
        JSON.stringify(this.relColumns)
      );
    },
    // 列设置全选
    onCheckAllChange: function(e) {
      if (e.target.checked) {
        this.relColumns = this.editColumns;
        this.relShowColumns = this.editColumns.map(function(item) {
          return item.dataIndex;
        });
      } else {
        this.relColumns = [];
        this.relShowColumns = [];
      }
      this.$emit("tableChange", this.relColumns);
      localStorage.setItem(
        this.$route.path + "TableColumns",
        JSON.stringify(this.relColumns)
      );
    },
    //左右移动
    leftOrRight: function(option, dataIndex) {
      //  var target =  this.editColumns.filter(item=>{
      //     return
      // })
      this.haveLeft = false;
      this.haveRight = false;
      var _this = this;
      // var newData = [...this.editColumns]
      var newData = [].concat(_this.editColumns);
      var indexTitle = 0;
      for (var i = 0; i < newData.length; i++) {
        if (newData[i].dataIndex == dataIndex) {
          indexTitle = i;
          break;
        }
      }
      var target = newData.splice(indexTitle, 1);
      if (option == "left") {
        target[0].optionType = "left";
        if (this.scroll) {
          target[0].fixed = "left";
        }
        newData.unshift(target[0]);
      }
      if (option == "right") {
        target[0].optionType = "right";

        if (this.scroll) {
          target[0].fixed = "right";
        }
        newData.push(target[0]);
      }
      if (option == "cancel") {
        target[0].fixed = false;
        target[0].optionType = null;
        newData.push(target[0]);
      }

      newData.sort(function(a, b) {
        if (a.optionType == "left" && b.optionType == "left") {
          _this.haveLeft = true;
          return a.index - b.index;
        }
        if (a.optionType == "right" && b.optionType == "right") {
          _this.haveRight = true;
          return a.index - b.index;
        }

        if (a.optionType == "left") {
          _this.haveLeft = true;
          return -1;
        }
        if (a.optionType == "right") {
          _this.haveRight = true;
          return 1;
        }
        if (b.optionType == "left") {
          _this.haveLeft = true;
          return 1;
        }
        if (b.optionType == "right") {
          _this.haveRight = true;
          return -1;
        }

        return a.index - b.index;
      });
      this.editColumns = newData;
      var list = this.relShowColumns;
      var newShowColumns = this.editColumns.filter(function(item) {
        for (var i = 0; i < list.length; i++) {
          if (item.dataIndex == list[i]) {
            return item;
          }
        }
      });
      this.relColumns = newShowColumns;
      this.dealScrollX();
      this.$emit("tableChange", this.relColumns);
      localStorage.setItem(
        this.$route.path + "TableColumns",
        JSON.stringify(this.relColumns)
      );
    },
    resetColumn: function() {
      this.haveLeft = false;
      this.haveRight = false;
      this.editColumns = this.columns.map(function(item, index) {
        item.index = index;
        // return {...item}
        return Object.assign({}, item);
      });

      this.relColumns = this.editColumns;
      this.relShowColumns = this.columns.map(function(item) {
        return item.dataIndex;
      });
      this.$emit("tableChange", this.relColumns);
      localStorage.setItem(
        this.$route.path + "TableColumns",
        JSON.stringify(this.relColumns)
      );
    }
  },
  mounted: function() {
    // this.defaultPagination = {...this.defaultPagination,...this.pagination};
    this.defaultPagination = Object.assign(
      {},
      this.defaultPagination,
      this.pagination
    );
    this.editColumns = this.columns.map(function(item, index) {
      item.index = index;
      if (item.fixed) {
        item.optionType = item.fixed;
      }
      //{...item}
      return Object.assign({}, item);
    });
    this.relColumns = this.editColumns;
    this.relShowColumns = this.editColumns.map(function(item) {
      return item.dataIndex;
    });
    if (this.myRowSelection) {
      // this.mRowSelection = {hideDefaultSelections:true,selectedRowKeys: this.selectedRowKeys,onChange: this.selectChange,...this.myRowSelection,}
      this.mRowSelection = Object.assign(
        {
          hideDefaultSelections: true,
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.selectChange
        },
        this.myRowSelection
      );
    } else {
      this.mRowSelection = null;
    }
    if (this.scroll) {
      this.tableSetting.scroll = this.scroll;
    }

    // fixed: 'left'
  }
};
</script>
<style lang="less">
.tool-table-box {
  position: relative;
  .auto-btn {
    padding: 0 !important;
    position: absolute;
    right: 0;
    top: -84px;
  }
  .jump_a {
    color: #1890ff;
    + .jump_a {
      margin-left: 12px;
    }
  }
}
.table-tool-box {
  // .ant-popover-title{
  //   border-bottom: none;
  // }
  .ant-popover-title {
    min-width: 177px;
    min-height: 32px;
    padding: 20px 16px 12px;
    color: #262626;
    border-bottom: none;
    font-size: 16px;
  }
  .ant-checkbox-wrapper {
    margin-right: 9px;
  }
  .ant-checkbox-group {
    color: #262626;
    line-height: 32px;
    .ant-checkbox-wrapper {
      margin-right: 7px;
    }
  }
  .ant-col {
    padding: 0 6px;
    border-radius: 1px;
  }
  .ding {
    // width: 100px;
  }
  .ant-popover-inner-content {
    padding: 0 5px 16px 16px;
  }
  .checkGroupBox {
    max-height: 320px;
  }
}

.ant-table td {
  white-space: nowrap;
}
.topTitle {
}
.paddingBo {
  // padding-bottom: 10px;
}
// .likeButton {
//   cursor: pointer;
// }
// .likeButton:hover {
//   background: #f5f5f5;
// }
.activeLikeButton {
  color: #1890ff;
  cursor: pointer;
  margin-left: 16px;
}
.topIcon {
  font-size: 16px;
  padding-right: 10px;
}
.popoCheckBox {
  width: 100%;
}
.moveParent .anticon {
  /*display: none;*/
  display: none;
  // + .anticon {
  //   margin-left: 10px;
  // }
}
.moveParent:hover {
  background-color: #f7f7f7;
}
.moveParent:hover .anticon {
  /*display: inline-block;*/
  display: block;
  color: #1890ff;
}

// .heightLight {
//   background: #e6f7ff;
// }
.topTitle .ant-select-selection {
  background-color: #00aaa6;
  border-color: #00aaa6;
}
.popoCheckBox .padBot {
  padding-bottom: 5px;
  font-size: 12px;
  color: #857d7d;
}
.popoCheckBox .paddtop {
  padding-top: 5px;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: 100px;
}

.ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #eee;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    background: transparent;
  }
}

.my-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #eee;
}
.my-table .ant-table-thead > tr > th {
  background-color: #f7f7f7;
}
.my-table
  .ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f6f6f6;
}

.my-table
  .ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.my-table
  .ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.my-table
  .ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.my-table
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: #ecf8f8;
}
.my-table .ant-table-pagination.ant-pagination {
  float: right;
  margin: 24px 0 0;
}

.my-table .ant-pagination .ant-pagination-item {
  border: 1px solid #eeeeee;
  border-radius: 2px;
  line-height: 30px;
}
.my-table .ant-pagination .ant-pagination-prev,
.my-table .ant-pagination .ant-pagination-next,
.my-table .ant-pagination .ant-pagination-jump-prev,
.my-table .ant-pagination .ant-pagination-jump-next {
  border: 1px solid #eeeeee;
  border-radius: 2px;
  line-height: 30px;
}
.my-table .ant-pagination .ant-pagination-item-active {
  background-color: #fff;
  border: 1px solid #00aaa6;
}
.my-table .ant-pagination .ant-pagination-item:focus,
.my-table .ant-pagination .ant-pagination-item:hover {
  background-color: #fff;
  border: 1px solid #00aaa6;
}
.my-table .ant-pagination .ant-pagination-item-active a {
  color: #00aaa6;
}
.my-table .ant-pagination .ant-pagination-item:focus a,
.my-table .ant-pagination .ant-pagination-item:hover a {
  color: #00aaa6;
}
.my-table .ant-select-selection {
  border-radius: 2px;
  box-shadow: none;
  border: 1px solid #eeeeee;
  color: #777;
}
.my-table .ant-pagination-prev:focus,
.my-table .ant-pagination-next:focus .my-table .ant-pagination-prev:hover,
.my-table .ant-pagination-next:hover {
  color: #00aaa6;
  border: 1px solid #00aaa6;
  line-height: 30px;
}
.my-table .ant-pagination-options-quick-jumper input {
  border: 1px solid #eee;
  border-radius: 2px;
  width: 46px;
  box-shadow: none;
}
</style>