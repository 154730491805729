/*
 * @Author: sunzuoyuan
 * @Date: 2020-09-28 14:26:41
 * @LastEditTime: 2020-11-18 20:17:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: B2B\src\router\modules\shoppingCart.js
 */



export default [
    {
      path: "/cart",
      name: "cart",
      redirect: "/cart",
      component: () => import("@/layout/TopDownLayout/Frame.vue"),
      children: [
        {
          path: "/cart",
          component: () => import("@/views/cart/index.vue"),
          meta:{
            title:"购物车",
            keepAlive: false,
            isBarShow:true,
            filterConfigStr:'cartList'
          }
        },
      ]
    }
  ];
  