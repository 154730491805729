/**
 * @description:
 * @Author：songqiang
 * @Date:2020-08-18 11:05
 * @LastEditors:songqiang
 * @FilePath: /Cis/src/Permissions.js
 */

// import Vue from "vue";
import router from "./router";
import store from "./store";

import NProgress from "nprogress"; //progress
import "nprogress/nprogress.css"; //progress style
import {
	getToken
} from "./utils/auth";

import Util from "./utils/utils";

import "@/views/historyList/api";
NProgress.configure({
	showSpinner: false
}); //NProGress config
const whiteList = ["/login", "/index", "/product/detail", "/findePassword", "/getCode", "/reset", "/privacy","/personalInfo","/thirdPartyInfo","/shopPersonalInfo","/shopThirdPartyInfo",
	"/questionnaireEditor", "/questionnaire/answer","/questionnaire/noLoginanswer", "/questionnaire/checkAnswer", "/questionnaireEditorPreview",
	"/intendedMerchant","/IntendedMerchantSuccess", "/marketingActivitiesPage", "/marketingActivitiesNew", "/marketingActivitiesRevelry", "/AugustRefrigerator", "/correctionError","/annualReport", "/mobilePayment",
	'/productDetailH5','/operatingManualH5',"/yearScanResultH5","/reportOfAnnual","/submitEndActivitySuccess","/oldMachineCouponList","/positionInformation","/telephonePermissions","/accessingPhotoAlbums","/accessCamera",
]; //no redirect whitelist

router.beforeEach(async (to, from, next) => {
	//start progress bar
	NProgress.start();

	const hasToken = getToken();
	if (hasToken) {
		//如果登陆状态
		// const hasRoles = store.getters.roles && store.getters.roles.length > 0;
		// if (hasRoles) {
		// store.state.historyList.list = '';
		let itemObj = {
			key: Util.dateFmt(new Date(), "yyyy-MM-dd hh:mm:ss"),
			path: to.path,
			name: to.meta.title,
			time: Util.dateFmt(new Date(), "yyyy-MM-dd hh:mm:ss"),
		};
		// setHistoryList(itemObj)
		//   .then((response) => {
		//此处应该为异步请求 发送给后台
		store.state.historyList.list.push(itemObj);
		// })
		// .catch((error) => {
		//   reject(error);
		// });
		if (to.path == '/') {
			next(`/index`);
		} else {
			next();
		}
		// } else {
		//   const { roles } = await store.dispatch("user/getInfo");

		//   const accessRoutes = await store.dispatch(
		//     "permission/generateRoutes",
		//     roles
		//   );
		//   router.addRoutes(accessRoutes);

		//   next({ ...to, replace: true });
		// }
	} else {
		//白名单
		if (whiteList.indexOf(to.path) !== -1) {
			next();
		} else if (to.path == '/') {
			next(`/index`);
			NProgress.done();
		} else {
			next(`/login?redirect=${to.path}`);
			NProgress.done();
		}
	}
});

router.afterEach(() => {
	NProgress.done();
});
