import http from "@/utils/request";
export function queryAgentOrg(data) {
  return http({
    method: "get",
    url: "/comm/queryAgentOrg.nd",
    data
  });
}
export function cartParam(data) {
  return http({
    method: "get",
    url: "/cart/cartParam.nd?loginType=CS",
    params: data
  });
}

// export function showCartList(data) {
//   return http({
//     method: "get",
//     url: "/cart/showCartList.nd?orgAndGroup=" + data,
//   });
// }
export function showCartList(data) {
  return http({
    method: "get",
    url: "/cart/showCartList.nd",
    params: data
  });
}
// 删除购物车
export function delList(data) {
  return http({
    method: "post",
    url: "/cart/deleteProduct.nd",
    data
  });
}
// 应急下单需要调接口
export function checkChangGuiQty(data) {
  return http({
    method: "post",
    url: "/cart/checkChangGuiQty.nd",
    data
  });
}

export function updateQuantity(data) {
  return http({
    method: "post",
    url: "/cart/updateQuantity.nd",
    data
  });
}
// 更改政策查询折扣 cart/changePolicy.nd


export function getCust(data) {
  return http({
    method: "post",
    url: "/cart/changePolicy.nd",
    data
  });
}

// 清空购物车

export function clearCart(data) {
  return http({
    method: "post",
    url: "/cart/emptyCart.nd",
    data
  });
}
