<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="scanningQrCode"
      :width="540"
      :closable="false"
      centered
      :maskClosable="false"
      :footer="null"
    >
      <div class="modal_close" @click="cancle">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
      </div>
      <div class="modal_title_box">
        <span class="modal_title">
          <i class="modal_title_line"></i>担保合同授权
        </span>
      </div>
      <div class="cancelContent">
        <p class="tips">保存二维码或复制链接，分享给采集对象以完成档案采集</p>
        <div class="imgOutBox">
            <div class="imgBox">
              <img class="deoImg" src="./../../../../src/assets/noticeList/分享采集二维码.png" alt="">
              <p class="save" @click="saveImg" style="cursor:pointer;">保存二维码到本地</p>
            </div>
          </div>
        <div class="copyBox">
          <div class="urlTxt">
            <p class="url">{{httpUrl}}</p>
            <div class="btn"  @click="copy(httpUrl)">
              复制
            </div>
          </div>

        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>

export default {
  name: "scanningQrCode",
  components: {},
  data() {
    return {
      imgSrc:'',
      httpUrl:'https://ent.bestsign.cn/s/ivpuxvnptw',
      imgUrl: require("@/assets/noticeList/分享采集二维码.png")
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
  },

  watch: {



  },
  mounted() {

  },
  methods: {
    saveImg() {
      const a = document.createElement('a');
      let e = new MouseEvent('click');//模拟点击事件
      a.download = '二维码.png'
      a.href = this.imgUrl
      a.click()
    },
    copy(data) {
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.info('复制成功');
      oInput.remove()
    },
    handleOk() {
      this.$emit("update:visible", false);
      this.$emit("cancelReasonHandOk",);
    },
    cancle() {
      this.$emit("update:visible", false);
      this.$forceUpdate()
    },

  }
};
</script>
<style lang="less" scoped>
/deep/ .scanningQrCode {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 50px 120px 34px 120px;
      .cancelContent {
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tips {
          width: 300px;
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #777777;
          line-height: 17px;
          margin-top: 8px;
        }
        .imgOutBox {
          width: 201px;
          height: 217px;
          box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.08);
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          padding:15px 20px 9px 20px;
          margin-top: 20px;
          text-align: center;
          background: #fff;
          .imgBox {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .deoImg {
              width: 160px;
              height: 160px;
            }
            .save {
              width: 112px;
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #00AAA6;
              line-height: 20px;
              text-shadow: 0px 3px 8px rgba(0,0,0,0.08);
              margin-top: 13px;
            }
          }
        }
        .copyBox {
          display: flex;
          flex-direction: row;
          margin-top: 28px;
          .urlTxt {
            width: 360px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #EEEEEE;
            position: relative;
            text-align: left;
            align-items: center;
            .url {
              padding-top: 5px;
              padding-left: 16px;
            }
            .btn {
              width: 54px;
              height: 28px;
              border-radius: 2px;
              position: absolute;
              top: 0px;
              right: 0px;
              padding-top: 5px;
              padding-left: 13px;
              border-left: 1px solid #EEEEEE;
              color: #00AAA6;
              cursor: pointer;
            }
          }

        }
      }

      .modal_close {
        position: absolute;
        width: 42px;
        height: 42px;
        top: 0px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        .modal_title {
          height: 28px;
          font-size: 20px;
          font-weight: 500;
          color: #262626;
          line-height: 28px;
        }
      }
    }

  }
}
</style>


