/*
 * @Author: linheng
 * @Date: 2022-03-14
 * @LastEditTime: 2022-08-11 18:24:38
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 分销商仓库
 * @FilePath: \B2B\src\router\modules\distributorWarehouse.js
 */

export default [
    // 分销商仓库
    {
      path: "/distributorWarehouse",
      name: "distributorWarehouse",
      redirect: "/distributorWarehouse",
      component: () => import("@/layout/TopDownLayout/Frame"),
      children: [{
        path: "/distributorWarehouse/addDistributorWarehouse",
        name: "distributorWarehouse",
        component: () => import("@/views/distributorWarehouse/addDistributorWarehouse/index.vue"),
        meta: {
          title: "新增/编辑仓库", //配置title
          keepAlive: false, //是否缓存
          isBarShow: true,
        },
      },
    //   {
    //     path: "/distributorWarehouse/checkDistributorWarehouse",
    //     name: "checkDistributorWarehouse",
    //     component: () => import("@/views/distributorWarehouse/checkDistributorWarehouse/index.vue"),
    //     meta: {
    //       title: "查看仓库", //配置title
    //       keepAlive: false, //是否缓存
    //       isBarShow: true,
    //     },
    //   },
    ]
    }
  ];
