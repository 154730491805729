<!--
 * @Author: sqk
 * @Date: 2020-08-12 15:51:12
 * @LastEditTime: 2021-01-22 13:38:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\CardItem\CardItem.vue
-->


<template>
  <div
    @click="goDetail(data)"
    class="card-ani-box animated fadeInRight"
    :style="{ animationDelay: (itemIndex + 1) * .1 + 's' }"
  >
    <div class="card-item-box box-shadow">
      <div class="color-line" :style="borderColor ? 'background:' + borderColor : ''"></div>
      <span class="flag" :class="getFlagCls">{{ getFlagTitle }}</span>
      <div class="img-box">
        <!-- <div class="img-box-in" :style="{ backgroundImage: 'url(' + picture + ')' }"></div> -->
        <!-- <img :src="picture" v-real-img="require('@/assets/home/banner.jpg')" alt=""> -->
        <div class="img-box-in">
          <!-- <img :src="picture" :onerror="defaultImg" alt=""> -->
          <h-img :imgUrl="picture" :errorImg="errorImg" isUseBasePath></h-img>
        </div>
      </div>
      <p class="top" :title="productName">{{ productName }}</p>
      <p class="btm" :title="importInfo">{{ importInfo }}</p>
      <h5 class="amount">
        <span v-if="price" class="trueAmount">{{ price }}元</span>
        <span v-if="falseAmount" class="falseAmount">{{ falseAmount }}</span>
      </h5>
    </div>
  </div>
</template>

<script>
import { defaultImgPath } from "@/common/constant.js";
// import { commImgUrl } from "@/common/constant.js";
// 导入api接口
import {
  product, //规格属性接口
  // showProductDesc, //未登录时规格属性接口
  saveCustBrowseRecord //商家浏览记录接口
} from "@/utils/api.js";
export default {
  name: "card-item",
  data() {
    return {
      imgUrl: ""
      // defaultImg: 'this.src="' + require('@/assets/home/7.jpg') + '"'
      // defaultImg: require('@/assets/home/banner.jpg')
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    productName: {
      //产品名称
      type: String,
      default: ""
    },
    importInfo: {
      //卖点信息
      type: String,
      default: ""
    },

    picture: {
      //图片地址
      type: String,
      default: ""
    },
    price: {
      //价格 另外调用接口
      type: [String, Number]
    },

    productCode: {
      //产品Id
      type: String,
      default: ""
    },
    errorImg: {
      //报错图片
      type: String,
      default: ""
    },
    falseAmount: {
      type: [String, Number],
      default: ""
    },
    //活动编号
    activityCode: {
      type: [String, Number],
      default: ""
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    borderColor: {
      type: String,
      default: ""
    }
  },
  mounted() {
    // this.imgUrl = commImgUrl;
    this.defaultImgPath = defaultImgPath;
  },
  computed: {
    getFlagCls: function() {
      let cls = "";
      if (this.data.type == "popularProduct") {
        cls = "type1";
      } else if (this.data.type == "hotProduct") {
        cls = "type2";
      } else if (this.data.type == "newProduct") {
        cls = "type3";
      }
      return cls;
    },
    getFlagTitle: function() {
      let title = "";
      if (this.data.type == "popularProduct") {
        title = "爆款";
      } else if (this.data.type == "hotProduct") {
        title = "热卖";
      } else if (this.data.type == "newProduct") {
        title = "新品";
      }
      return title;
    },
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    }
  },
  methods: {
    //点击跳转
    goDetail(data) {
      // if(this.userInfo){
      //    this.doSaveCustBrowseRecord();
      // }
      //已登录
      if (this.userInfo) {
        if (this.activityCode) {
          //跳转活动页
          this.$router.push({
            path: "/activity",
            query: { activityCode: this.activityCode }
          });
          
        } else {
          //跳转详情或商品列表页
          product({ productId: data.productCode }).then(res => {
            if (res.data.targetPage == "product") {
              this.$router.push({
                path: "/product/detail",
                query: { productId: this.productCode }
              });
            } else if (res.data.targetPage == "productList") {
              this.$router.push({
                path: "/product",
                query: {
                  productId: this.productCode,
                  tagNameL1: this.productName
                }
              });
            } else if (res.data.targetPage == "orgProduct") {
              this.$router.push({
                path: "/product/detail",
                query: { productId: this.productCode }
              });
            }
          });
        }
      } else {
        this.$router.push({
          path: "/product/detail",
          query: { productId: this.productCode }
        });
      }
    },

    //商家浏览接口
    doSaveCustBrowseRecord() {
      let params = {
        productInfoId: this.productCode, //产品id
        custAccountId: this.userInfo.account.id, //商家账号id
        custInfoId: this.userInfo.customer.id, //商家id
        type: 0 //0 pc端 1 小程序
      };
      saveCustBrowseRecord(params)
        .then(res => {

        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.hvr-float-shadow:hover {
  &.animated {
    transform: translateY(-5px) !important;
  }
}
.card-ani-box {
  margin-top: 12px;
  margin-left: 12px;
}
.card-ani-box:nth-of-type(4n + 1) {
  margin-left: 0;
}
.card-item-box {
  width: 288px;
  height: 360px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  .color-line {
    width: 100%;
    height: 2px;
  }
  .img-box {
    width: 100%;
    height: 228px;
    overflow: hidden;
    .img-box-in {
      width: 210px;
      height: 210px;
      margin: 18px auto 0;
      // background: url("../../../assets/home/demo (1).png") no-repeat center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      // border: 1px solid #efefef;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .flag {
    position: absolute;
    top: 0;
    font-size: 12px;
    left: 16px;
    width: 50px;
    height: 20px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: none;
    color: #fff;
    &.type1 {
      background-color: #d9161c;
      display: block;
    }
    &.type2 {
      background-color: #ff8f3c;
      display: block;
    }
    &.type3 {
      background-color: #00b7b3;
      display: block;
    }
  }

  p {
    // text-align: left;
    width: 210px;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p.top {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    line-height: 20px;
    margin-top: 20px;
  }
  p.btm {
    height: 16px;
    margin-top: 4px;
    font-size: 12px;
    color: #777;
    line-height: 16px;
  }
  h5.amount {
    height: 28px;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    margin-top: 16px;
    .trueAmount {
      color: #d9161c;
      font-size: 16px;
    }
    .falseAmount {
      margin-left: 6px;
      color: #aaa;
      font-size: 12px;
      text-decoration: line-through;
    }
  }
}
</style>
