<template>
  <a-modal 
    v-model="visible" 
    @ok="handleOk" 
    @cancel="cancle" 
    @afterClose='cancle'
    wrapClassName="cancelName" 
    :width='648'
    :closable="false"
    centered
    :maskClosable='false'
    :ok-button-props="{ props: { disabled: submitBtn } }"
    >
    <div class="modal_close" @click="cancle">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>填写入库信息
      </span>
    </div>
    <div class="cancelContent">
      <a-form :form="form" ref="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="选择仓库">
          <a-select 
            :allowClear="selsetClear"
            style="width: 300px;height:32px;" 
            placeholder="请选择"
            @change="changeInput"
            v-decorator="['11', { rules: [{ required: true, message: 'Please input your note!' }] }]"
            >
            <a-select-option :value="item.id" v-for="(item,index) in stockData" :key="index">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="选择门店">
          <a-select 
            :allowClear="selsetClear"
            style="width: 300px;height:32px;" 
            placeholder="请选择"
            @change="changeShop"
            v-decorator="['note', { rules: [{ required: true, message: 'Please input your note!' }] }]"
            >
            <a-select-option :value="item.id" v-for="(item,index) in shoplist" :key="index">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </div>
    <div class="cancelInfo">
    
    </div>
  </a-modal>
</template>
<script>
// import { saveEvaluate, getEvaluate, inStockSignle, findBaseData} from "./api";
import { findBaseData } from '@/views/order/progress/api.js';
import service from "@/utils/request";
export default {
  // 示例
  // <cancelreason-modal 
  // :visible.sync="visible"
  // info='提示文字'
  // ></message-modal>
  
  name:'chooseShopWare-modal',
  data(){
    return{
      submitBtn:true,
      selsetClear:false,
      list:[],
      shoplist:[],
      stockData: [],
      isEdit:true,
      shopValye:'',
      reason:'',
      textarea:'',
      form: this.$form.createForm(this, { name: 'advanced_search' }),
    }
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    },
  },
  mounted(){
    this.getStock()
    this.getshop()
  },
  methods:{
    // textChange(e){
    //   if(e.target.value){
    //     this.submitBtn = false
    //   }
    //   this.reason = e.target.value
    // },
    changeInput(value){
     
        this.isEdit = true
        this.reason = value
        this.submitBtn = false
      
    },
    changeShop(value) {

        this.isEdit = true
        this.shopValye = value
        this.submitBtn = false
      
    },
    handleOk(){
        if(!this.reason) {
            this.$message.warning('请选择仓库');
            return
        }
        if(!this.shopValye) {
            this.$message.warning('请选择门店');
            return
        }
      let param = {
        ck:this.reason,
        shop:this.shopValye
      }
      this.$emit('update:visible',false)
      this.$emit('cancelReasonHandOk',param,)
      //  this.$emit('cancelReasonHandOk',this.shopValye)
      this.textarea = ''
      this.form.resetFields();
      this.isEdit = true
    },
    cancle(){
      this.$emit('update:visible',false)
      this.textarea = ''
      this.form.resetFields();
      this.isEdit = true
      this.reason = ''
      this.shopValye = ''
    },
    // cancelReason(){
    //   return service({
    //     method: "post",
    //     url: "/comm/dict.nd?pid=14963960650",
    //   })
    // },
    // cancelReaList(){
    //   this.cancelReason().then(res=>{
    //     console.log(res);
    //     this.list = res.data.list
    //   }).catch(err=>{
    //     console.log(err);
    //   })
    // },

    // 获取仓库数据
    getStock() {
      let data = {
        loginType: "CS",
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "cgrkrkck"
      };
      findBaseData(data).then(res => {
        var obj = JSON.parse(res.data);
        var newArr = [];
        // 重组仓库数据
        obj.data.forEach(item => {
          for (let key in item) {
            newArr.push({
              id: key,
              name: item[key]
            });
          }
        });

        this.stock = newArr;
        this.stockData =  this.stock
      
      });
    },
        // 获取仓库数据
    getshop() {
      let data = {
        loginType: "CS",
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "ssmd"
      };
      findBaseData(data).then(res => {
        var obj = JSON.parse(res.data);
        var newArr = [];
        // 重组仓库数据
        obj.data.forEach(item => {
          for (let key in item) {
            newArr.push({
              id: key,
              name: item[key]
            });
          }
        });

        this.shoplist = newArr;
      });
    },
    
  }
}
</script>
<style lang="less" scoped>
  /deep/ .cancelName{
    font-family: PingFangSC-Medium, PingFang SC;
    
    .ant-modal-content{
      text-align:center;
      border-radius: 10px;
      .ant-modal-body{
        padding:30px 0px 0 40px;
        .cancelContent{
          text-align: left;
          padding:0 40px 0 0;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          .ant-tree-node-selected {
            background-color: red;
          }
          .ant-form {
            line-height: 32px;
          }
          .ant-form-item-control-wrapper{
            margin-left:16px;
          }
          .ant-form-item-control{
            line-height: 32px;
          }
          .ant-form-item-label{
            line-height: 32px;
          }
          .ant-form-item{
            margin-top: 24px;
            margin-bottom:0;
          }
          .messageInfo{
            font-size: 12px;
            font-weight: 400;
            color: #AAAAAA;
            line-height: 12px;
            margin-top:8px;
            margin-left:86px;
          }
        }
        .cancelInfo{
          padding:24px 0 50px 0px;
          text-align: left;
          overflow: hidden;
          .cancleZi{
            width: 78px;
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            line-height: 20px;
            float:left;
            padding-left:11px;
            margin-right:9px;
          }
          .ant-input{
            width: 400px;
            height:88px;
            overflow: auto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
          .ant-input-affix-wrapper{
            width: 400px;
          }
        }
        .modal_close {
          position: absolute;
          top: 0;
          right: 0;
          width: 42px;
          height: 42px;
          line-height: 42px;
          border-radius: 0 10px 0 0;
          text-align: center;
          cursor: pointer;
          .modal_close_icon {
            color: #aaa;
            font-size: 16px;
          }
        }
        .modal_close:hover {
          background: #E4F8F7;
          .modal_close_icon {
            color: #08ABA8;
          }
        }
        .modal_title_box {
          text-align: center;
          padding-bottom:50px;
          .modal_title {
            position: relative;
            color: #262626;
            text-align: center;
            z-index: 10;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing:1px;
            .modal_title_line {
              display: inline-block;
              width: 100%;
              position: absolute;
              top: 13px;
              left: 0;
              border-top: 10px solid #E4F8F7;
              z-index: -10;
            }
          }
        }
      }
      p{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 30px;
      }
      .ant-modal-footer{
        text-align: center;
        border-top:0px solid #fff;
        padding:0px 0 30px;
        .ant-btn{
          width: 120px;
          height:40px;
          border-radius: 4px;
          font-size: 16px;
          color:#777;
        }
        .ant-btn-primary{
          color: #fff;
          background:#00AAA6;
          margin-left:24px;
        }
      }
    }
  }
</style>


