/*
 * @Author: mayu
 * @Date: 2020-09-28 14:26:41
 * @LastEditTime: 2021-03-24 20:58:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: B2B\src\router\modules\shoppingCart.js
 */



export default [
    {
      path: "/basicInfo",
      name: "basicInfo",
      redirect: "/basicInfo",
      component: () => import("@/layout/TopDownLayout/Frame.vue"),
      children: [
        {
          path: "/basicInfo",
          component: () => import("@/views/basicInformation/index.vue"),
            meta:{
              title:"基础管理",
              // keepAlive: false,
              // isBarShow:true,
              // filterConfigStr:'cartList'
            }
          },
        {

          path: "/addPerson",
          name: 'addPerson',
          component: () => import("@/views/basicInformation/components/addPerson/index.vue"),
          meta: {
            title: "新增人员",
           
          }
        },
        {

          path: "/editPerson",
          name: 'editPerson',
          component: () => import("@/views/basicInformation/components/editPerson/index.vue"),
          meta: {
            title: "编辑人员",
           
          }
        },
        {
          path: "/businessAddEdit",
          name: 'businessAddEdit',
          component: () => import("@/views/basicInformation/components/businessAddEdit/index.vue"),
          meta: {
            title: "人员档案",
           
          }
        },
        {
          path: "/newMerchantaddEdit",
          name: 'newMerchantaddEdit',
          component: () => import("@/views/basicInformation/components/newMerchantaddEdit/index.vue"),
          meta: {
            title: "新增/编辑分销商",
          }
        },
        {
          path: "/newMerchantView",
          name: 'newMerchantView',
          component: () => import("@/views/basicInformation/components/newMerchantView/index.vue"),
          meta: {
            title: "查看分销商",
          }
        },
      ]
    }
  ];
  