/*
 * @Author: sq
 * @Date: 2020-10-21 16:41:51
 * @LastEditTime: 2020-11-18 19:55:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\router\modules\coverBuyZone.js
 */

export default [
  // 特惠专区
  {
    path: "/package",
    name: "packages",
    redirect: "/package",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/package",
        component: () => import("@/views/package/index.vue"),
        meta: {
          title: "套购专区",
          keepAlive: false,
          isBarShow:true,
          filterConfigStr:'packageList'
        }
      }
    ]
  }
];
