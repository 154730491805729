<!--
 * @Author: sq
 * @Date: 2020-10-15 16:34:29
 * @LastEditTime: 2021-06-21 11:00:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\CardItemDiszone\index.vue
-->

<template>
  <div
    class="card-ani-box animated fadeInRight"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <div class="card-item-box box-shadow">
      <div class="timedown">
        <div class="fwOrgName">{{ data.fwOrgName }}</div>
        <div class="i-tag">
          <div class="content bg0" v-if="data.statusName == '未开始'">未开始</div>
          <div class="content bg1" v-if="data.statusName == '进行中'">进行中</div>
          <div class="content bg2" v-if="data.statusName == '已结束'">已结束</div>
        </div>
      </div>
      <div class="img-box">
        <div class="img-box-in">
          <h-img
            :imgUrl="data.img"
            :errorImg="data.defaultImg"
            :isUseBasePath="true"
          ></h-img>
        </div>
      </div>
      <div class="top" :title="productName" style="height: 18px; 
          display: flex;
          align-items: center;
          justify-content: space-between;font-size:12px">
        <h5 class="amount">
         <span v-if="price" class="trueAmount">
            <i class="fh">￥</i>
            <span class="before">{{ price ? price.toFixed(2).toString().split('.')[0] : 0 }}</span>
            <i class="dian">.</i>
            <span class="after">{{ price ? price.toFixed(2).toString().split('.')[1] : 0 }}</span>
          </span>
          <span
            v-if="standPrice && standPrice != price"
            class="falseAmount"
          >￥{{ standPrice.toFixed(2) }}</span>

        </h5>
        <div>
          <span style="
              font-weight: 400;
              padding:0 2px;
              border-radius: 2px;
              border: 1px solid #FF8F3C;
              color:#FF8F3C;"
            >批次{{ batch }}
          </span>
        </div>
      </div>
     
      <div class="info-p" style=" text-align:left!important;height:28px;line-height:38px;padding-top: 4px;">
        <span class="ghs" style="color:#777;width: 139px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis" 
          :title="data.reportCode || '--'">{{
          data.reportCode || "--"
        }}</span>
        <div
        class="buy-count animated"
        :class="chooseValue > 0 ? 'fadeIn' : amount_hover"
        ref="amount"
      >
        <slot />
      </div>
      </div>
     
      <div class="line">
        
        <p class="top2" :title="productName">
          <span class="freeShipping"  v-if="data.productLabel && data.productLabel.indexOf('15691143850') !== -1 ">免运费</span>
          <span class="bright"  v-if="data.productLabel && data.productLabel.indexOf('15691143849') !== -1 ">璀璨</span>
          【{{ productName }}】</p>
        <p class="btm2"  :title="importInfo">{{ importInfo||'--'}}</p>
      </div>
      <p class="sysl">
        <span class="num">剩余：{{ inventory }}</span>
        <span class="time">{{data.statusName == '未开始' ? '开始时间' : '结束时间'}}：{{ dateFormat() }}</span>
      </p>
      
    </div>
  </div>
</template>

<script>
// import countdown from "@/views/preferential/components/CountDownNew";
export default {
  name: "card-item-diszone",
  // components: { countdown },
  data() {
    return {
      imgUrl: "",
      amount_hover: ""
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    productName: {
      //产品名称
      type: String,
      default: ""
    },
    importInfo: {
      //卖点信息
      type: String,
      default: ""
    },
    // picture: {
    //   //图片地址
    //   type: String,
    //   default: ""
    // },
    price: {
      //价格
      type: [String, Number]
    },
    standPrice: {
      //提货价
      type: [String, Number]
    },
    inventory: {
      //库存
      type: [String, Number]
    },

    falseAmount: {
      type: [String, Number],
      default: null
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    batch: {
      //批次
      type: String
    },
    chooseValue: {
      //选购数量
      type: Number
    }
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    }
  },
  methods: {
    dateFormat(){
      let time;
      // debugger
      if(this.data.statusName == '未开始'){
        // time = new Date(new Date(this.data.startDate)).getTime()
        time = this.data.startDate;

        
      }else {
        // time = new Date(new Date(this.data.endDate)).getTime() +(24*60*60*1000-1)
        time = this.data.endDate;
      }
      // return this.$util.dateFmt(new Date(time),'yyyy-MM-dd hh:mm')
      return time;
    },
    mouseenter() {
      // if(new Date().getTime()<new Date(this.data.startDate).getTime() || new Date().getTime() > new Date(this.data.endDate).getTime()){
      //   return;
      // }
      if(new Date().getTime()<new Date(this.data.startDate+' 00:00:00').getTime() || new Date().getTime() > new Date(this.data.endDate+' 23:59:59').getTime()){
        return;
      }
      this.amount_hover = "fadeIn";
    },
    mouseleave() {
      // if(new Date().getTime()<new Date(this.data.startDate).getTime() || new Date().getTime() > new Date(this.data.endDate).getTime()){
      //   return;
      // }
      if(new Date().getTime()<new Date(this.data.startDate+' 00:00:00').getTime() || new Date().getTime() > new Date(this.data.endDate+' 23:59:59').getTime()){
        return;
      }
      this.amount_hover = "fadeOut";
    },
    //点击跳转
    goDetail() {
      return false;
    }
  }
};
</script>

<style lang="less" scoped>
.timedown {
  overflow: hidden;
  padding: 16px 16px 0;
  .fwOrgName {
    float: right;
    height: 14px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #00aaa6;
    line-height: 14px;
  }
  .count-down-wrap {
    float: right;
  }
}
.hvr-float-shadow:hover {
  &.animated {
    transform: translateY(-5px) !important;
  }
}
.card-ani-box {
  margin-top: 12px;
  margin-left: 12px;
}
.card-ani-box:nth-of-type(4n + 1) {
  margin-left: 0;
}
.card-item-box {
  width: 288px;
  // height: 395px;
  height: 390px;
  background-color: #fff;
  // cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
  .img-box {
    width: 100%;
    //height: 217px;
    //overflow: hidden;
    margin-top: 6px;
    .img-box-in {
      width: 210px;
      height: 210px;
      margin: 0 auto;
      // background: url("../../../assets/home/demo (1).png") no-repeat center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      // border: 1px solid #efefef;
    }
  }
  .top{
    padding: 0 22px;
    margin-top: 4px;
  }
  .info-p {
    padding: 0 22px;
  }
  p {
     width: 100%;
    
    text-align: left;
    &.info-p {
      display: block;
      font-size: 12px;
      line-height: 12px;
      margin-top: 8px;
      color: #777;
      text-align:left!important;
      .ghs {
        float: left;
        //width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
      .num {
        //width: 200px;
        float: right;
        text-align: right;
      }
    }
    &.top {
      height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: #1E1E1E;
      line-height: 20px;
      margin-top: 10px;
     
     
      span {
        font-size: 12px;
        color: #1890ff;
        width: auto;
        height: 16px;
        line-height: 14px;
        text-align: center;
        border-radius: 2px;
        padding: 0 4px;
        border: 1px solid #1890FF;
        // transform: scale(0.8);
        // padding: 1px 7px 2px 7px;
        // border-radius: 12px 12px 12px 0;
        // background: #e9f4ff;
        height: 16px;
        margin-left: 8px;
      }
    }
    &.btm {
      height: 12px;
      font-size: 12px;
      color: #777;
      line-height: 12px;
      // margin-top: 3px;
      margin-top: 8px;
      // width: 196px;
      padding-right:38px;
    }
    &.sysl{
      font-size: 12px;
      color: #AAAAAA;
      line-height: 12px;
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
       .time{
        max-width: 220px;
        margin-left: 12px;
      }
    }
    
  }

  // h5.amount {
  //   padding: 0 16px;
  //   height: 28px;
  //   font-size: 14px;
  //   font-weight: 500;
  //   line-height: 28px;
  //   margin-top: 6px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   float: left;
  //   .trueAmount {
  //     color: #d9161c;
  //     font-size: 16px;
  //     // font-weight: 600;
  //   }
  //   .falseAmount {
  //     margin-left: 8px;
  //     color: #aaa;
  //     font-size: 16px;
  //     text-decoration: line-through;
  //   }
  // }
  h5.amount {
    // padding: 0 16px;
    // padding: 0 22px;
    height: 28px;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    // margin-top: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // text-align: left;
    .trueAmount {
      //margin-left: 6px;
      color: #d9161c;
      // font-size: 18px;
      // font-weight: 600;
      i,
      span {
        float: left;
      }
       i.fh {
        font-size: 12px;
        width: 12px;
        line-height: 30px;
        height: 28px;
        // margin-right: 2px;
      }
      i.dian{
        display: flex;
        align-items: flex-end;
        height: 29px;
      }
      span.before {
        font-size: 18px;
      }
      span.after {
        font-size: 12px;
        line-height: 32px;
        height: 28px;
      }
    }
    .falseAmount {
      margin-left: 8px;
      color: #777;
      font-size: 12px;
      text-decoration: line-through;
      margin-top: 2px;
    }
  }

  .buy-count {
    // width: 100%;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // background: #ecf8f8;
    // position: absolute;
    // border-radius: 10px 10px 0 0;
    // bottom: -45px;
    // left: 0;
    // padding: 0 24px;
    // box-sizing: border-box;
    // height: 45px;
    // transition: bottom 0.5s;
    // &-title {
    //   font-size: 12px;
    //   color: #00aaa6;
    // }

    width: 96px;
    height: 28px;
    opacity: 0;
    animation-duration: .5s;
    position: absolute;
    right: 22px;
    bottom: 86px;
    // &-con {
    //   width: 120px;
    // }
    /deep/.number-container{
      width: 44px;
    }
  }

  // .amount_active {
  //   bottom: 0;
  // }

  // .amount_hover {
  //   bottom: 0;
  // }
  // .amount_hoverOut {
  //   bottom: -45px;
  // }
}
.i-tag {
  position: absolute;
  top: 0;
  left: 0;

  .content {
    // padding: 0 8px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    color: white;
    width: 50px;
    line-height: 20px;
    height: 20px;
    font-size: 12px;
    text-align: center;
  }

  .bg0 {
    background-color: #FF7474;
  }

  .bg1 {
    background-color: #18D1BC;
  }

  .bg2 {
    background-color: #9BB1D2;
  }
}
.line{
    margin-top: 12px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: left;
    padding: 0 22px;
     .top2 {
      height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: #1e1e1e;
      display: inline;
      margin-left: -6px;
      .freeShipping {
        border: none;
        margin-left: 6px;
        font-size: 12px;
        color: #fff;
        // width: 42px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
        border-radius: 2px;
        padding:0 2px;
        font-weight: 400;
      }
     .bright {
       font-size: 12px;
       color: #433538;
       width: 30px;
       height: 16px;
       text-align: center;
       line-height: 16px;
       background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
       border-radius: 2px;
       margin-left: 4px;
       font-weight: 400;
     }
    }
    .btm2 {
      height: 12px;
      font-size: 12px;
      color: #777;
      margin-top: 8px;
      display: inline;
    }
  }
  .sysl{
    padding: 0 22px;
      font-size: 12px;
      color: #AAAAAA;
      line-height: 12px;
      margin-top: 12px;
       .time{
        max-width: 220px;
        margin-left: 12px;
      }
    }
</style>
