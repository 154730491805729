
export default [
    {
        path: "/agentVerificationActivities",
        name: "agentVerificationActivities",
        redirect: "/agentVerificationActivities",
        component: () => import("@/layout/TopDownLayout/Frame.vue"),
        children: [
        {
            path: "/agentVerificationActivities",
            component: () => import("@/views/agentVerificationActivities/index.vue"),
            meta:{
                title:"代理商市场活动",
            }
        },
        {
            path: "/agentVerificationActivities/activityView",
            component: () => import("@/views/agentVerificationActivities/activityView/index.vue"),
            meta:{
                title:"活动查看",
            }
        },
        {
            path: "/agentVerificationActivities/addOredit",
            component: () => import("@/views/agentVerificationActivities/addOredit/index.vue"),
            meta:{
                title:"活动新增/编辑",
            }
        },
        {
            path: "/agentVerificationActivities/writeOff",
            component: () => import("@/views/agentVerificationActivities/hexiao/index.vue"),
            meta:{
                title:"活动核销",
            }
        },
        
        
        
        ]
    }
];
  