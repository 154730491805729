/*
 * @Description: global 模块入口文件
 * @Version: 2.0
 * @Autor: syc
 * @Date: 2020-05-22 10:53:59
 * @LastEditors: syc
 * @LastEditTime: 2020-06-02 10:16:40
 */

import Vue from "vue";
const files = require.context(".", true, /\.vue$/);

files.keys().forEach(file => {
  let entity = files(file).default;
  Vue.component(entity.name, entity);
});
