
import http from "@/utils/request";


//人员档案详情
export function personnelRecordsManagementDetail(data) {
    return http({
        method: "post",
        url: "/personnelRecordsManagement/detail.nd",
        data: data
    });
}
//详情选择框
export function getSlt(data) {
    return http({
        method: "post",
        url: "/personnelRecordsManagement/params.nd",
        data: data
    });
}

// 新增编辑保存
export function save(data) {
    return http({
        method: "post",
        url: "/personnelRecordsManagement/save.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}

// 新增暂存
export function tempSave(data) {
    return http({
        method: "post",
        url: "/personnelRecordsManagement/tempSave.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}

// 获取单独物料
export function getmarket(data) {
    return http({
        method: "get",
        url: "/customer/getMatklAndShopData.nd",
        data
    });
}

//发起离职流程
export function dimission(data) {
    return http({
        method: "post",
        url: "/personnelRecordsManagement/dimission.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
  }

// 上传接口
export function uploadFile(data) {
    return http({
        method: "get",
        url: "/comm/method=uploadFile.nd",
        data
    });
}


// 企业查询 天眼查
export function callTYC(params) {
    return http({
        method: "get",
        url: "/IntentionCust/callTYC.nd",
        params
    });
}



// // 新增编辑保存
// export function delete (data){
//     return http({
//         method: "post",
//         url: "/personnelRecordsManagement/delete.nd",
//         data
//     });
// }


export function showFullIdNumber(data) {
    return http({
        method: "post",
        url: "/personnelRecordsManagement/showIdCard.nd",
        data: data
    });
}

// 识别身份证
export function ocrRecognizeIdCard(data) {
    return http({
        method: "post",
        url: "/personnelRecordsManagement/ocrRecognizeIdCard.nd",
        data: data
    });
}

// 清理数据库的管辖门店和数据
export function deleteShopByAccount(data) {
    return http({
        method: "post",
        url: "/custbasePermission/deleteShopByAccount.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data: data
    });
}


































































