/*
 * @Author: mayu
 * @Date: 2020-09-28 14:26:41
 * @LastEditTime: 2020-11-26 09:19:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: B2B\src\router\modules\shoppingCart.js
 */



export default [{
    path: "/footerLinke",
    name: "footerLinke",
    component: () => import("@/layout/TopDownLayout/Frame.vue"),
    children: [{
            // 如何下单
            path: "/footerLinke/homeOrderProcess",
            name: 'homeOrderProcess',
            component: () => import("@/views/html/RHXD.vue"),
            meta: {
                title: "信天翁",
                keepAlive: false,
                isBarShow: true,
                // filterConfigStr: 'custTaskList'
            }
        },
        {
            // 支付方式下单
            path: "/footerLinke/homePayMode",
            name: 'homePayMode',
            component: () => import("@/views/html/ZFFS.vue"),
            meta: {
                title: "信天翁",
                keepAlive: false,
                isBarShow: true,
                // filterConfigStr: 'custTaskList'
            }
        },

        {
            // 法律声明
            path: "/footerLinke/homeLegalStatement",
            name: 'homeLegalStatement',
            component: () => import("@/views/html/FLSM.vue"),
            meta: {
                title: "信天翁",
                keepAlive: false,
                isBarShow: true,
                // filterConfigStr: 'custTaskList'
            }
        },

        {
            // 常见问题
            path: "/footerLinke/homeCommonProblem",
            name: 'homeCommonProblem',
            component: () => import("@/views/html/CJWT.vue"),
            meta: {
                title: "信天翁",
                keepAlive: false,
                isBarShow: true,
                // filterConfigStr: 'custTaskList'
            }
        },
        {
            // 售后服务
            path: "/footerLinke/homeService",
            name: 'homeService',
            component: () => import("@/views/html/SHFW.vue"),
            meta: {
                title: "信天翁",
                keepAlive: false,
                isBarShow: true,
                // filterConfigStr: 'custTaskList'
            }
        },
        {
            // 纪检监察举报
            path: "/footerLinke/loginMessageProbabilityCheck",
            name: 'loginMessageProbabilityCheck',
            component: () => import("@/views/html/LXJB.vue"),
            meta: {
                title: "信天翁",
                keepAlive: false,
                isBarShow: true,
                // filterConfigStr: 'custTaskList'
            }
        },
        {
            // 企业简介
            path: "/footerLinke/homeAboutUs",
            name: 'homeAboutUs',
            component: () => import("@/views/html/QYJJ.vue"),
            meta: {
                title: "信天翁",
                keepAlive: false,
                isBarShow: true,
                // filterConfigStr: 'custTaskList'
            }
        },
        {
            // 联系我们
            path: "/footerLinke/homeContactUs",
            name: 'homeContactUs',
            component: () => import("@/views/html/LXWM.vue"),
            meta: {
                title: "信天翁",
                keepAlive: false,
                isBarShow: true,
                // filterConfigStr: 'custTaskList'
            }
        },
    ]
}];