/*
 * @Author: your name
 * @Date: 2020-08-24 16:03:05
 * @LastEditTime: 2020-09-23 15:56:47
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\auth.js
 */
import Cookie from "js-cookie";

const TokenKey = "ssoLoginToken";

const JSESSIONID = "JSESSIONID";

export function setToken(token) {
  return Cookie.set(TokenKey, token);
}

export function getToken() {
  return Cookie.get(TokenKey);
}

export function removeToken() {
  return Cookie.remove(TokenKey);
}

export function removeJession() {
  return Cookie.remove(JSESSIONID);
}
