
// export default [
//   {
//     path: "/mobilePayment",
//     name: "mobilePayment",
//     redirect: "/mobilePayment",
//     component: () => import("@/layout/TopDownLayout/Frame"),
//     children: [
//       {
//         path: "/mobilePayment",
//         component: () => import("@/views/H5Pay/index.vue"),
//         meta: {
//           title: "支付",
//           isMenuHide: true,
//         }
//       }
//     ]
//   }
// ];
export default [
  {
    path: "/mobilePayment",
    name: "mobilePayment",
    component: () => import("@/views/H5Pay/index.vue"),
    meta: {
      title: "支付", //配置title
      keepAlive: false, //是否缓存
      isBarShow: false,
    },
  },
];
