<template>
  <a-modal
    v-model="visible"
    wrapClassName="messageName"
    :width="800"
    :closable="false"
    centered
    :maskClosable="false"
  >
    <img v-for="(item,index) in imgurl" :src="item" :key="index" alt />

    <template slot="footer">
      <a-button class="ant-btn-primary" @click="handleOk">知道了</a-button>
    </template>
  </a-modal>
</template>
<script>
import { loadChange } from "./api";
export default {
  // 示例
  // <message-modal
  // :visible.sync="visible"
  // info='提示文字'
  // ></message-modal>
  name: "img-modal",
  data() {
    return {
      imgurl: []
    };
  },
  watch: {
    imgURL() {
      this.downChange();
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    imgURL: {
      type: Array
    }
  },
  methods: {
    handleOk() {
      this.$emit("update:visible", false);
    },
    downChange() {
      var newArray = [];
      for (let i = 0; i < this.imgURL.length; i++) {
        let data = {
          url: this.imgURL[i]
        };
        newArray.push(loadChange(data));
      }
      var urlIm = [];

      Promise.all(newArray).then(res => {
        for (let y = 0; y < res.length; y++) {
          urlIm.push(window.URL.createObjectURL(res[y].data));
        }
      });
      this.imgurl = urlIm;
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .messageName {
  .ant-modal-content {
    text-align: center;
    // height: 100vh;
    .ant-modal-body {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding:0;
      //padding: 30px 70px 0 70px;
      img {
        width: 100%;
        height: 400px;
        padding:24px;
      }
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:0px 0 30px;
      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
      }
      .ant-btn-primary {
        color: #fff;
        background: #00aaa6;
      }
    }
  }
}
</style>


