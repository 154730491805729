<template>
  <a-modal
    v-model="visible"
    wrapClassName="messageName"
    :width="648"
    centered
    :footer="null"
    :closable="false"
    :maskClosable="false"
  >
    <div
      class="close"
      @mouseenter="closeHover = true"
      @mouseleave="closeHover = false"
    >
      <img
        v-if="!closeHover"
        src="../../../assets/home/close.png"
        @click="close"
      />
      <img
        v-if="closeHover"
        src="../../../assets/home/closeHover.png"
        @click="close"
      />
    </div>

    <div v-if="step == 1" class="step1">
      <div class="title">
        <span>申请注销信天翁账号</span>
      </div>
      <div class="content" style="margin-bottom: 7px!important;">
        <p style="font-size: 16px; font-weight: 600; color: #262626">
          为保障您的信息、财产安全，提交的注销申请生效前，海信信天翁团队将进行以下验证：
        </p>
        <p style="font-size: 16px; font-weight: 600; color: #262626">
          1.账号处于安全状态；
          <span style="font-weight: 400">
            （账号没有被盗，被封等风险；账号一年内无异常行为；账号无任何纠纷。）
          </span>
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-top: 15px;
          "
        >
          2.账务余额已结清；
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-top: 15px;
          "
        >
          3.库存已结清；
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-top: 15px;
          "
        >
          4.订单已全部完成。
        </p>
        <p style=" color: #D9161C;font-size: 14px; margin-top: 5px;height: 20px;line-height: 20px">提示：如果账号绑定了业务档案，注销成功后档案将随之删除。</p>
      </div>
      <div class="tips">
        <a-checkbox :checked="agree" @change="agreeChange"></a-checkbox>
        <span> 申请注销即标识您自愿放弃账号内所有虚拟资产并同意 </span>
        <span @click="step2()">《注销须知》</span>
      </div>
      <div class="footer">
        <a-button class="ant-btn-primary" @click="step3()">申请注销</a-button>
      </div>
    </div>

    <div v-if="step == 2" class="step2">
      <div class="title">
        <span>海信信天翁账号注销须知</span>
      </div>
      <div class="content">
        <p style="font-size: 16px; font-weight: 600; color: #262626">
          注销须知内容：
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-top: 15px;
          "
        >
          您正在进入注销账号的程序，您在注销当前账号后，将放弃以下权益和资产。
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-top: 15px;
          "
        >
          1. 无法使用账号原手机或邮箱登录信天翁及使用服务。
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-top: 15px;
          "
        >
          2. 财务余额无法恢复。
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-top: 15px;
          "
        >
          3. 库存无法恢复。
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-top: 15px;
          "
        >
          4. 订单无法恢复。
        </p>
        <p style="font-size: 16px; color: #262626; margin-top: 15px">
          您理解并同意，海信信天翁无法协助您重新恢复上述服务，您注销账户的行为会给您的售后维权带来诸多不便，请您在操作之前，确保您的账号无任何纠纷，并自行备份信天翁账号相关的所有信息和数据，下载并保存好订单商品和服务的交易凭证、票据等资料，否则您有可能须支付额外的账户和订单查询费用或无法享受相关售后服务。
        </p>
        <p style="font-size: 16px; color: #262626; margin-top: 15px">
          点击“已阅读并同意”按钮，即表示您已阅读并同意《海信信天翁账号注销须知》。
          您在申请注销流程中点击同意前，应当认证阅读《海信信天翁账号注销须知》。请您务必审慎阅读、充分理解协议中的相关条款内容，其中包括：
          1、与您约定免除或限制责任的条款。 2、其他以粗体下划线标识的重要条款。
          如您对本注销须知有任何疑问，可以通过个人中心--互动--咨询投诉，联系我们进行自助咨询。
          注销须知内容：
        </p>
      </div>
      <div class="footer">
        <a-button class="ant-btn-primary" @click="step1()"
          >已阅读并同意</a-button
        >
      </div>
    </div>

    <div v-if="step == 3" :class="codeError ?'step3 codeError' :'step3'">
      <div class="title">
        <span>账户注销</span>
      </div>
      <div class="phone">
        {{ s != 60 ? "申请注销短信验证码已发送至" + phone : "" }}
      </div>
      <div class="input">
        <a-input placeholder="短信验证码" v-model="code" @input="codeError = false"/>
        <div @click="getCode" class="code" :class="s != 60 && 'send'">
          {{ content }}
        </div>
      </div>
      <div class="error" v-if="codeError">
        <img src="../../../assets/home/error.png" />
        <span>您输入的验证码有误！</span>
      </div>
      <div class="footer">
        <a-button class="ant-btn-primary" @click="step4()">下一步</a-button>
      </div>
    </div>

    <div v-if="step == 4" class="step4">
      <div class="title">
        <span>账户注销</span>
      </div>
      <div class="reason">
        <span>*</span>
        <span>注销原因：</span>
        <a-select
          placeholder="请选择"
          style="width: 300px"
          @change="reasonChange"
        >
          <a-select-option
            v-for="(item, idx) of reasons"
            :key="idx"
            :value="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="explain">
        <span>原因说明：</span>
        <a-textarea v-model="explain" placeholder="请输入原因说明" />
      </div>
      <div class="footer">
        <a-button class="ant-btn-primary" @click="step5()">下一步</a-button>
      </div>
    </div>

    <div v-if="step == 5" class="step5">
      <div class="title">
        <span>注销确认</span>
      </div>
      <div class="confirm">
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-bottom: 20px;
          "
        >
          注销后海信信天翁将做以下处理：
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-bottom: 20px;
          "
        >
          1. 将无法使用账号/手机号/邮箱等登录系统。
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-bottom: 20px;
          "
        >
          2. 释放关联账号的手机号、邮箱等基本信息。
        </p>
        <p
          style="
            font-size: 16px;
            font-weight: 600;
            color: #262626;
            margin-bottom: 20px;
          "
        >
          3. 清理账号关联的财务余额、订单、库存、门店和仓库等数据。
        </p>
      </div>
      <div class="footer">
        <a-button class="ant-btn-primary" @click="step6()">确定</a-button>
      </div>
    </div>

    <div v-if="step == 6" class="step6">
      <div class="title">
        <span>注销申请已提交完成</span>
      </div>
      <div class="success">
        <img src="../../../assets/home/logoffSuccess.png" />
        <p>
          管理账号将在48小时内完成审核，审核通过将以短信的形式告知您，请耐心等待
        </p>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { getCode, getReasons, logoffSub, logoffConfirm,checkCode } from "./api.js";
export default {
  // 示例
  // <message-modal
  // :visible.sync="visible"
  // info='提示文字'
  // ></message-modal>
  name: "log-off-modal",
  data() {
    return {
      agree: false,
      step: 1,
      send: false,
      s: 60,
      content: "获取验证码",
      code: "", //验证码
      reasons: [], //原因列表
      reason: "", //理由
      explain: "", //原因
      closeHover: false,
      codeError: false,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getReasons();
  },
  methods: {
    close() {
      this.$emit("close", false);
      this.agree = false;
      this.send = false;
      this.s = 60;
      this.content = "获取验证码";
      this.code = "";
      this.reason = "";
      this.step = 1;
      this.codeError = false;
    },
    handleOk() {
      this.$emit("update:visible", false);
    },
    agreeChange(e) {
      this.agree = e.target.checked;
    },
    step1() {
      this.step = 1;
      this.agree = true;
    },
    step2() {
      this.step = 2;
    },
    step3() {
      if (!this.agree) {
        this.$message.warning("请阅读并同意《注销须知》");
        return;
      }
      this.step = 3;
    },
    step4() {
      if (!this.code) {
        this.$message.warning("请输入验证码");
        return;
      }
      let data = {
          type: 'applyCancelAccount',
          code: this.code
      }
      checkCode(data).then(res => {
          if(res.data.code == 0) {
              this.step = 4;
          } else {
              this.codeError = true;
          }
      })
    },
    step5() {
      if (!this.reason) {
        this.$message.warning("请选择注销原因");
        return;
      }
      this.step = 5;
    },
    step6() {
      let data = {
        code: this.code,
        reason: this.explain ? this.reason + "," + this.explain : this.reason,
      };
      logoffSub(data).then((res) => {
        if (res.data.code == 0) {
          this.step = 6;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    // 获取验证码
    getCode() {
      if (this.s < 60) {
        return;
      }
      let timmer = setInterval(() => {
        if (this.s < 1) {
          clearInterval(timmer);
          this.s = 60;
          this.content = "获取验证码";
          this.send = false;
        } else {
          let s = this.s;
          --s;
          this.s = s;
          this.content = "已发送" + s + "秒";
        }
      }, 1000);
      getCode({ type: "applyCancelAccount" }).then((res) => {
        let data = res.data;
        if (data.code == 0) {
          this.send = true;
        } else {
          this.s = 60;
          this.send = false;
          this.content = "获取验证码";
          clearInterval(timmer);
        }
      });
    },
    // 获取原因
    getReasons() {
      getReasons().then((res) => {
        if (res.data.code == 0) {
          this.reasons = res.data.list;
        }
      });
    },
    reasonChange(e) {
      this.reason = e;
    },
  },
};
</script>
<style lang="less" scoped>
.step2 {
  .content {
    height: 350px;
    overflow: auto;
  }
}
.close {
  position: absolute;
  width: 42px;
  height: 42px;
  top: 0px;
  right: 0px;
  cursor: pointer;
  text-align: center;
  line-height: 42px;
  border-radius: 0px 5px 0px 0px;
  img {
    width: 13px;
    height: 13px;
  }
}
.close:hover {
  background: #e4f8f7;
}
.title {
  span {
    font-size: 20px;
    font-weight: 600;
    color: #262626;
    background: linear-gradient(to bottom, #fff 30%, #e4f8f7);
  }
}
.content {
  margin: 16px 20px;
  box-sizing: border-box;
  text-align: left;
}
.tips {
  text-align: left;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  display: flex;
  align-items: center;
  .ant-checkbox-wrapper {
    margin-right: 5px;
  }
  span:last-child {
    color: #00aaa6;
    font-weight: 400;
    cursor: pointer;
  }
}

.phone {
    position: absolute;
  font-size: 16px;
  font-weight: 400;
  color: #777777;
  top: 78px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.input {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
  input {
    width: 200px;
    height: 40px;
    margin-right: 17px;
  }
  .code {
    width: 104px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 1px solid #00aaa6;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #00aaa6;
    cursor: pointer;
  }
  .send {
    border: 1px solid #e8e8e8;
    color: #aaaaaa;
  }
}

.reason {
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  margin: 40px 0 24px;
  text-align: left;
  padding-left: 70px;
  box-sizing: border-box;
  span:first-child {
    color: #d9161c;
  }

  span:nth-child(2) {
    margin-right: 15px;
  }
}
.explain {
  padding-left: 70px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: flex-start;
  margin-bottom: 70px;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #262626;
    margin-left: 7px;
    margin-right: 15px;
  }
  textarea {
    width: 400px;
    height: 88px;
  }
}
.confirm {
  text-align: left;
  margin: 16px 20px 5px;
}

.success {
  margin: 48px 120px 72px;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  img {
    width: 130px;
    height: 130px;
    margin-bottom: 8px;
  }
}
.footer {
  margin: 16px auto 10px;
  .ant-btn-primary {
    border-radius: 4px;
    font-size: 16px;
    width: 140px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background: #00aaa6;
  }
}
/deep/ .messageName {
  .ant-modal-content {
    text-align: center;
    .ant-modal-body {
      p {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 30px;
      }
    }
  }
}
.step3 {
  position: relative;

  .error {
    position: absolute;
    top: 166px;
    left: 140px;
    img {
      width: 12px;
      height: 12px;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      color: #d9161c;
      line-height: 12px;
      margin-left: 6px;
    }
  }
}
.codeError.step3 {
  input {
    border-color: #d9161c;
  }
}
</style>


