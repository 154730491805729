/*
 * @Author: sqk
 * @Date: 2020-08-07 16:16:59
 * @LastEditTime: 2024-08-23 19:46:30
 * @LastEditors: 尚宏浩 aaa@hisense.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\api.js
 */
import service from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";

//获取供应商、物料组
export function getBaseData() {
  return service({
    method: "get",
    url: "/order/orderList.htm",
  });
}
// 获取列表数据
export function getOrderList(params) {
  return service({
    method: "get",
    url: "/order/list.nd",
    params
  });
}


// 取消整笔订单
export function cancleOrderAll(params) {
  return service({
    method: "post",
    url: "/order/cancelHeader.nd",
    params
  });
}
// 取消商品行
export function cancleOrderLine(params) {
  return service({
    method: "post",
    url: "/order/cancelLine.nd",
    params
  });
}
// 加入购物车
export function addCart(params) {
  return service({
    method: "get",
    url: "/cart/addToCartNew.nd",
    params
  });
}
// 审核单导出
export function exportOrderSap(params) {
  return service({
    method: "post",
    url: "/order/exportSapOrder.nd",
    params,
    responseType: 'blob'
  });
}
//渠道采购订单列表接口
export function getChannelOrderList(data) {
  return dmshttp({
    url: "findDistributorPurchaseOrderList",
    data
  });
}
//渠道采购取消订单接口
export function cancleChannelOrder(data) {
  return dmshttp({
    url: "cancelPurchaseOrderByDistributor",
    data
  });
}
// 订单导出
export function exportOrder(params) {
  return service({
    method: "post",
    url: "/order/exportOrder.nd",
    params,
    responseType: 'blob'
  });
}

export function exportOrderChannel(data) {
  return dmshttp({
    url: "exportDistributorPurchaseOrderList&type=1",
    data,
    responseType: 'blob'
  });
}
export function exportOrderChannelShen(data) {
  return dmshttp({
    url: "exportDistributorPurchaseOrderList&type=1",
    data,
    responseType: 'blob'
  });
}
export function changeTransFlag(data) {
  return service({
      url: '/marketActivity/changeTransFlag.nd',
      method: 'post',
      data
  })
}

// ---------ECPX-----------

/*订单导出-ecpx*/
export function exportOrderEcpx(params) {
  return service({
    method: "post",
    url: "/ecpx/soExport.nd",
    data: params,
    type: 'stringfy',
    contentType: "application/json;charset=UTF-8",
    responseType: 'blob'
  });
}

// 获取列表数据_ecpx
export function getOrderListEcpx(params) {
  return service({
    method: "POST",
    url: "/ecpx/soQuery.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data: params
  });
}

// 查看发票-ecpx
export function getTicketEcpx(params) {
  return service({
    method: "GET",
    url: "/ecpx/invoices.nd",
    contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params
  });
}

// 查询筛选条件
export function getSelectEcpx(params) {
  return service({
    method: "GET",
    url: "/ecpx/soParam.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    params
  })
};

/*查询用什么物流*/
export function getLogisticsEcpx(params) {
  return service({
    method: "GET",
    url: "/ecpx/soDelivery.nd",
    contentType: "application/json;charset=UTF-8",
    params
  })
};
