/*
 * @Author: sqk
 * @Date: 2020-08-07 16:16:59
 * @LastEditTime: 2020-11-18 09:00:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\api.js
 */
import http from "@/utils/request";

export function loadChange(data) {
  return http({
    url: '/custbaseExportCenter/exportCenter.nd',
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    responseType: 'blob',
    method: 'post',
    data
  })
}