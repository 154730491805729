export default [
  {
    path: "/finaceClaim",
    name: "finaceClaim",
    // redirect: "/finaceClaim",
    component: () => import("@/layout/TopDownLayout/Frame"),
    children: [
      {
        path: "/finaceClaim",
        component: () => import("@/views/finaceClaim/index.vue"),
        meta: {
          title: "资金认领",
          keepAlive: false,
          isBarShow: true
        }
      },
      {
        path: "/finaceClaim/claim",
        component: () => import("@/views/finaceClaim/claim/index.vue"),
        meta: {
          title: "资金认领",
          keepAlive: false,
          isBarShow: true
        }
      },
      {
        path: "/finaceClaim/detail",
        component: () => import("@/views/finaceClaim/detail/index.vue"),
        meta: {
          title: "资金认领详情",
          keepAlive: false,
          isBarShow: true
        }
      },
      {
        path: "/assessmentNotice",
        component: () => import("@/views/financialModule/assessmentNotice/index.vue"),
        meta: {
          title: "考核通知单",
          keepAlive: false,
          isBarShow: true
        }
      },
      {
        path: "/assessmentNotice/detail",
        component: () => import("@/views/financialModule/assessmentNotice/detail/index.vue"),
        meta: {
          title: "考核通知单详情",
          keepAlive: false,
          isBarShow: true
        }
      },
      {
        path: "/assessmentNotice/detailZD",
        component: () => import("@/views/financialModule/assessmentNotice/detailZD/index.vue"),
        meta: {
          title: "考核通知单详情",
          keepAlive: false,
          isBarShow: true
        }
      },
    ]
  }
];
